export type Absence = {
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date?: string
  comment?: string
  /**
   * * `late` - Late
   * * `absent_excused` - Absent excused
   * * `absent_unexcused` - Absent unexcused
   * * `sick` - Sick
   */
  absence_type: AbsenceTypeEnum
  sick_report: string
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type?: ClassTypeEnum
  added_by?: string
  last_modified_by?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  source: SourceEnum
  source_display: string
}

export type AbsenceRequest = {
  students: string[]
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date?: string
  comment?: string
  /**
   * * `late` - Late
   * * `absent_excused` - Absent excused
   * * `absent_unexcused` - Absent unexcused
   * * `sick` - Sick
   */
  absence_type: AbsenceTypeEnum
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type?: ClassTypeEnum
  added_by?: string
  last_modified_by?: string
}

/**
 * * `late` - Late
 * * `absent_excused` - Absent excused
 * * `absent_unexcused` - Absent unexcused
 * * `sick` - Sick
 */
export type AbsenceTypeEnum =
  | 'late'
  | 'absent_excused'
  | 'absent_unexcused'
  | 'sick'

export type AcademicAttendance = {
  /** @format uuid */
  id: string
  /** @format uuid */
  lesson: string
  student: StudentInAttendance
  is_present?: boolean
  is_late?: boolean
  is_absent_excused?: boolean
  is_absent_unexcused?: boolean
  is_sick?: boolean
  is_marked_automatically: boolean
  /** @format date-time */
  modified: string
  /** @format date-time */
  marked_at?: string | null
  course_drop: number | null
}

export type AcademicAttendanceAlert = {
  /** @format uuid */
  id: string
  teacher: string
  course: string
  /** @format date */
  lesson_date: string
  lesson_time: string
  /**
   * * `unresolved` - Unresolved
   * * `resolved` - Resolved
   */
  status?: AcademicAttendanceAlertStatusEnum
  is_read?: boolean
  is_notification_sent?: boolean
}

export type AcademicAttendanceAlertRequest = {
  /** @format date */
  lesson_date: string
  /** @minLength 1 */
  lesson_time: string
  /**
   * * `unresolved` - Unresolved
   * * `resolved` - Resolved
   */
  status?: AcademicAttendanceAlertStatusEnum
  is_read?: boolean
  is_notification_sent?: boolean
}

/**
 * * `unresolved` - Unresolved
 * * `resolved` - Resolved
 */
export type AcademicAttendanceAlertStatusEnum = 'unresolved' | 'resolved'

export type AcademicAttendanceRequest = {
  is_present?: boolean
  is_late?: boolean
  is_absent_excused?: boolean
  is_absent_unexcused?: boolean
  is_sick?: boolean
  unlock_edit?: boolean
  /** @format date-time */
  marked_at?: string | null
}

export type AccGrade = {
  id: number
  quarter?: number | null
  aim: number | null
  semester?: number | null
  student_aim_for_course: number
  /** @maxLength 2000 */
  comment?: string
  /** @format uuid */
  course: string
  grade: string | null
  target_grade_status: TargetGradeStatusEnum
  weighted_percents: number | null
  course_str: string
  student_str: string
  /** @format uuid */
  student: string
  target_grade: string | null
  teacher_str: string
  teacher_id: string
  custom_grade?: CustomGradeEnum | BlankEnum
  course_drop_id: number | null
}

export type AccGradeRequest = {
  quarter?: number | null
  semester?: number | null
  /** @maxLength 2000 */
  comment?: string
  /** @format uuid */
  course: string
  /** @format uuid */
  student: string
  custom_grade?: CustomGradeEnum | BlankEnum
}

/**
 * * `green_paper` - Green paper
 * * `pink_paper` - Pink paper
 * * `breaks` - Breaks
 * * `prompt` - Prompt
 * * `use_laptop` - Use of laptop
 * * `reader_pen` - Reader pen
 * * `own_room` - Own room
 */
export type AccessArrangementsEnum =
  | 'green_paper'
  | 'pink_paper'
  | 'breaks'
  | 'prompt'
  | 'use_laptop'
  | 'reader_pen'
  | 'own_room'

export type Accommodation = {
  id: number
  /** @format uuid */
  student: string
  student_str: string
  room: number
  /** @format date */
  start_date: string
  /** @format date */
  end_date?: string | null
  is_temporary_movement?: boolean
  /**
   * * `isolation` - Isolation
   * * `quarantine` - Quarantine
   * * `sleepover` - Sleepover
   */
  temporary_movement_type: TemporaryMovementTypeEnum
  temporary_movement_type_display: string
  house_str: string
  house: number
  room_str: string
  room_code: string
  room_name: string
  /** @format date-time */
  deleted: string | null
  is_room_only_for_student?: boolean
}

export type AccommodationRequest = {
  /** @format uuid */
  student: string
  room: number
  /** @format date */
  start_date: string
  /** @format date */
  end_date?: string | null
  is_temporary_movement?: boolean
  /**
   * * `isolation` - Isolation
   * * `quarantine` - Quarantine
   * * `sleepover` - Sleepover
   */
  temporary_movement_type: TemporaryMovementTypeEnum
  is_room_only_for_student?: boolean
  added_by?: string
  last_modified_by?: string
}

export type AccountOverdueEnum =
  | 'settled'
  | 'first_reminder'
  | 'second_reminder'
  | 'app_locked'
export type ActivityMove = {
  /** @format uuid */
  activity: string
  hour: number
  hour_display: string
  day_display: string
  day: string
  duration: string
  artisans_off_work: SimpleUser[]
}

export type AdminAbsenceRecovery = {
  id: string
  polymorphic_ctype: number | null
  /** @format date-time */
  datetime_from: string
  /** @format date-time */
  datetime_to: string
  school_programme: SchoolProgrammeRecovery
  programme_attendance_report: ProgrammeAttendanceReportRecovery
  assigned_to_str: string
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /** via annotate */
  location: string
  is_editable: boolean
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype: RecoveryResourcetypeEnum
}

export type AdminAbsenceRecoveryRequest = {
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype: RecoveryResourcetypeEnum
}

export type AdminAdditionalAusgang = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   * * `expired` - Expired
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  leave_details: string
  /** @maxLength 128 */
  absence_name: string
  participants: MeritRewardParticipant[]
  decision_comment?: string
  /** @format uuid */
  campus_absence?: string | null
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}
export type AdminApproveIdpPlan = {
  /** @format date */
  parent_approval_deadline?: string | null
  parent_approval_needed?: boolean
}

export type AdminApproveIdpPlanRequest = {
  /** @format date */
  parent_approval_deadline?: string | null
  parent_approval_needed?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminBoard = {
  id: string
  created_by?: string
  widget: AdminWidget
  show_in_idp_app: boolean
  show_in_parents_app: boolean
  app_visibility: AppVisibilityEnum
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 128 */
  name: string
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_from: string
  /** @format date-time */
  display_to?: string | null
  publish_on_infoboards?: boolean
  widgets_str: string[]
  widget_str: string
  /**
   * * `square` - Square
   * * `framed` - Framed
   * * `fullscreen` - Fullscreen
   */
  layout?: LayoutEnum
  /**
   * @min -2147483648
   * @max 2147483647
   */
  display_time?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  sort_value?: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminBoardDraft = {
  id: string
  /** @maxLength 128 */
  name?: string
  created_by?: string
  widget: AdminWidget
  /** @format date-time */
  display_from?: string | null
  /** @format date-time */
  display_to?: string | null
  publish_on_infoboards?: boolean
  /**
   * * `square` - Square
   * * `framed` - Framed
   * * `fullscreen` - Fullscreen
   */
  layout?: LayoutEnum
  /**
   * @min -2147483648
   * @max 2147483647
   */
  display_time?: number
  status: WidgetStatusEnum
  widget_str: string
  show_in_idp_app: boolean
  show_in_parents_app: boolean
}

export type AdminBoardDraftList = {
  id: string
  /** @maxLength 128 */
  name: string
  /** @maxLength 128 */
  widget_str: string
  /** @format date-time */
  created: string
  created_by: string
  /**
   * * `unpublished` - Unpublished
   * * `published` - Published
   * * `scheduled` - Scheduled
   * * `draft` - Draft
   */
  status?: WidgetStatusEnum
  show_in_idp_app: boolean
  show_in_parents_app: boolean
  publish_on_infoboards?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminBoardDraftRequest = {
  /** @maxLength 128 */
  name?: string
  created_by?: string
  widget: AdminWidgetRequest
  /** @format date-time */
  display_from?: string | null
  /** @format date-time */
  display_to?: string | null
  publish_on_infoboards?: boolean
  /**
   * * `square` - Square
   * * `framed` - Framed
   * * `fullscreen` - Fullscreen
   */
  layout?: LayoutEnum
  /**
   * @min -2147483648
   * @max 2147483647
   */
  display_time?: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminBoardRequest = {
  created_by?: string
  widget: AdminWidgetRequest
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_from: string
  /** @format date-time */
  display_to?: string | null
  publish_on_infoboards?: boolean
  /**
   * * `square` - Square
   * * `framed` - Framed
   * * `fullscreen` - Fullscreen
   */
  layout?: LayoutEnum
  /**
   * @min -2147483648
   * @max 2147483647
   */
  display_time?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  sort_value?: number
}
export type AdminBoardingAnnouncementWidget = {
  id: string
  /** @default "#FD8F5E" */
  bg_color: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /** @maxLength 90 */
  text: string
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminBoardingAnnouncementWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  text: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminBookTheCafe = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   * * `expired` - Expired
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  /** @maxLength 128 */
  movie: string
  participants: MeritRewardParticipant[]
  decision_comment?: string
  /** @format uuid */
  campus_absence?: string | null
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type AdminCasualAnyday = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   * * `expired` - Expired
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  decision_comment?: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type AdminCategoryUpgrade = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   * * `expired` - Expired
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  decision_comment?: string
  category_before_upgrade: string
  category_after_upgrade: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type AdminCelebrationWidget = {
  id: string
  /** @default "#FF607F" */
  bg_color: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  polymorphic_ctype: number | null
  students: string[]
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminCelebrationWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminCeleryTaskStatus = {
  id: number
  /** @format uuid */
  uuid: string
  timetable: number | null
  /** @format date-time */
  started_at: string
  /** @format date-time */
  ended_at: string | null
  execution_time: string
  state: StateEnum
  history: AdminCeleryTaskStatusHistory[]
  task_arguments: AdminCeleryTaskStatusTaskArguments | null
}

export type AdminCeleryTaskStatusHistory = {
  id: number
  state: StateEnum
  done_in: number
  /**
   * time when state was changed
   * @format date-time
   */
  timestamp: string
  /**
   * in seconds
   * @format decimal
   * @pattern ^-?\d{0,9}(?:\.\d{0,2})?$
   */
  execution_time: string
}

export type AdminCeleryTaskStatusList = {
  id: number
  /** @format uuid */
  uuid: string
  timetable: number | null
  /** @format date-time */
  started_at: string
  /** @format date-time */
  ended_at: string | null
  execution_time: string
  state: StateEnum
  /** via annotate */
  latest_state: string | null
}

export type AdminCeleryTaskStatusTaskArguments = {
  /** @format date */
  start_date: string
  /** @format date */
  today: string
  weeks_to_generate: number
  /**
   * * `ImportSource.MANUAL` - ImportSource.MANUAL
   * * `ImportSource.AUTOMATIC` - ImportSource.AUTOMATIC
   */
  import_source: ImportSourceEnum
  user: SimpleUser | null
}

export type AdminCityBrunchZurich = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   * * `expired` - Expired
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  participants: MeritRewardParticipant[]
  decision_comment?: string
  /** @format uuid */
  campus_absence?: string | null
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type AdminClockWidget = {
  id: string
  /** @default "#000000" */
  bg_color: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminClockWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminCongratulationsWidget = {
  id: string
  /** @default "#FFFFFF" */
  bg_color: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminCongratulationsWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminDashboardUser = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventAgendaWidget = {
  id: string
  /** @default "#5965EF" */
  bg_color: string
  agenda_items: AgendaItem[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /** @maxLength 90 */
  header: string
  /** @format date */
  date_from: string
  /** @format date */
  date_to?: string | null
  /** @format time */
  time_from: string
  /** @format time */
  time_to: string
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventAgendaWidgetRequest = {
  agenda_items: AgendaItemRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  header: string
  /** @format date */
  date_from: string
  /** @format date */
  date_to?: string | null
  /** @format time */
  time_from: string
  /** @format time */
  time_to: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventInfoWidget = {
  id: string
  bg_color: string
  link?: AdminLinkWithQRCode | null
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /** @maxLength 90 */
  header: string
  /** @maxLength 400 */
  message?: string
  /** @maxLength 64 */
  classroom_shortcut?: string
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventInfoWidgetRequest = {
  /** @minLength 1 */
  bg_color: string
  link?: AdminLinkWithQRCodeRequest | null
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  header: string
  /** @maxLength 400 */
  message?: string
  /** @maxLength 64 */
  classroom_shortcut?: string
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventInfoWithPhotoWidget = {
  id: string
  bg_color: string
  link?: AdminLinkWithQRCode | null
  photo_data: Image
  photo_in_my_rosenberg_idp_data: Image
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /** @maxLength 100 */
  header: string
  include_message?: boolean
  /** @maxLength 400 */
  message?: string
  crop_my_rosenberg_idp_photo_separately?: boolean
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  polymorphic_ctype: number | null
  photo: string
  photo_in_my_rosenberg_idp?: string | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventInfoWithPhotoWidgetRequest = {
  /** @minLength 1 */
  bg_color: string
  link?: AdminLinkWithQRCodeRequest | null
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * @minLength 1
   * @maxLength 100
   */
  header: string
  include_message?: boolean
  /** @maxLength 400 */
  message?: string
  crop_my_rosenberg_idp_photo_separately?: boolean
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  /** @minLength 1 */
  photo: string
  /** @minLength 1 */
  photo_in_my_rosenberg_idp?: string | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}
export type AdminImportRequest = {
  /**
   * @min 1
   * @max 4
   */
  num_of_weeks_to_generate: number
  /** @format date */
  start_date: string
  timetable: number
}

export type AdminImportResponse = {
  task_id: string
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminJournalItem = {
  id: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  article_url: AdminLinkWithQRCode
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminJournalItemRequest = {
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  article_url: AdminLinkWithQRCodeRequest
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminJournalWidget = {
  id: string
  /** @default "#EBCFCF" */
  bg_color: string
  journal_items: AdminJournalItem[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminJournalWidgetRequest = {
  journal_items: AdminJournalItemRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminLinkWithQRCode = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /**
   * @format uri
   * @maxLength 200
   */
  url: string
  /** @format uri */
  qr_code: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminLinkWithQRCodeRequest = {
  /**
   * @format uri
   * @minLength 1
   * @maxLength 200
   */
  url: string
}

export type AdminLunchtimeRecovery = {
  id: string
  polymorphic_ctype: number | null
  /** @format date-time */
  datetime_from: string
  /** @format date-time */
  datetime_to: string
  school_programme: SchoolProgrammeRecovery
  programme_attendance_report: ProgrammeAttendanceReportRecovery
  assigned_to_str: string
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /** via annotate */
  location: string
  is_editable: boolean
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype: RecoveryResourcetypeEnum
}

export type AdminLunchtimeRecoveryRequest = {
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype: RecoveryResourcetypeEnum
}

export type AdminMenuWidget = {
  id: string
  /** @default "#00AC69" */
  bg_color: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  polymorphic_ctype: number | null
  menu?: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminMenuWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  menu?: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminMeritReward =
  | AdminCasualAnyday
  | AdminRoomService
  | AdminBookTheCafe
  | AdminCityBrunchZurich
  | AdminAdditionalAusgang
  | AdminCategoryUpgrade
  | AdminStopTheClock
export type AdminMysteryLinkWidget = {
  id: string
  /** @default "#9561E8" */
  bg_color: string
  approved_mystery_links: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminMysteryLinkWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminParentApproveIdpPlanRequest = {
  /** @minLength 1 */
  parent_approve_comment: string
}

export type AdminPosterWidget = {
  id: string
  /** @default "#808080" */
  bg_color: string
  image_data: Image
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  polymorphic_ctype: number | null
  image: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminPosterWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /** @minLength 1 */
  image: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminQuarter = {
  id: number
  /** @maxLength 32 */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semester?: number
}

export type AdminQuarterRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semester?: number
}

export type AdminRecovery = AdminAbsenceRecovery | AdminLunchtimeRecovery

export type AdminRecoveryRequest =
  | AdminAbsenceRecoveryRequest
  | AdminLunchtimeRecoveryRequest

export type AdminRoomService = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   * * `expired` - Expired
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  house: string
  room: string
  decision_comment?: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminSchoolYear = {
  id: number
  /** @maxLength 32 */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semesters: AdminSemester[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminSchoolYearRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semesters: AdminSemesterRequest[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminSemester = {
  id: number
  /** @maxLength 32 */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  year?: number | null
  quarters: AdminQuarter[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminSemesterRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  year?: number | null
  quarters: AdminQuarterRequest[]
}
export type AdminSiteSettings = {
  id: number
  /** Allow teachers to set target grades */
  target_grades_set_allowed?: boolean
  /**
   * Setting target grades starts at
   * @format date-time
   */
  target_grades_start?: string | null
  /**
   * Setting target grades ends at
   * @format date-time
   */
  target_grades_end?: string | null
  /** Edit values, or set from pending only */
  target_grades_set_all?: boolean
  /**
   * Disable generating tasks from datetime
   * @format date-time
   */
  disable_generating_tasks_from?: string | null
  /**
   * Disable generating tasks to datetime
   * @format date-time
   */
  disable_generating_tasks_to?: string | null
  /** Disable generating take student to destination task */
  disable_take_student_to_destination_task?: boolean
}

export type AdminSiteSettingsRequest = {
  /** Allow teachers to set target grades */
  target_grades_set_allowed?: boolean
  /**
   * Setting target grades starts at
   * @format date-time
   */
  target_grades_start?: string | null
  /**
   * Setting target grades ends at
   * @format date-time
   */
  target_grades_end?: string | null
  /** Edit values, or set from pending only */
  target_grades_set_all?: boolean
  /**
   * Disable generating tasks from datetime
   * @format date-time
   */
  disable_generating_tasks_from?: string | null
  /**
   * Disable generating tasks to datetime
   * @format date-time
   */
  disable_generating_tasks_to?: string | null
  /** Disable generating take student to destination task */
  disable_take_student_to_destination_task?: boolean
}

export type AdminStopTheClock = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   * * `expired` - Expired
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  decision_comment?: string
  original_date: string
  prolonged_date: string
  /** @format uuid */
  campus_absence?: string | null
  participants: SimpleCampusAbsenceStudent[]
  leave_details: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminStudentAnnouncement = {
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  emojis?: string[]
  /** @maxLength 100 */
  text: string
  /** @maxLength 7 */
  bg_color?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  verbose_name: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

export type AdminStudentAnnouncementWidget = {
  id: string
  /** @default "#FFFFFF" */
  bg_color: string
  approved_student_announcements: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminStudentAnnouncementWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}
export type AdminStudentArt = {
  id: number
  image: string
  image_data: Image
  /** @maxLength 255 */
  name: string
  /** @maxLength 255 */
  author?: string
  /**
   * * `student` - Student
   * * `author` - Author
   */
  author_type: AuthorTypeEnum
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
  /** @format uuid */
  student?: string | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminStudentArtGalleryWidget = {
  id: string
  /** @default "#808080" */
  bg_color: string
  arts: AdminStudentArt[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminStudentArtGalleryWidgetRequest = {
  arts: AdminStudentArtRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminStudentArtRequest = {
  /** @minLength 1 */
  image: string
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string
  /** @maxLength 255 */
  author?: string
  /**
   * * `student` - Student
   * * `author` - Author
   */
  author_type: AuthorTypeEnum
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
  /** @format uuid */
  student?: string | null
}

export type AdminStudentContent =
  | AdminStudentMysteryLink
  | AdminStudentAnnouncement
  | AdminStudentPic

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminStudentMysteryLink = {
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  link: AdminLinkWithQRCode
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  verbose_name: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminStudentPic = {
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: Image
  /** @maxLength 50 */
  title?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  verbose_name: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

export type AdminStudentsPicWidget = {
  id: string
  /** @default "#FFFFFF" */
  bg_color: string
  approved_student_pics: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminStudentsPicWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminTeacher = {
  id: string
  username: string
  /** @format email */
  email: string
  first_name: string
  last_name: string
  full_name: string
  /** @maxLength 16 */
  acronym: string
  phone_number?: string
  departments?: number[]
  departments_str: string[]
  preferred_rooms?: number[]
  preferred_rooms_str: string[]
  two_factor_auth?: boolean
  /** Teacher hidden in timetable generator */
  is_hidden_in_generator?: boolean
  custom_schedule?: Record<string, any>
  is_active: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminTeacherRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  first_name: string
  /** @minLength 1 */
  last_name: string
  /**
   * @minLength 1
   * @maxLength 16
   */
  acronym: string
  /** @minLength 1 */
  phone_number?: string
  departments?: number[]
  preferred_rooms?: number[]
  two_factor_auth?: boolean
  /** Teacher hidden in timetable generator */
  is_hidden_in_generator?: boolean
  custom_schedule?: Record<string, any>
  is_active: boolean
}

export type AdminTimetable =
  | TimetableExam
  | TimetableLesson
  | TimetableSchoolProgramme
  | TimetableRotaActivity

export type AdminTimetableCourse = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  type: string
  level: string
  departments: string[]
}
export type AdminTimetableLesson = {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  start: string
  end: string
  display_hour: string
  course: AdminTimetableCourse
  room: AdminTimetableRoom
  is_archived?: boolean
  /** @format uuid */
  teacher: string
  /** @format uuid */
  teacher_cover?: string | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  teacher_details: SimpleUser
  teacher_cover_details?: SimpleUser | null
  co_teacher_1_details: SimpleUser
  co_teacher_2_details: SimpleUser
}

export type AdminTimetableLessonRequest = {
  /** @format date */
  date: string
  is_archived?: boolean
  /** @format uuid */
  teacher: string
  /** @format uuid */
  teacher_cover?: string | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
}

export type AdminTimetableLessonRoomAssignment = {
  room?: number | null
}

export type AdminTimetableLessonRoomAssignmentRequest = {
  room?: number | null
}

export type AdminTimetableLessonUpdate = {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  start: string
  end: string
  is_archived: boolean
  /** @format uuid */
  teacher: string
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2: string | null
  /** @format uuid */
  teacher_cover?: string | null
}

export type AdminTimetableLessonUpdateRequest = {
  /** @format uuid */
  teacher_cover?: string | null
}

/**
 * * `Exam` - Exam
 * * `Lesson` - Lesson
 * * `SchoolProgramme` - SchoolProgramme
 * * `Rota` - Rota
 */
export type AdminTimetableResourcetypeEnum =
  | 'Exam'
  | 'Lesson'
  | 'SchoolProgramme'
  | 'Rota'

export type AdminTimetableRoom = {
  id: number
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
}
export type AdminTypes = {
  label: string
  resourcetype: ResourcetypeEnum
  polymorphic_ctype: number
  initial_data: AdminWidget
  /** @default "" */
  require_module: string
  thumbnail: string | null
  allowed_layouts: string
  use_default_bg_color: string
}

export type AdminUser = {
  /** @format uuid */
  id: string
  academichod: UserAcademicHODProfile | null
  academicadmin: UserAcademicAdminProfile | null
  idpadmin: UserIdpAdminProfile | null
  admin: UserAdminProfile | null
  teacher: UserTeacherProfile | null
  boardingdirector: UserBoardingDirectorProfile | null
  healthcoordinator: UserHealthCoordinatorProfile | null
  headofhouse: HeadOfHouseProfile | null
  housetutor: HouseTutorProfile | null
  nightguard: NightGuardProfile | null
  viewer: ViewerProfile | null
  campusheadteam: CampusHeadTeamProfile | null
  propertymanager: PropertyManagerProfile | null
  infoboardeditor: InfoboardEditorProfile | null
  behaviourmanagement: BehaviourManagementProfile | null
  securityteam: SecurityTeamProfile | null
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string
  first_name: string
  last_name: string
  /** @format email */
  email: string
  phone_number: string
  two_factor_auth?: boolean
  full_name: string
  /** @format date-time */
  last_login?: string | null
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  /** @format date-time */
  date_joined?: string
  search?: string | null
  searchable_content?: string
  profiles: string[]
  /** @maxLength 256 */
  name?: string
  gender?: GenderEnum | BlankEnum
  disable_access_to_idp?: boolean
  staff_type: StaffTypeEnum | BlankEnum
  can_handle_generator?: boolean
  is_vaccinated?: boolean
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  accept_campus_notifications?: boolean
  accept_education_notifications?: boolean
  accept_reminders_notifications?: boolean
  accept_dashboard_notifications?: boolean
  cannot_use_sms_login?: boolean
  can_login_as_student: boolean
  can_report_bug: boolean
  is_teacher: boolean
  is_student: boolean
  is_parent: boolean
}

export type AdminUserChangeRequest = {
  academichod?: UserAcademicHODProfileRequest | null
  academicadmin?: UserAcademicAdminProfileRequest | null
  idpadmin?: UserIdpAdminProfileRequest | null
  admin?: UserAdminProfileRequest | null
  teacher?: UserTeacherProfileRequest | null
  boardingdirector?: UserBoardingDirectorProfileRequest | null
  healthcoordinator?: UserHealthCoordinatorProfileRequest | null
  headofhouse?: HeadOfHouseProfileRequest | null
  housetutor?: HouseTutorProfileRequest | null
  nightguard?: NightGuardProfileRequest | null
  viewer?: ViewerProfileRequest | null
  campusheadteam?: CampusHeadTeamProfileRequest | null
  propertymanager?: PropertyManagerProfileRequest | null
  infoboardeditor?: InfoboardEditorProfileRequest | null
  behaviourmanagement?: BehaviourManagementProfileRequest | null
  securityteam?: SecurityTeamProfileRequest | null
  /** @minLength 1 */
  password1?: string
  /** @minLength 1 */
  password2?: string
  /** @minLength 1 */
  first_name: string
  /** @minLength 1 */
  last_name: string
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  phone_number: string
  two_factor_auth?: boolean
  /** @format date-time */
  last_login?: string | null
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  /** @format date-time */
  date_joined?: string
  search?: string | null
  searchable_content?: string
  /** @maxLength 256 */
  name?: string
  gender?: GenderEnum | BlankEnum
  disable_access_to_idp?: boolean
  staff_type: StaffTypeEnum | BlankEnum
  can_handle_generator?: boolean
  is_vaccinated?: boolean
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  accept_campus_notifications?: boolean
  accept_education_notifications?: boolean
  accept_reminders_notifications?: boolean
  accept_dashboard_notifications?: boolean
  cannot_use_sms_login?: boolean
}

export type AdminUserList = {
  /** @format uuid */
  id: string
  full_name: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups?: number[]
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  /** @format date-time */
  date_joined?: string
  /** @format date-time */
  last_login?: string | null
  staff_type?: StaffTypeEnum | BlankEnum
}

export type AdminUserPasswordChange = {
  /** @maxLength 128 */
  new_password1: string
  /** @maxLength 128 */
  new_password2: string
}

export type AdminUserPasswordChangeRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  new_password1: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  new_password2: string
}

export type AdminUserRequest = {
  /** @minLength 1 */
  password1: string
  /** @minLength 1 */
  password2: string
  /** @minLength 1 */
  first_name: string
  /** @minLength 1 */
  last_name: string
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  phone_number: string
  two_factor_auth?: boolean
  /** @format date-time */
  last_login?: string | null
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  /** @format date-time */
  date_joined?: string
  search?: string | null
  searchable_content?: string
  /** @maxLength 256 */
  name?: string
  gender?: GenderEnum | BlankEnum
  disable_access_to_idp?: boolean
  staff_type: StaffTypeEnum | BlankEnum
  can_handle_generator?: boolean
  is_vaccinated?: boolean
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  accept_campus_notifications?: boolean
  accept_education_notifications?: boolean
  accept_reminders_notifications?: boolean
  accept_dashboard_notifications?: boolean
  cannot_use_sms_login?: boolean
}

export type AdminVariantManualCongratulations = {
  id: number
  /**
   * * `wreath` - Wreath
   * * `cap` - Cap
   */
  variant: VariantEnum
  images: string[]
  images_data: Image[]
  /** @format uuid */
  student: string
  student_str: string
  /** @maxLength 400 */
  message: string
  /** @maxLength 128 */
  course_name?: string
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

export type AdminVariantManualCongratulationsRequest = {
  /**
   * * `wreath` - Wreath
   * * `cap` - Cap
   */
  variant: VariantEnum
  images: string[]
  /** @format uuid */
  student: string
  /**
   * @minLength 1
   * @maxLength 400
   */
  message: string
  /** @maxLength 128 */
  course_name?: string
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminVariantManualCongratulationsWidget = {
  id: string
  /** @default "#808080" */
  bg_color: string
  items: AdminVariantManualCongratulations[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminVariantManualCongratulationsWidgetRequest = {
  items: AdminVariantManualCongratulationsRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminWidget =
  | AdminBoardingAnnouncementWidget
  | AdminCelebrationWidget
  | AdminEventAgendaWidget
  | AdminCongratulationsWidget
  | AdminPosterWidget
  | AdminStudentArtGalleryWidget
  | AdminEventInfoWidget
  | AdminEventInfoWithPhotoWidget
  | AdminJournalWidget
  | AdminMysteryLinkWidget
  | AdminMenuWidget
  | AdminStudentAnnouncementWidget
  | AdminStudentsPicWidget
  | AdminVariantManualCongratulationsWidget
  | AdminClockWidget

export type AdminWidgetRequest =
  | AdminBoardingAnnouncementWidgetRequest
  | AdminCelebrationWidgetRequest
  | AdminEventAgendaWidgetRequest
  | AdminCongratulationsWidgetRequest
  | AdminPosterWidgetRequest
  | AdminStudentArtGalleryWidgetRequest
  | AdminEventInfoWidgetRequest
  | AdminEventInfoWithPhotoWidgetRequest
  | AdminJournalWidgetRequest
  | AdminMysteryLinkWidgetRequest
  | AdminMenuWidgetRequest
  | AdminStudentAnnouncementWidgetRequest
  | AdminStudentsPicWidgetRequest
  | AdminVariantManualCongratulationsWidgetRequest
  | AdminClockWidgetRequest

export type AgendaItem = {
  id: number
  /** @format time */
  time_from?: string | null
  /** @format time */
  time_to?: string | null
  activity: string
  location?: string
}

export type AgendaItemRequest = {
  /** @format time */
  time_from?: string | null
  /** @format time */
  time_to?: string | null
  /** @minLength 1 */
  activity: string
  location?: string
}

export type Aim = {
  id: number
  /** @maxLength 64 */
  name: string
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string
  is_default?: boolean
}

export type AimScorings = {
  min: number
  max: number
  grade: string
}

export type AllergenKindOption = {
  label: string
  value: string
  allergens: StringOption[]
}

export type Allergy = {
  id: number
  /** @format date-time */
  created: string
  type: string
  subtypes?: string[]
}

export type AllergyChoicesResponse = {
  reactions: AllergyReactionOption[]
  allergen_kinds: AllergenKindOption[]
  medicines: StringOption[]
  medicine_locations: StringOption[]
  immediate_responses: StringOption[]
}

export type AllergyReactionOption = {
  label: string
  value: string
  mild_reactions: StringOption[]
  severe_reactions: StringOption[]
}
/**
 * * `reserved` - reserved
 * * `social_room` - social_room
 * * `duty_room` - duty_room
 * * `storage` - storage
 * * `artisan_private_room` - artisan_private_room
 * * `student_accommodation` - student_accommodation
 * * `not_assigned` - not_assigned
 */
export type AnnotatedHouseRoomStatusChoices =
  | 'reserved'
  | 'social_room'
  | 'duty_room'
  | 'storage'
  | 'artisan_private_room'
  | 'student_accommodation'
  | 'not_assigned'
/**
 * * `both` - Both
 * * `idp_app` - Student
 * * `parent_app` - Parent
 * * `not_visible` - Not visible
 */
export type AppVisibilityEnum =
  | 'both'
  | 'idp_app'
  | 'parent_app'
  | 'not_visible'
export type ApplyChanges = {
  no_of_applied_items: number
}

export type Appointment = {
  id: number
  /** @format date-time */
  created: string
  /**
   * * `hospital` - Hospital
   * * `doctor` - Doctor
   */
  appointment_type?: AppointmentTypeEnum
  appointment_type_label: string
  doctor?: number | null
  doctor_name?: string
  doctor_str: string
  hospital?: number | null
  hospital_name?: string
  hospital_str: string
  /** @format date-time */
  date_and_time: string
  report: string
  diagnosis: string
  recommendations: string
  status: AppointmentStatusEnum
  duration_in_minutes: number
  /** @format date-time */
  end_of_visit: string
  cancellation_reason: string
}

export type AppointmentCancellation = {
  cancellation_reason?: string
}

export type AppointmentCancellationRequest = {
  /** @minLength 1 */
  cancellation_reason?: string
}

export type AppointmentRequest = {
  /**
   * * `hospital` - Hospital
   * * `doctor` - Doctor
   */
  appointment_type?: AppointmentTypeEnum
  doctor?: number | null
  doctor_name?: string
  hospital?: number | null
  hospital_name?: string
  /** @format date-time */
  date_and_time: string
  /** @minLength 1 */
  report: string
  /** @format date-time */
  end_of_visit: string
}

/**
 * * `new` - New
 * * `cancelled` - Cancelled
 * * `after_the_visit` - After the visit
 */
export type AppointmentStatusEnum = 'new' | 'cancelled' | 'after_the_visit'

/**
 * * `hospital` - Hospital
 * * `doctor` - Doctor
 */
export type AppointmentTypeEnum = 'hospital' | 'doctor'

export type ApproveEnrolment = {
  enrolment: number
}

export type ApproveEnrolmentRequest = {
  enrolment: number
}

export type ApproveStudentContent = {
  /** @format date */
  display_to: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ArrivalInfo = {
  /** @format date */
  arrival?: string | null
  airport_service?: boolean
  /** @maxLength 256 */
  airport_origin?: string
  /** @maxLength 256 */
  flight_number?: string
  /** @maxLength 256 */
  train_number?: string
  /** @format date */
  requested_arrival_date?: string | null
  /** @format time */
  requested_arrival_time?: string | null
  /** @format uuid */
  contact_person?: string | null
  contact_person_str: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ArrivalInfoRequest = {
  /** @format date */
  arrival?: string | null
  airport_service?: boolean
  /** @maxLength 256 */
  airport_origin?: string
  /** @maxLength 256 */
  flight_number?: string
  /** @maxLength 256 */
  train_number?: string
  /** @format date */
  requested_arrival_date?: string | null
  /** @format time */
  requested_arrival_time?: string | null
  /** @format uuid */
  contact_person?: string | null
}

export type AssignStudentToSectionRequest = {
  target_group: number
  idp_section_enrollments: number[]
}

/**
 * * `4` - S
 * * `3` - E
 * * `2` - A
 * * `1` - C
 * * `0` - not assigned
 */
export type AssignedCategoryEnum = 4 | 3 | 2 | 1 | 0

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type Assignment = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  description: string
  type: number
  /** @format date */
  due_date: string
  /** @format date-time */
  created: string
  /** @format uuid */
  lesson?: string | null
  /** @format uuid */
  course: string
  course_str: string
  teacher_str: string
  /** @format uuid */
  teacher: string | null
  student_items: StudentAssignmentItem[]
  is_homework?: boolean
  quarter?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AssignmentGrade = {
  /** @format uuid */
  id: string
  /** @format date-time */
  created: string
  /**
   * @min 0
   * @max 100
   */
  grade_in_percent?: number | null
  /** @maxLength 32 */
  grade_in_ib?: string
  is_submitted?: boolean
  is_incomplete?: boolean
  is_late?: boolean
  is_exempt?: boolean
  /** @format uuid */
  student: string
  assignment: AssignmentInStudentAssignmentItem
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AssignmentGradeRequest = {
  /**
   * @min 0
   * @max 100
   */
  grade_in_percent?: number | null
  /** @maxLength 32 */
  grade_in_ib?: string
  is_submitted?: boolean
  is_incomplete?: boolean
  is_late?: boolean
  is_exempt?: boolean
  /** @format uuid */
  student: string
  assignment: AssignmentInStudentAssignmentItemRequest
}

export type AssignmentInStudentAssignmentItem = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  description: string
  type: number
  /** @format date */
  due_date: string
  type_str: string
}

export type AssignmentInStudentAssignmentItemRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  /** @minLength 1 */
  description: string
  type: number
  /** @format date */
  due_date: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AssignmentRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  /** @minLength 1 */
  description: string
  type: number
  /** @format date */
  due_date: string
  /** @format uuid */
  lesson?: string | null
  /** @format uuid */
  course: string
  student_items: StudentAssignmentItemRequest[]
  is_homework?: boolean
  quarter?: number
}

export type AssignmentType = {
  id: number
  /** @maxLength 64 */
  name: string
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string
}

export type Attachment = {
  id: string
  /** @format uri */
  file: string
  user?: string
  file_name: string
}

export type AttachmentRequest = {
  /** @format binary */
  file: File
  user?: string
}

export type AuthTokenRequest = {
  /** @minLength 1 */
  username: string
  /** @minLength 1 */
  password: string
}

/**
 * * `student` - Student
 * * `author` - Author
 */
export type AuthorTypeEnum = 'student' | 'author'

export type AutomatedWidgetsStatus = {
  widget: string
  status: string | null
}

export type AvailableIntegerFilter = {
  query_param: string
  label: string
  choices: IntegerOption[]
}

export type AvailableIntegerFilterStudentsStudentId = {
  query_param: string
  label: string
  choices: StudentsStudentIDOption[]
}

export type AvailableStringFilter = {
  query_param: string
  label: string
  choices: StringOption[]
}

export type AvailableStringFilterHealthCheckStudent = {
  query_param: string
  label: string
  choices: HealthCheckStudentOption[]
}

export type AvailableStringFilterHouseRoomBookedFor = {
  query_param: string
  label: string
  choices: HouseRoomBookedForOption[]
}

export type AvailableStudent = {
  /** @format uuid */
  id: string
  full_name: string
  student_grade_level: number
  student_house: string
  student_category: string
  student_category_label: string
}

export type AvailableStudentForSchoolProgrammeAvailableFilters = {
  houses: AvailableIntegerFilter
  grade_levels: AvailableIntegerFilter
  ages: AvailableIntegerFilter
  categories: AvailableIntegerFilter
}
export type BaseCSVFileRequest = {
  /** @format binary */
  file: File
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type Basket = {
  id: number
  courses: CourseInBasket[]
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
  /** Basket type */
  choice_type: ChoiceTypeEnum
  name?: string
  key: string
  is_ignored?: boolean
  /** @format double */
  credit_count: number
  include_extra_basket?: boolean
  idp_plan?: string | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type BasketRequest = {
  courses: CourseInBasketRequest[]
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
  /** Basket type */
  choice_type: ChoiceTypeEnum
  name?: string
  /** @minLength 1 */
  key: string
  is_ignored?: boolean
  include_extra_basket?: boolean
  /** @minLength 1 */
  idp_plan?: string | null
}
/** Collects and raises validation errors in bulk */
export type BehaviourManagementProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type BehaviourManagementProfileRequest = {
  /** @format uuid */
  user?: string | null
}

export type BillingReport = {
  /** @format date-time */
  created: string
  /** @maxLength 120 */
  name: string
  timeframe_str: string
  author_str: string
  id: string
}

export type BillingReportCreate = {
  /** @maxLength 120 */
  name: string
  all_students: boolean
  students: string[]
  /**
   * * `quarter` - Quarter
   * * `school_year` - School Year
   */
  timeframe_type: TimeframeTypeEnum
  product_types: ProductTypesEnum[]
  quarter?: number | null
  school_year?: number | null
  grade_levels?: number[]
  author?: string
  items?: BillingReportItem[] | null
  id: string
}

export type BillingReportCreateRequest = {
  /**
   * @minLength 1
   * @maxLength 120
   */
  name: string
  all_students: boolean
  students: string[]
  /**
   * * `quarter` - Quarter
   * * `school_year` - School Year
   */
  timeframe_type: TimeframeTypeEnum
  product_types: ProductTypesEnum[]
  quarter?: number | null
  school_year?: number | null
  grade_levels?: number[]
  author?: string
  items?: BillingReportItemRequest[] | null
}

export type BillingReportItem = {
  /** @format uuid */
  student: string
  description?: string
  show_description_in_excel?: boolean
  /**
   * * `quarter` - Quarter
   * * `week` - Week
   * * `year` - Year
   * * `course` - Course
   * * `lesson` - Lesson
   */
  quantity_unit: QuantityUnitEnum
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_price: string
  total_price_currency: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  unit_price: string
  unit_price_currency: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  quantity: number
  /**
   * * `academic_individual` - Academic Individual
   * * `sports` - Sports
   * * `co_curricular` - Co-Curricular
   * * `graduation_fee` - Graduation Fee
   * * `art_materials` - Art Materials
   * * `a_level_program` - A-level Programme
   * * `ib_programme` - IB Programme
   * * `ap_programme` - AP Programme
   * * `ielts_preparation_course` - IELTS Preparation Course
   * * `sat_preparation_course` - SAT/ACT
   * * `private_lessons` - Private Lessons
   * * `accommodation_single` - Accommodation - Single room
   * * `accommodation_double` - Accommodation - Double room
   */
  product_type: ProductTypeEnum
  product_type_display: string
  product_subtype?: string
  details?: Record<string, any>
  plural_quantity_unit: string
  abacus_invoice_product?: number | null
  abacus_invoice_product_name?: string
}

export type BillingReportItemRequest = {
  /** @format uuid */
  student: string
  description?: string
  show_description_in_excel?: boolean
  /**
   * * `quarter` - Quarter
   * * `week` - Week
   * * `year` - Year
   * * `course` - Course
   * * `lesson` - Lesson
   */
  quantity_unit: QuantityUnitEnum
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_price: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  unit_price: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  quantity: number
  /**
   * * `academic_individual` - Academic Individual
   * * `sports` - Sports
   * * `co_curricular` - Co-Curricular
   * * `graduation_fee` - Graduation Fee
   * * `art_materials` - Art Materials
   * * `a_level_program` - A-level Programme
   * * `ib_programme` - IB Programme
   * * `ap_programme` - AP Programme
   * * `ielts_preparation_course` - IELTS Preparation Course
   * * `sat_preparation_course` - SAT/ACT
   * * `private_lessons` - Private Lessons
   * * `accommodation_single` - Accommodation - Single room
   * * `accommodation_double` - Accommodation - Double room
   */
  product_type: ProductTypeEnum
  product_subtype?: string
  details?: Record<string, any>
  abacus_invoice_product?: number | null
  abacus_invoice_product_name?: string
}

export type BillingReportRequest = {
  /**
   * @minLength 1
   * @maxLength 120
   */
  name: string
}

export type BlankEnum = ''

/**
 * * `0-` - 0-
 * * `0+` - 0+
 * * `a-` - A-
 * * `a+` - A+
 * * `b-` - B-
 * * `b+` - B+
 * * `ab-` - AB-
 * * `ab+` - AB+
 * * `unknown` - Unknown
 */
export type BloodTypeEnum =
  | '0-'
  | '0+'
  | 'a-'
  | 'a+'
  | 'b-'
  | 'b+'
  | 'ab-'
  | 'ab+'
  | 'unknown'

/**
 * * `boarding_director` - Boarding Director
 * * `campus_head` - Campus Head
 * * `deputy_campus_head_main` - Deputy Campus Head (Main)
 * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
 * * `head_of_house` - Head of House
 * * `house_tutor` - House Tutor
 */
export type BoardingArtisanRoleEnum =
  | 'boarding_director'
  | 'campus_head'
  | 'deputy_campus_head_main'
  | 'deputy_campus_head_senior'
  | 'head_of_house'
  | 'house_tutor'

export type BoardingAttendance = {
  /** @format uuid */
  id: string
  mark?: BoardingAttendanceMarkEnum | BlankEnum
  student_str: string
  /** @format uuid */
  student: string
  is_at_home?: boolean
  is_at_class?: boolean
  is_at_exam?: boolean
  boarding_status?: string
  category: number
  category_week_ago: string
  category_str: string
  category_week_ago_str: string
}

/**
 * * `present` - Present
 * * `late` - Late
 * * `sick` - Sick
 * * `absent_excused` - Absent exc.
 * * `absent_unexcused` - Absent unexc.
 */
export type BoardingAttendanceMarkEnum =
  | 'present'
  | 'late'
  | 'sick'
  | 'absent_excused'
  | 'absent_unexcused'

export type BoardingAttendanceReport = {
  id: number
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  /** @format uuid */
  tutor?: string | null
  tutor_str: string
  status_display: string
  house?: number | null
  house_str: string
  /** @format date-time */
  created: string
  next_report: string
  previous_report: string
  for_senior_campus?: boolean
  is_automatic: boolean
  allow_mark_absent_excused: boolean
}

export type BoardingAttendanceReportList = {
  id: number
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  /** @format uuid */
  tutor?: string | null
  tutor_str: string
  status_display: string
  /** @format date-time */
  created: string
  house?: number | null
  house_str: string
}

export type BoardingAttendanceReportRequest = {
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  /** @format uuid */
  tutor?: string | null
  house?: number | null
  for_senior_campus?: boolean
}

/**
 * * `new` - New
 * * `incomplete` - Incomplete
 * * `finished` - Finished
 */
export type BoardingAttendanceReportStatusEnum =
  | 'new'
  | 'incomplete'
  | 'finished'

export type BoardingAttendanceRequest = {
  mark?: BoardingAttendanceMarkEnum | BlankEnum
  /** @format uuid */
  student: string
  is_at_home?: boolean
  is_at_class?: boolean
  is_at_exam?: boolean
  /** @minLength 1 */
  boarding_status?: string
}

export type BoardingComment = {
  /** @format uuid */
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  added_by?: string
  /** @format uuid */
  added_by_id: string | null
  last_modified_by?: string
  /**
   * @min 0
   * @max 32767
   */
  semester?: TeamMemberRoleEnum | NullEnum | null
  /** @format uuid */
  student: string
  school_year: number
  current_quarter: number
  content: string
}

export type BoardingCommentRequest = {
  added_by?: string
  last_modified_by?: string
  /**
   * @min 0
   * @max 32767
   */
  semester?: TeamMemberRoleEnum | NullEnum | null
  /** @format uuid */
  student: string
  school_year: number
  current_quarter: number
  /** @minLength 1 */
  content: string
}

export type BoardingCommentSimple = {
  /**
   * @min 0
   * @max 32767
   */
  semester?: TeamMemberRoleEnum | NullEnum | null
}

export type BusyInSlotRequestRequest = {
  /**
   * * `0` - Monday
   * * `1` - Tuesday
   * * `2` - Wednesday
   * * `3` - Thursday
   * * `4` - Friday
   * * `5` - Saturday
   * * `6` - Sunday
   */
  day: RotaDayEnum
  /**
   * * `0` - 8:00-8:40
   * * `1` - 8:45-9:25
   * * `2` - 9:30-10:10
   * * `3` - 10:30-11:10
   * * `4` - 11:15-11:55
   * * `5` - 13:00-13:40
   * * `6` - 13:45-14:25
   * * `7` - 14:30-15:10
   * * `8` - 15:15-15:55
   * * `9` - 16:00-16:40
   * * `10` - 16:45-17:25
   * * `11` - 17:30-18:10
   * * `12` - 18:15-18:55
   * * `13` - 19:00-19:40
   * * `14` - 19:40-20:30
   * * `15` - 20:30-21:00
   */
  hour?: HourEnum
  /** @min 1 */
  duration?: number
  /** @format time */
  custom_start?: string
  /** @format time */
  custom_end?: string
}

export type BusyInSlotResponse = {
  busy_teachers_ids: string[]
  busy_rooms_ids: number[]
  off_schedule_room_ids: number[]
}

export type BusyUsersRequestRequest = {
  /**
   * * `0` - Monday
   * * `1` - Tuesday
   * * `2` - Wednesday
   * * `3` - Thursday
   * * `4` - Friday
   * * `5` - Saturday
   * * `6` - Sunday
   */
  day: RotaDayEnum
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
  /**
   * * `regular` - Duty
   * * `break` - Break
   * * `meeting` - Leaders Meeting
   * * `night_presence` - Night Presence
   * * `ski_trip` - Ski trip
   * * `sudden_time_off` - Sudden Time Off
   */
  rota_type?: RotaTypeEnum
  /** @format date */
  date: string
}

export type BusyUsersResponse = {
  users: string[]
  rooms: number[]
}

export type CalendarEvent = {
  id: number
  event_type_display: string
  /** @format date */
  date: string
  /**
   * * `community_weekend` - Community weekend
   * * `academic_tests` - Academic tests
   */
  event_type: CalendarEventEventTypeEnum
}

/**
 * * `community_weekend` - Community weekend
 * * `academic_tests` - Academic tests
 */
export type CalendarEventEventTypeEnum = 'community_weekend' | 'academic_tests'

export type CalendarEventRequest = {
  /** @format date */
  date: string
  /**
   * * `community_weekend` - Community weekend
   * * `academic_tests` - Academic tests
   */
  event_type: CalendarEventEventTypeEnum
}
/** Adds nested create feature */
export type CampusAbsence = {
  /** @format uuid */
  id: string
  created_by?: string
  last_modified_by?: string
  campus_absence_students?: CampusAbsenceStudent[]
  quarter: number
  status: CampusAbsenceStatusEnum
  status_label: string
  /**
   * * `ausgang` - Ausgang
   * * `external_exam` - External exam
   * * `official` - Official
   * * `school_programme` - School Programme
   * * `extra_ausgang` - Extra Ausgang
   */
  campus_absence_type: CampusAbsenceTypeEnum
  campus_absence_type_label: string
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  campus_absence_subtype_label: string
  leave_details: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  check_in: boolean
  check_out: boolean
  disable_students_length_validation?: boolean
  supervisors?: (string | null)[]
  house?: number | null
  has_comment: string
  merit_reward: string
  prolonged_by: string
}

export type CampusAbsenceChangeStatus = {
  /**
   * * `requested` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `canceled_by_student` - Canceled by student
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status: CampusAbsenceStatusEnum
}

export type CampusAbsenceChangeStatusRequest = {
  /**
   * * `requested` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `canceled_by_student` - Canceled by student
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status: CampusAbsenceStatusEnum
}

export type CampusAbsenceCheckInOut = {
  check_in: boolean
  check_out: boolean
}

export type CampusAbsenceCheckInOutRequest = {
  check_in: boolean
  check_out: boolean
}

export type CampusAbsenceComment = {
  /** @format uuid */
  id: string
  author?: string
  content: string
  /** @format date-time */
  modified: string
  can_edit: boolean
}

export type CampusAbsenceCommentRequest = {
  author?: string
  /** @minLength 1 */
  content: string
  reject?: boolean
}

export type CampusAbsenceLog = {
  id: number
  action: string
  description: string
  /** @format date-time */
  timestamp: string
}

/** Adds nested create feature */
export type CampusAbsenceRequest = {
  created_by?: string
  last_modified_by?: string
  campus_absence_students?: CampusAbsenceStudentRequest[]
  quarter: number
  /**
   * * `ausgang` - Ausgang
   * * `external_exam` - External exam
   * * `official` - Official
   * * `school_programme` - School Programme
   * * `extra_ausgang` - Extra Ausgang
   */
  campus_absence_type: CampusAbsenceTypeEnum
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  /** @minLength 1 */
  leave_details: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  disable_students_length_validation?: boolean
  supervisors?: (string | null)[]
  house?: number | null
}

/**
 * * `requested` - Pending
 * * `approved` - Approved
 * * `rejected` - Rejected
 * * `canceled_by_student` - Canceled by student
 * * `done` - Done
 * * `cancelled` - Cancelled
 */
export type CampusAbsenceStatusEnum =
  | 'requested'
  | 'approved'
  | 'rejected'
  | 'canceled_by_student'
  | 'done'
  | 'cancelled'

export type CampusAbsenceStudent = {
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  student_data: StudentSimple
  saved_category: number | null
  saved_category_week_ago: number | null
  saved_homework_status: string | null
  saved_current_week_ausgangs_count: Record<string, any>
  homework_status: string
  category: string
  category_week_ago: string
  ausgangs_this_week: Record<string, number | string>
  ausgangs_per_week: Record<string, Record<string, number>>
}

export type CampusAbsenceStudentRequest = {
  /** @format uuid */
  student: string
}

/**
 * * `afternoon_ausgang` - Afternoon Ausgang
 * * `evening_ausgang` - Evening Ausgang
 * * `evening_campus_ausgang` - Evening - Campus Ausgang
 * * `birthday_ausgang` - Birthday Ausgang
 * * `family_visit` - Family visit
 * * `on_board` - On board Ausgang
 * * `prolonged_ausgang` - Prolonged Ausgang
 * * `campus` - Campus
 * * `offsite` - Offsite
 * * `offsite_overnight` - Offsite, Overnight
 * * `official_appointment` - Official appointment
 * * `specialist_doctor_appointment` - Specialist Doctor Appointment
 * * `programme` - Programme
 * * `house_evening` - House evening
 * * `term_break` - Term Break
 * * `school_trip` - School Trip
 * * `weekend_programme` - Weekend Programme
 * * `other` - Other
 * * `book_the_cafe` - Book the Cafe
 * * `city_brunch_zurich` - City Brunch Zurich
 * * `additional_ausgang` - Additional Ausgang
 */
export type CampusAbsenceSubTypeEnum =
  | 'afternoon_ausgang'
  | 'evening_ausgang'
  | 'evening_campus_ausgang'
  | 'birthday_ausgang'
  | 'family_visit'
  | 'on_board'
  | 'prolonged_ausgang'
  | 'campus'
  | 'offsite'
  | 'offsite_overnight'
  | 'official_appointment'
  | 'specialist_doctor_appointment'
  | 'programme'
  | 'house_evening'
  | 'term_break'
  | 'school_trip'
  | 'weekend_programme'
  | 'other'
  | 'book_the_cafe'
  | 'city_brunch_zurich'
  | 'additional_ausgang'

/**
 * * `ausgang` - Ausgang
 * * `external_exam` - External exam
 * * `official` - Official
 * * `school_programme` - School Programme
 * * `extra_ausgang` - Extra Ausgang
 */
export type CampusAbsenceTypeEnum =
  | 'ausgang'
  | 'external_exam'
  | 'official'
  | 'school_programme'
  | 'extra_ausgang'

export type CampusAbsenceWarning = {
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  students: string[]
  /** @format uuid */
  excluded_campus_absence?: string | null
}

export type CampusAbsenceWarningRequest = {
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  students: string[]
  /** @format uuid */
  excluded_campus_absence?: string | null
}

/** Collects and raises validation errors in bulk */
export type CampusHeadTeamProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type CampusHeadTeamProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/**
 * * `no_cancellation` - Don't cancel classes
 * * `msl_hsl_enrolled` - Cancel all the MSL and HSL classes for the students enrolled in this exam session.
 * * `msl_hsl_enrolled_and_not_enrolled` - Cancel all the MSL and HSL classes for all the students from selected years (enrolled and not enrolled in this exam session).
 * * `enrolled` - Cancel all the classes for the students enrolled in this exam session.
 * * `enrolled_and_not_enrolled` - Cancel all the classes for all the students from selected years (enrolled and not enrolled in this exam session).
 */
export type CancelClassTypeEnum =
  | 'no_cancellation'
  | 'msl_hsl_enrolled'
  | 'msl_hsl_enrolled_and_not_enrolled'
  | 'enrolled'
  | 'enrolled_and_not_enrolled'

export type CancelLesson = {
  id: number
  lesson: TimetableLesson
  related_object_content_type: number | null
  /** Python model class name */
  related_object_content_type_model: string
  related_object_id: string
  cancellation_reason_label: string
}

/**
 * * `0` - U
 * * `1` - S
 * * `2` - D
 * * `3` - T
 */
export type CapacityEnum = 0 | 1 | 2 | 3
/**
 * * `4` - S
 * * `3` - E
 * * `2` - A
 * * `1` - C
 * * `0` - not assigned
 */
export type CategoriesEnum = 4 | 3 | 2 | 1 | 0
export type ChangeCoTeacher1InSectionRequest = {
  /** @format uuid */
  co_teacher_1: string | null
  target_group: number
  apply_in_quarters: QuarterShortNameEnum[]
}

export type ChangeCoTeacher2InSectionRequest = {
  /** @format uuid */
  co_teacher_2: string | null
  target_group: number
  apply_in_quarters: QuarterShortNameEnum[]
}

export type ChangeEndDateMedicineInPlan = {
  /** @format date-time */
  end_date: string
}

export type ChangeEndDateMedicineInPlanRequest = {
  /** @format date-time */
  end_date: string
}

export type ChangeOrder = {
  boards: string[]
}

export type ChangeOrderRequest = {
  boards: string[]
}

export type ChangeTeacherInSectionRequest = {
  /** @format uuid */
  teacher: string
  target_group: number
  apply_in_quarters: QuarterShortNameEnum[]
}

export type ChangesInSectionsRequest = {
  enrolments: AssignStudentToSectionRequest[]
  teachers: ChangeTeacherInSectionRequest[]
  co_teachers_1: ChangeCoTeacher1InSectionRequest[]
  co_teachers_2: ChangeCoTeacher2InSectionRequest[]
}
/**
 * * `default` - Default
 * * `private` - Private
 * * `addon` - Addon
 * * `free_choice` - Free Choice
 * * `exam` - Exam
 * * `higher_level` - Higher Level
 */
export type ChoiceTypeEnum =
  | 'default'
  | 'private'
  | 'addon'
  | 'free_choice'
  | 'exam'
  | 'higher_level'

export type ChronicCondition = {
  id: number
  /** @format date-time */
  created: string
  type: string
  subtypes?: string[]
}

export type ChronicConditionChoicesResponse = {
  condition_types: ConditionTypeOption[]
  impacts_on_daily_routine: StringOption[]
  watch_out_for: StringOption[]
  emergency_medicine_applications: StringOption[]
  emergency_medicine_locations: StringOption[]
  iep_contacts: StringOption[]
  immediate_responses: StringOption[]
}

export type ChronicConditionRequest = {
  /** @minLength 1 */
  type: string
  subtypes?: string[]
}

export type ClashWithExamEC = {
  id: string
  course_name: string
  /** @format date */
  date?: string | null
  /** @format time */
  start_time?: string | null
  /** @format time */
  end_time?: string | null
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
}

/**
 * * `regular` - Regular
 * * `sport` - Sport
 */
export type ClassTypeEnum = 'regular' | 'sport'

/**
 * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
 * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
 * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
 * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
 */
export type ClassificationEnum = 'l1' | 'l2' | 'l3' | 'l4'

/**
 * * `mistake` - Sick report made by mistake
 * * `student_not_sick` - Student not sick
 * * `student_recovered` - Student recovered
 */
export type CloseReasonEnum =
  | 'mistake'
  | 'student_not_sick'
  | 'student_recovered'

export type CloseSickReport = {
  id: string
  /**
   * * `mistake` - Sick report made by mistake
   * * `student_not_sick` - Student not sick
   * * `student_recovered` - Student recovered
   */
  close_reason: CloseReasonEnum
}

export type CloseSickReportRequest = {
  /**
   * * `mistake` - Sick report made by mistake
   * * `student_not_sick` - Student not sick
   * * `student_recovered` - Student recovered
   */
  close_reason: CloseReasonEnum
}
export type Comment = {
  related_id: string
  related_author: string
  author_first_name: string
  author_last_name: string
  author_profiles: string[]
  /** @format uuid */
  student_id: string
  student_first_name: string
  student_last_name: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  comment_type: string
  is_weekly: boolean
  related_course: string
  course_name: string
  content: string
}

export type ComparisonTableRow = Record<
  string,
  {
    updated: boolean
    hidden: boolean
    old: string | null
    new: string | null
    errors: string[]
  }
>

export type ComparisonTableRowRequest = Record<
  string,
  {
    updated: boolean
    hidden: boolean
    /** @minLength 1 */
    old: string | null
    /** @minLength 1 */
    new: string | null
    errors: string[]
  }
>

export type Competency = {
  id: number
  /** @maxLength 256 */
  name: string
}
export type Condition = {
  id: number
  /** @format uuid */
  health_check: string
  /**
   * * `unchanged` - Unchanged
   * * `better` - Better
   * * `worse` - Worse
   */
  status: ConditionStatusEnum
  new_symptoms?: NewSymptomsEnum[]
  new_symptoms_appeared?: boolean
}

export type ConditionRequest = {
  /** @format uuid */
  health_check: string
  /**
   * * `unchanged` - Unchanged
   * * `better` - Better
   * * `worse` - Worse
   */
  status: ConditionStatusEnum
  new_symptoms?: NewSymptomsEnum[]
  new_symptoms_appeared?: boolean
}

/**
 * * `unchanged` - Unchanged
 * * `better` - Better
 * * `worse` - Worse
 */
export type ConditionStatusEnum = 'unchanged' | 'better' | 'worse'

export type ConditionTypeOption = {
  label: string
  value: string
  subtypes: StringOption[]
}
export type CopyTalentEnrichment = {
  source_timetable: number
  target_timetable: number
}

export type CopyTalentEnrichmentRequest = {
  source_timetable: number
  target_timetable: number
}

export type CountedLessonsParams = {
  /** @format date */
  date_from: string
  /** @format date */
  date_to: string
}

export type Country = {
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia (Federated States of)
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  code: NationalityEnum
  name: string
}

export type CourseAllowedSlots = {
  id: number
  /**
   * @min 0
   * @max 2147483647
   */
  day: WeekDayEnum
  /** @format time */
  start_time: string
  /** @format time */
  end_time: string
}

export type CourseAllowedSlotsRequest = {
  /**
   * @min 0
   * @max 2147483647
   */
  day: WeekDayEnum
  /** @format time */
  start_time: string
  /** @format time */
  end_time: string
}

export type CourseDepartment = {
  id: number
  /** @maxLength 32 */
  name: string
}

export type CourseDescription = {
  description?: string
}

export type CourseDetail = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  /** @maxLength 128 */
  code: string
  types?: CourseType[]
  preparation_for_types?: CourseType[]
  levels?: CourseLevel[]
  /** @format double */
  credits: number | null
  departments?: CourseDepartment[]
  rooms?: CourseRoom[]
  teachers: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  humanix_factor?: string
  difficulty: string
  previous_courses: number[]
  next_courses: number[]
  group?: CourseGroup
  families?: CourseFamily[]
  intelligences?: CourseIntelligence[]
  description?: string
  has_academic_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  exam_duration?: number | null
  has_external_exam?: boolean
  external_exam_type?: ExternalExamTypeEnum | BlankEnum
  /** RPS-6527 */
  is_payable?: boolean
  /** @format uri */
  outline_file?: string | null
  outline_file_name: string | null
}
export type CourseDropDataRequest = {
  section: number
}

export type CourseEnrollment = {
  /** @format uuid */
  id: string
  name: string
  teacher_str: string
  teacher: string
  is_talent_and_enrichment: string
  enrollment_id: number
  student: EnrolledStudent
  quarter: number
  course: EnrolledCourse
  course_drop_id: number | null
  /** @format date-time */
  course_drop_created: string | null
}

export type CourseFamily = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}
export type CourseGroup = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type CourseInBasket = {
  id: number
  requested_course?: RequestedCourse | null
  /** @format uuid */
  course?: string | null
  course_name: string
  course_code: string
  quarters: IdpSectionEnrollment[]
  basket?: number
  apply_for_all_quarters?: boolean
  mission_control_department?: MissionControlDepartmentEnum | BlankEnum
  changed_to_after_drop?: boolean
  swapped?: boolean
  swap_department?: string | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type CourseInBasketRequest = {
  requested_course?: RequestedCourseRequest | null
  /** @format uuid */
  course?: string | null
  quarters: IdpSectionEnrollmentRequest[]
  basket?: number
  apply_for_all_quarters?: boolean
  mission_control_department?: MissionControlDepartmentEnum | BlankEnum
  changed_to_after_drop?: boolean
  swapped?: boolean
  /** @minLength 1 */
  swap_department?: string | null
}

export type CourseInBasketUpdateDepartment = {
  id: number
  requested_course: number | null
  /** @format uuid */
  course: string | null
  quarters: number[]
  basket: number
  apply_for_all_quarters: boolean
  mission_control_department?: MissionControlDepartmentEnum | BlankEnum
}

export type CourseInBasketUpdateDepartmentRequest = {
  mission_control_department?: MissionControlDepartmentEnum | BlankEnum
}

export type CourseInExamSession = {
  exam_id: string
  date: string
  start_time: string
  end_time: string
  course_id: string
  course_name: string
}

export type CourseInIdp = {
  /** @format uuid */
  id: string
  name: string
  code: string
  departments: string[]
  departments_for_mission_control: string[]
  types: string[]
  levels: string[]
  group: string | null
  subject: string | null
  number_of_lessons: number | null
  is_talent_and_enrichment: string
  next_course_ids: string[]
  performed_in_quarters: QuarterShortNameEnum[]
}

export type CourseIntelligence = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}
export type CourseLevel = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}
export type CourseList = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  types: string[]
  preparation_for_types?: string[]
  department_names: string[]
  /** @maxLength 128 */
  code: string
  version: number
  departments: number[]
  is_latest_version?: boolean
  levels: CourseLevel[]
  readable_filter_option: string
  /** RPS-6527 */
  is_payable?: boolean
}
export type CourseMissionControlDepartmentRequest = {
  /** @format uuid */
  course: string
  /**
   * * `ENG` - ENG
   * * `MAT` - MAT
   * * `SCI` - SCI
   * * `MLA` - MLA
   * * `SSD` - SSD
   * * `ART` - ART
   */
  mission_control_department: MissionControlDepartmentEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type CoursePlanner = {
  /** @format uuid */
  id: string
  number_of_lessons?: number | null
  /** @format uri */
  outline_file?: string | null
  allowed_slots?: CourseAllowedSlots[] | null
  outline_file_name: string | null
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  /** @maxLength 128 */
  name: string
  code: string
  /** @default 1 */
  version: number
  is_latest_version?: boolean
  /** @format double */
  credits: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  difficulty: string
  number: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  humanix_factor?: string
  /** @maxItems 4 */
  performed_in_quarters?: QuarterShortNameEnum[]
  description?: string
  has_academic_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  exam_duration?: number | null
  has_external_exam?: boolean
  external_exam_type?: ExternalExamTypeEnum | BlankEnum
  old_is_exam_course_flag: boolean
  exam_course_name?: string
  /** RPS-6527 */
  is_payable?: boolean
  /** Used in summer camps */
  is_camps_focus?: boolean
  /** Used in summer camps */
  is_camps_private?: boolean
  /** Used in billing reports for sport package++ */
  is_included_in_sport_package?: boolean
  custom_constraints?: boolean
  override_allowed_slots?: boolean
  /**
   * @min 5
   * @max 240
   */
  lesson_duration?: number | null
  override_preparation_time?: boolean
  /**
   * @min 0
   * @max 60
   */
  preparation_time_before?: number | null
  /**
   * @min 0
   * @max 60
   */
  preparation_time_after?: number | null
  legacy_department?: number | null
  group: string
  subject: string
  departments: number[]
  families: string[]
  intelligences: string[]
  types: string[]
  preparation_for_types?: string[]
  levels: string[]
  rooms?: number[]
}

export type CoursePlannerList = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  code: string
  /** @maxLength 128 */
  name: string
  is_latest_version?: boolean
  version: number
  /** @maxItems 4 */
  performed_in_quarters?: QuarterShortNameEnum[]
  custom_constraints?: boolean
  group?: string | null
  departments: CourseDepartment[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type CoursePlannerRequest = {
  number_of_lessons?: number | null
  next_courses?: string[]
  related_courses?: string[]
  course_request?: number | null
  /** @format binary */
  outline_file?: File | null
  allowed_slots?: CourseAllowedSlotsRequest[] | null
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  is_latest_version?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  /** @minLength 1 */
  difficulty: string
  /** @minLength 1 */
  number: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  humanix_factor?: string
  /** @maxItems 4 */
  performed_in_quarters?: QuarterShortNameEnum[]
  description?: string
  has_academic_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  exam_duration?: number | null
  has_external_exam?: boolean
  external_exam_type?: ExternalExamTypeEnum | BlankEnum
  exam_course_name?: string
  /** RPS-6527 */
  is_payable?: boolean
  /** Used in summer camps */
  is_camps_focus?: boolean
  /** Used in summer camps */
  is_camps_private?: boolean
  /** Used in billing reports for sport package++ */
  is_included_in_sport_package?: boolean
  custom_constraints?: boolean
  override_allowed_slots?: boolean
  /**
   * @min 5
   * @max 240
   */
  lesson_duration?: number | null
  override_preparation_time?: boolean
  /**
   * @min 0
   * @max 60
   */
  preparation_time_before?: number | null
  /**
   * @min 0
   * @max 60
   */
  preparation_time_after?: number | null
  legacy_department?: number | null
  /** @minLength 1 */
  group: string
  /** @minLength 1 */
  subject: string
  departments: number[]
  families: string[]
  intelligences: string[]
  types: string[]
  preparation_for_types?: string[]
  levels: string[]
  rooms?: number[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type CoursePlannerResponse = {
  /** @format uuid */
  id: string
  number_of_lessons?: number | null
  next_courses: string[]
  /** @format uri */
  outline_file?: string | null
  allowed_slots?: CourseAllowedSlots[] | null
  outline_file_name: string | null
  previous_courses: string[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  /** @maxLength 128 */
  name: string
  code: string
  /** @default 1 */
  version: number
  is_latest_version?: boolean
  /** @format double */
  credits: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  difficulty: string
  number: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  humanix_factor?: string
  /** @maxItems 4 */
  performed_in_quarters?: QuarterShortNameEnum[]
  description?: string
  has_academic_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  exam_duration?: number | null
  has_external_exam?: boolean
  external_exam_type?: ExternalExamTypeEnum | BlankEnum
  old_is_exam_course_flag: boolean
  exam_course_name?: string
  /** RPS-6527 */
  is_payable?: boolean
  /** Used in summer camps */
  is_camps_focus?: boolean
  /** Used in summer camps */
  is_camps_private?: boolean
  /** Used in billing reports for sport package++ */
  is_included_in_sport_package?: boolean
  custom_constraints?: boolean
  override_allowed_slots?: boolean
  /**
   * @min 5
   * @max 240
   */
  lesson_duration?: number | null
  override_preparation_time?: boolean
  /**
   * @min 0
   * @max 60
   */
  preparation_time_before?: number | null
  /**
   * @min 0
   * @max 60
   */
  preparation_time_after?: number | null
  legacy_department?: number | null
  group: string
  subject: string
  departments: number[]
  families: string[]
  intelligences: string[]
  types: string[]
  preparation_for_types?: string[]
  levels: string[]
  rooms?: number[]
}

export type CourseRoom = {
  id: number
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
}
export type CourseType = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}
export type Cover = {
  covering: string[]
  covered?: string[]
}

export type CoverConflictRequest = {
  /** @format date */
  cover_date: string
  cover: CoverRequest
  rota_timetable: number
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
}

export type CoverRequest = {
  covering: string[]
  covered?: string[]
}

export type CreateDraftActivitySuggesterTaskRequest = {
  /** @format uuid */
  activity_id: string
  years_to_rearrange: number[]
  /**
   * @min 0
   * @max 2147483647
   */
  to_day?: RotaDayEnum | NullEnum | null
  /**
   * @min 0
   * @max 2147483647
   */
  to_hour: HourEnum
  /** @format uuid */
  edit_session_id: string | null
}

export type CreateEditSessionRequest = {
  /** @format uuid */
  edit_session_id?: string | null
}

export type CreateSuggesterTaskResponse = {
  task_id: number
}

export type CumulativeItem = {
  course: string
  is_present: number
  is_late: number
  is_absent_excused: number
  is_absent_unexcused: number
  is_sick: number
  marked_attendances_count: number
  missed_lessons_count: number
  /** @format double */
  missed_lessons_percentage: number
  is_course_dropped: boolean
}

export type CumulativeResponse = {
  summary: CumulativeSummary
  items: CumulativeItem[]
}

export type CumulativeSummary = {
  /** @default "General" */
  course: string
  is_present: number
  is_late: number
  is_absent_excused: number
  is_absent_unexcused: number
  is_sick: number
  marked_attendances_count: number
  missed_lessons_count: number
  /** @format double */
  missed_lessons_percentage: number
}

/**
 * * `4` - S
 * * `3` - E
 * * `2` - A
 * * `1` - C
 * * `0` - not assigned
 */
export type CurrentCategoryEnum = 4 | 3 | 2 | 1 | 0

export type CurrentEditor = {
  id: string
  full_name: string
}

export type CurrentLesson = {
  /** @format uuid */
  id: string
  students: string[]
  course: string
}

export type CurrentLocation = {
  /** @format uuid */
  id: string
  current_location: string
  boarding_status: string
}

/**
 * * `EI` - Grade Exempt: Illness, Credit Awarded
 * * `EF` - Grade Exempt: Force-Majeure, Credit Awarded
 * * `EO` - Grade Exempt: Other, Credit Awarded
 * * `IC` - Incomplete
 * * `SC` - Successfully Completed
 * * `CA` - Successfully Completed & Credit Awarded
 */
export type CustomGradeEnum = 'EI' | 'EF' | 'EO' | 'IC' | 'SC' | 'CA'

export type DayHour = {
  /**
   * * `0` - 8:00-8:40
   * * `1` - 8:45-9:25
   * * `2` - 9:30-10:10
   * * `3` - 10:30-11:10
   * * `4` - 11:15-11:55
   * * `5` - 13:00-13:40
   * * `6` - 13:45-14:25
   * * `7` - 14:30-15:10
   * * `8` - 15:15-15:55
   * * `9` - 16:00-16:40
   * * `10` - 16:45-17:25
   * * `11` - 17:30-18:10
   * * `12` - 18:15-18:55
   * * `13` - 19:00-19:40
   * * `14` - 19:40-20:30
   * * `15` - 20:30-21:00
   */
  value: HourEnum
  /**
   * * `8:00-8:40` - 8:00-8:40
   * * `8:45-9:25` - 8:45-9:25
   * * `9:30-10:10` - 9:30-10:10
   * * `10:30-11:10` - 10:30-11:10
   * * `11:15-11:55` - 11:15-11:55
   * * `13:00-13:40` - 13:00-13:40
   * * `13:45-14:25` - 13:45-14:25
   * * `14:30-15:10` - 14:30-15:10
   * * `15:15-15:55` - 15:15-15:55
   * * `16:00-16:40` - 16:00-16:40
   * * `16:45-17:25` - 16:45-17:25
   * * `17:30-18:10` - 17:30-18:10
   * * `18:15-18:55` - 18:15-18:55
   * * `19:00-19:40` - 19:00-19:40
   * * `19:40-20:30` - 19:40-20:30
   * * `20:30-21:00` - 20:30-21:00
   */
  label: DayHourLabelEnum
}

/**
 * * `8:00-8:40` - 8:00-8:40
 * * `8:45-9:25` - 8:45-9:25
 * * `9:30-10:10` - 9:30-10:10
 * * `10:30-11:10` - 10:30-11:10
 * * `11:15-11:55` - 11:15-11:55
 * * `13:00-13:40` - 13:00-13:40
 * * `13:45-14:25` - 13:45-14:25
 * * `14:30-15:10` - 14:30-15:10
 * * `15:15-15:55` - 15:15-15:55
 * * `16:00-16:40` - 16:00-16:40
 * * `16:45-17:25` - 16:45-17:25
 * * `17:30-18:10` - 17:30-18:10
 * * `18:15-18:55` - 18:15-18:55
 * * `19:00-19:40` - 19:00-19:40
 * * `19:40-20:30` - 19:40-20:30
 * * `20:30-21:00` - 20:30-21:00
 */
export type DayHourLabelEnum =
  | '8:00-8:40'
  | '8:45-9:25'
  | '9:30-10:10'
  | '10:30-11:10'
  | '11:15-11:55'
  | '13:00-13:40'
  | '13:45-14:25'
  | '14:30-15:10'
  | '15:15-15:55'
  | '16:00-16:40'
  | '16:45-17:25'
  | '17:30-18:10'
  | '18:15-18:55'
  | '19:00-19:40'
  | '19:40-20:30'
  | '20:30-21:00'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type DayMenu = {
  id: number
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date */
  date: string
  is_filled?: boolean
  is_skipped?: boolean
  is_published: boolean
  /** @maxLength 120 */
  lunch_starter?: string
  /** @maxLength 120 */
  lunch_soup?: string
  /** @maxLength 120 */
  lunch_main_course?: string
  /** @maxLength 120 */
  lunch_dessert?: string
  /** @maxLength 120 */
  lunch_starter_vege?: string
  /** @maxLength 120 */
  lunch_soup_vege?: string
  /** @maxLength 120 */
  lunch_main_course_vege?: string
  /** @maxLength 120 */
  lunch_dessert_vege?: string
  /** @maxLength 120 */
  dinner_starter?: string
  /** @maxLength 120 */
  dinner_soup?: string
  /** @maxLength 120 */
  dinner_main_course?: string
  /** @maxLength 120 */
  dinner_dessert?: string
  /** @maxLength 120 */
  dinner_starter_vege?: string
  /** @maxLength 120 */
  dinner_soup_vege?: string
  /** @maxLength 120 */
  dinner_main_course_vege?: string
  /** @maxLength 120 */
  dinner_dessert_vege?: string
  /** @maxLength 120 */
  breakfast_special?: string
  /** @maxLength 120 */
  breakfast_special_vege?: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type DayMenuRequest = {
  /** @format date */
  date: string
  is_filled?: boolean
  is_skipped?: boolean
  /** @maxLength 120 */
  lunch_starter?: string
  /** @maxLength 120 */
  lunch_soup?: string
  /** @maxLength 120 */
  lunch_main_course?: string
  /** @maxLength 120 */
  lunch_dessert?: string
  /** @maxLength 120 */
  lunch_starter_vege?: string
  /** @maxLength 120 */
  lunch_soup_vege?: string
  /** @maxLength 120 */
  lunch_main_course_vege?: string
  /** @maxLength 120 */
  lunch_dessert_vege?: string
  /** @maxLength 120 */
  dinner_starter?: string
  /** @maxLength 120 */
  dinner_soup?: string
  /** @maxLength 120 */
  dinner_main_course?: string
  /** @maxLength 120 */
  dinner_dessert?: string
  /** @maxLength 120 */
  dinner_starter_vege?: string
  /** @maxLength 120 */
  dinner_soup_vege?: string
  /** @maxLength 120 */
  dinner_main_course_vege?: string
  /** @maxLength 120 */
  dinner_dessert_vege?: string
  /** @maxLength 120 */
  breakfast_special?: string
  /** @maxLength 120 */
  breakfast_special_vege?: string
}

export type DefaultCustomConstraints = {
  lesson_duration: number
  preparation_time_before: number
  preparation_time_after: number
  default_schedule: Period[]
}

export type DegreeAim = {
  id: number
  /** @maxLength 256 */
  name: string
}
export type Department = {
  id: number
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  /** @maxLength 32 */
  name: string
  /** @maxLength 32 */
  label?: string
  is_talent_and_enrichment?: boolean
  /** @format uuid */
  head_of_department?: string | null
  heads_of_department: string[]
}

export type DepartmentRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name: string
  /** @maxLength 32 */
  label?: string
  is_talent_and_enrichment?: boolean
  /** @format uuid */
  head_of_department?: string | null
}

/**
 * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
 * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
 * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
 * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
 */
export type DietTypeEnum = 'l1' | 'l2' | 'l3' | 'l4'

export type DinningAndSickMealAttendance = {
  dining: MealPreferenceCount
  sick: MealPreferenceCount
}

export type DisabledAutoimportTimetable = {
  timetable_id: number
  rota_timetable_id: number | null
}
/**
 * * `idp_app` - IDP app
 * * `parents_app` - Parents app
 */
export type DisplayInEnum = 'idp_app' | 'parents_app'

export type Doctor = {
  id: number
  /** @maxLength 256 */
  name: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  appointment_count: string
}

export type DoctorDiagnosis = {
  id: number
  diagnosis: string
  recommendations: string
  /**
   * * `new` - New
   * * `cancelled` - Cancelled
   * * `after_the_visit` - After the visit
   */
  status?: AppointmentStatusEnum
}

export type DoctorDiagnosisRequest = {
  /** @minLength 1 */
  diagnosis: string
  /** @minLength 1 */
  recommendations: string
  /**
   * * `new` - New
   * * `cancelled` - Cancelled
   * * `after_the_visit` - After the visit
   */
  status?: AppointmentStatusEnum
}

export type DoctorRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
}

/**
 * * `capsule` - capsule
 * * `drop` - drop
 * * `puff` - puff
 * * `sachet` - sachet
 * * `table_spoon` - table spoon
 * * `tablet` - tablet
 * * `tea_spoon` - tea spoon
 * * `finger_tip` - finger tip unit (cream)
 */
export type DosageFormEnum =
  | 'capsule'
  | 'drop'
  | 'puff'
  | 'sachet'
  | 'table_spoon'
  | 'tablet'
  | 'tea_spoon'
  | 'finger_tip'

export type DraftActivityAvailableFilters = {
  students: AvailableStringFilter
  course: AvailableStringFilter
  room: AvailableStringFilter
  teacher: AvailableStringFilter
}

export type DraftActivityCallArgs = {
  /** @format uuid */
  id: string
  section_id: number
  course: SimpleCourse
  day: RotaDayEnum | NullEnum | null
  /**
   * * `0` - 8:00-8:40
   * * `1` - 8:45-9:25
   * * `2` - 9:30-10:10
   * * `3` - 10:30-11:10
   * * `4` - 11:15-11:55
   * * `5` - 13:00-13:40
   * * `6` - 13:45-14:25
   * * `7` - 14:30-15:10
   * * `8` - 15:15-15:55
   * * `9` - 16:00-16:40
   * * `10` - 16:45-17:25
   * * `11` - 17:30-18:10
   * * `12` - 18:15-18:55
   * * `13` - 19:00-19:40
   * * `14` - 19:40-20:30
   * * `15` - 20:30-21:00
   */
  hour: HourEnum
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1_id: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2_id: string | null
  /**
   * Artisan
   * @format uuid
   */
  teacher_id: string
  room_id: number | null
  student_ids: string[]
  timetable_id: number
}

export type DraftActivityCreate = {
  /** @format uuid */
  id: string
  teacher_display: string
  co_teacher_1_display: string
  co_teacher_2_display: string
  room_display: string
  /**
   * @min 0
   * @max 2147483647
   */
  day: RotaDayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /** @format double */
  duration?: number
  is_archived?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  is_private: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  fet_id?: number | null
  is_moved: boolean
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  /** @format uuid */
  course: string
  /**
   * Artisan
   * @format uuid
   */
  teacher: string
  room?: number | null
  timetable: number
  section?: number | null
  from_optimization?: number | null
  /** @format uuid */
  separated_from?: string | null
  students: string[]
}

export type DraftActivityCreateRequest = {
  create_new_section?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  day: RotaDayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /** @format double */
  duration?: number
  is_archived?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  fet_id?: number | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  /** @format uuid */
  course: string
  /**
   * Artisan
   * @format uuid
   */
  teacher: string
  room?: number | null
  timetable: number
  section?: number | null
  from_optimization?: number | null
  /** @format uuid */
  separated_from?: string | null
  students: string[]
}

export type DraftActivityEditor = {
  /** @format uuid */
  id: string
  full_name: string
  /**
   * * `active` - ACTIVE
   * * `inactive` - INACTIVE
   */
  status: DraftActivityEditorStatusEnum
  is_edit_session_locked: boolean
  /**
   * * `user` - USER
   * * `suggester_generation` - SUGGESTER_GENERATION
   * * `suggester_preview` - SUGGESTER_PREVIEW
   * * `tae_generator` - TAE_GENERATOR
   */
  edit_session_source: EditSessionSourceEnum
}

/**
 * * `active` - ACTIVE
 * * `inactive` - INACTIVE
 */
export type DraftActivityEditorStatusEnum = 'active' | 'inactive'

export type DraftActivityInProgressSuggesterTask = {
  id: number
  /**
   * * `pending` - Pending
   * * `running` - Running
   * * `succeeded` - Succeeded
   * * `failed` - Failed
   * * `cancelled` - Cancelled
   * * `completed` - Completed
   */
  status?: StatusCb0Enum
  author: SimpleUser
  /**
   * @min 0
   * @max 2147483647
   */
  to_hour: HourEnum
  /**
   * @min 0
   * @max 2147483647
   */
  to_day?: RotaDayEnum | NullEnum | null
  years_to_rearrange: number[]
  /** @format date-time */
  started_at?: string | null
  draft_activity: SimpleDraftActivity
}

export type DraftActivityList = {
  /** @format uuid */
  id: string
  date: string
  start: string
  end: string
  start_date: string
  end_date: string
  /** @format uuid */
  course: string
  course_str: string
  users: SimpleUser[]
  /**
   *  type
   * @default "lesson"
   */
  _type: string
  display_hour: string
  room?: number | null
  room_str: string
  teacher: SimpleUser
  co_teacher_1?: SimpleUser | null
  co_teacher_2?: SimpleUser | null
  current_editor: DraftActivityEditor | null
}

export type DraftActivityListRequest = {
  room?: number | null
}

export type DraftActivitySuggesterTaskCallArgs = {
  draft_activity: DraftActivityCallArgs
  to_day: RotaDayEnum | NullEnum | null
  /**
   * * `0` - 8:00-8:40
   * * `1` - 8:45-9:25
   * * `2` - 9:30-10:10
   * * `3` - 10:30-11:10
   * * `4` - 11:15-11:55
   * * `5` - 13:00-13:40
   * * `6` - 13:45-14:25
   * * `7` - 14:30-15:10
   * * `8` - 15:15-15:55
   * * `9` - 16:00-16:40
   * * `10` - 16:45-17:25
   * * `11` - 17:30-18:10
   * * `12` - 18:15-18:55
   * * `13` - 19:00-19:40
   * * `14` - 19:40-20:30
   * * `15` - 20:30-21:00
   */
  to_hour: HourEnum
  years_to_rearrange: number[]
}

export type DraftActivitySuggestion = {
  id: number
  task_id: number
  moves: DraftActivitySuggestionMove[]
}

export type DraftActivitySuggestionMove = {
  /** @format uuid */
  activity_id: string
  course: SimpleCourse
  artisans: SimpleUser[]
  students: SimpleStudentWithGradeLevel[]
  room: SimpleRoom | null
  /**
   * @min 0
   * @max 2147483647
   */
  from_day: RotaDayEnum
  from_time_slot: string
  /**
   * @min 0
   * @max 2147483647
   */
  to_day: RotaDayEnum
  to_time_slot: string
}

export type DraftActivitySuggestionPreview = {
  /** @format uuid */
  id: string
  date: string
  start: string
  end: string
  start_date: string
  end_date: string
  /** @format uuid */
  course: string
  course_str: string
  /**
   *  type
   * @default "lesson"
   */
  _type: string
  display_hour: string
  room?: number | null
  room_str: string
  teacher: SimpleUser
  /** @default false */
  is_suggested_move?: boolean
  /** @default false */
  is_duplicate?: boolean
}

export type DraftRotaActivity = {
  id: string
  /** @format uuid */
  user?: string | null
  users?: string[]
  /**
   * * `regular` - Duty
   * * `break` - Break
   * * `meeting` - Leaders Meeting
   * * `night_presence` - Night Presence
   * * `ski_trip` - Ski trip
   * * `sudden_time_off` - Sudden Time Off
   */
  rota_type?: RotaTypeEnum
  rota_timetable: number
  /**
   * @min 0
   * @max 2147483647
   */
  day: RotaDayEnum
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
  /** @format uuid */
  team?: string | null
  role?: BoardingArtisanRoleEnum | BlankEnum
  room?: number | null
  cover?: Cover | null
  /** @format date */
  cover_date?: string
  /** @format date */
  date?: string
}

export type DraftRotaActivityConflictCheck = {
  id: string
  /** @format uuid */
  user?: string | null
  users?: string[]
  /**
   * * `regular` - Duty
   * * `break` - Break
   * * `meeting` - Leaders Meeting
   * * `night_presence` - Night Presence
   * * `ski_trip` - Ski trip
   * * `sudden_time_off` - Sudden Time Off
   */
  rota_type?: RotaTypeEnum
  rota_timetable: number
  /**
   * @min 0
   * @max 2147483647
   */
  day: RotaDayEnum
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
  /** @format uuid */
  team?: string | null
  role?: BoardingArtisanRoleEnum | BlankEnum
  room?: number | null
  /** @format date */
  date?: string
}

export type DraftRotaActivityConflictCheckRequest = {
  /** @format uuid */
  user?: string | null
  users?: string[]
  /**
   * * `regular` - Duty
   * * `break` - Break
   * * `meeting` - Leaders Meeting
   * * `night_presence` - Night Presence
   * * `ski_trip` - Ski trip
   * * `sudden_time_off` - Sudden Time Off
   */
  rota_type?: RotaTypeEnum
  rota_timetable: number
  /**
   * @min 0
   * @max 2147483647
   */
  day: RotaDayEnum
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
  /** @format uuid */
  team?: string | null
  role?: BoardingArtisanRoleEnum | BlankEnum
  room?: number | null
  /** @format date */
  date?: string
}

export type DraftRotaActivityConstraintCheck = {
  id: string
  /**
   * @min 0
   * @max 2147483647
   */
  day: RotaDayEnum
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
  /**
   * * `regular` - Duty
   * * `break` - Break
   * * `meeting` - Leaders Meeting
   * * `night_presence` - Night Presence
   * * `ski_trip` - Ski trip
   * * `sudden_time_off` - Sudden Time Off
   */
  rota_type?: RotaTypeEnum
  rota_timetable: number
  /** @format uuid */
  user?: string | null
  users?: string[]
  /** @format uuid */
  team?: string | null
  cover?: Cover | null
  /** @format date */
  cover_date?: string
  /** @format date */
  date?: string
}

export type DraftRotaActivityConstraintCheckRequest = {
  /**
   * @min 0
   * @max 2147483647
   */
  day: RotaDayEnum
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
  /**
   * * `regular` - Duty
   * * `break` - Break
   * * `meeting` - Leaders Meeting
   * * `night_presence` - Night Presence
   * * `ski_trip` - Ski trip
   * * `sudden_time_off` - Sudden Time Off
   */
  rota_type?: RotaTypeEnum
  rota_timetable: number
  /** @format uuid */
  user?: string | null
  users?: string[]
  /** @format uuid */
  team?: string | null
  cover?: CoverRequest | null
  /** @format date */
  cover_date?: string
  /** @format date */
  date?: string
}

export type DraftRotaActivityRequest = {
  /** @format uuid */
  user?: string | null
  users?: string[]
  /**
   * * `regular` - Duty
   * * `break` - Break
   * * `meeting` - Leaders Meeting
   * * `night_presence` - Night Presence
   * * `ski_trip` - Ski trip
   * * `sudden_time_off` - Sudden Time Off
   */
  rota_type?: RotaTypeEnum
  rota_timetable: number
  /**
   * @min 0
   * @max 2147483647
   */
  day: RotaDayEnum
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
  /** @format uuid */
  team?: string | null
  role?: BoardingArtisanRoleEnum | BlankEnum
  room?: number | null
  cover?: CoverRequest | null
  /** @format date */
  cover_date?: string
  /** @format date */
  date?: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type DraftRotaActivityResponse = {
  id: string
  /** @format uuid */
  user: string | null
  user_str: string
  quarter: number
  rota_type: RotaTypeEnum
  rota_timetable: number
  /**
   *  type
   * @default "rota"
   */
  _type: string
  /** @format date */
  date: string
  start: string
  end: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  role: string
  role_str: string
  /** @default "" */
  house_name: string
  /** @format uuid */
  team: string | null
  team_str: string
  users: string[]
  users_str: string
  room: number | null
  covered_by: SimpleUser[]
  covering: SimpleUser[]
  /** @format uuid */
  display_for_user: string
}

export type DrinkGive = {
  id: number
  was_given: boolean
  /** @format uuid */
  health_check: string
  /** @format date-time */
  created: string
}

export type DrinkGiveRequest = {
  was_given: boolean
  /** @format uuid */
  health_check: string
}

export type EditSessionConflictResponse = {
  message: string
  current_editor: CurrentEditor | null
}

export type EditSessionCreatedResponse = {
  message: string
  /** @format uuid */
  edit_session_id: string
}

/**
 * * `user` - USER
 * * `suggester_generation` - SUGGESTER_GENERATION
 * * `suggester_preview` - SUGGESTER_PREVIEW
 * * `tae_generator` - TAE_GENERATOR
 */
export type EditSessionSourceEnum =
  | 'user'
  | 'suggester_generation'
  | 'suggester_preview'
  | 'tae_generator'

/**
 * * `topical` - Topical (Skin)
 * * `oral` - Oral
 * * `nasal` - Nasal
 * * `injection` - Injection
 */
export type EmergencyMedicineApplicationsEnum =
  | 'topical'
  | 'oral'
  | 'nasal'
  | 'injection'

/**
 * * `student` - Student (at all times)
 * * `house` - House
 * * `BDT` - BDT
 */
export type EmergencyMedicineLocationEnum = 'student' | 'house' | 'BDT'

export type EnrolledCourse = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  difficulty: string
}

export type EnrolledStudent = {
  /** @format uuid */
  id: string
  full_name: string
}

export type Enrollment = {
  id: number
  student: string
  student_str: string
  /** @default true */
  is_active?: boolean
  number_of_lessons: number
  /** @default false */
  is_private?: boolean
  quarter: string
}
/**
 * * `pending` - Pending
 * * `requested` - Requested
 * * `waiting_parental_approve` - Waiting parental approve
 * * `rejected` - Rejected
 * * `approved` - Approved
 * * `leave_absent` - Leave - Absent
 */
export type EnrolmentStatusEnum =
  | 'pending'
  | 'requested'
  | 'waiting_parental_approve'
  | 'rejected'
  | 'approved'
  | 'leave_absent'

/**
 * * `week` - Weekly
 * * `quarter` - Quarterly
 * * `semester` - Semesterly
 * * `year` - Yearly
 */
export type EscalationExpireAfterEnum = 'week' | 'quarter' | 'semester' | 'year'

export type Exam = {
  id: string
  /** @format uuid */
  course: string
  course_str: string
  /** @format date */
  date?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  duration?: number | null
  /** @format time */
  start_time?: string | null
  /** @format time */
  end_time?: string | null
  location?: number | null
  location_str: string
  is_published: boolean
  test_invigilators?: string[]
  test_invigilators_str: string
  saa_invigilators?: string[]
  saa_invigilators_str: string
  users: string
  teachers?: string[]
  teachers_str: string
  grade_levels?: number[]
  students: ExamStudent[]
  has_any_data: string
  has_all_required_data: string
  is_read_only: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  exam_session_id: string
}

export type ExamClash = {
  id: number
  group: ExamClashGroupEnum
  exam_id: string
  /** @format uuid */
  user_id: string | null
  /** @format uuid */
  student_id: string | null
  location_id: number | null
  user_details: SimpleUser
  student_details: SimpleUser
  location_details: LocationEC
  clash_with_exam_details: ClashWithExamEC
}

/**
 * * `student` - Student
 * * `location` - Location
 * * `teacher` - Teacher
 * * `saa_invigilator` - Saa Invigilator
 * * `test_invigilator` - Test Invigilator
 */
export type ExamClashGroupEnum =
  | 'student'
  | 'location'
  | 'teacher'
  | 'saa_invigilator'
  | 'test_invigilator'

export type ExamClashPreview = {
  for_exam_id?: string | null
  /** @format date */
  date: string
  /** @format time */
  start_time: string
  /** @format time */
  end_time: string
  location_id: number
  test_invigilators_ids?: string[]
  saa_invigilators_ids?: string[]
  students_ids?: string[]
  exam_ids_to_ignore?: string[]
}

export type ExamClashPreviewRequest = {
  for_exam_id?: string | null
  /** @format date */
  date: string
  /** @format time */
  start_time: string
  /** @format time */
  end_time: string
  location_id: number
  test_invigilators_ids?: string[]
  saa_invigilators_ids?: string[]
  students_ids?: string[]
  exam_ids_to_ignore?: string[]
}

export type ExamClashes = {
  id: string
  course: string
  /** @format date */
  date?: string | null
  /** @format time */
  start_hour: string
  /** @format time */
  end_hour: string
  /** @default "exams" */
  clash_type: string
}
export type ExamCoursesRequest = {
  /** @format uuid */
  course: string
}
/**
 * * `25` - 25% extra time
 * * `50` - 50% extra time
 * * `100` - 100% extra time
 */
export type ExamExtraTimeEnum = 25 | 50 | 100

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type ExamForm = {
  id: string
  /** @format date */
  date?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  duration?: number | null
  /** @format time */
  start_time?: string | null
  /** @format time */
  end_time?: string | null
  location?: number | null
  test_invigilators?: string[]
  saa_invigilators?: string[]
  teachers?: string[]
  is_published: boolean
  keep_published?: boolean
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type ExamFormRequest = {
  /** @format date */
  date?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  duration?: number | null
  /** @format time */
  start_time?: string | null
  /** @format time */
  end_time?: string | null
  location?: number | null
  test_invigilators?: string[]
  saa_invigilators?: string[]
  teachers?: string[]
  exam_courses: ExamCoursesRequest[]
  exams_students: Record<string, any>[]
  keep_published?: boolean
}

export type ExamScore = {
  id: number
  idp_plan?: string | null
  /**
   * * `SAT_MAT` - SAT MAT
   * * `SAT_ENG` - SAT ENG
   * * `IELTS` - IELTS
   * * `WIDA` - WIDA
   * * `MAP` - MAP
   */
  name: NameEnum
  /**
   * @min 0
   * @max 100
   */
  score: number
}

export type ExamScoreRequest = {
  /** @minLength 1 */
  idp_plan?: string | null
  /**
   * * `SAT_MAT` - SAT MAT
   * * `SAT_ENG` - SAT ENG
   * * `IELTS` - IELTS
   * * `WIDA` - WIDA
   * * `MAP` - MAP
   */
  name: NameEnum
  /**
   * @min 0
   * @max 100
   */
  score: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type ExamSession = {
  id: string
  name: string
  /**
   * * `quarter` - Quarter exams
   * * `academic` - Academic
   * * `external` - External exams
   */
  exam_type: ExamTypeEnum
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  quarter: number
  departments: number[]
  grade_levels: number[]
  cancellation_dates: ExamSessionCancellationDate[]
  /** @default 0 */
  exams_count: string
  /** @default 0 */
  published_exams_count: string
  has_exam_ready_to_publish: string
  next_session: string
  previous_session: string
}

export type ExamSessionCancellationDate = {
  id: number
  /**
   * * `no_cancellation` - Don't cancel classes
   * * `msl_hsl_enrolled` - Cancel all the MSL and HSL classes for the students enrolled in this exam session.
   * * `msl_hsl_enrolled_and_not_enrolled` - Cancel all the MSL and HSL classes for all the students from selected years (enrolled and not enrolled in this exam session).
   * * `enrolled` - Cancel all the classes for the students enrolled in this exam session.
   * * `enrolled_and_not_enrolled` - Cancel all the classes for all the students from selected years (enrolled and not enrolled in this exam session).
   */
  cancel_class_type?: CancelClassTypeEnum
  /** @format date */
  date: string
  /** @format time */
  time_from?: string | null
  /** @format time */
  time_to?: string | null
  cancel_for_years: number[]
}

export type ExamSessionCancellationDateRequest = {
  /**
   * * `no_cancellation` - Don't cancel classes
   * * `msl_hsl_enrolled` - Cancel all the MSL and HSL classes for the students enrolled in this exam session.
   * * `msl_hsl_enrolled_and_not_enrolled` - Cancel all the MSL and HSL classes for all the students from selected years (enrolled and not enrolled in this exam session).
   * * `enrolled` - Cancel all the classes for the students enrolled in this exam session.
   * * `enrolled_and_not_enrolled` - Cancel all the classes for all the students from selected years (enrolled and not enrolled in this exam session).
   */
  cancel_class_type?: CancelClassTypeEnum
  /** @format date */
  date: string
  /** @format time */
  time_from?: string | null
  /** @format time */
  time_to?: string | null
  cancel_for_years: number[]
}

export type ExamSessionList = {
  id: string
  name: string
  /**
   * * `quarter` - Quarter exams
   * * `academic` - Academic
   * * `external` - External exams
   */
  exam_type: ExamTypeEnum
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  quarter: number
  /** @default 0 */
  exams_count: string
  /** @default 0 */
  published_exams_count: string
  has_read_only_exam: string
  has_exam_ready_to_publish: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type ExamSessionRequest = {
  /** @minLength 1 */
  name: string
  /**
   * * `quarter` - Quarter exams
   * * `academic` - Academic
   * * `external` - External exams
   */
  exam_type: ExamTypeEnum
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  quarter: number
  departments: number[]
  grade_levels: number[]
  cancellation_dates: ExamSessionCancellationDateRequest[]
}

export type ExamSessionSimpleList = {
  id: string
  name: string
}

export type ExamStudent = {
  /** @format uuid */
  id: string
  full_name: string
  is_at_home?: boolean
  saa_student?: boolean
  is_statemented?: boolean
  access_arrangements_labels: string
}
/**
 * * `quarter` - Quarter exams
 * * `academic` - Academic
 * * `external` - External exams
 */
export type ExamTypeEnum = 'quarter' | 'academic' | 'external'

/**
 * * `ACT` - ACT
 * * `A_LEVEL` - A Level (GCE), iGCSE
 * * `AP` - Advanced Placement
 * * `CAMBRIDGE` - CAMBRIDGE
 * * `DELE` - DELE
 * * `DELF` - DELF
 * * `GCSE` - GCSE
 * * `GMAT` - GMAT
 * * `GOETHE` - GOETHE
 * * `IAL` - International A Level
 * * `IB` - International Baccalaureate
 * * `IELTS` - IELTS
 * * `LAMDA` - LAMDA
 * * `MAP` - MAP
 * * `SAT_ENG` - SAT ENG
 * * `SAT_MAT` - SAT MAT
 * * `WIDA` - WIDA
 */
export type ExternalExamTypeEnum =
  | 'ACT'
  | 'A_LEVEL'
  | 'AP'
  | 'CAMBRIDGE'
  | 'DELE'
  | 'DELF'
  | 'GCSE'
  | 'GMAT'
  | 'GOETHE'
  | 'IAL'
  | 'IB'
  | 'IELTS'
  | 'LAMDA'
  | 'MAP'
  | 'SAT_ENG'
  | 'SAT_MAT'
  | 'WIDA'
export type FileForStudent = {
  id: number
  /** @maxLength 100 */
  name: string
  author?: string
  author_str: string
  /** @format uri */
  file?: string
  /** @format date-time */
  created: string
  str: string
  filename: string
  /**
   * @format uri
   * @maxLength 200
   */
  link?: string
  display_in: DisplayInEnum[]
}

export type FileForStudentChoices = {
  display_in: StringOption[]
}

export type FileForStudentSwaggerRequest = {
  /** @maxLength 100 */
  name: string
  author?: string
  /** @minLength 1 */
  file?: string
  /**
   * @format uri
   * @minLength 1
   * @maxLength 200
   */
  link?: string
  display_in: DisplayInEnum[]
}

export type FilterRepresentationResponseSchema = {
  resourcetype: AvailableStringFilter
  student: AvailableStringFilter
  status: AvailableStringFilter
  house: AvailableIntegerFilter
}

export type FirstContact = {
  id: number
  symptoms: SymptomsEnum[]
  details: string
  has_covid_symptoms: boolean
  symptoms_display: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  temperature?: string | null
}

export type FirstContactRequest = {
  symptoms: SymptomsEnum[]
  /** @minLength 1 */
  details: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  temperature?: string | null
}

export type FoodGive = {
  id: number
  was_given: boolean
  /** @format uuid */
  health_check: string
  /** @format date-time */
  created: string
}

export type FoodGiveRequest = {
  was_given: boolean
  /** @format uuid */
  health_check: string
}

/**
 * * `male` - Male
 * * `female` - Female
 */
export type ForGenderEnum = 'male' | 'female'

export type FreeSlot = {
  /**
   * @min 0
   * @max 2147483647
   */
  day: RotaDayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  display_hour: string
  start: string
  end: string
  course: string
}

export type GPAForCourse = {
  id: number
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,4})?$
   */
  unweighted_value: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,4})?$
   */
  weighted_value: string
  grade: string | null
  /** @format uuid */
  course: string
  course_str: string
  teacher: string
  teacher_str: string
}

/**
 * * `male` - Male
 * * `female` - Female
 */
export type GenderEnum = 'male' | 'female'

export type GenerateSummerCertificateRequest = {
  course_ids: string[]
}

export type GenerateZippedCertificatesRequest = {
  students_ids: string[]
  ceremony_dates: string[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type GenerationTask = {
  id: number
  /**
   * Timeout in seconds
   * @min 1
   * @max 86400
   */
  timeout?: number
  use_main_teacher_only?: boolean
  /**
   * Generate students above grade
   * @min 0
   * @max 2147483647
   */
  min_grade?: number | null
  status: GenerationTaskStatusEnum
  stop_request_sent?: boolean
  best_timetable: number | null
  optimize_only_late_slots?: boolean
  status_display: string
  /** @format date-time */
  created: string
  assign_rooms?: boolean
  quarter: number
  /**
   * @min 0
   * @max 15
   */
  late_hour?: HourEnum
  late_hour_display: string
  is_finished?: boolean
  students?: string[]
  fail_reason_display: string
  timetables: string
  generate_blank_timetable?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type GenerationTaskRequest = {
  /**
   * Timeout in seconds
   * @min 1
   * @max 86400
   */
  timeout?: number
  use_main_teacher_only?: boolean
  /**
   * Generate students above grade
   * @min 0
   * @max 2147483647
   */
  min_grade?: number | null
  stop_request_sent?: boolean
  optimize_only_late_slots?: boolean
  assign_rooms?: boolean
  quarter: number
  /**
   * @min 0
   * @max 15
   */
  late_hour?: HourEnum
  is_finished?: boolean
  students?: string[]
  generate_blank_timetable?: boolean
}

/**
 * * `new` - New
 * * `server_obtained` - Server has been obtained
 * * `generation_in_progress` - Generation in progress
 * * `failed` - Generation has failed
 * * `success` - Success
 */
export type GenerationTaskStatusEnum =
  | 'new'
  | 'server_obtained'
  | 'generation_in_progress'
  | 'failed'
  | 'success'

export type GradeLevel = {
  id: number
  /** @maxLength 64 */
  name: string
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  stage?: StageEnum | NullEnum | null
  stage_label: string
}

export type HaveStaffUsersClashes = {
  have_clashes: boolean
}

export type HaveStudentsClashes = {
  have_clashes: boolean
}

/** Collects and raises validation errors in bulk */
export type HeadOfHouseProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type HeadOfHouseProfileRequest = {
  /** @format uuid */
  user?: string | null
}

export type HealthCheck = {
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /** @format date-time */
  slot_start: string
  /** @format date-time */
  slot_end: string
  comment: string
  is_completed?: boolean
  added_after_time?: boolean
  check_type_str: string
  /**
   * * `room` - room check
   * * `campus` - campus check
   */
  check_type?: HealthCheckTypeEnum
  sick_report: SickReportInHealthCheck
  has_medicine: string
  has_fever: boolean
  current_location: string
}

export type HealthCheckAvailableFilters = {
  student: AvailableStringFilterHealthCheckStudent
  check_type: AvailableStringFilter
}

export type HealthCheckComment = {
  id: number
  /** @format uuid */
  health_check: string
  content: string
  /** @format date-time */
  created: string
}

export type HealthCheckCommentRequest = {
  /** @format uuid */
  health_check: string
  /** @minLength 1 */
  content: string
}

export type HealthCheckList = {
  /** @format uuid */
  id: string
  sick_report_id: string
  /** @format uuid */
  student: string
  student_str: string
  /** @format date-time */
  slot_start: string
  /** @format date-time */
  slot_end: string
  comment: string
  is_completed?: boolean
  added_after_time?: boolean
  check_type_str: string
  /**
   * * `room` - room check
   * * `campus` - campus check
   */
  check_type?: HealthCheckTypeEnum
  has_medicine: string
  current_location: string
}

export type HealthCheckLog = {
  id: number
  str: string
  set_after_time?: boolean
  /** @format uuid */
  added_by?: string | null
  added_by_str: string
  /** @format date-time */
  created: string
  /** @format date-time */
  slot_start: string
  /** @format date-time */
  slot_end: string
}

export type HealthCheckStudentOption = {
  label: string
  value: string
  searchable_content: string
}

/**
 * * `room` - room check
 * * `campus` - campus check
 */
export type HealthCheckTypeEnum = 'room' | 'campus'

export type HighSchoolGPA = {
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  unweighted_value: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  weighted_value: string
}

export type Hospital = {
  id: number
  /** @maxLength 256 */
  name: string
}
/**
 * * `0` - 8:00-8:40
 * * `1` - 8:45-9:25
 * * `2` - 9:30-10:10
 * * `3` - 10:30-11:10
 * * `4` - 11:15-11:55
 * * `5` - 13:00-13:40
 * * `6` - 13:45-14:25
 * * `7` - 14:30-15:10
 * * `8` - 15:15-15:55
 * * `9` - 16:00-16:40
 * * `10` - 16:45-17:25
 * * `11` - 17:30-18:10
 * * `12` - 18:15-18:55
 * * `13` - 19:00-19:40
 * * `14` - 19:40-20:30
 * * `15` - 20:30-21:00
 */
export type HourEnum =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15

export type House = {
  id: number
  /** @maxLength 128 */
  name: string
  tutors: string[]
  generate_reports?: boolean
  generate_reports_at_study_time?: boolean
  generate_reports_at_the_weekend?: boolean
  generate_night_attendance_reports?: boolean
  is_senior_campus?: boolean
  is_junior_campus?: boolean
}

export type HouseList = {
  id: number
  /** @maxLength 128 */
  name: string
  tutors_data: SimpleUser[]
}

export type HouseRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  tutors: string[]
  generate_reports?: boolean
  generate_reports_at_study_time?: boolean
  generate_reports_at_the_weekend?: boolean
  generate_night_attendance_reports?: boolean
  is_senior_campus?: boolean
  is_junior_campus?: boolean
}

export type HouseRoom = {
  id: number
  str: string
  code: string
  house: number
  name: string
  can_handle_isolation: boolean
  house_str: string
  reserved_slots: string
  available_slots: string
  is_reserved_now_for_student_only: boolean
  student_which_reserves_now_info: string
  status: AnnotatedHouseRoomStatusChoices
  /**
   * * `S` - Small
   * * `L` - Large
   */
  size?: SizeEnum
  /**
   * @min 0
   * @max 32767
   */
  capacity?: CapacityEnum
  /**
   * @min 0
   * @max 32767
   */
  people_per_bathroom?: number
}

export type HouseRoomAvailableFilters = {
  booked_for: AvailableStringFilterHouseRoomBookedFor
  house: AvailableStringFilter
  id: AvailableStringFilter
  code: AvailableStringFilter
  status: AvailableStringFilter
}

export type HouseRoomBookedForOption = {
  label: string
  value: string
  is_student: boolean
  searchable_content: string
}

export type HouseRoomList = {
  id: number
  str: string
  code: string
  house: number
  name: string
  can_handle_isolation: boolean
  house_str: string
  reserved_slots: string
  available_slots: string
  is_reserved_now_for_student_only: boolean
  student_which_reserves_now_info: string
  status: AnnotatedHouseRoomStatusChoices
  /**
   * * `S` - Small
   * * `L` - Large
   */
  size?: SizeEnum
  /**
   * @min 0
   * @max 32767
   */
  capacity?: CapacityEnum
  /**
   * @min 0
   * @max 32767
   */
  people_per_bathroom?: number
  is_currently_booked: boolean
  prebooked_date: string
  is_taken_by_booking: string
  places_taken: number
  booked_by: string
  occupied_places_by_accommodations: HouseRoomOccupiedPlacesByAccommodations[]
  occupied_places_by_bookings: HouseRoomOccupiedPlacesByBookings[]
}

export type HouseRoomOccupiedPlacesByAccommodations = {
  id: number
  /** @format uuid */
  occupier_id: string
  occupier_str: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date?: string | null
  is_temporary_movement?: boolean
  is_room_only_for_student?: boolean
}

export type HouseRoomOccupiedPlacesByBookings = {
  id: number
  /** @format uuid */
  occupier_id: string | null
  occupier_str: string
  /** @format date */
  start_date: string
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
  is_occupier_student: string
}

export type HouseRoomRequest = {
  /**
   * * `S` - Small
   * * `L` - Large
   */
  size?: SizeEnum
  /**
   * @min 0
   * @max 32767
   */
  capacity?: CapacityEnum
  /**
   * @min 0
   * @max 32767
   */
  people_per_bathroom?: number
}

/** Collects and raises validation errors in bulk */
export type HouseTutorProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type HouseTutorProfileRequest = {
  /** @format uuid */
  user?: string | null
}
export type IdpPlanStudent = {
  /** @format uuid */
  id: string
  full_name: string
  /** @format date */
  date_of_entry?: string | null
  entry_quarter: string
  is_new: boolean
}

export type IdpSectionEnroll = {
  id: number
  section?: number | null
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_lessons?: number
}

export type IdpSectionEnrollRequest = {
  section?: number | null
  /** @format uuid */
  course?: string | null
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_lessons?: number
}

export type IdpSectionEnrollment = {
  id: number
  /** @format uuid */
  student: string
  student_str: string
  quarter: number
  section?: number | null
  course_drop_student_idp_plan_id: string | null
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_lessons: number
  is_private?: boolean
  /** @format date-time */
  section_deleted_at?: string | null
  course_in_basket?: number | null
  /** @format uuid */
  section_deleted_by?: string | null
  course_drop?: number | null
}

export type IdpSectionEnrollmentRequest = {
  /** @format uuid */
  student: string
  quarter: number
  section?: number | null
  course_drop_data?: CourseDropDataRequest | null
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_lessons: number
  is_private?: boolean
  /** @format date-time */
  section_deleted_at?: string | null
  course_in_basket?: number | null
  /** @format uuid */
  section_deleted_by?: string | null
  course_drop?: number | null
}

/**
 * * `teacher` - Teacher
 * * `admin` - Admin
 */
export type IepContactEnum = 'teacher' | 'admin'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Image = {
  id: string
  ppoi?: string
  thumbnail: string
  catalogs?: number[]
  /** @format date-time */
  created: string
  /** @format date-time */
  created_at: string
  /** @maxLength 7 */
  dominant_color?: string
  /** @maxLength 128 */
  title?: string
  /** @maxLength 128 */
  alt?: string
  file_url: string
  /** @format uri */
  file?: string
  filename: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ImageRequest = {
  /** @minLength 1 */
  ppoi?: string
  catalogs?: number[]
  /**
   * @minLength 1
   * @maxLength 7
   */
  dominant_color?: string
  /** @maxLength 128 */
  title?: string
  /** @maxLength 128 */
  alt?: string
  /** @format binary */
  file?: File
}

/**
 * * `4` - S
 * * `3` - E
 * * `2` - A
 * * `1` - C
 * * `0` - not assigned
 */
export type ImmediateDowngradeEnum = 4 | 3 | 2 | 1 | 0

/**
 * * `medicine_and_emergency` - Apply Medicine + Contact Emergency Services (Dial 122)
 * * `emergency` - Contact Emergency Services (Dial 122)
 * * `report_to_health_coordinator` - Immediate Report to Health Coordinator + BDT
 */
export type ImmediateResponseEnum =
  | 'medicine_and_emergency'
  | 'emergency'
  | 'report_to_health_coordinator'

/**
 * * `avoid mild physical exercise` - Avoid Mild Physical Exercise
 * * `avoid intensive physical exercise` - Avoid Intensive Physical Exercise
 * * `no impact` - No Impact
 * * `concentration` - Concentration
 * * `fatigue` - Fatigue
 * * `headache` - Headache
 * * `body ache or pain` - Body Ache or Pain
 * * `cognitive issues` - Cognitive issues
 * * `flashing lights (incl. movies & video games)` - Flashing Lights (incl. Movies & Video Games)
 */
export type ImpactEnum =
  | 'avoid mild physical exercise'
  | 'avoid intensive physical exercise'
  | 'no impact'
  | 'concentration'
  | 'fatigue'
  | 'headache'
  | 'body ache or pain'
  | 'cognitive issues'
  | 'flashing lights (incl. movies & video games)'

/**
 * * `ImportSource.MANUAL` - ImportSource.MANUAL
 * * `ImportSource.AUTOMATIC` - ImportSource.AUTOMATIC
 */
export type ImportSourceEnum = 'ImportSource.MANUAL' | 'ImportSource.AUTOMATIC'

export type ImportStatusCheckResponse = {
  /**
   * * `Exam(s)` - Exams
   * * `Timetable & Boarding Rota` - Tt
   */
  type?: ImportStatusCheckResponseTypeEnum
  in_progress: boolean
}

/**
 * * `Exam(s)` - Exams
 * * `Timetable & Boarding Rota` - Tt
 */
export type ImportStatusCheckResponseTypeEnum =
  | 'Exam(s)'
  | 'Timetable & Boarding Rota'

export type IncompleteAssignmentCount = {
  me: number
  my_teams: number
}

/** Used only for correct schema display in TaskViewSet.count_daily_incomplete() */
export type IncompleteDailyTaskCount = {
  daily: number
}

/** Used only for correct schema display in TaskViewSet.count_incomplete() */
export type IncompleteTaskCount = {
  daily: number
  assignment: IncompleteAssignmentCount
  teams: IncompleteTaskPerTeamCount
  status: IncompleteTaskPerStatusCount
}

export type IncompleteTaskPerStatusCount = {
  overdue: number
  close_deadline: number
  unscheduled: number
}

export type IncompleteTaskPerTeamCount = {
  '2f53d039-567a-43a7-8d3e-9c7a16bd1ae7': number
}

/** Collects and raises validation errors in bulk */
export type InfoboardEditorProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type InfoboardEditorProfileRequest = {
  /** @format uuid */
  user?: string | null
}

export type IntegerOption = {
  label: string
  value: number
}

export type Interest = {
  id: number
  /** @maxLength 256 */
  name: string
}
export type Internship = {
  id: number
  /** @maxLength 256 */
  name: string
  is_hidden?: boolean
}

export type InternshipAttachment = {
  id: number
  /** @format uri */
  file: string
  file_name: string
}
/**
 * * `square` - Square
 * * `framed` - Framed
 * * `fullscreen` - Fullscreen
 */
export type LayoutEnum = 'square' | 'framed' | 'fullscreen'

export type LeaveCheck = {
  /** @format uuid */
  id: string
  /** @maxLength 1024 */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /**
   * * `in` - Check-in
   * * `out` - Check-out
   */
  check_type: LeaveCheckTypeEnum
  /** @maxLength 64 */
  contact_person: string
  /** @maxLength 128 */
  contact_person_phone: string
  is_completed?: boolean
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
  /** @format uuid */
  leave_request: string
}

export type LeaveCheckComplete = {
  /** @format date-time */
  date: string
}

export type LeaveCheckCompleteRequest = {
  /** @format date-time */
  date: string
}

export type LeaveCheckRequest = {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /**
   * * `in` - Check-in
   * * `out` - Check-out
   */
  check_type: LeaveCheckTypeEnum
  /**
   * @minLength 1
   * @maxLength 64
   */
  contact_person: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  contact_person_phone: string
  is_completed?: boolean
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
  /** @format uuid */
  leave_request: string
}

/**
 * * `in` - Check-in
 * * `out` - Check-out
 */
export type LeaveCheckTypeEnum = 'in' | 'out'

export type LeaveRequest = {
  /** @format uuid */
  id: string
  check_out?: RelatedLeaveCheck
  check_in?: RelatedLeaveCheck
  /** @format uuid */
  student: string
  student_str: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type: LeaveRequestTypeEnum
  get_leave_type_display: string
  /**
   * * `manual` - Manual
   * * `from idp` - From IDP
   * * `by_student` - Requested by student
   */
  request_type?: RequestTypeEnum
  get_request_type_display: string
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  status?: LeaveRequestStatusEnum
  get_status_display: string
  history_items: LeaveRequestHistoryItem[]
  check_out_is_completed: string
  check_in_is_completed: string
  has_siblings_not_completed: string
  student_message?: string
  has_clashes?: boolean
  clashes_resolved?: boolean
  approve_url: string
}

export type LeaveRequestClashes = {
  /** @format uuid */
  id: string
  /**
   * * `manual` - Manual
   * * `from idp` - From IDP
   * * `by_student` - Requested by student
   */
  request_type?: RequestTypeEnum
  request_type_label: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type?: LeaveRequestTypeEnum
  leave_type_label: string
  /** @format date-time */
  check_out_date: string
  /** @format date-time */
  check_in_date: string | null
  /** @default "leave_requests" */
  clash_type: string
}

export type LeaveRequestHistoryItem = {
  /** @format uuid */
  id: string
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  status?: LeaveRequestStatusEnum
  /** @format date-time */
  created: string
  /**
   * * `create` - Created
   * * `change` - Changed
   * * `email_send` - E-mail to parent
   * * `contact_person_approve` - Approved by parent
   * * `contact_person_reject` - Rejected by parent
   * * `academic_approve` - Approved by academic
   * * `academic_reject` - Rejected by academic
   * * `academic_requested` - Moved to requested by academic
   * * `bdt_approve` - Approved by boarding
   * * `bdt_reject` - Rejected by boarding
   * * `bdt_requested` - Moved to requested by boarding
   * * `student_cancel` - Canceled by student
   */
  log_type: LeaveRequestHistoryItemLogTypeEnum
  get_log_type_display: string
  description: string
  comment?: string
}

/**
 * * `create` - Created
 * * `change` - Changed
 * * `email_send` - E-mail to parent
 * * `contact_person_approve` - Approved by parent
 * * `contact_person_reject` - Rejected by parent
 * * `academic_approve` - Approved by academic
 * * `academic_reject` - Rejected by academic
 * * `academic_requested` - Moved to requested by academic
 * * `bdt_approve` - Approved by boarding
 * * `bdt_reject` - Rejected by boarding
 * * `bdt_requested` - Moved to requested by boarding
 * * `student_cancel` - Canceled by student
 */
export type LeaveRequestHistoryItemLogTypeEnum =
  | 'create'
  | 'change'
  | 'email_send'
  | 'contact_person_approve'
  | 'contact_person_reject'
  | 'academic_approve'
  | 'academic_reject'
  | 'academic_requested'
  | 'bdt_approve'
  | 'bdt_reject'
  | 'bdt_requested'
  | 'student_cancel'
export type LeaveRequestRequest = {
  check_out?: RelatedLeaveCheckRequest
  check_in?: RelatedLeaveCheckRequest
  /** @format uuid */
  student: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type: LeaveRequestTypeEnum
  /**
   * * `manual` - Manual
   * * `from idp` - From IDP
   * * `by_student` - Requested by student
   */
  request_type?: RequestTypeEnum
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  status?: LeaveRequestStatusEnum
  student_message?: string
  has_clashes?: boolean
  clashes_resolved?: boolean
}

/**
 * * `approve` - Approve
 * * `reject` - Reject
 * * `requested` - Requested
 */
export type LeaveRequestReviewActionEnum = 'approve' | 'reject' | 'requested'

/**
 * * `tentative` - Pending parental
 * * `confirmed_contact_person` - Pending academic
 * * `confirmed_academic` - Pending boarding
 * * `changed` - Changed
 * * `confirmed` - Approved
 * * `declined` - Rejected
 * * `declined_contact_person` - Rejected by contact person
 * * `declined_academic` - Rejected by academic
 * * `canceled` - Canceled by student
 * * `expired` - Expired
 */
export type LeaveRequestStatusEnum =
  | 'tentative'
  | 'confirmed_contact_person'
  | 'confirmed_academic'
  | 'changed'
  | 'confirmed'
  | 'declined'
  | 'declined_contact_person'
  | 'declined_academic'
  | 'canceled'
  | 'expired'

/**
 * * `0` - Weekend
 * * `1` - Holiday
 * * `2` - Other
 */
export type LeaveRequestTypeEnum = 0 | 1 | 2

/**
 * * `family_member_attended` - A family member attended Rosenberg.
 * * `friend_attended` - My friends attended Rosenberg and recommended me.
 * * `family_friend_told_me` - A family friend told me about Rosenberg.
 * * `educational_consultant` - Educational consultant.
 * * `other` - Other
 */
export type LegacyTypeEnum =
  | 'family_member_attended'
  | 'friend_attended'
  | 'family_friend_told_me'
  | 'educational_consultant'
  | 'other'
export type LessonClashes = {
  /** @format uuid */
  id: string
  course: string
  /** @format date */
  date: string
  start_hour: string
  end_hour: string
  /** @default "lessons" */
  clash_type: string
}

/**
 * * `beginner` - Beginner
 * * `intermediate` - Intermediate
 * * `advanced` - Advanced
 * * `` - Not provided
 */
export type LevelEnum = 'beginner' | 'intermediate' | 'advanced'
export type LocationEC = {
  id: number
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity_for_exam?: number | null
}

export type LogEntry = {
  id: number
  action: string
  request_id: string
  /** @format uuid */
  actor?: string | null
  /** Actor representation */
  actor_repr: string
  str: string
  object_id: string
  /** Object representation */
  object_repr: string
  additional_data?: Record<string, any>
  /** Remote address */
  remote_addr?: string | null
  /** @format date-time */
  timestamp: string
  changes: string
}

export type LoginLinkResponse = {
  token: string
  expiry: string
  user: LoginLinkUser
}

export type LoginLinkUser = {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number: string
}

export type LoginResponse = {
  token: string
  expiry: string
}

export type LoginUserResponse = {
  phone_number: string
  can_login_as_student: boolean
}

export type ManyEnrolmentsToApproved = {
  enrolments: number[]
}

export type ManyEnrolmentsToApprovedRequest = {
  enrolments: number[]
}

export type ManyEnrolmentsToRejected = {
  enrolments: number[]
  rejection_comment: string
}

export type ManyEnrolmentsToRejectedRequest = {
  enrolments: number[]
  /** @minLength 1 */
  rejection_comment: string
}

export type ManyEnrolmentsToRequested = {
  enrolments: number[]
}

export type ManyEnrolmentsToRequestedRequest = {
  enrolments: number[]
}

export type ManyPeriodGPASerializer = Record<
  string,
  {
    id: number
    /**
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,4})?$
     */
    unweighted_value: string
    /**
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,4})?$
     */
    weighted_value: string
    period: string
    gpa_for_courses: GPAForCourse[]
  }[]
>

export type ManyStaffUsersClashes = Record<
  string,
  {
    lessons: LessonClashes[]
    exams: ExamClashes[]
    programmes: ProgrammeClashes[]
  }
>

export type ManyStudentsClashes = Record<
  string,
  {
    lessons: LessonClashes[]
    exams: ExamClashes[]
    programmes: ProgrammeClashes[]
    leave_requests: LeaveRequestClashes[]
  }
>
export type MealAttendanceResponse = {
  breakfast: DinningAndSickMealAttendance
  lunch: DinningAndSickMealAttendance
  dinner: DinningAndSickMealAttendance
  is_academic_saturday: boolean
  /** @format date-time */
  last_update: string
}

export type MealAttendanceStudent = {
  name: string
  classification: ClassificationEnum
  /** @default "student" */
  role: string
}

export type MealPreferenceCount = {
  /** @min 0 */
  l1: number
  /** @min 0 */
  l2: number
  /** @min 0 */
  l3: number
  /** @min 0 */
  l4: number
  users: MealAttendanceStudent[]
}

export type MedicalReportAttachment = {
  id: number
  /** @format uri */
  file: string
  file_name: string
}

export type Medicine = {
  id: number
  created_by?: string
  /** @maxLength 255 */
  name: string
  prescription?: boolean
  tags: string
}

export type MedicineGiven = {
  /** @format uuid */
  id: string
  /** @format uuid */
  health_check: string
  /** @format uuid */
  scheduled_medicine?: string | null
  optional_medicine?: string | null
}

export type MedicineGivenRequest = {
  /** @format uuid */
  health_check: string
  /** @format uuid */
  scheduled_medicine?: string | null
  /** @minLength 1 */
  optional_medicine?: string | null
}

/** Adds nested create feature */
export type MedicineInPlan = {
  id: string
  created_by?: string
  /** @format uuid */
  student: string
  medicine: number
  medicine_name: string
  medicine_prescription: boolean
  /** @maxLength 255 */
  medicine_strength: string
  medicine_plan?: string
  /** @format date-time */
  start_date: string
  /** Is medicine only sick report */
  is_med_only_sick_report: boolean
  /** @format date-time */
  end_date: string
  /**
   * * `daily` - Daily
   * * `every_x_days` - Every "X" days
   * * `monthly` - Monthly
   * * `weekly` - Weekly
   */
  repeat_form: RepeatFormEnum
  repeat_form_label: string
  /**
   * @min 0
   * @max 2147483647
   */
  repeat_every: number
  comment?: string
  intakes?: MedicineIntakePlan[]
  is_archived: string
  student_str: string
  is_temporary: boolean
  sick_report_id: string | null
  is_optional?: boolean
}

/** Adds nested create feature */
export type MedicineInPlanRequest = {
  created_by?: string
  /** @format uuid */
  student: string
  medicine: number
  /**
   * @minLength 1
   * @maxLength 255
   */
  medicine_strength: string
  /** @minLength 1 */
  medicine_plan?: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   * * `daily` - Daily
   * * `every_x_days` - Every "X" days
   * * `monthly` - Monthly
   * * `weekly` - Weekly
   */
  repeat_form: RepeatFormEnum
  /**
   * @min 0
   * @max 2147483647
   */
  repeat_every: number
  comment?: string
  intakes?: MedicineIntakePlanRequest[]
  is_optional?: boolean
}

export type MedicineIntakePlan = {
  id: string
  medicine_in_plan?: string
  /** @format time */
  intake_time: string
  formatted_intake_time: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  dose: string
  /**
   * * `capsule` - capsule
   * * `drop` - drop
   * * `puff` - puff
   * * `sachet` - sachet
   * * `table_spoon` - table spoon
   * * `tablet` - tablet
   * * `tea_spoon` - tea spoon
   * * `finger_tip` - finger tip unit (cream)
   */
  dosage_form: DosageFormEnum
  dosage_form_label: string
}

export type MedicineIntakePlanRequest = {
  /** @minLength 1 */
  medicine_in_plan?: string
  /** @format time */
  intake_time: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  dose: string
  /**
   * * `capsule` - capsule
   * * `drop` - drop
   * * `puff` - puff
   * * `sachet` - sachet
   * * `table_spoon` - table spoon
   * * `tablet` - tablet
   * * `tea_spoon` - tea spoon
   * * `finger_tip` - finger tip unit (cream)
   */
  dosage_form: DosageFormEnum
}

/**
 * * `student` - Student (at all times)
 * * `house` - House
 * * `BDT` - BDT
 */
export type MedicineLocationEnum = 'student' | 'house' | 'BDT'

/** Adds nested create feature */
export type MedicinePlan = {
  id: string
  /** @format date-time */
  created: string
  created_by?: string
  /** @format uuid */
  student: string
  medicines: MedicineInPlan[]
  sick_report?: string | null
  is_temporary?: boolean
  /** Is medicine only sick report */
  is_med_only_sick_report?: boolean
}

/** Adds nested create feature */
export type MedicinePlanRequest = {
  created_by?: string
  /** @format uuid */
  student: string
  medicines: MedicineInPlanRequest[]
  /** @minLength 1 */
  sick_report?: string | null
  is_temporary?: boolean
  student_application_medication?: number | null
  /** Is medicine only sick report */
  is_med_only_sick_report?: boolean
}

export type MedicineRequest = {
  created_by?: string
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string
  prescription?: boolean
}

export type MedicinesInHealthCheck = {
  /** @format uuid */
  id: string
  /** @format date-time */
  should_be_given_at: string
  was_given?: boolean
  /** @format date-time */
  given_at?: string | null
  /** @format uuid */
  given_by?: string | null
  medicine_name: string
  medicine_strength: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Merit = {
  id: number
  /** @format uuid */
  student: string
  /** @format uuid */
  course?: string | null
  note: string
  merit_item: number
  merit_item_details: MeritItem
  /** @format uuid */
  author: string | null
  author_str: string
  /** @format date-time */
  created: string
  course_str: string
  lesson_date_str: string
  is_academic_absence_or_lateness_demerit: boolean
  student_str: string
  status: string
  status_display: string
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type: MeritTypeEnum
  /** @format double */
  alcohol_level?: number | null
  /** @format double */
  value?: number
  merit_type_display: string
  reviewed_by_str: string
  escalated_merit_item: MeritItem
  sanction_message: string
  escalated_sanction: NextLastSanctionEnum
  escalated_sanction_display: string
  /** @format date-time */
  reviewed_at: string | null
  house_room?: number | null
  house_room_str: string | null
  source_object_id: string
  is_for_exam_attendance: string
  added_on: string
  rejection_reason: string
  electronics_involved: boolean
  week: string
  iso_year: string
}

export type MeritConfirm = {
  id: number
  sanction_message?: string
  /** @format date */
  suspension_date_from?: string | null
  /** @format date */
  suspension_date_to?: string | null
}

export type MeritConfirmRequest = {
  sanction_message?: string
  /** @format date */
  suspension_date_from?: string | null
  /** @format date */
  suspension_date_to?: string | null
}

export type MeritFilterOptions = {
  student_options: StringOption[]
  author_options: StringOption[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type MeritItem = {
  id: number
  /**
   * * `respect` - Respect
   * * `community` - Community
   * * `safety` - Safety
   * * `health` - Health
   */
  type: MeritItemTypeEnum
  name: string
  /**
   * * `attitude` - Attitude
   * * `kindness` - Kindness
   * * `service` - Service
   * * `language` - Language
   * * `electronics` - Electronics
   * * `dress` - Dress
   * * `late` - Late
   * * `night` - Night
   * * `room` - Room
   * * `absence` - Absence
   * * `leave_request` - Leave Request
   * * `ausgang` - Ausgang
   * * `deception` - Deception
   * * `transport` - Transport
   * * `private_home` - Private Home
   * * `deliveries` - Deliveries
   * * `safety` - Safety
   * * `facilities` - Facilities
   * * `aggression` - Aggression
   * * `recklessness` - Recklessness
   * * `covid` - Covid
   * * `smoking` - Smoking
   * * `alcohol` - Alcohol
   * * `drugs` - Drugs
   * * `possession` - Possession
   * * `reputation` - Reputation
   * * `refusal` - Refusal
   * * `abusive_behaviour` - Abusive Behaviour
   */
  subtype: SubtypeEnum
  subtype_display: string
  /**
   * * `merit` - Merit
   * * `verbal_commendation` - Verbal commendation
   * * `written_commendation` - Written commendation
   * * `certificate` - Certificate
   * * `verbal_warning` - Verbal warning
   * * `written_warning` - Written warning
   * * `ultimatum` - Ultimatum
   * * `suspension` - Suspension
   * * `exclusion` - Exclusion
   */
  sanction: NextLastSanctionEnum
  sanction_display: string
  /** @format double */
  merit_factor?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  immediate_downgrade?: ImmediateDowngradeEnum | NullEnum | null
  /**
   * * `week` - Weekly
   * * `quarter` - Quarterly
   * * `semester` - Semesterly
   * * `year` - Yearly
   */
  escalation_expire_after?: EscalationExpireAfterEnum
  immediate_downgrade_display: string
  escalation_expire_after_display: string
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type: MeritTypeEnum
  profiles_that_can_review?: string[]
  profiles_that_can_add?: string[]
  system_generated: boolean
  allow_to_select?: boolean
  all_profiles_can_add?: boolean
  is_automatic_approve?: boolean
  next_merit_item?: number | null
  next_last_sanction?: NextLastSanctionEnum | BlankEnum
  has_grade_based_escalation_factor?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  pri_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  msl_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  hsl_escalation_factor?: number | null
  /** @format date */
  due_date: string
}

export type MeritItemAvailableFilters = {
  escalation_expire_after: AvailableStringFilter
  sanction: AvailableStringFilter
}

export type MeritItemBehaviour = {
  id: number
  name: string
  type: MeritItemTypeEnum
  subtype: SubtypeEnum
  merit_type: MeritTypeEnum
  sanction: NextLastSanctionEnum
  require_alcohol_level: boolean
  profiles_that_can_review: string[]
  profiles_that_can_add: string[]
  system_generated: boolean
  is_automatic_approve: boolean
}

export type MeritItemOptions = {
  types: StringOption[]
  subtypes: StringOption[]
  merit_types: StringOption[]
  profiles: StringOption[]
  sanctions: SanctionsOption
  escalation_expire_after_options: StringOption[]
  categories: IntegerOption[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type MeritItemPreview = {
  id: number
  type: MeritItemTypeEnum
  name: string
  subtype: SubtypeEnum
  subtype_display: string
  sanction: NextLastSanctionEnum
  sanction_display: string
  /** @format double */
  merit_factor: number
  immediate_downgrade: ImmediateDowngradeEnum | NullEnum | null
  escalation_expire_after: EscalationExpireAfterEnum
  immediate_downgrade_display: string
  escalation_expire_after_display: string
  merit_type: MeritTypeEnum
  profiles_that_can_review: string[]
  profiles_that_can_add: string[]
  system_generated: boolean
  allow_to_select: boolean
  all_profiles_can_add: boolean
  is_automatic_approve: boolean
  next_merit_item: number | null
  next_last_sanction: NextLastSanctionEnum
  has_grade_based_escalation_factor: boolean
  escalation_factor: number | null
  pri_escalation_factor: number | null
  msl_escalation_factor: number | null
  hsl_escalation_factor: number | null
  /** @format date */
  due_date: string
  /** @format date-time */
  downgrade_due_date?: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type MeritItemRequest = {
  /**
   * * `respect` - Respect
   * * `community` - Community
   * * `safety` - Safety
   * * `health` - Health
   */
  type: MeritItemTypeEnum
  /** @minLength 1 */
  name: string
  /**
   * * `attitude` - Attitude
   * * `kindness` - Kindness
   * * `service` - Service
   * * `language` - Language
   * * `electronics` - Electronics
   * * `dress` - Dress
   * * `late` - Late
   * * `night` - Night
   * * `room` - Room
   * * `absence` - Absence
   * * `leave_request` - Leave Request
   * * `ausgang` - Ausgang
   * * `deception` - Deception
   * * `transport` - Transport
   * * `private_home` - Private Home
   * * `deliveries` - Deliveries
   * * `safety` - Safety
   * * `facilities` - Facilities
   * * `aggression` - Aggression
   * * `recklessness` - Recklessness
   * * `covid` - Covid
   * * `smoking` - Smoking
   * * `alcohol` - Alcohol
   * * `drugs` - Drugs
   * * `possession` - Possession
   * * `reputation` - Reputation
   * * `refusal` - Refusal
   * * `abusive_behaviour` - Abusive Behaviour
   */
  subtype: SubtypeEnum
  /**
   * * `merit` - Merit
   * * `verbal_commendation` - Verbal commendation
   * * `written_commendation` - Written commendation
   * * `certificate` - Certificate
   * * `verbal_warning` - Verbal warning
   * * `written_warning` - Written warning
   * * `ultimatum` - Ultimatum
   * * `suspension` - Suspension
   * * `exclusion` - Exclusion
   */
  sanction: NextLastSanctionEnum
  /** @format double */
  merit_factor?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  immediate_downgrade?: ImmediateDowngradeEnum | NullEnum | null
  /**
   * * `week` - Weekly
   * * `quarter` - Quarterly
   * * `semester` - Semesterly
   * * `year` - Yearly
   */
  escalation_expire_after?: EscalationExpireAfterEnum
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type: MeritTypeEnum
  profiles_that_can_review?: string[]
  profiles_that_can_add?: string[]
  allow_to_select?: boolean
  all_profiles_can_add?: boolean
  is_automatic_approve?: boolean
  next_merit_item?: number | null
  next_last_sanction?: NextLastSanctionEnum | BlankEnum
  has_grade_based_escalation_factor?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  pri_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  msl_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  hsl_escalation_factor?: number | null
}

/**
 * * `respect` - Respect
 * * `community` - Community
 * * `safety` - Safety
 * * `health` - Health
 */
export type MeritItemTypeEnum = 'respect' | 'community' | 'safety' | 'health'

export type MeritLastSanctionPreview = {
  sanction: string
  sanction_display: string
  profiles_that_can_review: string[]
  profiles_that_can_add: string[]
}

export type MeritReject = {
  rejection_reason: string
}

export type MeritRejectRequest = {
  /** @minLength 1 */
  rejection_reason: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type MeritRequest = {
  /** @format uuid */
  student: string
  /** @format uuid */
  course?: string | null
  /** @minLength 1 */
  note: string
  merit_item: number
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type: MeritTypeEnum
  /** @format double */
  alcohol_level?: number | null
  /** @format double */
  value?: number
  house_room?: number | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type MeritRewardApprove = {
  /** Adds nested create feature */
  campus_absence?: CampusAbsence
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type MeritRewardApproveRequest = {
  /** Adds nested create feature */
  campus_absence?: CampusAbsenceRequest
}

export type MeritRewardParticipant = {
  id: number
  /** @format uuid */
  student: string
  student_data: StudentSimple
  homework_status: string
  category: string
  category_week_ago: string
  ausgangs_this_week: string
}
export type MeritRewardReject = {
  decision_comment: string
}

export type MeritRewardRejectRequest = {
  /** @minLength 1 */
  decision_comment: string
}

/**
 * * `CasualAnyday` - CasualAnyday
 * * `RoomService` - RoomService
 * * `BookTheCafe` - BookTheCafe
 * * `CityBrunchZurich` - CityBrunchZurich
 * * `AdditionalAusgang` - AdditionalAusgang
 * * `CategoryUpgrade` - CategoryUpgrade
 * * `StopTheClock` - StopTheClock
 */
export type MeritRewardResourcetypeEnum =
  | 'CasualAnyday'
  | 'RoomService'
  | 'BookTheCafe'
  | 'CityBrunchZurich'
  | 'AdditionalAusgang'
  | 'CategoryUpgrade'
  | 'StopTheClock'
/**
 * * `pending` - Pending
 * * `pending_attendees` - Pending attendees
 * * `approved` - Approved
 * * `rejected` - Rejected
 * * `done` - Done
 * * `cancelled` - Cancelled
 * * `expired` - Expired
 */
export type MeritRewardStatusEnum =
  | 'pending'
  | 'pending_attendees'
  | 'approved'
  | 'rejected'
  | 'done'
  | 'cancelled'
  | 'expired'

export type MeritSanctionPreview = MeritItemPreview | MeritLastSanctionPreview

export type MeritStatistics = {
  merits_count: number
  demerits_count: number
  /**
   * * `4` - S
   * * `3` - E
   * * `2` - A
   * * `1` - C
   * * `0` - not assigned
   */
  current_category?: CurrentCategoryEnum
  /**
   * * `4` - S
   * * `3` - E
   * * `2` - A
   * * `1` - C
   * * `0` - not assigned
   */
  potential_category?: PotentialCategoryEnum
}

/**
 * * `merit` - Merit
 * * `demerit` - Demerit
 */
export type MeritTypeEnum = 'merit' | 'demerit'

export type MessageResponseSchema = {
  message: string
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type MissionControl = {
  id: number
  current_aim_str: string
  level_str: string
  degree_aim_1: DegreeAim
  degree_aim_1_id?: number | null
  degree_destination_1: NationalityEnum | BlankEnum
  degree_aim_2: DegreeAim
  degree_aim_2_id?: number | null
  degree_destination_2: NationalityEnum | BlankEnum
  estimated_completion_display: string
  years_remaining: string
  career_goal: string
  interests_objs: Interest[]
  interests: number[]
  skills_objs: Skill[]
  skills: number[]
  competencies_objs: Competency[]
  competencies: number[]
  saa_student: string
  is_statemented: string
  exam_extra_time: string
  access_arrangements: string
  age: string
  /** @format date */
  estimated_completion?: string | null
  recommendations?: string
  /** @format uuid */
  student: string
}

/**
 * * `ENG` - ENG
 * * `MAT` - MAT
 * * `SCI` - SCI
 * * `MLA` - MLA
 * * `SSD` - SSD
 * * `ART` - ART
 */
export type MissionControlDepartmentEnum =
  | 'ENG'
  | 'MAT'
  | 'SCI'
  | 'MLA'
  | 'SSD'
  | 'ART'

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type MissionControlRequest = {
  degree_aim_1_id?: number | null
  degree_destination_1: NationalityEnum | BlankEnum
  degree_aim_2_id?: number | null
  degree_destination_2: NationalityEnum | BlankEnum
  interests: number[]
  skills: number[]
  competencies: number[]
  /** @format date */
  estimated_completion?: string | null
  recommendations?: string
  /** @format uuid */
  student: string
}

export type MultipleExam = {
  ids: string[]
}

export type MultipleExamRequest = {
  ids: string[]
}

/**
 * * `SAT_MAT` - SAT MAT
 * * `SAT_ENG` - SAT ENG
 * * `IELTS` - IELTS
 * * `WIDA` - WIDA
 * * `MAP` - MAP
 */
export type NameEnum = 'SAT_MAT' | 'SAT_ENG' | 'IELTS' | 'WIDA' | 'MAP'

/**
 * * `AF` - Afghanistan
 * * `AX` - Åland Islands
 * * `AL` - Albania
 * * `DZ` - Algeria
 * * `AS` - American Samoa
 * * `AD` - Andorra
 * * `AO` - Angola
 * * `AI` - Anguilla
 * * `AQ` - Antarctica
 * * `AG` - Antigua and Barbuda
 * * `AR` - Argentina
 * * `AM` - Armenia
 * * `AW` - Aruba
 * * `AU` - Australia
 * * `AT` - Austria
 * * `AZ` - Azerbaijan
 * * `BS` - Bahamas
 * * `BH` - Bahrain
 * * `BD` - Bangladesh
 * * `BB` - Barbados
 * * `BY` - Belarus
 * * `BE` - Belgium
 * * `BZ` - Belize
 * * `BJ` - Benin
 * * `BM` - Bermuda
 * * `BT` - Bhutan
 * * `BO` - Bolivia
 * * `BQ` - Bonaire, Sint Eustatius and Saba
 * * `BA` - Bosnia and Herzegovina
 * * `BW` - Botswana
 * * `BV` - Bouvet Island
 * * `BR` - Brazil
 * * `IO` - British Indian Ocean Territory
 * * `BN` - Brunei
 * * `BG` - Bulgaria
 * * `BF` - Burkina Faso
 * * `BI` - Burundi
 * * `CV` - Cabo Verde
 * * `KH` - Cambodia
 * * `CM` - Cameroon
 * * `CA` - Canada
 * * `KY` - Cayman Islands
 * * `CF` - Central African Republic
 * * `TD` - Chad
 * * `CL` - Chile
 * * `CN` - China
 * * `CX` - Christmas Island
 * * `CC` - Cocos (Keeling) Islands
 * * `CO` - Colombia
 * * `KM` - Comoros
 * * `CG` - Congo
 * * `CD` - Congo (the Democratic Republic of the)
 * * `CK` - Cook Islands
 * * `CR` - Costa Rica
 * * `CI` - Côte d'Ivoire
 * * `HR` - Croatia
 * * `CU` - Cuba
 * * `CW` - Curaçao
 * * `CY` - Cyprus
 * * `CZ` - Czechia
 * * `DK` - Denmark
 * * `DJ` - Djibouti
 * * `DM` - Dominica
 * * `DO` - Dominican Republic
 * * `EC` - Ecuador
 * * `EG` - Egypt
 * * `SV` - El Salvador
 * * `GQ` - Equatorial Guinea
 * * `ER` - Eritrea
 * * `EE` - Estonia
 * * `SZ` - Eswatini
 * * `ET` - Ethiopia
 * * `FK` - Falkland Islands (Malvinas)
 * * `FO` - Faroe Islands
 * * `FJ` - Fiji
 * * `FI` - Finland
 * * `FR` - France
 * * `GF` - French Guiana
 * * `PF` - French Polynesia
 * * `TF` - French Southern Territories
 * * `GA` - Gabon
 * * `GM` - Gambia
 * * `GE` - Georgia
 * * `DE` - Germany
 * * `GH` - Ghana
 * * `GI` - Gibraltar
 * * `GR` - Greece
 * * `GL` - Greenland
 * * `GD` - Grenada
 * * `GP` - Guadeloupe
 * * `GU` - Guam
 * * `GT` - Guatemala
 * * `GG` - Guernsey
 * * `GN` - Guinea
 * * `GW` - Guinea-Bissau
 * * `GY` - Guyana
 * * `HT` - Haiti
 * * `HM` - Heard Island and McDonald Islands
 * * `VA` - Holy See
 * * `HN` - Honduras
 * * `HK` - Hong Kong
 * * `HU` - Hungary
 * * `IS` - Iceland
 * * `IN` - India
 * * `ID` - Indonesia
 * * `IR` - Iran
 * * `IQ` - Iraq
 * * `IE` - Ireland
 * * `IM` - Isle of Man
 * * `IL` - Israel
 * * `IT` - Italy
 * * `JM` - Jamaica
 * * `JP` - Japan
 * * `JE` - Jersey
 * * `JO` - Jordan
 * * `KZ` - Kazakhstan
 * * `KE` - Kenya
 * * `KI` - Kiribati
 * * `KW` - Kuwait
 * * `KG` - Kyrgyzstan
 * * `LA` - Laos
 * * `LV` - Latvia
 * * `LB` - Lebanon
 * * `LS` - Lesotho
 * * `LR` - Liberia
 * * `LY` - Libya
 * * `LI` - Liechtenstein
 * * `LT` - Lithuania
 * * `LU` - Luxembourg
 * * `MO` - Macao
 * * `MG` - Madagascar
 * * `MW` - Malawi
 * * `MY` - Malaysia
 * * `MV` - Maldives
 * * `ML` - Mali
 * * `MT` - Malta
 * * `MH` - Marshall Islands
 * * `MQ` - Martinique
 * * `MR` - Mauritania
 * * `MU` - Mauritius
 * * `YT` - Mayotte
 * * `MX` - Mexico
 * * `FM` - Micronesia (Federated States of)
 * * `MD` - Moldova
 * * `MC` - Monaco
 * * `MN` - Mongolia
 * * `ME` - Montenegro
 * * `MS` - Montserrat
 * * `MA` - Morocco
 * * `MZ` - Mozambique
 * * `MM` - Myanmar
 * * `NA` - Namibia
 * * `NR` - Nauru
 * * `NP` - Nepal
 * * `NL` - Netherlands
 * * `NC` - New Caledonia
 * * `NZ` - New Zealand
 * * `NI` - Nicaragua
 * * `NE` - Niger
 * * `NG` - Nigeria
 * * `NU` - Niue
 * * `NF` - Norfolk Island
 * * `KP` - North Korea
 * * `MK` - North Macedonia
 * * `MP` - Northern Mariana Islands
 * * `NO` - Norway
 * * `OM` - Oman
 * * `PK` - Pakistan
 * * `PW` - Palau
 * * `PS` - Palestine, State of
 * * `PA` - Panama
 * * `PG` - Papua New Guinea
 * * `PY` - Paraguay
 * * `PE` - Peru
 * * `PH` - Philippines
 * * `PN` - Pitcairn
 * * `PL` - Poland
 * * `PT` - Portugal
 * * `PR` - Puerto Rico
 * * `QA` - Qatar
 * * `RE` - Réunion
 * * `RO` - Romania
 * * `RU` - Russia
 * * `RW` - Rwanda
 * * `BL` - Saint Barthélemy
 * * `SH` - Saint Helena, Ascension and Tristan da Cunha
 * * `KN` - Saint Kitts and Nevis
 * * `LC` - Saint Lucia
 * * `MF` - Saint Martin (French part)
 * * `PM` - Saint Pierre and Miquelon
 * * `VC` - Saint Vincent and the Grenadines
 * * `WS` - Samoa
 * * `SM` - San Marino
 * * `ST` - Sao Tome and Principe
 * * `SA` - Saudi Arabia
 * * `SN` - Senegal
 * * `RS` - Serbia
 * * `SC` - Seychelles
 * * `SL` - Sierra Leone
 * * `SG` - Singapore
 * * `SX` - Sint Maarten (Dutch part)
 * * `SK` - Slovakia
 * * `SI` - Slovenia
 * * `SB` - Solomon Islands
 * * `SO` - Somalia
 * * `ZA` - South Africa
 * * `GS` - South Georgia and the South Sandwich Islands
 * * `KR` - South Korea
 * * `SS` - South Sudan
 * * `ES` - Spain
 * * `LK` - Sri Lanka
 * * `SD` - Sudan
 * * `SR` - Suriname
 * * `SJ` - Svalbard and Jan Mayen
 * * `SE` - Sweden
 * * `CH` - Switzerland
 * * `SY` - Syria
 * * `TW` - Taiwan
 * * `TJ` - Tajikistan
 * * `TZ` - Tanzania
 * * `TH` - Thailand
 * * `TL` - Timor-Leste
 * * `TG` - Togo
 * * `TK` - Tokelau
 * * `TO` - Tonga
 * * `TT` - Trinidad and Tobago
 * * `TN` - Tunisia
 * * `TR` - Türkiye
 * * `TM` - Turkmenistan
 * * `TC` - Turks and Caicos Islands
 * * `TV` - Tuvalu
 * * `UG` - Uganda
 * * `UA` - Ukraine
 * * `AE` - United Arab Emirates
 * * `GB` - United Kingdom
 * * `UM` - United States Minor Outlying Islands
 * * `US` - United States of America
 * * `UY` - Uruguay
 * * `UZ` - Uzbekistan
 * * `VU` - Vanuatu
 * * `VE` - Venezuela
 * * `VN` - Vietnam
 * * `VG` - Virgin Islands (British)
 * * `VI` - Virgin Islands (U.S.)
 * * `WF` - Wallis and Futuna
 * * `EH` - Western Sahara
 * * `YE` - Yemen
 * * `ZM` - Zambia
 * * `ZW` - Zimbabwe
 */
export type NationalityEnum =
  | 'AF'
  | 'AX'
  | 'AL'
  | 'DZ'
  | 'AS'
  | 'AD'
  | 'AO'
  | 'AI'
  | 'AQ'
  | 'AG'
  | 'AR'
  | 'AM'
  | 'AW'
  | 'AU'
  | 'AT'
  | 'AZ'
  | 'BS'
  | 'BH'
  | 'BD'
  | 'BB'
  | 'BY'
  | 'BE'
  | 'BZ'
  | 'BJ'
  | 'BM'
  | 'BT'
  | 'BO'
  | 'BQ'
  | 'BA'
  | 'BW'
  | 'BV'
  | 'BR'
  | 'IO'
  | 'BN'
  | 'BG'
  | 'BF'
  | 'BI'
  | 'CV'
  | 'KH'
  | 'CM'
  | 'CA'
  | 'KY'
  | 'CF'
  | 'TD'
  | 'CL'
  | 'CN'
  | 'CX'
  | 'CC'
  | 'CO'
  | 'KM'
  | 'CG'
  | 'CD'
  | 'CK'
  | 'CR'
  | 'CI'
  | 'HR'
  | 'CU'
  | 'CW'
  | 'CY'
  | 'CZ'
  | 'DK'
  | 'DJ'
  | 'DM'
  | 'DO'
  | 'EC'
  | 'EG'
  | 'SV'
  | 'GQ'
  | 'ER'
  | 'EE'
  | 'SZ'
  | 'ET'
  | 'FK'
  | 'FO'
  | 'FJ'
  | 'FI'
  | 'FR'
  | 'GF'
  | 'PF'
  | 'TF'
  | 'GA'
  | 'GM'
  | 'GE'
  | 'DE'
  | 'GH'
  | 'GI'
  | 'GR'
  | 'GL'
  | 'GD'
  | 'GP'
  | 'GU'
  | 'GT'
  | 'GG'
  | 'GN'
  | 'GW'
  | 'GY'
  | 'HT'
  | 'HM'
  | 'VA'
  | 'HN'
  | 'HK'
  | 'HU'
  | 'IS'
  | 'IN'
  | 'ID'
  | 'IR'
  | 'IQ'
  | 'IE'
  | 'IM'
  | 'IL'
  | 'IT'
  | 'JM'
  | 'JP'
  | 'JE'
  | 'JO'
  | 'KZ'
  | 'KE'
  | 'KI'
  | 'KW'
  | 'KG'
  | 'LA'
  | 'LV'
  | 'LB'
  | 'LS'
  | 'LR'
  | 'LY'
  | 'LI'
  | 'LT'
  | 'LU'
  | 'MO'
  | 'MG'
  | 'MW'
  | 'MY'
  | 'MV'
  | 'ML'
  | 'MT'
  | 'MH'
  | 'MQ'
  | 'MR'
  | 'MU'
  | 'YT'
  | 'MX'
  | 'FM'
  | 'MD'
  | 'MC'
  | 'MN'
  | 'ME'
  | 'MS'
  | 'MA'
  | 'MZ'
  | 'MM'
  | 'NA'
  | 'NR'
  | 'NP'
  | 'NL'
  | 'NC'
  | 'NZ'
  | 'NI'
  | 'NE'
  | 'NG'
  | 'NU'
  | 'NF'
  | 'KP'
  | 'MK'
  | 'MP'
  | 'NO'
  | 'OM'
  | 'PK'
  | 'PW'
  | 'PS'
  | 'PA'
  | 'PG'
  | 'PY'
  | 'PE'
  | 'PH'
  | 'PN'
  | 'PL'
  | 'PT'
  | 'PR'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RU'
  | 'RW'
  | 'BL'
  | 'SH'
  | 'KN'
  | 'LC'
  | 'MF'
  | 'PM'
  | 'VC'
  | 'WS'
  | 'SM'
  | 'ST'
  | 'SA'
  | 'SN'
  | 'RS'
  | 'SC'
  | 'SL'
  | 'SG'
  | 'SX'
  | 'SK'
  | 'SI'
  | 'SB'
  | 'SO'
  | 'ZA'
  | 'GS'
  | 'KR'
  | 'SS'
  | 'ES'
  | 'LK'
  | 'SD'
  | 'SR'
  | 'SJ'
  | 'SE'
  | 'CH'
  | 'SY'
  | 'TW'
  | 'TJ'
  | 'TZ'
  | 'TH'
  | 'TL'
  | 'TG'
  | 'TK'
  | 'TO'
  | 'TT'
  | 'TN'
  | 'TR'
  | 'TM'
  | 'TC'
  | 'TV'
  | 'UG'
  | 'UA'
  | 'AE'
  | 'GB'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VU'
  | 'VE'
  | 'VN'
  | 'VG'
  | 'VI'
  | 'WF'
  | 'EH'
  | 'YE'
  | 'ZM'
  | 'ZW'

/**
 * * `aa` - Afar
 * * `ab` - Abkhaz
 * * `ae` - Avestan
 * * `af` - Afrikaans
 * * `ak` - Akan
 * * `am` - Amharic
 * * `an` - Aragonese
 * * `ar` - Arabic
 * * `as` - Assamese
 * * `av` - Avaric
 * * `ay` - Aymara
 * * `az` - Azerbaijani
 * * `ba` - Bashkir
 * * `be` - Belarusian
 * * `bg` - Bulgarian
 * * `bi` - Bislama
 * * `bm` - Bambara
 * * `bn` - Bengali
 * * `bo` - Tibetan
 * * `br` - Breton
 * * `bs` - Bosnian
 * * `ca` - Catalan
 * * `ce` - Chechen
 * * `ch` - Chamorro
 * * `co` - Corsican
 * * `cr` - Cree
 * * `cs` - Czech
 * * `cu` - Old Church Slavonic
 * * `cv` - Chuvash
 * * `cy` - Welsh
 * * `da` - Danish
 * * `de` - German
 * * `dv` - Divehi
 * * `dz` - Dzongkha
 * * `ee` - Ewe
 * * `el` - Greek
 * * `en` - English
 * * `eo` - Esperanto
 * * `es` - Spanish
 * * `et` - Estonian
 * * `eu` - Basque
 * * `fa` - Persian
 * * `ff` - Fula
 * * `fi` - Finnish
 * * `fj` - Fijian
 * * `fo` - Faroese
 * * `fr` - French
 * * `fy` - Western Frisian
 * * `ga` - Irish
 * * `gd` - Scottish Gaelic
 * * `gl` - Galician
 * * `gn` - Guaraní
 * * `gu` - Gujarati
 * * `gv` - Manx
 * * `ha` - Hausa
 * * `he` - Hebrew
 * * `hi` - Hindi
 * * `ho` - Hiri Motu
 * * `hr` - Croatian
 * * `ht` - Haitian
 * * `hu` - Hungarian
 * * `hy` - Armenian
 * * `hz` - Herero
 * * `ia` - Interlingua
 * * `id` - Indonesian
 * * `ie` - Interlingue
 * * `ig` - Igbo
 * * `ii` - Nuosu
 * * `ik` - Inupiaq
 * * `io` - Ido
 * * `is` - Icelandic
 * * `it` - Italian
 * * `iu` - Inuktitut
 * * `ja` - Japanese
 * * `jv` - Javanese
 * * `ka` - Georgian
 * * `kg` - Kongo
 * * `ki` - Kikuyu
 * * `kj` - Kwanyama
 * * `kk` - Kazakh
 * * `kl` - Kalaallisut
 * * `km` - Khmer
 * * `kn` - Kannada
 * * `ko` - Korean
 * * `kr` - Kanuri
 * * `ks` - Kashmiri
 * * `ku` - Kurdish
 * * `kv` - Komi
 * * `kw` - Cornish
 * * `ky` - Kyrgyz
 * * `la` - Latin
 * * `lb` - Luxembourgish
 * * `lg` - Ganda
 * * `li` - Limburgish
 * * `ln` - Lingala
 * * `lo` - Lao
 * * `lt` - Lithuanian
 * * `lu` - Luba-Katanga
 * * `lv` - Latvian
 * * `mg` - Malagasy
 * * `mh` - Marshallese
 * * `mi` - Māori
 * * `mk` - Macedonian
 * * `ml` - Malayalam
 * * `mn` - Mongolian
 * * `mr` - Marathi
 * * `ms` - Malay
 * * `mt` - Maltese
 * * `my` - Burmese
 * * `na` - Nauru
 * * `nb` - Norwegian Bokmål
 * * `nd` - Northern Ndebele
 * * `ne` - Nepali
 * * `ng` - Ndonga
 * * `nl` - Dutch
 * * `nn` - Norwegian Nynorsk
 * * `no` - Norwegian
 * * `nr` - Southern Ndebele
 * * `nv` - Navajo
 * * `ny` - Chichewa
 * * `oc` - Occitan
 * * `oj` - Ojibwe
 * * `om` - Oromo
 * * `or` - Oriya
 * * `os` - Ossetian
 * * `pa` - Panjabi
 * * `pi` - Pāli
 * * `pl` - Polish
 * * `ps` - Pashto
 * * `pt` - Portuguese
 * * `qu` - Quechua
 * * `rm` - Romansh
 * * `rn` - Kirundi
 * * `ro` - Romanian
 * * `ru` - Russian
 * * `rw` - Kinyarwanda
 * * `sa` - Sanskrit
 * * `sc` - Sardinian
 * * `sd` - Sindhi
 * * `se` - Northern Sami
 * * `sg` - Sango
 * * `si` - Sinhala
 * * `sk` - Slovak
 * * `sl` - Slovenian
 * * `sm` - Samoan
 * * `sn` - Shona
 * * `so` - Somali
 * * `sq` - Albanian
 * * `sr` - Serbian
 * * `ss` - Swati
 * * `st` - Southern Sotho
 * * `su` - Sundanese
 * * `sv` - Swedish
 * * `sw` - Swahili
 * * `ta` - Tamil
 * * `te` - Telugu
 * * `tg` - Tajik
 * * `th` - Thai
 * * `ti` - Tigrinya
 * * `tk` - Turkmen
 * * `tl` - Tagalog
 * * `tn` - Tswana
 * * `to` - Tonga
 * * `tr` - Turkish
 * * `ts` - Tsonga
 * * `tt` - Tatar
 * * `tw` - Twi
 * * `ty` - Tahitian
 * * `ug` - Uyghur
 * * `uk` - Ukrainian
 * * `ur` - Urdu
 * * `uz` - Uzbek
 * * `ve` - Venda
 * * `vi` - Vietnamese
 * * `vo` - Volapük
 * * `wa` - Walloon
 * * `wo` - Wolof
 * * `xh` - Xhosa
 * * `yi` - Yiddish
 * * `yo` - Yoruba
 * * `za` - Zhuang
 * * `zh` - Chinese
 * * `zu` - Zulu
 */
export type NativeLanguageEnum =
  | 'aa'
  | 'ab'
  | 'ae'
  | 'af'
  | 'ak'
  | 'am'
  | 'an'
  | 'ar'
  | 'as'
  | 'av'
  | 'ay'
  | 'az'
  | 'ba'
  | 'be'
  | 'bg'
  | 'bi'
  | 'bm'
  | 'bn'
  | 'bo'
  | 'br'
  | 'bs'
  | 'ca'
  | 'ce'
  | 'ch'
  | 'co'
  | 'cr'
  | 'cs'
  | 'cu'
  | 'cv'
  | 'cy'
  | 'da'
  | 'de'
  | 'dv'
  | 'dz'
  | 'ee'
  | 'el'
  | 'en'
  | 'eo'
  | 'es'
  | 'et'
  | 'eu'
  | 'fa'
  | 'ff'
  | 'fi'
  | 'fj'
  | 'fo'
  | 'fr'
  | 'fy'
  | 'ga'
  | 'gd'
  | 'gl'
  | 'gn'
  | 'gu'
  | 'gv'
  | 'ha'
  | 'he'
  | 'hi'
  | 'ho'
  | 'hr'
  | 'ht'
  | 'hu'
  | 'hy'
  | 'hz'
  | 'ia'
  | 'id'
  | 'ie'
  | 'ig'
  | 'ii'
  | 'ik'
  | 'io'
  | 'is'
  | 'it'
  | 'iu'
  | 'ja'
  | 'jv'
  | 'ka'
  | 'kg'
  | 'ki'
  | 'kj'
  | 'kk'
  | 'kl'
  | 'km'
  | 'kn'
  | 'ko'
  | 'kr'
  | 'ks'
  | 'ku'
  | 'kv'
  | 'kw'
  | 'ky'
  | 'la'
  | 'lb'
  | 'lg'
  | 'li'
  | 'ln'
  | 'lo'
  | 'lt'
  | 'lu'
  | 'lv'
  | 'mg'
  | 'mh'
  | 'mi'
  | 'mk'
  | 'ml'
  | 'mn'
  | 'mr'
  | 'ms'
  | 'mt'
  | 'my'
  | 'na'
  | 'nb'
  | 'nd'
  | 'ne'
  | 'ng'
  | 'nl'
  | 'nn'
  | 'no'
  | 'nr'
  | 'nv'
  | 'ny'
  | 'oc'
  | 'oj'
  | 'om'
  | 'or'
  | 'os'
  | 'pa'
  | 'pi'
  | 'pl'
  | 'ps'
  | 'pt'
  | 'qu'
  | 'rm'
  | 'rn'
  | 'ro'
  | 'ru'
  | 'rw'
  | 'sa'
  | 'sc'
  | 'sd'
  | 'se'
  | 'sg'
  | 'si'
  | 'sk'
  | 'sl'
  | 'sm'
  | 'sn'
  | 'so'
  | 'sq'
  | 'sr'
  | 'ss'
  | 'st'
  | 'su'
  | 'sv'
  | 'sw'
  | 'ta'
  | 'te'
  | 'tg'
  | 'th'
  | 'ti'
  | 'tk'
  | 'tl'
  | 'tn'
  | 'to'
  | 'tr'
  | 'ts'
  | 'tt'
  | 'tw'
  | 'ty'
  | 'ug'
  | 'uk'
  | 'ur'
  | 'uz'
  | 've'
  | 'vi'
  | 'vo'
  | 'wa'
  | 'wo'
  | 'xh'
  | 'yi'
  | 'yo'
  | 'za'
  | 'zh'
  | 'zu'

export type NewStudentsBannerResponse = {
  /** @format date */
  date_of_entry: string
  is_imported: boolean
  students: string[]
}

export type NewStudentsCountResponse = {
  new_students_count: number
}

/**
 * * `cold` - Cold
 * * `flu` - Flu
 * * `stomach` - Stomach
 * * `allergy` - Allergy
 * * `teeth` - Teeth
 * * `headache` - Headache
 * * `fatigue` - Fatigue
 * * `nausea` - Nausea
 * * `menstruation_pain` - Menstruation pain
 * * `fever` - Fever
 * * `covid_isolation` - Covid - isolation
 * * `covid_quarantine` - Covid - quarantine
 * * `other` - Other
 */
export type NewSymptomsEnum =
  | 'cold'
  | 'flu'
  | 'stomach'
  | 'allergy'
  | 'teeth'
  | 'headache'
  | 'fatigue'
  | 'nausea'
  | 'menstruation_pain'
  | 'fever'
  | 'covid_isolation'
  | 'covid_quarantine'
  | 'other'

/**
 * * `merit` - Merit
 * * `verbal_commendation` - Verbal commendation
 * * `written_commendation` - Written commendation
 * * `certificate` - Certificate
 * * `verbal_warning` - Verbal warning
 * * `written_warning` - Written warning
 * * `ultimatum` - Ultimatum
 * * `suspension` - Suspension
 * * `exclusion` - Exclusion
 */
export type NextLastSanctionEnum =
  | 'merit'
  | 'verbal_commendation'
  | 'written_commendation'
  | 'certificate'
  | 'verbal_warning'
  | 'written_warning'
  | 'ultimatum'
  | 'suspension'
  | 'exclusion'

export type NightAttendance = {
  /** @format uuid */
  id: string
  mark?: NightAttendanceMarkEnum | BlankEnum
  student_str: string
  /** @format uuid */
  student: string
  house_name: string
  room_id: string
  room_name: string
  has_comment: string
  is_at_home?: boolean
  is_at_campus_absence?: boolean
  /** @format time */
  bedtime?: string | null
  merit_note: string
  merit_electronics_involved: string
  is_marking_disabled: string
  boarding_status?: string
  is_room_replacement: string
}

export type NightAttendanceComment = {
  /** @format uuid */
  id: string
  author?: string
  author_str: string
  content: string
  /** @format date-time */
  modified: string
}

export type NightAttendanceCommentRequest = {
  author?: string
  /** @minLength 1 */
  content: string
  mark_disturbance?: boolean
}

/**
 * * `present` - Present
 * * `disturbance` - Disturbance
 * * `absent` - Absent
 */
export type NightAttendanceMarkEnum = 'present' | 'disturbance' | 'absent'

export type NightAttendanceReport = {
  id: number
  /** @format date-time */
  created: string
  status: BoardingAttendanceReportStatusEnum
  night_guard?: string
  night_guard_str: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  for_gender: ForGenderEnum
  for_gender_display: string
  status_display: string
  has_disruption?: boolean
  next_report: string
  previous_report: string
}

export type NightAttendanceReportList = {
  id: number
  /** @format date-time */
  created: string
  status: BoardingAttendanceReportStatusEnum
  night_guard_str: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  for_gender?: ForGenderEnum
  for_gender_display: string
  status_display: string
  has_disruption?: boolean
}

export type NightAttendanceReportRequest = {
  night_guard?: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  for_gender: ForGenderEnum
  has_disruption?: boolean
}

export type NightAttendanceRequest = {
  mark?: NightAttendanceMarkEnum | BlankEnum
  /** @format uuid */
  student: string
  /** @minLength 1 */
  disturbance_note: string
  electronics_involved: boolean
  is_at_home?: boolean
  is_at_campus_absence?: boolean
  /** @format time */
  bedtime?: string | null
  /** @minLength 1 */
  boarding_status?: string
}

/** Collects and raises validation errors in bulk */
export type NightGuardProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type NightGuardProfileRequest = {
  /** @format uuid */
  user?: string | null
}
export type NullEnum = null

export type ObjectTypeEnum = 'leave_request' | 'campus_absence'

export type OptionalMedicinesInHealthCheck = {
  id: string
  medicine_name: string
  /** @maxLength 255 */
  medicine_strength: string
  medicine?: number | null
  medicine_plan: string
}

/**
 * * `standard` - Standard
 * * `special` - Special
 */
export type Package0D4Enum = 'standard' | 'special'

/**
 * * `standard` - Standard
 * * `special` - Special
 */
export type PackageOptionEnum = 'standard' | 'special'
export type PaginatedAbsenceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Absence[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAcademicAttendanceAlertList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AcademicAttendanceAlert[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAcademicAttendanceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AcademicAttendance[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAccGradeList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AccGrade[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAccommodationList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Accommodation[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedAdminBoardDraftListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminBoardDraftList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminBoardList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminBoard[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedAdminCeleryTaskStatusListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminCeleryTaskStatusList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedAdminMeritRewardList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminMeritReward[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedAdminRecoveryList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminRecovery[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminSchoolYearList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminSchoolYear[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminStudentContentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminStudentContent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminTeacherList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminTeacher[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminTimetableLessonList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminTimetableLesson[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminUserListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminUserList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminWidgetList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminWidget[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAimList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Aim[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedAssignmentGradeList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AssignmentGrade[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAssignmentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Assignment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAttachmentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Attachment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAvailableStudentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AvailableStudent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedBillingReportList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: BillingReport[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedBoardingAttendanceReportListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: BoardingAttendanceReportList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedBoardingCommentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: BoardingComment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCalendarEventList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CalendarEvent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCampusAbsenceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CampusAbsence[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCancelLessonList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CancelLesson[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedChronicConditionList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ChronicCondition[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCommentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Comment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCompetencyList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Competency[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedCourseListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CourseList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCoursePlannerListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CoursePlannerList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedDayMenuList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: DayMenu[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDegreeAimList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: DegreeAim[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDepartmentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Department[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDoctorList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Doctor[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDraftActivityListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: DraftActivityList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDraftActivitySuggestionPreviewList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: DraftActivitySuggestionPreview[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDraftRotaActivityResponseList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: DraftRotaActivityResponse[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedExamList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Exam[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedFileForStudentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: FileForStudent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedGenerationTaskList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: GenerationTask[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedGradeLevelList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: GradeLevel[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedHealthCheckListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: HealthCheckList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedHouseListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: HouseList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedHouseRoomListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: HouseRoomList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedInterestList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Interest[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedInternshipList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Internship[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedLeaveRequestList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: LeaveRequest[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedLogEntryList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: LogEntry[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMedicineInPlanList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: MedicineInPlan[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMedicineList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Medicine[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMedicinePlanList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: MedicinePlan[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMeritItemList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: MeritItem[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMeritList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Merit[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMissionControlList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: MissionControl[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedNightAttendanceReportListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: NightAttendanceReportList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedParentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Parent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedProgrammeAttendanceReportList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ProgrammeAttendanceReport[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedProgrammeStudentAttendanceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ProgrammeStudentAttendance[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedQuarterList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Quarter[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  current_period?: Quarter
}
export type PaginatedRequestedCourseList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: RequestedCourse[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedRoomList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Room[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedRotaGenerationListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: RotaGenerationList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSchoolBreakList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SchoolBreak[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSchoolProgrammeListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SchoolProgrammeList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSchoolYearList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SchoolYear[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  current_period?: SchoolYear
}

export type PaginatedSectionListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SectionList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSemesterList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Semester[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  current_period?: Semester
}

export type PaginatedSickReportListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SickReportList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSimpleSectionList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SimpleSection[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSkillList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Skill[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStringOptionList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StringOption[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedStudentCampusAbsenceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentCampusAbsence[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentCategoryReportListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentCategoryReportList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedStudentCourseRequirementList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentCourseRequirement[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedStudentExternalExamList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentExternalExam[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedStudentIdpPlanListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentIdpPlanList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentInternshipList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentInternship[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedStudentListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedStudentTAEExperienceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentTAEExperience[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentTAEProjectList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentTAEProject[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedTAEDraftActivityListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: TAEDraftActivityList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  /** @example 100 */
  total_count: number
}

export type PaginatedTAEExperienceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: TAEExperience[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTaskList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Task[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTeacherTimetableListLessonList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: TeacherTimetableListLesson[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTeamList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Team[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTimetableList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Timetable[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTimetableLogList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: TimetableLog[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTimetableMentoringList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: TimetableMentoring[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTutorList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Tutor[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedWeeklyCommentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: WeeklyComment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type Parent = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name: string
  /** @maxLength 128 */
  last_name: string
  full_name: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /**
   * @format email
   * @maxLength 254
   */
  secondary_email?: string
  /** @maxLength 128 */
  phone_number: string
  /**
   * * `miss` - Miss
   * * `ms` - Ms.
   * * `mr` - Mr.
   * * `mrs` - Mrs.
   * * `title` - Title
   * * `other` - Other
   */
  salutation: SalutationEnum
  salutation_other?: string
  occupation?: string
  /**
   * * `parent` - Parent
   * * `legal_guardian` - Legal Guardian
   * * `in_legal_guardian_absence` - In Legal Guardian Absence
   * * `arrival_contact` - Arrival Contact
   */
  contact_type?: ParentContactTypeEnum
  contact_leave_request?: boolean
  is_allowed_to_visit?: boolean
  is_app_locked: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  contacts?: ParentStudentContact[]
}
/**
 * * `parent` - Parent
 * * `legal_guardian` - Legal Guardian
 * * `in_legal_guardian_absence` - In Legal Guardian Absence
 * * `arrival_contact` - Arrival Contact
 */
export type ParentContactTypeEnum =
  | 'parent'
  | 'legal_guardian'
  | 'in_legal_guardian_absence'
  | 'arrival_contact'
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type ParentRequest = {
  /** @maxLength 128 */
  first_name: string
  /** @maxLength 128 */
  last_name: string
  /**
   * Email address
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /**
   * @format email
   * @maxLength 254
   */
  secondary_email?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  phone_number: string
  /**
   * * `miss` - Miss
   * * `ms` - Ms.
   * * `mr` - Mr.
   * * `mrs` - Mrs.
   * * `title` - Title
   * * `other` - Other
   */
  salutation: SalutationEnum
  salutation_other?: string
  occupation?: string
  /**
   * * `parent` - Parent
   * * `legal_guardian` - Legal Guardian
   * * `in_legal_guardian_absence` - In Legal Guardian Absence
   * * `arrival_contact` - Arrival Contact
   */
  contact_type?: ParentContactTypeEnum
  contact_leave_request?: boolean
  is_allowed_to_visit?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  contacts?: ParentStudentContactRequest[]
}
export type ParentStudentContact = {
  id: number
  /** @format uuid */
  parent: string
  /** Student details */
  contact_data: SimpleStudentContact
  arrival_contact_for_student: string
  /** @format uuid */
  student: string
  /** Parent roles for student */
  roles: ParentContactTypeEnum[]
  has_app_access?: boolean
}

export type ParentStudentContactRequest = {
  /** @format uuid */
  student: string
  /** Parent roles for student */
  roles: ParentContactTypeEnum[]
  has_app_access?: boolean
}

export type ParentWarning = {
  id: number
  idp_plan?: string | null
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
  content: string
  accepted_by_parent: boolean
  note?: string
}

export type ParentWarningRequest = {
  /** @minLength 1 */
  idp_plan?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
  /** @minLength 1 */
  content: string
  accepted_by_parent: boolean
  note?: string
}
export type ParticipantAvailableStringFilter = {
  query_param: string
  label: string
  choices: ParticipantStringOption[]
}

export type ParticipantStringOption = {
  label: string
  value: string
  is_leader: boolean
  is_member: boolean
  is_owner: boolean
}
export type PatchedAbsenceRequest = {
  students?: string[]
  /** @format date-time */
  start_date?: string
  /** @format date-time */
  end_date?: string
  comment?: string
  /**
   * * `late` - Late
   * * `absent_excused` - Absent excused
   * * `absent_unexcused` - Absent unexcused
   * * `sick` - Sick
   */
  absence_type?: AbsenceTypeEnum
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type?: ClassTypeEnum
  added_by?: string
  last_modified_by?: string
}

export type PatchedAcademicAttendanceRequest = {
  is_present?: boolean
  is_late?: boolean
  is_absent_excused?: boolean
  is_absent_unexcused?: boolean
  is_sick?: boolean
  unlock_edit?: boolean
  /** @format date-time */
  marked_at?: string | null
}

export type PatchedAccGradeRequest = {
  quarter?: number | null
  semester?: number | null
  /** @maxLength 2000 */
  comment?: string
  /** @format uuid */
  course?: string
  /** @format uuid */
  student?: string
  custom_grade?: CustomGradeEnum | BlankEnum
}

export type PatchedAccommodationRequest = {
  /** @format uuid */
  student?: string
  room?: number
  /** @format date */
  start_date?: string
  /** @format date */
  end_date?: string | null
  is_temporary_movement?: boolean
  /**
   * * `isolation` - Isolation
   * * `quarantine` - Quarantine
   * * `sleepover` - Sleepover
   */
  temporary_movement_type?: TemporaryMovementTypeEnum
  is_room_only_for_student?: boolean
  added_by?: string
  last_modified_by?: string
}

export type PatchedAdminAbsenceRecoveryRequest = {
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype?: RecoveryResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminBoardDraftRequest = {
  /** @maxLength 128 */
  name?: string
  created_by?: string
  widget?: AdminWidgetRequest
  /** @format date-time */
  display_from?: string | null
  /** @format date-time */
  display_to?: string | null
  publish_on_infoboards?: boolean
  /**
   * * `square` - Square
   * * `framed` - Framed
   * * `fullscreen` - Fullscreen
   */
  layout?: LayoutEnum
  /**
   * @min -2147483648
   * @max 2147483647
   */
  display_time?: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminBoardRequest = {
  created_by?: string
  widget?: AdminWidgetRequest
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_from?: string
  /** @format date-time */
  display_to?: string | null
  publish_on_infoboards?: boolean
  /**
   * * `square` - Square
   * * `framed` - Framed
   * * `fullscreen` - Fullscreen
   */
  layout?: LayoutEnum
  /**
   * @min -2147483648
   * @max 2147483647
   */
  display_time?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  sort_value?: number
}
export type PatchedAdminBoardingAnnouncementWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  text?: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminCelebrationWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminClockWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminCongratulationsWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminEventAgendaWidgetRequest = {
  agenda_items?: AgendaItemRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  header?: string
  /** @format date */
  date_from?: string
  /** @format date */
  date_to?: string | null
  /** @format time */
  time_from?: string
  /** @format time */
  time_to?: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminEventInfoWidgetRequest = {
  /** @minLength 1 */
  bg_color?: string
  link?: AdminLinkWithQRCodeRequest | null
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  header?: string
  /** @maxLength 400 */
  message?: string
  /** @maxLength 64 */
  classroom_shortcut?: string
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminEventInfoWithPhotoWidgetRequest = {
  /** @minLength 1 */
  bg_color?: string
  link?: AdminLinkWithQRCodeRequest | null
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * @minLength 1
   * @maxLength 100
   */
  header?: string
  include_message?: boolean
  /** @maxLength 400 */
  message?: string
  crop_my_rosenberg_idp_photo_separately?: boolean
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  /** @minLength 1 */
  photo?: string
  /** @minLength 1 */
  photo_in_my_rosenberg_idp?: string | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminJournalWidgetRequest = {
  journal_items?: AdminJournalItemRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminLunchtimeRecoveryRequest = {
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype?: RecoveryResourcetypeEnum
}

export type PatchedAdminMenuWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  menu?: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}
export type PatchedAdminMysteryLinkWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminPosterWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /** @minLength 1 */
  image?: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminRecoveryRequest =
  | PatchedAdminAbsenceRecoveryRequest
  | PatchedAdminLunchtimeRecoveryRequest

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminSchoolYearRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name?: string
  /** @format date */
  start_date?: string
  /** @format date */
  end_date?: string
  semesters?: AdminSemesterRequest[]
}

export type PatchedAdminSiteSettingsRequest = {
  /** Allow teachers to set target grades */
  target_grades_set_allowed?: boolean
  /**
   * Setting target grades starts at
   * @format date-time
   */
  target_grades_start?: string | null
  /**
   * Setting target grades ends at
   * @format date-time
   */
  target_grades_end?: string | null
  /** Edit values, or set from pending only */
  target_grades_set_all?: boolean
  /**
   * Disable generating tasks from datetime
   * @format date-time
   */
  disable_generating_tasks_from?: string | null
  /**
   * Disable generating tasks to datetime
   * @format date-time
   */
  disable_generating_tasks_to?: string | null
  /** Disable generating take student to destination task */
  disable_take_student_to_destination_task?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminStudentAnnouncementRequest = {
  /** @format uuid */
  student?: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  emojis?: string[]
  /**
   * @minLength 1
   * @maxLength 100
   */
  text?: string
  /** @maxLength 7 */
  bg_color?: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype?: StudentContentResourcetypeEnum
}

export type PatchedAdminStudentAnnouncementWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminStudentArtGalleryWidgetRequest = {
  arts?: AdminStudentArtRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminStudentContentRequest =
  | PatchedAdminStudentMysteryLinkRequest
  | PatchedAdminStudentAnnouncementRequest
  | PatchedAdminStudentPicRequest

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminStudentMysteryLinkRequest = {
  /** @format uuid */
  student?: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  link?: AdminLinkWithQRCodeRequest
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype?: StudentContentResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminStudentPicRequest = {
  /** @format uuid */
  student?: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image?: ImageRequest
  /** @maxLength 50 */
  title?: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype?: StudentContentResourcetypeEnum
}

export type PatchedAdminStudentsPicWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminTeacherRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email?: string
  /** @minLength 1 */
  first_name?: string
  /** @minLength 1 */
  last_name?: string
  /**
   * @minLength 1
   * @maxLength 16
   */
  acronym?: string
  /** @minLength 1 */
  phone_number?: string
  departments?: number[]
  preferred_rooms?: number[]
  two_factor_auth?: boolean
  /** Teacher hidden in timetable generator */
  is_hidden_in_generator?: boolean
  custom_schedule?: Record<string, any>
  is_active?: boolean
}

export type PatchedAdminTimetableLessonUpdateRequest = {
  /** @format uuid */
  teacher_cover?: string | null
}

export type PatchedAdminUserRequest = {
  /** @minLength 1 */
  password1?: string
  /** @minLength 1 */
  password2?: string
  /** @minLength 1 */
  first_name?: string
  /** @minLength 1 */
  last_name?: string
  /**
   * @format email
   * @minLength 1
   */
  email?: string
  /** @minLength 1 */
  phone_number?: string
  two_factor_auth?: boolean
  /** @format date-time */
  last_login?: string | null
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  /** @format date-time */
  date_joined?: string
  search?: string | null
  searchable_content?: string
  /** @maxLength 256 */
  name?: string
  gender?: GenderEnum | BlankEnum
  disable_access_to_idp?: boolean
  staff_type?: StaffTypeEnum | BlankEnum
  can_handle_generator?: boolean
  is_vaccinated?: boolean
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  accept_campus_notifications?: boolean
  accept_education_notifications?: boolean
  accept_reminders_notifications?: boolean
  accept_dashboard_notifications?: boolean
  cannot_use_sms_login?: boolean
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminVariantManualCongratulationsWidgetRequest = {
  items?: AdminVariantManualCongratulationsRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @format date-time */
  published_at?: string | null
  from_automation?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminWidgetRequest =
  | PatchedAdminBoardingAnnouncementWidgetRequest
  | PatchedAdminCelebrationWidgetRequest
  | PatchedAdminEventAgendaWidgetRequest
  | PatchedAdminCongratulationsWidgetRequest
  | PatchedAdminPosterWidgetRequest
  | PatchedAdminStudentArtGalleryWidgetRequest
  | PatchedAdminEventInfoWidgetRequest
  | PatchedAdminEventInfoWithPhotoWidgetRequest
  | PatchedAdminJournalWidgetRequest
  | PatchedAdminMysteryLinkWidgetRequest
  | PatchedAdminMenuWidgetRequest
  | PatchedAdminStudentAnnouncementWidgetRequest
  | PatchedAdminStudentsPicWidgetRequest
  | PatchedAdminVariantManualCongratulationsWidgetRequest
  | PatchedAdminClockWidgetRequest
export type PatchedAppointmentCancellationRequest = {
  /** @minLength 1 */
  cancellation_reason?: string
}

export type PatchedAppointmentRequest = {
  /**
   * * `hospital` - Hospital
   * * `doctor` - Doctor
   */
  appointment_type?: AppointmentTypeEnum
  doctor?: number | null
  doctor_name?: string
  hospital?: number | null
  hospital_name?: string
  /** @format date-time */
  date_and_time?: string
  /** @minLength 1 */
  report?: string
  /** @format date-time */
  end_of_visit?: string
}

export type PatchedApproveStudentContentRequest = {
  /** @format date */
  display_to?: string | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAssignmentGradeRequest = {
  /**
   * @min 0
   * @max 100
   */
  grade_in_percent?: number | null
  /** @maxLength 32 */
  grade_in_ib?: string
  is_submitted?: boolean
  is_incomplete?: boolean
  is_late?: boolean
  is_exempt?: boolean
  /** @format uuid */
  student?: string
  assignment?: AssignmentInStudentAssignmentItemRequest
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAssignmentRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  /** @minLength 1 */
  description?: string
  type?: number
  /** @format date */
  due_date?: string
  /** @format uuid */
  lesson?: string | null
  /** @format uuid */
  course?: string
  student_items?: StudentAssignmentItemRequest[]
  is_homework?: boolean
  quarter?: number
}

export type PatchedBillingReportRequest = {
  /**
   * @minLength 1
   * @maxLength 120
   */
  name?: string
}

export type PatchedBoardingAttendanceReportRequest = {
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  /** @format uuid */
  tutor?: string | null
  house?: number | null
  for_senior_campus?: boolean
}

export type PatchedBoardingAttendanceRequest = {
  mark?: BoardingAttendanceMarkEnum | BlankEnum
  /** @format uuid */
  student?: string
  is_at_home?: boolean
  is_at_class?: boolean
  is_at_exam?: boolean
  /** @minLength 1 */
  boarding_status?: string
}

export type PatchedBoardingCommentRequest = {
  added_by?: string
  last_modified_by?: string
  /**
   * @min 0
   * @max 32767
   */
  semester?: TeamMemberRoleEnum | NullEnum | null
  /** @format uuid */
  student?: string
  school_year?: number
  current_quarter?: number
  /** @minLength 1 */
  content?: string
}

export type PatchedCalendarEventRequest = {
  /** @format date */
  date?: string
  /**
   * * `community_weekend` - Community weekend
   * * `academic_tests` - Academic tests
   */
  event_type?: CalendarEventEventTypeEnum
}

export type PatchedCampusAbsenceCommentRequest = {
  author?: string
  /** @minLength 1 */
  content?: string
  reject?: boolean
}

/** Adds nested create feature */
export type PatchedCampusAbsenceRequest = {
  created_by?: string
  last_modified_by?: string
  campus_absence_students?: CampusAbsenceStudentRequest[]
  quarter?: number
  /**
   * * `ausgang` - Ausgang
   * * `external_exam` - External exam
   * * `official` - Official
   * * `school_programme` - School Programme
   * * `extra_ausgang` - Extra Ausgang
   */
  campus_absence_type?: CampusAbsenceTypeEnum
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype?: CampusAbsenceSubTypeEnum
  /** @minLength 1 */
  leave_details?: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from?: string
  /** @format date-time */
  date_to?: string
  disable_students_length_validation?: boolean
  supervisors?: (string | null)[]
  house?: number | null
}
export type PatchedChronicConditionRequest = {
  /** @minLength 1 */
  type?: string
  subtypes?: string[]
}

export type PatchedConditionRequest = {
  /** @format uuid */
  health_check?: string
  /**
   * * `unchanged` - Unchanged
   * * `better` - Better
   * * `worse` - Worse
   */
  status?: ConditionStatusEnum
  new_symptoms?: NewSymptomsEnum[]
  new_symptoms_appeared?: boolean
}

export type PatchedCourseDescriptionRequest = {
  description?: string
}
export type PatchedCourseInBasketUpdateDepartmentRequest = {
  mission_control_department?: MissionControlDepartmentEnum | BlankEnum
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedCoursePlannerRequest = {
  number_of_lessons?: number | null
  next_courses?: string[]
  related_courses?: string[]
  course_request?: number | null
  /** @format binary */
  outline_file?: File | null
  allowed_slots?: CourseAllowedSlotsRequest[] | null
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  is_latest_version?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  /** @minLength 1 */
  difficulty?: string
  /** @minLength 1 */
  number?: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  humanix_factor?: string
  /** @maxItems 4 */
  performed_in_quarters?: QuarterShortNameEnum[]
  description?: string
  has_academic_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  exam_duration?: number | null
  has_external_exam?: boolean
  external_exam_type?: ExternalExamTypeEnum | BlankEnum
  exam_course_name?: string
  /** RPS-6527 */
  is_payable?: boolean
  /** Used in summer camps */
  is_camps_focus?: boolean
  /** Used in summer camps */
  is_camps_private?: boolean
  /** Used in billing reports for sport package++ */
  is_included_in_sport_package?: boolean
  custom_constraints?: boolean
  override_allowed_slots?: boolean
  /**
   * @min 5
   * @max 240
   */
  lesson_duration?: number | null
  override_preparation_time?: boolean
  /**
   * @min 0
   * @max 60
   */
  preparation_time_before?: number | null
  /**
   * @min 0
   * @max 60
   */
  preparation_time_after?: number | null
  legacy_department?: number | null
  /** @minLength 1 */
  group?: string
  /** @minLength 1 */
  subject?: string
  departments?: number[]
  families?: string[]
  intelligences?: string[]
  types?: string[]
  preparation_for_types?: string[]
  levels?: string[]
  rooms?: number[]
}
export type PatchedCoverConflictRequest = {
  /** @format date */
  cover_date?: string
  cover?: CoverRequest
  rota_timetable?: number
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedDayMenuRequest = {
  /** @format date */
  date?: string
  is_filled?: boolean
  is_skipped?: boolean
  /** @maxLength 120 */
  lunch_starter?: string
  /** @maxLength 120 */
  lunch_soup?: string
  /** @maxLength 120 */
  lunch_main_course?: string
  /** @maxLength 120 */
  lunch_dessert?: string
  /** @maxLength 120 */
  lunch_starter_vege?: string
  /** @maxLength 120 */
  lunch_soup_vege?: string
  /** @maxLength 120 */
  lunch_main_course_vege?: string
  /** @maxLength 120 */
  lunch_dessert_vege?: string
  /** @maxLength 120 */
  dinner_starter?: string
  /** @maxLength 120 */
  dinner_soup?: string
  /** @maxLength 120 */
  dinner_main_course?: string
  /** @maxLength 120 */
  dinner_dessert?: string
  /** @maxLength 120 */
  dinner_starter_vege?: string
  /** @maxLength 120 */
  dinner_soup_vege?: string
  /** @maxLength 120 */
  dinner_main_course_vege?: string
  /** @maxLength 120 */
  dinner_dessert_vege?: string
  /** @maxLength 120 */
  breakfast_special?: string
  /** @maxLength 120 */
  breakfast_special_vege?: string
}

export type PatchedDepartmentRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name?: string
  /** @maxLength 32 */
  label?: string
  is_talent_and_enrichment?: boolean
  /** @format uuid */
  head_of_department?: string | null
}

export type PatchedDoctorDiagnosisRequest = {
  /** @minLength 1 */
  diagnosis?: string
  /** @minLength 1 */
  recommendations?: string
  /**
   * * `new` - New
   * * `cancelled` - Cancelled
   * * `after_the_visit` - After the visit
   */
  status?: AppointmentStatusEnum
}

export type PatchedDoctorRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name?: string
}

export type PatchedDraftActivityCreateRequest = {
  create_new_section?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  day?: RotaDayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /** @format double */
  duration?: number
  is_archived?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  fet_id?: number | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  /** @format uuid */
  course?: string
  /**
   * Artisan
   * @format uuid
   */
  teacher?: string
  room?: number | null
  timetable?: number
  section?: number | null
  from_optimization?: number | null
  /** @format uuid */
  separated_from?: string | null
  students?: string[]
}

export type PatchedDraftRotaActivityConflictCheckRequest = {
  /** @format uuid */
  user?: string | null
  users?: string[]
  /**
   * * `regular` - Duty
   * * `break` - Break
   * * `meeting` - Leaders Meeting
   * * `night_presence` - Night Presence
   * * `ski_trip` - Ski trip
   * * `sudden_time_off` - Sudden Time Off
   */
  rota_type?: RotaTypeEnum
  rota_timetable?: number
  /**
   * @min 0
   * @max 2147483647
   */
  day?: RotaDayEnum
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
  /** @format uuid */
  team?: string | null
  role?: BoardingArtisanRoleEnum | BlankEnum
  room?: number | null
  /** @format date */
  date?: string
}

export type PatchedDraftRotaActivityRequest = {
  /** @format uuid */
  user?: string | null
  users?: string[]
  /**
   * * `regular` - Duty
   * * `break` - Break
   * * `meeting` - Leaders Meeting
   * * `night_presence` - Night Presence
   * * `ski_trip` - Ski trip
   * * `sudden_time_off` - Sudden Time Off
   */
  rota_type?: RotaTypeEnum
  rota_timetable?: number
  /**
   * @min 0
   * @max 2147483647
   */
  day?: RotaDayEnum
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
  /** @format uuid */
  team?: string | null
  role?: BoardingArtisanRoleEnum | BlankEnum
  room?: number | null
  cover?: CoverRequest | null
  /** @format date */
  cover_date?: string
  /** @format date */
  date?: string
}

export type PatchedDrinkGiveRequest = {
  was_given?: boolean
  /** @format uuid */
  health_check?: string
}
export type PatchedExamRequest = {
  /** @format uuid */
  course?: string
  /** @format date */
  date?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  duration?: number | null
  /** @format time */
  start_time?: string | null
  /** @format time */
  end_time?: string | null
  location?: number | null
  test_invigilators?: string[]
  saa_invigilators?: string[]
  teachers?: string[]
  grade_levels?: number[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedExamSessionRequest = {
  /** @minLength 1 */
  name?: string
  /**
   * * `quarter` - Quarter exams
   * * `academic` - Academic
   * * `external` - External exams
   */
  exam_type?: ExamTypeEnum
  /** @format date */
  start_date?: string
  /** @format date */
  end_date?: string
  quarter?: number
  departments?: number[]
  grade_levels?: number[]
  cancellation_dates?: ExamSessionCancellationDateRequest[]
}
export type PatchedFileForStudentRequest = {
  /** @maxLength 100 */
  name?: string
  author?: string
  /** @format binary */
  file?: File
  /**
   * @format uri
   * @minLength 1
   * @maxLength 200
   */
  link?: string
  display_in?: DisplayInEnum[]
}

export type PatchedFirstContactRequest = {
  symptoms?: SymptomsEnum[]
  /** @minLength 1 */
  details?: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  temperature?: string | null
}

export type PatchedFoodGiveRequest = {
  was_given?: boolean
  /** @format uuid */
  health_check?: string
}

export type PatchedHealthCheckCommentRequest = {
  /** @format uuid */
  health_check?: string
  /** @minLength 1 */
  content?: string
}
export type PatchedHouseRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  tutors?: string[]
  generate_reports?: boolean
  generate_reports_at_study_time?: boolean
  generate_reports_at_the_weekend?: boolean
  generate_night_attendance_reports?: boolean
  is_senior_campus?: boolean
  is_junior_campus?: boolean
}

export type PatchedHouseRoomRequest = {
  /**
   * * `S` - Small
   * * `L` - Large
   */
  size?: SizeEnum
  /**
   * @min 0
   * @max 32767
   */
  capacity?: CapacityEnum
  /**
   * @min 0
   * @max 32767
   */
  people_per_bathroom?: number
}

export type PatchedIdpSectionEnrollRequest = {
  section?: number | null
  /** @format uuid */
  course?: string | null
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_lessons?: number
}
export type PatchedLeaveCheckRequest = {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  destination?: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /**
   * * `in` - Check-in
   * * `out` - Check-out
   */
  check_type?: LeaveCheckTypeEnum
  /**
   * @minLength 1
   * @maxLength 64
   */
  contact_person?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  contact_person_phone?: string
  is_completed?: boolean
  is_driver_needed?: boolean
  /** @format date-time */
  date?: string
  /** @format uuid */
  leave_request?: string
}

export type PatchedLeaveRequestRequest = {
  check_out?: RelatedLeaveCheckRequest
  check_in?: RelatedLeaveCheckRequest
  /** @format uuid */
  student?: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type?: LeaveRequestTypeEnum
  /**
   * * `manual` - Manual
   * * `from idp` - From IDP
   * * `by_student` - Requested by student
   */
  request_type?: RequestTypeEnum
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  status?: LeaveRequestStatusEnum
  student_message?: string
  has_clashes?: boolean
  clashes_resolved?: boolean
}
export type PatchedMedicineGivenRequest = {
  /** @format uuid */
  health_check?: string
  /** @format uuid */
  scheduled_medicine?: string | null
  /** @minLength 1 */
  optional_medicine?: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedMeritItemRequest = {
  /**
   * * `respect` - Respect
   * * `community` - Community
   * * `safety` - Safety
   * * `health` - Health
   */
  type?: MeritItemTypeEnum
  /** @minLength 1 */
  name?: string
  /**
   * * `attitude` - Attitude
   * * `kindness` - Kindness
   * * `service` - Service
   * * `language` - Language
   * * `electronics` - Electronics
   * * `dress` - Dress
   * * `late` - Late
   * * `night` - Night
   * * `room` - Room
   * * `absence` - Absence
   * * `leave_request` - Leave Request
   * * `ausgang` - Ausgang
   * * `deception` - Deception
   * * `transport` - Transport
   * * `private_home` - Private Home
   * * `deliveries` - Deliveries
   * * `safety` - Safety
   * * `facilities` - Facilities
   * * `aggression` - Aggression
   * * `recklessness` - Recklessness
   * * `covid` - Covid
   * * `smoking` - Smoking
   * * `alcohol` - Alcohol
   * * `drugs` - Drugs
   * * `possession` - Possession
   * * `reputation` - Reputation
   * * `refusal` - Refusal
   * * `abusive_behaviour` - Abusive Behaviour
   */
  subtype?: SubtypeEnum
  /**
   * * `merit` - Merit
   * * `verbal_commendation` - Verbal commendation
   * * `written_commendation` - Written commendation
   * * `certificate` - Certificate
   * * `verbal_warning` - Verbal warning
   * * `written_warning` - Written warning
   * * `ultimatum` - Ultimatum
   * * `suspension` - Suspension
   * * `exclusion` - Exclusion
   */
  sanction?: NextLastSanctionEnum
  /** @format double */
  merit_factor?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  immediate_downgrade?: ImmediateDowngradeEnum | NullEnum | null
  /**
   * * `week` - Weekly
   * * `quarter` - Quarterly
   * * `semester` - Semesterly
   * * `year` - Yearly
   */
  escalation_expire_after?: EscalationExpireAfterEnum
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type?: MeritTypeEnum
  profiles_that_can_review?: string[]
  profiles_that_can_add?: string[]
  allow_to_select?: boolean
  all_profiles_can_add?: boolean
  is_automatic_approve?: boolean
  next_merit_item?: number | null
  next_last_sanction?: NextLastSanctionEnum | BlankEnum
  has_grade_based_escalation_factor?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  pri_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  msl_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  hsl_escalation_factor?: number | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedMeritRequest = {
  /** @format uuid */
  student?: string
  /** @format uuid */
  course?: string | null
  /** @minLength 1 */
  note?: string
  merit_item?: number
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type?: MeritTypeEnum
  /** @format double */
  alcohol_level?: number | null
  /** @format double */
  value?: number
  house_room?: number | null
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedMissionControlRequest = {
  degree_aim_1_id?: number | null
  degree_destination_1?: NationalityEnum | BlankEnum
  degree_aim_2_id?: number | null
  degree_destination_2?: NationalityEnum | BlankEnum
  interests?: number[]
  skills?: number[]
  competencies?: number[]
  /** @format date */
  estimated_completion?: string | null
  recommendations?: string
  /** @format uuid */
  student?: string
}

export type PatchedNightAttendanceCommentRequest = {
  author?: string
  /** @minLength 1 */
  content?: string
  mark_disturbance?: boolean
}

export type PatchedNightAttendanceReportRequest = {
  night_guard?: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  for_gender?: ForGenderEnum
  has_disruption?: boolean
}

export type PatchedNightAttendanceRequest = {
  mark?: NightAttendanceMarkEnum | BlankEnum
  /** @format uuid */
  student?: string
  /** @minLength 1 */
  disturbance_note?: string
  electronics_involved?: boolean
  is_at_home?: boolean
  is_at_campus_absence?: boolean
  /** @format time */
  bedtime?: string | null
  /** @minLength 1 */
  boarding_status?: string
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedParentRequest = {
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  /**
   * Email address
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email?: string
  /**
   * @format email
   * @maxLength 254
   */
  secondary_email?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  phone_number?: string
  /**
   * * `miss` - Miss
   * * `ms` - Ms.
   * * `mr` - Mr.
   * * `mrs` - Mrs.
   * * `title` - Title
   * * `other` - Other
   */
  salutation?: SalutationEnum
  salutation_other?: string
  occupation?: string
  /**
   * * `parent` - Parent
   * * `legal_guardian` - Legal Guardian
   * * `in_legal_guardian_absence` - In Legal Guardian Absence
   * * `arrival_contact` - Arrival Contact
   */
  contact_type?: ParentContactTypeEnum
  contact_leave_request?: boolean
  is_allowed_to_visit?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  contacts?: ParentStudentContactRequest[]
}

export type PatchedProgrammeAttendanceReportRequest = {
  programme?: number
  /** @maxLength 64 */
  location?: string
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  assigned_to?: string
}

export type PatchedProgrammeStudentAttendanceRequest = {
  report?: number
  /** @format date-time */
  marked_at?: string | null
  is_present?: boolean
  is_late?: boolean
  is_absent_unexcused?: boolean
  /** is exempt */
  is_absent_excused?: boolean
  is_sick?: boolean
  is_disturbance?: boolean
  is_marked_automatically?: boolean
  is_marked?: boolean
  disturbance_merit_note?: string
}
/** Adds nested create feature */
export type PatchedPublishBoardRequest = {
  widget?: PublishWidgetSimpleRequest
  publish_on_infoboards?: boolean
  /** @format date-time */
  display_from?: string | null
  /** @format date-time */
  display_to?: string | null
}

export type PatchedRoomBookingUpdateRequest = {
  /** @format date */
  start_date?: string
  /** @format uuid */
  booked_for?: string | null
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
  last_modified_by?: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedRoomRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  is_private?: boolean
  msl_room?: boolean
  pri_room?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  group?: RoomGroupEnum | NullEnum | null
  schedule_automatically?: boolean
  available_for_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  capacity_for_exam?: number | null
  department?: number
  override_allowed_slots?: boolean
  allowed_slots?: RoomAllowedSlotsRequest[] | null
  allow_multiple_activities?: boolean
  /**
   * @min 2
   * @max 2147483647
   */
  max_activities_number?: number | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedSchoolBreakRequest = {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name?: string
  school_year?: number
  /** @format date */
  start_date?: string
  /** @format date */
  end_date?: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedSchoolProgrammeRequest = {
  is_compulsory?: boolean
  /**
   * * `house_evening` - House Evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `absence_recovery` - Absence Recovery
   * * `lunchtime_recovery` - Lunchtime Recovery
   */
  type?: SchoolProgrammeTypeEnum
  /**
   * @minLength 1
   * @maxLength 128
   */
  title?: string
  description?: string
  parental_approval_required?: boolean
  message_to_parents?: string
  enrol_all_students?: boolean
  categories?: CategoriesEnum[]
  houses?: number[]
  grade_levels?: number[]
  ages?: number[]
  added_by?: string
  last_modified_by?: string
  leaders?: string[]
  members?: string[]
  /** @format date-time */
  start_date?: string
  /** @format date-time */
  end_date?: string
  /** @format date-time */
  enrolment_deadline?: string | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  cost?: string | null
  /**
   * @min 0
   * @max 32767
   */
  students_limit?: number
  risk_assessment_file?: number
  other_files?: number[]
  enrolments?: ProgrammeEnrolmentRequest[]
  rejection_reason?: string
}

/** Adds nested create feature */
export type PatchedSectionRequest = {
  enrollments?: SectionEnrollmentRequest[]
  create_for_quarters?: number[]
  is_mentoring?: boolean | null
  must_be_consecutive?: boolean
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  quarter?: number
  /** @format uuid */
  course?: string
  /**
   * Main teacher
   * @format uuid
   */
  teacher?: string
  teachers?: string[]
}

export type PatchedSickReportAbsenceDatesRequest = {
  created_by?: string
  last_modified_by?: string
  /** @minLength 1 */
  sick_report?: string
  /** @format date-time */
  absent_from?: string | null
  /** @format date-time */
  absent_to?: string | null
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type?: ClassTypeEnum
  omit_campus_checks?: boolean
}

export type PatchedSickReportCommentRequest = {
  author?: string
  /** @minLength 1 */
  content?: string
}

export type PatchedSickReportRequest = {
  /**
   * * `regular` - Regular
   * * `med_only` - Med-only
   */
  type?: SickReportTypeEnum
  /** @format uuid */
  student?: string
  generate_absences_on_create?: boolean
  close_reason?: CloseReasonEnum | BlankEnum
  /** @format date-time */
  closed_at?: string | null
  created_by?: string
  last_modified_by?: string
}

export type PatchedStudentAccountOverdueRequest = {
  account_overdue?: AccountOverdueEnum
}
export type PatchedStudentAimForCourseRequest = {
  /** @format uuid */
  student?: string
  /** @format uuid */
  course?: string
  aim?: number | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedStudentAllergyRequest = {
  /** @format uuid */
  student?: string
  /** @minLength 1 */
  allergen_kind?: string
  allergen_subtypes?: string[]
  reactions?: StudentAllergyReactionRequest[]
  medicines?: string[]
  is_emergency_medicine?: boolean
  medicine_location?: MedicineLocationEnum | BlankEnum
  immediate_response?: ImmediateResponseEnum | BlankEnum
}

export type PatchedStudentBloodTypeRequest = {
  /**
   * * `0-` - 0-
   * * `0+` - 0+
   * * `a-` - A-
   * * `a+` - A+
   * * `b-` - B-
   * * `b+` - B+
   * * `ab-` - AB-
   * * `ab+` - AB+
   * * `unknown` - Unknown
   */
  blood_type?: BloodTypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedStudentCampusAbsenceRequest = {
  created_by?: string
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype?: CampusAbsenceSubTypeEnum
  custom_name?: string
  leave_details?: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from?: string
  /** @format date-time */
  date_to?: string
  campus_absence_students?: StudentCampusAbsenceStudentRequest[]
}

export type PatchedStudentCategoryItemRequest = {
  /** @format uuid */
  student?: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  assigned_category?: AssignedCategoryEnum
  is_completed?: boolean
}

export type PatchedStudentChronicConditionRequest = {
  /** @format uuid */
  student?: string
  /** @minLength 1 */
  condition_type?: string
  condition_subtype?: string[]
  other_condition_type?: string
  impact?: ImpactEnum[]
  watch_out_for?: WatchOutForEnum[]
  emergency_medicines?: string[]
  emergency_medicine_applications?: EmergencyMedicineApplicationsEnum[]
  emergency_medicine_location?: EmergencyMedicineLocationEnum[]
  iep_contact?: IepContactEnum[]
  immediate_response?: ImmediateResponseEnum | BlankEnum
  comment?: string
  has_medicine_plan?: boolean
  has_emergency_medicine?: boolean
  has_iep?: boolean
}
export type PatchedStudentCourseRequirementRequest = {
  /** @minLength 1 */
  min_grade?: string
  /** @minLength 1 */
  current_grade?: string
  /** @minLength 1 */
  potential_grade?: string
  /**
   * @min 0
   * @max 100
   */
  min_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  current_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  potential_percents?: number | null
  aim?: number | null
  year?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedStudentDetailRequest = {
  /** @maxLength 256 */
  nickname?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  first_name?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  last_name?: string
  /**
   * @minLength 1
   * @maxLength 256
   */
  name?: string
  current_grade_level?: number
  /**
   * Email address
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email?: string
  /** @maxLength 128 */
  phone_number?: string
  /** @maxItems 249 */
  nationalities?: (NationalityEnum | BlankEnum)[]
  native_language?: NativeLanguageEnum | BlankEnum
  education_details?: string
  /** @format date */
  date_of_birth?: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  gender?: GenderEnum
  is_at_home?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  saa_student?: boolean
  is_statemented?: boolean
  /**
   * @min -2147483648
   * @max 2147483647
   */
  exam_extra_time?: ExamExtraTimeEnum | NullEnum | null
  access_arrangements?: AccessArrangementsEnum[]
  is_draft?: boolean
  external_id_in_invoice_system?: string
  account_overdue?: AccountOverdueEnum
  has_any_dietary_restrictions?: boolean
  dietary_restrictions?: string
  /** @format date */
  date_of_entry?: string | null
}

export type PatchedStudentDietaryRestrictionsRequest = {
  has_any_dietary_restrictions?: boolean
  dietary_restrictions?: string
}

export type PatchedStudentExternalExamRequest = {
  /**
   * * `ACT` - ACT
   * * `A_LEVEL` - A Level (GCE), iGCSE
   * * `AP` - Advanced Placement
   * * `CAMBRIDGE` - CAMBRIDGE
   * * `DELE` - DELE
   * * `DELF` - DELF
   * * `GCSE` - GCSE
   * * `GMAT` - GMAT
   * * `GOETHE` - GOETHE
   * * `IAL` - International A Level
   * * `IB` - International Baccalaureate
   * * `IELTS` - IELTS
   * * `LAMDA` - LAMDA
   * * `MAP` - MAP
   * * `SAT_ENG` - SAT ENG
   * * `SAT_MAT` - SAT MAT
   * * `WIDA` - WIDA
   */
  type?: ExternalExamTypeEnum
  /** @format date */
  date?: string
  /** @format double */
  min_score?: number | null
  /** @format double */
  score?: number | null
  /** @maxLength 16 */
  subtype?: string
}

export type PatchedStudentFurtherMedicalInfoRequest = {
  is_more_medical_information_available?: boolean
  more_medical_information?: string
  is_student_swimmer?: boolean
  has_student_contact_with_drugs?: boolean
  student_fitness_for_sports?: StudentFitnessForSportsEnum | BlankEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedStudentIdpPlanRequest = {
  baskets?: BasketRequest[]
  /** @format uuid */
  student?: string
  note?: string
  grade_level?: number
  /** @minLength 1 */
  aim?: string
  year?: number
  draft_payload?: Record<string, any>
  exams?: ExamScoreRequest[]
  warnings?: ParentWarningRequest[]
  /** @default true */
  commit?: boolean
  /** @format double */
  credit_count?: number | null
  courses_mission_control_departments?: CourseMissionControlDepartmentRequest[]
  parent_message?: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedStudentInternshipsUpdateRequest = {
  internships?: StudentInternshipUpdateRequest[]
}
export type PatchedStudentProfileRequest = {
  /** @format double */
  merit_points?: number
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  favourites?: string[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedStudentPsychologicalCareRequest = {
  has_any_mental_health_issues?: boolean
  has_regular_psychological_care?: boolean
  summer_mental_health_issues?: SummerMentalHealthIssueRequest[]
}

export type PatchedStudentTAEExperienceRequest = {
  experience?: number
  /** @format date */
  date?: string
}

export type PatchedStudentTAEProjectRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name?: string
  /** @format date */
  expected_completion_date?: string
  /** @format date */
  completion_date?: string | null
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedSummerCampStudentAssignsRequest = {
  /** Course booking additional comment */
  additional_comments?: string
  summer_assigns?: SummerSubjectAssignRequest[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedSummerCampStudentRequest = {
  /**
   * * `standard` - Standard
   * * `special` - Special
   */
  package?: Package0D4Enum
  has_tetanus_vaccination?: boolean
  /** @format date */
  tetanus_vaccination_date?: string | null
}

export type PatchedSummerCertificateDraftRequest = {
  /** It's a helper field for storing data only for frontend purposes. */
  draft?: Record<string, any>
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedTaskRequest = {
  /** @minLength 1 */
  title?: string
  description?: string
  /**
   * @format uri
   * @maxLength 200
   */
  link?: string
  /** @format date-time */
  deadline?: string | null
  created_by?: string
  /** @format uuid */
  assignee?: string | null
  /** @format uuid */
  team?: string | null
  subtasks?: SubtaskRequest[]
  done_comment?: string
  recurring?: RecurringRequest | null
  is_cloned?: boolean
  /** @minLength 1 */
  cloned_from?: string | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedTeamRequest = {
  /** @minLength 1 */
  name?: string
  house?: number | null
  members?: TeamMemberRequest[]
  include_in_boarding_rota?: boolean
}

export type PatchedTemperatureCheckRequest = {
  /** @format uuid */
  health_check?: string
  /** @format double */
  temperature?: number
}

export type PatchedTimetableAutoImportConfigUpdateRequest = {
  is_enabled?: boolean
  is_daily?: boolean
  days?: RotaDayEnum[]
  /** @format time */
  time?: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedTimetableMentoringRequest = {
  ignored_students?: string[]
  timetable?: number
  grades_to_assign?: number[]
  /**
   * @min 0
   * @max 2147483647
   */
  students_in_section?: number
  copy_from_previous_quarter?: boolean
  teachers_per_grade?: TeachersPerGradeRequest[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedTimetableRequest = {
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: Status97AEnum
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
}
export type PatchedWeeklyCommentRequest = {
  /**
   * @minLength 1
   * @maxLength 2000
   */
  content?: string
  /** @format uuid */
  course?: string
  /** @format uuid */
  student?: string
}

export type PendingMerit = {
  id: number
  /** @format date-time */
  created: string
}

export type PendingMeritsPreview = {
  merits: PendingMerit[]
}

export type Period = {
  /**
   * @min 0
   * @max 5
   */
  day: number
  /** @format time */
  start: string
  /** @format time */
  end: string
}
/**
 * * `4` - S
 * * `3` - E
 * * `2` - A
 * * `1` - C
 * * `0` - not assigned
 */
export type PotentialCategoryEnum = 4 | 3 | 2 | 1 | 0
/**
 * * `academic_individual` - Academic Individual
 * * `sports` - Sports
 * * `co_curricular` - Co-Curricular
 * * `graduation_fee` - Graduation Fee
 * * `art_materials` - Art Materials
 * * `a_level_program` - A-level Programme
 * * `ib_programme` - IB Programme
 * * `ap_programme` - AP Programme
 * * `ielts_preparation_course` - IELTS Preparation Course
 * * `sat_preparation_course` - SAT/ACT
 * * `private_lessons` - Private Lessons
 * * `accommodation_single` - Accommodation - Single room
 * * `accommodation_double` - Accommodation - Double room
 */
export type ProductTypeEnum =
  | 'academic_individual'
  | 'sports'
  | 'co_curricular'
  | 'graduation_fee'
  | 'art_materials'
  | 'a_level_program'
  | 'ib_programme'
  | 'ap_programme'
  | 'ielts_preparation_course'
  | 'sat_preparation_course'
  | 'private_lessons'
  | 'accommodation_single'
  | 'accommodation_double'

/**
 * * `academic_individual` - Academic Individual
 * * `sports` - Sports
 * * `co_curricular` - Co-Curricular
 * * `graduation_fee` - Graduation Fee
 * * `art_materials` - Art Materials
 * * `a_level_program` - A-level Programme
 * * `ib_programme` - IB Programme
 * * `ap_programme` - AP Programme
 * * `ielts_preparation_course` - IELTS Preparation Course
 * * `sat_preparation_course` - SAT/ACT
 * * `private_lessons` - Private Lessons
 * * `accommodation_single` - Accommodation - Single room
 * * `accommodation_double` - Accommodation - Double room
 */
export type ProductTypesEnum =
  | 'academic_individual'
  | 'sports'
  | 'co_curricular'
  | 'graduation_fee'
  | 'art_materials'
  | 'a_level_program'
  | 'ib_programme'
  | 'ap_programme'
  | 'ielts_preparation_course'
  | 'sat_preparation_course'
  | 'private_lessons'
  | 'accommodation_single'
  | 'accommodation_double'

export type ProgrammeAttendanceReport = {
  id: number
  /** @format date-time */
  created: string
  programme: number
  /** @maxLength 64 */
  location?: string
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  assigned_to?: string
  status_display: string
  previous_obj_id: string
  next_obj_id: string
  is_performable_by_requested_user: string
}

export type ProgrammeAttendanceReportCreate = {
  programme: number
  assigned_to?: string
}

export type ProgrammeAttendanceReportCreateRequest = {
  programme: number
  assigned_to?: string
}

export type ProgrammeAttendanceReportRecovery = {
  id: number
  /** @format date-time */
  created: string
  programme: number
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  status_display: string
}
export type ProgrammeAttendanceReportUpdate = {
  /** @maxLength 64 */
  location?: string
}

export type ProgrammeAttendanceReportUpdateRequest = {
  /** @maxLength 64 */
  location?: string
}

export type ProgrammeClashes = {
  id: number
  /** @maxLength 128 */
  title: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @default "programmes" */
  clash_type: string
}

export type ProgrammeEnrolment = {
  id: number
  status: EnrolmentStatusEnum
  rejection_comment: string
  from_custom_list?: boolean
  /** @format uuid */
  student: string
  student_data: SimpleEnrolledStudent
  student_house?: number | null
  student_grade_level: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  student_category?: StudentCategoryEnum | NullEnum | null
  student_category_label: string
}

export type ProgrammeEnrolmentRequest = {
  from_custom_list?: boolean
  /** @format uuid */
  student: string
  student_house?: number | null
  student_grade_level: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  student_category?: StudentCategoryEnum | NullEnum | null
}

export type ProgrammeStudentAttendance = {
  id: number
  /** @format date-time */
  created: string
  report: number
  student_str: string
  /** @format date-time */
  marked_at?: string | null
  is_present?: boolean
  is_late?: boolean
  is_absent_unexcused?: boolean
  /** is exempt */
  is_absent_excused?: boolean
  is_sick?: boolean
  is_disturbance?: boolean
  is_marked_automatically?: boolean
  is_marked?: boolean
  disturbance_merit_note?: string
  is_performable_by_requested_user: string
}

export type ProgrammeStudentAttendanceUpdate = {
  id: number
  is_present?: boolean
  is_late?: boolean
  is_absent_unexcused?: boolean
  /** is exempt */
  is_absent_excused?: boolean
  is_sick?: boolean
  is_disturbance?: boolean
  is_marked_automatically?: boolean
  disturbance_merit_note?: string
}

export type ProgrammeStudentAttendanceUpdateRequest = {
  is_present?: boolean
  is_late?: boolean
  is_absent_unexcused?: boolean
  /** is exempt */
  is_absent_excused?: boolean
  is_sick?: boolean
  is_disturbance?: boolean
  is_marked_automatically?: boolean
  disturbance_merit_note?: string
}
/** Collects and raises validation errors in bulk */
export type PropertyManagerProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type PropertyManagerProfileRequest = {
  /** @format uuid */
  user?: string | null
}
/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicImage = {
  id: string
  images: string
  alt: string
  title: string
  /** @format uri */
  file?: string
  height: number | null
  width: number | null
  ppoi_tuple: string
  /** @maxLength 7 */
  dominant_color?: string
  has_transparency?: boolean
}
/** Adds nested create feature */
export type PublishBoard = {
  id: string
  widget: PublishWidgetSimple
  publish_on_infoboards?: boolean
  /** @format date-time */
  display_from?: string | null
  /** @format date-time */
  display_to?: string | null
  status: WidgetStatusEnum
}

/** Adds nested create feature */
export type PublishBoardRequest = {
  widget: PublishWidgetSimpleRequest
  publish_on_infoboards?: boolean
  /** @format date-time */
  display_from?: string | null
  /** @format date-time */
  display_to?: string | null
}

export type PublishWidgetSimple = {
  id: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  status: WidgetStatusEnum
}

export type PublishWidgetSimpleRequest = {
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
}

/**
 * * `reserved` - Reserved
 * * `social_room` - Social room
 * * `duty_room` - Duty room
 * * `storage` - Storage
 * * `artisan_private_room` - Artisan private room
 */
export type PurposeEnum =
  | 'reserved'
  | 'social_room'
  | 'duty_room'
  | 'storage'
  | 'artisan_private_room'

/**
 * * `quarter` - Quarter
 * * `week` - Week
 * * `year` - Year
 * * `course` - Course
 * * `lesson` - Lesson
 */
export type QuantityUnitEnum = 'quarter' | 'week' | 'year' | 'course' | 'lesson'

export type Quarter = {
  id: number
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semester: number
  short_name: QuarterShortNameEnum
  school_year: number
}

export type QuarterInCourses = {
  courses: string[]
  /**
   * * `Q1` - Q1
   * * `Q2` - Q2
   * * `Q3` - Q3
   * * `Q4` - Q4
   */
  quarter: QuarterShortNameEnum
}

export type QuarterInCoursesRequest = {
  courses: string[]
  /**
   * * `Q1` - Q1
   * * `Q2` - Q2
   * * `Q3` - Q3
   * * `Q4` - Q4
   */
  quarter: QuarterShortNameEnum
}

/**
 * * `Q1` - Q1
 * * `Q2` - Q2
 * * `Q3` - Q3
 * * `Q4` - Q4
 */
export type QuarterShortNameEnum = 'Q1' | 'Q2' | 'Q3' | 'Q4'

export type RecoveryAvailableFilters = {
  school_programme__type: AvailableStringFilter
  student: AvailableStringFilter
  assigned_to: AvailableStringFilter
}

/**
 * * `AbsenceRecovery` - AbsenceRecovery
 * * `LunchtimeRecovery` - LunchtimeRecovery
 */
export type RecoveryResourcetypeEnum = 'AbsenceRecovery' | 'LunchtimeRecovery'

export type Recurring = {
  pk: string
  /**
   * * `daily` - Daily
   * * `weekly` - Weekly
   * * `monthly` - Monthly
   * * `every_weekday` - Every weekday
   * * `every_weekend` - Every weekend
   */
  type: RecurringTypeEnum
  /** @format date */
  end_date?: string | null
}

export type RecurringRequest = {
  /**
   * * `daily` - Daily
   * * `weekly` - Weekly
   * * `monthly` - Monthly
   * * `every_weekday` - Every weekday
   * * `every_weekend` - Every weekend
   */
  type: RecurringTypeEnum
  /** @format date */
  end_date?: string | null
  update_type?: UpdateTypeEnum
}

/**
 * * `daily` - Daily
 * * `weekly` - Weekly
 * * `monthly` - Monthly
 * * `every_weekday` - Every weekday
 * * `every_weekend` - Every weekend
 */
export type RecurringTypeEnum =
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'every_weekday'
  | 'every_weekend'
export type RejectEnrolment = {
  rejection_comment: string
}

export type RejectEnrolmentRequest = {
  /** @minLength 1 */
  rejection_comment: string
}

export type RejectIdpPlan = {
  note: string
  rejected_by: string
  rejected_at: string
}

export type RejectIdpPlanRequest = {
  /** @minLength 1 */
  note: string
}

export type RelatedLeaveCheck = {
  /** @format uuid */
  id: string
  /** @maxLength 1024 */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  check_type: LeaveCheckTypeEnum
  /** @maxLength 64 */
  contact_person: string
  /** @maxLength 128 */
  contact_person_phone: string
  is_completed?: boolean
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
  /** @format date-time */
  original_date?: string | null
}

export type RelatedLeaveCheckRequest = {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /**
   * @minLength 1
   * @maxLength 64
   */
  contact_person: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  contact_person_phone: string
  is_completed?: boolean
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
  /** @format date-time */
  original_date?: string | null
}

/**
 * * `daily` - Daily
 * * `every_x_days` - Every "X" days
 * * `monthly` - Monthly
 * * `weekly` - Weekly
 */
export type RepeatFormEnum = 'daily' | 'every_x_days' | 'monthly' | 'weekly'
/**
 * * `manual` - Manual
 * * `from idp` - From IDP
 * * `by_student` - Requested by student
 */
export type RequestTypeEnum = 'manual' | 'from idp' | 'by_student'

export type RequestedCourse = {
  id: number
  status_display: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /**
   * * `new` - New
   * * `rejected` - Rejected
   * * `course_created` - Course created
   */
  status?: RequestedCourseStatusEnum
  /** @maxLength 256 */
  name: string
  note: string
  /** @format uuid */
  course: string | null
}

export type RequestedCourseRequest = {
  /**
   * * `new` - New
   * * `rejected` - Rejected
   * * `course_created` - Course created
   */
  status?: RequestedCourseStatusEnum
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
  /** @minLength 1 */
  note: string
}

/**
 * * `new` - New
 * * `rejected` - Rejected
 * * `course_created` - Course created
 */
export type RequestedCourseStatusEnum = 'new' | 'rejected' | 'course_created'
export type ResourcetypeEnum =
  | 'BoardingAnnouncementWidget'
  | 'CelebrationWidget'
  | 'EventAgendaWidget'
  | 'CongratulationsWidget'
  | 'PosterWidget'
  | 'StudentArtGalleryWidget'
  | 'EventInfoWidget'
  | 'EventInfoWithPhotoWidget'
  | 'JournalWidget'
  | 'MysteryLinkWidget'
  | 'MenuWidget'
  | 'StudentsAnnouncementWidget'
  | 'StudentsPicWidget'
  | 'VariantManualCongratulationsWidget'
  | 'ClockWidget'

export type RetrieveDraftActivitySuggesterTask = {
  id: number
  /**
   * * `pending` - Pending
   * * `running` - Running
   * * `succeeded` - Succeeded
   * * `failed` - Failed
   * * `cancelled` - Cancelled
   * * `completed` - Completed
   */
  status?: StatusCb0Enum
  call_args: DraftActivitySuggesterTaskCallArgs
  suggestions: DraftActivitySuggestion[] | null
}

export type ReviewLeaveRequest = {
  /**
   * * `approve` - Approve
   * * `reject` - Reject
   * * `requested` - Requested
   */
  action: LeaveRequestReviewActionEnum
  comment?: string
}

export type ReviewLeaveRequestRequest = {
  /**
   * * `approve` - Approve
   * * `reject` - Reject
   * * `requested` - Requested
   */
  action: LeaveRequestReviewActionEnum
  comment?: string
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type Room = {
  id: number
  /** @format date-time */
  deleted: string | null
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  is_private?: boolean
  msl_room?: boolean
  pri_room?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  group?: RoomGroupEnum | NullEnum | null
  schedule_automatically?: boolean
  available_for_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  capacity_for_exam?: number | null
  department: number
  override_allowed_slots?: boolean
  allowed_slots?: RoomAllowedSlots[] | null
  allow_multiple_activities?: boolean
  /**
   * @min 2
   * @max 2147483647
   */
  max_activities_number?: number | null
}

export type RoomAllowedSlots = {
  id: number
  /**
   * @min 0
   * @max 2147483647
   */
  day: WeekDayEnum
  /** @format time */
  start_time: string
  /** @format time */
  end_time: string
}

export type RoomAllowedSlotsRequest = {
  /**
   * @min 0
   * @max 2147483647
   */
  day: WeekDayEnum
  /** @format time */
  start_time: string
  /** @format time */
  end_time: string
}

export type RoomBookingBookForOptions = {
  label: string
  /** @format uuid */
  value: string
  is_student: boolean
}

export type RoomBookingCreate = {
  id: number
  room: number
  /** @format date */
  start_date: string
  /** @format uuid */
  booked_for?: string | null
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
}

export type RoomBookingCreateRequest = {
  room: number
  /** @format date */
  start_date: string
  /** @format uuid */
  booked_for?: string | null
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
  added_by?: string
}

export type RoomBookingUpdate = {
  id: number
  /** @format date */
  start_date: string
  /** @format uuid */
  booked_for?: string | null
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
}

export type RoomBookingUpdateRequest = {
  /** @format date */
  start_date: string
  /** @format uuid */
  booked_for?: string | null
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
  last_modified_by?: string
}

/**
 * * `1` - 1
 * * `2` - 2
 * * `3` - 3
 * * `4` - 4
 * * `5` - 5
 */
export type RoomGroupEnum = 1 | 2 | 3 | 4 | 5

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type RoomRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  is_private?: boolean
  msl_room?: boolean
  pri_room?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  group?: RoomGroupEnum | NullEnum | null
  schedule_automatically?: boolean
  available_for_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  capacity_for_exam?: number | null
  department: number
  override_allowed_slots?: boolean
  allowed_slots?: RoomAllowedSlotsRequest[] | null
  allow_multiple_activities?: boolean
  /**
   * @min 2
   * @max 2147483647
   */
  max_activities_number?: number | null
}

/**
 * * `0` - Monday
 * * `1` - Tuesday
 * * `2` - Wednesday
 * * `3` - Thursday
 * * `4` - Friday
 * * `5` - Saturday
 * * `6` - Sunday
 */
export type RotaDayEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6

export type RotaGenerationCallback = {
  event_data: Record<string, any>
  /**
   * * `task_succeeded` - TASK_SUCCEEDED
   * * `task_failed` - TASK_FAILED
   */
  event_type: RotaGenerationCallbackEventTypeEnum
}

/**
 * * `task_succeeded` - TASK_SUCCEEDED
 * * `task_failed` - TASK_FAILED
 */
export type RotaGenerationCallbackEventTypeEnum =
  | 'task_succeeded'
  | 'task_failed'

export type RotaGenerationCallbackRequest = {
  event_data: Record<string, any>
  /**
   * * `task_succeeded` - TASK_SUCCEEDED
   * * `task_failed` - TASK_FAILED
   */
  event_type: RotaGenerationCallbackEventTypeEnum
}

export type RotaGenerationIssue = {
  teams: SimpleTeam[]
  users: SimpleUser[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type RotaGenerationList = {
  id: number
  status: Status953Enum
  timetable: number | null
  /** @format date-time */
  created: string
  quarter: number
  rota_timetable_id: number | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type RotaGenerationTask = {
  id: number
  status: Status953Enum
  /** @format date-time */
  created: string
  /** @format date-time */
  finished_at: string | null
  rota_timetable_id: number | null
  teams: RotaTeam[]
  quarter: number
  timetable?: number | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type RotaGenerationTaskRequest = {
  timetable?: number | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type RotaGenerationTaskRetrieve = {
  id: number
  status: Status953Enum
  /** @format date-time */
  created: string
  /** @format date-time */
  finished_at: string | null
  rota_timetable_id: number | null
  teams: RotaTeamRetrieve[]
  quarter: number
  timetable?: number | null
  issues: RotaGenerationIssue
}

export type RotaRoleChoice = {
  label: string
  /**
   * * `boarding_director` - Boarding Director
   * * `campus_head` - Campus Head
   * * `deputy_campus_head_main` - Deputy Campus Head (Main)
   * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
   * * `head_of_house` - Head of House
   * * `house_tutor` - House Tutor
   */
  value: BoardingArtisanRoleEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type RotaTeam = {
  id: number
  team_id: string
  members: RotaTeamMember[]
}

export type RotaTeamMember = {
  id: number
  user_id: string
  /**
   * @min 0
   * @max 32767
   */
  team_role?: TeamMemberRoleEnum
  roles?: BoardingArtisanRoleEnum[]
}
export type RotaTeamMemberRetrieve = {
  id: number
  user_id: string
  rota_roles: string[]
  user_first_name: string
  user_last_name: string
}
/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type RotaTeamRetrieve = {
  /** @format uuid */
  id: string
  members: RotaTeamMemberRetrieve[]
  house_name: string
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type RotaTimetable = {
  id: number
  timetable: number
  status: RotaTimetableStatusEnum
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  rota_generation: number | null
}

/**
 * * `generation_in_progress` - Generation in progress
 * * `selected_for_import` - Selected for import
 * * `draft` - Draft
 */
export type RotaTimetableStatusEnum =
  | 'generation_in_progress'
  | 'selected_for_import'
  | 'draft'

/**
 * * `regular` - Duty
 * * `break` - Break
 * * `meeting` - Leaders Meeting
 * * `night_presence` - Night Presence
 * * `ski_trip` - Ski trip
 * * `sudden_time_off` - Sudden Time Off
 */
export type RotaTypeEnum =
  | 'regular'
  | 'break'
  | 'meeting'
  | 'night_presence'
  | 'ski_trip'
  | 'sudden_time_off'

/**
 * * `miss` - Miss
 * * `ms` - Ms.
 * * `mr` - Mr.
 * * `mrs` - Mrs.
 * * `title` - Title
 * * `other` - Other
 */
export type SalutationEnum = 'miss' | 'ms' | 'mr' | 'mrs' | 'title' | 'other'

export type SanctionsOption = {
  merit: StringOption[]
  demerit: StringOption[]
}

export type SaveStudentsFilesOrder = {
  files_to_order: number[]
}

export type SaveStudentsFilesOrderRequest = {
  files_to_order: number[]
}
/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type SchoolBreak = {
  id: number
  /** @maxLength 100 */
  name: string
  school_year: number
  school_year_name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  status: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type SchoolBreakRequest = {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string
  school_year: number
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type SchoolProgramme = {
  id: number
  status: SchoolProgrammeStatusEnum
  status_label: string
  /** @format date-time */
  created: string
  is_compulsory?: boolean
  /**
   * * `house_evening` - House Evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `absence_recovery` - Absence Recovery
   * * `lunchtime_recovery` - Lunchtime Recovery
   */
  type: SchoolProgrammeTypeEnum
  type_label: string
  /** @maxLength 128 */
  title: string
  description?: string
  parental_approval_required?: boolean
  message_to_parents?: string
  enrol_all_students?: boolean
  categories?: CategoriesEnum[]
  houses?: number[]
  grade_levels?: number[]
  ages?: number[]
  added_by?: string
  /** @format uuid */
  added_by_id: string
  last_modified_by?: string
  leaders: string[]
  leaders_data: SimpleUser[]
  members: string[]
  members_data: SimpleUser[]
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @format date-time */
  enrolment_deadline?: string | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  cost?: string | null
  cost_currency: string | null
  /**
   * @min 0
   * @max 32767
   */
  students_limit: number
  risk_assessment_file: number
  risk_assessment_file_details: SchoolProgrammeAttachment
  other_files?: number[]
  other_files_details: SchoolProgrammeAttachment[]
  enrolments: ProgrammeEnrolment[]
  approved_enrolments_count: string
  rejection_reason?: string
  is_editable: string
  is_status_editable: string
  can_be_deleted_by_user: string
}

export type SchoolProgrammeAttachment = {
  id: number
  /** @format uri */
  file: string
  file_name: string
}
export type SchoolProgrammeAvailableFilters = {
  student: StudentAvailableStringFilter
  participant: ParticipantAvailableStringFilter
  type: AvailableStringFilter
  is_compulsory: AvailableStringFilter
  status: AvailableStringFilter
}

export type SchoolProgrammeList = {
  id: number
  status: SchoolProgrammeStatusEnum
  status_label: string
  is_compulsory: boolean
  type: SchoolProgrammeTypeEnum
  type_label: string
  title: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  students_limit: number
  approved_enrolments_count: string
  requested_enrolments_count: string
  rejection_reason: string
  is_status_editable: string
  can_be_deleted_by_user: string
}

export type SchoolProgrammeRecovery = {
  id: number
  type: SchoolProgrammeTypeEnum
  type_label: string
  title: string
  description: string
  added_by_str: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
}

export type SchoolProgrammeReject = {
  rejection_reason: string
}

export type SchoolProgrammeRejectRequest = {
  /** @minLength 1 */
  rejection_reason: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type SchoolProgrammeRequest = {
  is_compulsory?: boolean
  /**
   * * `house_evening` - House Evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `absence_recovery` - Absence Recovery
   * * `lunchtime_recovery` - Lunchtime Recovery
   */
  type: SchoolProgrammeTypeEnum
  /**
   * @minLength 1
   * @maxLength 128
   */
  title: string
  description?: string
  parental_approval_required?: boolean
  message_to_parents?: string
  enrol_all_students?: boolean
  categories?: CategoriesEnum[]
  houses?: number[]
  grade_levels?: number[]
  ages?: number[]
  added_by?: string
  last_modified_by?: string
  leaders: string[]
  members: string[]
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @format date-time */
  enrolment_deadline?: string | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  cost?: string | null
  /**
   * @min 0
   * @max 32767
   */
  students_limit: number
  risk_assessment_file: number
  other_files?: number[]
  enrolments: ProgrammeEnrolmentRequest[]
  rejection_reason?: string
}

/**
 * * `requested` - Requested
 * * `approved` - Approved
 * * `rejected` - Rejected
 */
export type SchoolProgrammeStatusEnum = 'requested' | 'approved' | 'rejected'

/**
 * * `house_evening` - House Evening
 * * `term_break` - Term Break
 * * `school_trip` - School Trip
 * * `weekend_programme` - Weekend Programme
 * * `other` - Other
 * * `absence_recovery` - Absence Recovery
 * * `lunchtime_recovery` - Lunchtime Recovery
 */
export type SchoolProgrammeTypeEnum =
  | 'house_evening'
  | 'term_break'
  | 'school_trip'
  | 'weekend_programme'
  | 'other'
  | 'absence_recovery'
  | 'lunchtime_recovery'

export type SchoolYear = {
  id: number
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  short_name: string
}

export type Scorings = {
  scorings: Record<string, AimScorings[]>
}

/** Adds nested create feature */
export type Section = {
  id: number
  course_name: string
  course_code: string
  all_enrollments: Enrollment[]
  enrollments?: SectionEnrollment[]
  group_data: SectionGroup
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  is_mentoring?: boolean | null
  must_be_consecutive?: boolean
  hours: number | null
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  quarter: number
  /** @format uuid */
  course: string
  group: number
  /**
   * Main teacher
   * @format uuid
   */
  teacher: string
  teachers?: string[]
  preferred_rooms: number[]
}

export type SectionEnrollment = {
  id: number
  /** @format uuid */
  student: string
  section?: number
  /**
   * @min 1
   * @max 25
   */
  number_of_lessons: number
}

export type SectionEnrollmentRequest = {
  /** @format uuid */
  student: string
  section?: number
  /**
   * @min 1
   * @max 25
   */
  number_of_lessons: number
}

export type SectionGroup = {
  id: number
  /** @default 0 */
  group_number: number
  group_sub_number: number | null
  /** @format uuid */
  course: string
  school_year: number
  q1_section: number | null
  q2_section: number | null
  q3_section: number | null
  q4_section: number | null
}

export type SectionList = {
  id: number
  course_name: string
  course_code: string
  /** @format uuid */
  course_id: string
  /**
   * Main teacher
   * @format uuid
   */
  teacher_id: string | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1_id: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2_id: string | null
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  group_data: SectionGroup
}

/** Adds nested create feature */
export type SectionRequest = {
  enrollments?: SectionEnrollmentRequest[]
  create_for_quarters?: number[]
  is_mentoring?: boolean | null
  must_be_consecutive?: boolean
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  quarter: number
  /** @format uuid */
  course: string
  /**
   * Main teacher
   * @format uuid
   */
  teacher: string
  teachers?: string[]
}

export type SectionToEnroll = {
  id: number
  /**
   * Main teacher
   * @format uuid
   */
  teacher?: string | null
  teacher_str: string
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  co_teacher_1_str: string
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  co_teacher_2_str: string
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  all_enrollments: Enrollment[]
  score: string
  group_data: SectionGroup
}

/** Collects and raises validation errors in bulk */
export type SecurityTeamProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type SecurityTeamProfileRequest = {
  /** @format uuid */
  user?: string | null
}

export type Semester = {
  id: number
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  short_name: SemesterShortNameEnum
  school_year: number
}

export type SemesterShortNameEnum = 'S1' | 'S2'

export type SickReport = {
  id: string
  /**
   * * `regular` - Regular
   * * `med_only` - Med-only
   */
  type?: SickReportTypeEnum
  type_display: string
  /** @format uuid */
  student: string
  first_contact: FirstContact
  student_id: string
  generate_absences_on_create?: boolean
  /** @format uuid */
  requested_by: string | null
  student_str: string
  status_display: string
  status: string
  close_reason?: CloseReasonEnum | BlankEnum
  close_reason_display: string
  /** @format date-time */
  closed_at?: string | null
  /** @format date-time */
  created: string
  has_comments: string
  requested_by_str: string
  closed_by_str: string
  opened_by_str: string
  created_by?: string
  last_modified_by?: string
  absence_dates: SickReportAbsenceDates[]
  current_location: string
  pending_closure_request_id: number | null
  pending_closure_request_reason: string
}

export type SickReportAbsenceDates = {
  /** @format uuid */
  id: string
  created_by?: string
  last_modified_by?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  sick_report: string
  /** @format date-time */
  absent_from?: string | null
  /** @format date-time */
  absent_to?: string | null
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type: ClassTypeEnum
  omit_campus_checks?: boolean
}

export type SickReportAbsenceDatesRequest = {
  created_by?: string
  last_modified_by?: string
  /** @minLength 1 */
  sick_report: string
  /** @format date-time */
  absent_from?: string | null
  /** @format date-time */
  absent_to?: string | null
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type: ClassTypeEnum
  omit_campus_checks?: boolean
}

export type SickReportClosureRequest = {
  /**
   * * `mistake` - Sick report made by mistake
   * * `student_not_sick` - Student not sick
   * * `student_recovered` - Student recovered
   */
  close_reason: CloseReasonEnum
}

export type SickReportClosureRequestRequest = {
  /**
   * * `mistake` - Sick report made by mistake
   * * `student_not_sick` - Student not sick
   * * `student_recovered` - Student recovered
   */
  close_reason: CloseReasonEnum
}

export type SickReportComment = {
  /** @format uuid */
  id: string
  author?: string
  author_str: string
  content: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
}

export type SickReportCommentRequest = {
  author?: string
  /** @minLength 1 */
  content: string
}

export type SickReportInHealthCheck = {
  id: string
  /** @format date-time */
  created: string
  symptoms: SymptomsEnum[]
  status: string
}

export type SickReportList = {
  id: string
  /** @format uuid */
  student: string
  current_location: string
  student_id: string
  /** @format uuid */
  requested_by: string | null
  student_str: string
  /**
   * * `regular` - Regular
   * * `med_only` - Med-only
   */
  type?: SickReportTypeEnum
  type_display: string
  status: string
  status_display: string
  close_reason_display: string
  /** @format date-time */
  created: string
  absent_now: string
  /** Return shortened grade in number */
  student_grade: number
  has_comments: string
  pending_closure_request_id: number | null
  pending_closure_request_reason: string
  created_by?: string
  symptoms: string
}

export type SickReportRequest = {
  /**
   * * `regular` - Regular
   * * `med_only` - Med-only
   */
  type?: SickReportTypeEnum
  /** @format uuid */
  student: string
  generate_absences_on_create?: boolean
  close_reason?: CloseReasonEnum | BlankEnum
  /** @format date-time */
  closed_at?: string | null
  created_by?: string
  last_modified_by?: string
}

/**
 * * `regular` - Regular
 * * `med_only` - Med-only
 */
export type SickReportTypeEnum = 'regular' | 'med_only'

export type SimpleCampusAbsenceStudent = {
  /** @format uuid */
  id: string
  student_data: StudentSimple
}

export type SimpleCourse = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
}

export type SimpleDraftActivity = {
  /** @format uuid */
  id: string
  name: string
  timetable_id: number
}

export type SimpleEnrolledStudent = {
  /** @format uuid */
  id: string
  full_name: string
}

export type SimpleGradeLevel = {
  id: number
  /** @maxLength 64 */
  name: string
}

export type SimpleParentContact = {
  /** @format uuid */
  id: string
  full_name: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
}
export type SimpleRoom = {
  id: number
  /** @maxLength 128 */
  name: string
}

/** Sections without enrollments */
export type SimpleSection = {
  id: number
  quarter: number
  /** @format uuid */
  course: string
  /**
   * Main teacher
   * @format uuid
   */
  teacher?: string | null
  teacher_str: string
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  co_teacher_1_str: string
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  co_teacher_2_str: string
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  group_data: SectionGroup
}

export type SimpleSickReport = {
  id: string
}
export type SimpleStudentContact = {
  /** @format uuid */
  id: string
  full_name: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
}
export type SimpleStudentWithGradeLevel = {
  /** @format uuid */
  id: string
  full_name: string
  current_grade_level: SimpleGradeLevel
}

export type SimpleTeam = {
  /** @format uuid */
  id: string
  name: string
}

export type SimpleUser = {
  /** @format uuid */
  id: string
  full_name: string
}

export type SimpleUserWithAvatar = {
  /** @format uuid */
  id: string
  full_name: string
  /** @format uri */
  avatar: string | null
}
/**
 * * `S` - Small
 * * `L` - Large
 */
export type SizeEnum = 'S' | 'L'

export type Skill = {
  id: number
  /** @maxLength 256 */
  name: string
}
/**
 * * `manual` - Manual
 * * `check_out` - Check out
 * * `sick_report` - Sick report
 * * `doctor_appointment` - Doctor appointment
 * * `hospital_appointment` - Hospital appointment
 * * `campus_absence` - Campus absence
 * * `exam` - Exam session
 * * `programme_enrolment` - School Programme Enrolment
 */
export type SourceEnum =
  | 'manual'
  | 'check_out'
  | 'sick_report'
  | 'doctor_appointment'
  | 'hospital_appointment'
  | 'campus_absence'
  | 'exam'
  | 'programme_enrolment'

export type SourceIDPPlan = {
  id: number
  idp_plan?: string
  serialized_data: Record<string, any>
}

export type StaffMember = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  teams: Record<
    string,
    {
      name?: string
      roles?: {
        value?: string
        label?: string
      }[]
      team_role?: {
        value?: string
        label?: string
      }
    }
  >
}

/**
 * * `academic` - Academic
 * * `boarding` - Boarding
 * * `tae` - TAE
 * * `other` - Other
 */
export type StaffTypeEnum = 'academic' | 'boarding' | 'tae' | 'other'

/**
 * * `0` - Pre-School
 * * `1` - Primary School
 * * `2` - Middle School
 * * `3` - High School
 */
export type StageEnum = 0 | 1 | 2 | 3

/**
 * * `PENDING` - PENDING
 * * `RECEIVED` - RECEIVED
 * * `STARTED` - STARTED
 * * `PROGRESS` - PROGRESS
 * * `FINALIZE` - FINALIZE
 * * `SUCCESS` - SUCCESS
 * * `FAILURE` - FAILURE
 * * `REVOKED` - REVOKED
 * * `REJECTED` - REJECTED
 * * `RETRY` - RETRY
 * * `IGNORED` - IGNORED
 */
export type StateEnum =
  | 'PENDING'
  | 'RECEIVED'
  | 'STARTED'
  | 'PROGRESS'
  | 'FINALIZE'
  | 'SUCCESS'
  | 'FAILURE'
  | 'REVOKED'
  | 'REJECTED'
  | 'RETRY'
  | 'IGNORED'

/**
 * * `new` - New
 * * `in_progress` - In progress
 * * `failed` - Generation has failed
 * * `success` - Success
 */
export type Status953Enum = 'new' | 'in_progress' | 'failed' | 'success'

/**
 * * `live` - Live
 * * `active_in_past` - Active in past
 * * `generation_in_progress` - Generation in progress
 * * `draft` - Draft
 * * `infeasible` - Infeasible data. Please increase time, or verify sections
 * * `failed` - Generation process failed
 */
export type Status97AEnum =
  | 'live'
  | 'active_in_past'
  | 'generation_in_progress'
  | 'draft'
  | 'infeasible'
  | 'failed'

/**
 * * `pending` - Pending
 * * `running` - Running
 * * `succeeded` - Succeeded
 * * `failed` - Failed
 * * `cancelled` - Cancelled
 * * `completed` - Completed
 */
export type StatusCb0Enum =
  | 'pending'
  | 'running'
  | 'succeeded'
  | 'failed'
  | 'cancelled'
  | 'completed'

export type StringOption = {
  label: string
  value: string
}

export type Student = {
  first_name: string
  middle_name: string
  last_name: string
  /** @format date */
  birthdate: string
  birthplace: string
  gender: string
  address: string
  address_city: string
  address_zipcode: string
  address_country: string
  billing_address: string
  billing_address_city: string
  billing_address_zipcode: string
  billing_address_country: string
  is_billing_address_same: boolean
  native_language?: string
  nationalities?: NationalityEnum[]
}
export type StudentAccommodation = {
  id: number
  house: number
  room?: number | null
  house_str: string
  /** @format date */
  start_date: string
  room_str: string
  room_code: string
  room_name: string
  is_room_only_for_student?: boolean
}
export type StudentAccountOverdue = {
  account_overdue: AccountOverdueEnum
}
export type StudentAimForCourse = {
  id: number
  /** @format uuid */
  student: string
  /** @format uuid */
  course: string
  aim?: number | null
}

export type StudentAimForCourseRequest = {
  /** @format uuid */
  student: string
  /** @format uuid */
  course: string
  aim?: number | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentAllergy = {
  id: number
  /** @format uuid */
  student: string
  allergen_kind: string
  allergen_subtypes?: string[]
  reactions?: StudentAllergyReaction[]
  medicines?: string[]
  is_emergency_medicine?: boolean
  medicine_location?: MedicineLocationEnum | BlankEnum
  immediate_response?: ImmediateResponseEnum | BlankEnum
  medicine_location_display: string
  immediate_response_display: string
}

export type StudentAllergyReaction = {
  id: number
  reaction: string
  severe_reactions?: string[]
  mild_reactions?: string[]
}

export type StudentAllergyReactionRequest = {
  /** @minLength 1 */
  reaction: string
  severe_reactions?: string[]
  mild_reactions?: string[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentAllergyRequest = {
  /** @format uuid */
  student: string
  /** @minLength 1 */
  allergen_kind: string
  allergen_subtypes?: string[]
  reactions?: StudentAllergyReactionRequest[]
  medicines?: string[]
  is_emergency_medicine?: boolean
  medicine_location?: MedicineLocationEnum | BlankEnum
  immediate_response?: ImmediateResponseEnum | BlankEnum
}

export type StudentAnnouncement = {
  id: string
  emojis?: string[]
  /** @maxLength 100 */
  text: string
  /** @maxLength 7 */
  bg_color?: string
  student: string
}
export type StudentAssignmentItem = {
  /** @format uuid */
  id: string
  /**
   * @min 0
   * @max 100
   */
  grade_in_percent?: number | null
  /** @maxLength 32 */
  grade_in_ib?: string
  is_submitted?: boolean
  is_incomplete?: boolean
  is_late?: boolean
  is_exempt?: boolean
  /** @format uuid */
  student: string
  student_full_name: string
  /** @format date-time */
  grade_modified_at?: string | null
}

export type StudentAssignmentItemRequest = {
  /**
   * @min 0
   * @max 100
   */
  grade_in_percent?: number | null
  /** @maxLength 32 */
  grade_in_ib?: string
  is_submitted?: boolean
  is_incomplete?: boolean
  is_late?: boolean
  is_exempt?: boolean
  /** @format uuid */
  student: string
  /** @format date-time */
  grade_modified_at?: string | null
}

export type StudentAvailableStringFilter = {
  query_param: string
  label: string
  choices: StudentStringOption[]
}
export type StudentBloodType = {
  /**
   * * `0-` - 0-
   * * `0+` - 0+
   * * `a-` - A-
   * * `a+` - A+
   * * `b-` - B-
   * * `b+` - B+
   * * `ab-` - AB-
   * * `ab+` - AB+
   * * `unknown` - Unknown
   */
  blood_type: BloodTypeEnum
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentCampusAbsence = {
  /** @format uuid */
  id: string
  created_by?: string
  campus_absence_type: CampusAbsenceTypeEnum
  campus_absence_type_label: string
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  custom_name?: string
  campus_absence_subtype_label: string
  status: CampusAbsenceStatusEnum
  status_label: string
  leave_details?: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  can_cancel: boolean
  campus_absence_students?: StudentCampusAbsenceStudent[]
  can_edit: boolean
  student_saw_changes: boolean
  object_type: ObjectTypeEnum
  notifications_mark_as_read: number | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentCampusAbsenceRequest = {
  created_by?: string
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  custom_name?: string
  leave_details?: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  campus_absence_students?: StudentCampusAbsenceStudentRequest[]
}

export type StudentCampusAbsenceStatus = {
  label: string
  /**
   * * `requested` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `canceled_by_student` - Canceled by student
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  value: CampusAbsenceStatusEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentCampusAbsenceStudent = {
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  student_data: StudentData
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentCampusAbsenceStudentRequest = {
  /** @format uuid */
  student: string
}

export type StudentCampusAbsenceSubtype = {
  label: string
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  value: CampusAbsenceSubTypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentCampusAbsenceType = {
  label: string
  /**
   * * `ausgang` - Ausgang
   * * `external_exam` - External exam
   * * `official` - Official
   * * `school_programme` - School Programme
   * * `extra_ausgang` - Extra Ausgang
   */
  value: CampusAbsenceTypeEnum
  subtypes: StudentCampusAbsenceSubtype[]
}
/**
 * * `4` - S
 * * `3` - E
 * * `2` - A
 * * `1` - C
 * * `0` - not assigned
 */
export type StudentCategoryEnum = 4 | 3 | 2 | 1 | 0

export type StudentCategoryItem = {
  /** @format date-time */
  downgrade_due_date: string | null
  is_changed_by_downgrade: boolean
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  assigned_category?: AssignedCategoryEnum
  homework_status: string
  is_completed?: boolean
  week_ago_category: WeekAgoCategoryEnum
  two_weeks_ago_category: TwoWeeksAgoCategoryEnum
  /** @format double */
  weekly_merit_count: number
  pending_merit_count: number
}

export type StudentCategoryItemList = {
  house: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  results: StudentCategoryItem[]
}

export type StudentCategoryItemRequest = {
  /** @format uuid */
  student: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  assigned_category?: AssignedCategoryEnum
  is_completed?: boolean
}

export type StudentCategoryReportList = {
  /** @format uuid */
  id: string
  /** @format date-time */
  created: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  house_str: string
  status_display: string
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
}
export type StudentChronicCondition = {
  id: number
  /** @format uuid */
  student: string
  condition_type: string
  condition_subtype?: string[]
  other_condition_type?: string
  impact?: ImpactEnum[]
  watch_out_for?: WatchOutForEnum[]
  emergency_medicines?: string[]
  emergency_medicine_applications?: EmergencyMedicineApplicationsEnum[]
  emergency_medicine_location?: EmergencyMedicineLocationEnum[]
  iep_contact?: IepContactEnum[]
  immediate_response?: ImmediateResponseEnum | BlankEnum
  comment?: string
  has_medicine_plan?: boolean
  has_emergency_medicine?: boolean
  has_iep?: boolean
  impact_display: string
  watch_out_for_display: string
  emergency_medicine_applications_display: string
  emergency_medicine_location_display: string
  iep_contact_display: string
  immediate_response_display: string
}

export type StudentChronicConditionRequest = {
  /** @format uuid */
  student: string
  /** @minLength 1 */
  condition_type: string
  condition_subtype?: string[]
  other_condition_type?: string
  impact?: ImpactEnum[]
  watch_out_for?: WatchOutForEnum[]
  emergency_medicines?: string[]
  emergency_medicine_applications?: EmergencyMedicineApplicationsEnum[]
  emergency_medicine_location?: EmergencyMedicineLocationEnum[]
  iep_contact?: IepContactEnum[]
  immediate_response?: ImmediateResponseEnum | BlankEnum
  comment?: string
  has_medicine_plan?: boolean
  has_emergency_medicine?: boolean
  has_iep?: boolean
}
export type StudentClashes = {
  lessons: LessonClashes[]
  exams: ExamClashes[]
  programmes: ProgrammeClashes[]
  leave_requests: LeaveRequestClashes[]
}
export type StudentConnection = {
  id: number
  /** @format uuid */
  parent: string
  /** @format uuid */
  student: string
  /** Parent details */
  contact_data: SimpleParentContact
  /** Parent roles for student */
  roles: ParentContactTypeEnum[]
  has_app_access: boolean
}
/**
 * * `StudentMysteryLink` - StudentMysteryLink
 * * `StudentAnnouncement` - StudentAnnouncement
 * * `StudentPic` - StudentPic
 */
export type StudentContentResourcetypeEnum =
  | 'StudentMysteryLink'
  | 'StudentAnnouncement'
  | 'StudentPic'

/**
 * * `pending` - Pending
 * * `approved` - Approved
 * * `rejected` - Rejected
 * * `deleted` - Deleted
 * * `archived` - Archived
 */
export type StudentContentStatusEnum =
  | 'pending'
  | 'approved'
  | 'rejected'
  | 'deleted'
  | 'archived'

export type StudentCourseRequirement = {
  id: number
  course: string
  min_grade: string
  current_grade: string
  potential_grade: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /**
   * @min 0
   * @max 100
   */
  min_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  current_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  potential_percents?: number | null
  aim?: number | null
  year: number
}

export type StudentCourseRequirementRequest = {
  /** @minLength 1 */
  min_grade: string
  /** @minLength 1 */
  current_grade: string
  /** @minLength 1 */
  potential_grade: string
  /**
   * @min 0
   * @max 100
   */
  min_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  current_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  potential_percents?: number | null
  aim?: number | null
  year: number
}
export type StudentData = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  full_name: string
  /**
   * mainly student's app usage
   * -> we're considering only first name given
   */
  reduced_full_name: string
  /** @format uri */
  avatar?: string | null
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentDetail = {
  /** @format uuid */
  id: string
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string
  /** @maxLength 256 */
  nickname?: string
  /** @maxLength 128 */
  first_name: string
  /** @maxLength 128 */
  last_name: string
  full_name: string
  /** @maxLength 256 */
  name: string
  current_grade_level: number
  current_grade_level_str: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  /** @maxItems 249 */
  nationalities?: (NationalityEnum | BlankEnum)[]
  native_language?: NativeLanguageEnum | BlankEnum
  education_details?: string
  /** @format date */
  date_of_birth: string
  age: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  gender: GenderEnum
  is_at_home?: boolean
  is_vaccinated: boolean
  has_allergy: boolean
  has_chronic_condition: boolean
  accommodation: StudentAccommodation
  category: string
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  saa_student?: boolean
  is_statemented?: boolean
  /**
   * @min -2147483648
   * @max 2147483647
   */
  exam_extra_time?: ExamExtraTimeEnum | NullEnum | null
  access_arrangements?: AccessArrangementsEnum[]
  is_draft?: boolean
  has_pending_draft_medications: boolean
  contacts: StudentConnection[]
  external_id_in_invoice_system?: string
  meal_preference: StudentMealPreference
  blood_type: BloodTypeEnum | BlankEnum
  account_overdue?: AccountOverdueEnum
  has_any_dietary_restrictions?: boolean
  dietary_restrictions?: string
  is_more_medical_information_available: boolean
  more_medical_information: string
  is_student_swimmer: boolean
  has_student_contact_with_drugs: boolean
  student_fitness_for_sports: StudentFitnessForSportsEnum | BlankEnum
  has_been_student_expelled: boolean
  summer_legacies: SummerRosenbergLegacy[]
  /** @format date */
  date_of_entry?: string | null
  entry_quarter: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentDetailRequest = {
  /** @maxLength 256 */
  nickname?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  first_name: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  last_name: string
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
  current_grade_level: number
  /**
   * Email address
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  /** @maxItems 249 */
  nationalities?: (NationalityEnum | BlankEnum)[]
  native_language?: NativeLanguageEnum | BlankEnum
  education_details?: string
  /** @format date */
  date_of_birth: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  gender: GenderEnum
  is_at_home?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  saa_student?: boolean
  is_statemented?: boolean
  /**
   * @min -2147483648
   * @max 2147483647
   */
  exam_extra_time?: ExamExtraTimeEnum | NullEnum | null
  access_arrangements?: AccessArrangementsEnum[]
  is_draft?: boolean
  external_id_in_invoice_system?: string
  account_overdue?: AccountOverdueEnum
  has_any_dietary_restrictions?: boolean
  dietary_restrictions?: string
  /** @format date */
  date_of_entry?: string | null
}

export type StudentDietaryRestrictions = {
  has_any_dietary_restrictions?: boolean
  dietary_restrictions?: string
}

export type StudentDietaryRestrictionsRequest = {
  has_any_dietary_restrictions?: boolean
  dietary_restrictions?: string
}

export type StudentExternalExam = {
  id: number
  /**
   * * `ACT` - ACT
   * * `A_LEVEL` - A Level (GCE), iGCSE
   * * `AP` - Advanced Placement
   * * `CAMBRIDGE` - CAMBRIDGE
   * * `DELE` - DELE
   * * `DELF` - DELF
   * * `GCSE` - GCSE
   * * `GMAT` - GMAT
   * * `GOETHE` - GOETHE
   * * `IAL` - International A Level
   * * `IB` - International Baccalaureate
   * * `IELTS` - IELTS
   * * `LAMDA` - LAMDA
   * * `MAP` - MAP
   * * `SAT_ENG` - SAT ENG
   * * `SAT_MAT` - SAT MAT
   * * `WIDA` - WIDA
   */
  type: ExternalExamTypeEnum
  /** @format date */
  date: string
  /** @format double */
  min_score?: number | null
  /** @format double */
  score?: number | null
  /** @maxLength 16 */
  subtype?: string
  name: string
  certificate: Attachment
}

export type StudentExternalExamRequest = {
  /**
   * * `ACT` - ACT
   * * `A_LEVEL` - A Level (GCE), iGCSE
   * * `AP` - Advanced Placement
   * * `CAMBRIDGE` - CAMBRIDGE
   * * `DELE` - DELE
   * * `DELF` - DELF
   * * `GCSE` - GCSE
   * * `GMAT` - GMAT
   * * `GOETHE` - GOETHE
   * * `IAL` - International A Level
   * * `IB` - International Baccalaureate
   * * `IELTS` - IELTS
   * * `LAMDA` - LAMDA
   * * `MAP` - MAP
   * * `SAT_ENG` - SAT ENG
   * * `SAT_MAT` - SAT MAT
   * * `WIDA` - WIDA
   */
  type: ExternalExamTypeEnum
  /** @format date */
  date: string
  /** @format double */
  min_score?: number | null
  /** @format double */
  score?: number | null
  /** @maxLength 16 */
  subtype?: string
}

/**
 * * `moderate` - Moderate
 * * `good` - Good
 * * `very_good` - Very good
 * * `` - n/a
 */
export type StudentFitnessForSportsEnum = 'moderate' | 'good' | 'very_good'

export type StudentFurtherMedicalInfo = {
  is_more_medical_information_available?: boolean
  more_medical_information?: string
  is_student_swimmer?: boolean
  has_student_contact_with_drugs?: boolean
  student_fitness_for_sports?: StudentFitnessForSportsEnum | BlankEnum
}

export type StudentFurtherMedicalInfoRequest = {
  is_more_medical_information_available?: boolean
  more_medical_information?: string
  is_student_swimmer?: boolean
  has_student_contact_with_drugs?: boolean
  student_fitness_for_sports?: StudentFitnessForSportsEnum | BlankEnum
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentIdpPlan = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  baskets?: Basket[]
  /** @format uuid */
  student: string
  status: string
  status_display: string
  note?: string
  grade_level: number
  aim: string
  year: number
  draft_payload?: Record<string, any>
  exams?: ExamScore[]
  warnings?: ParentWarning[]
  /**
   * @min 0
   * @max 2147483647
   */
  version: number
  student_str: string
  reject_details: Record<string, any>
  /** @format double */
  credit_count?: number | null
  /** @format uuid */
  admin_approve_author: string | null
  admin_approve_author_str: string
  /** @format date-time */
  admin_approved_at: string | null
  /** @format date */
  parent_approval_deadline: string | null
  parent_message?: string
  parent_message_author_str: string
  /** @format date-time */
  parent_message_created_at: string | null
  parent_approve_comment: string
  /** @format uuid */
  parent_approve_author: string | null
  parent_approve_author_str: string
  /** @format date-time */
  parent_approved_at: string | null
  send_to_parents_as_update: boolean
}

export type StudentIdpPlanList = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  student: IdpPlanStudent
  status: string
  status_display: string
  note?: string
  grade_level: number
  aim: string
  year: number
  /**
   * @min 0
   * @max 2147483647
   */
  version: number
  /** Last approved version */
  is_active?: boolean
  student_str: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentIdpPlanRequest = {
  baskets?: BasketRequest[]
  /** @format uuid */
  student: string
  note?: string
  grade_level: number
  /** @minLength 1 */
  aim: string
  year: number
  draft_payload?: Record<string, any>
  exams?: ExamScoreRequest[]
  warnings?: ParentWarningRequest[]
  /** @default true */
  commit?: boolean
  /** @format double */
  credit_count?: number | null
  courses_mission_control_departments?: CourseMissionControlDepartmentRequest[]
  parent_message?: string
}

export type StudentIdsListRequest = {
  students: string[]
}

export type StudentInAttendance = {
  /** @format uuid */
  id: string
  full_name: string
  is_at_home?: boolean
}
export type StudentInternship = {
  id: number
  internship_str: string
  internship: number
  document?: number | null
  document_data: InternshipAttachment | null
  /** @maxLength 256 */
  organisation: string
  /** @format date */
  date: string
  /** @format date */
  date_to: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentInternshipUpdate = {
  id?: number
  internship: number
  document?: number | null
  /** @maxLength 256 */
  organisation: string
  /** @format date */
  date: string
  /** @format date */
  date_to: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentInternshipUpdateRequest = {
  id?: number
  internship: number
  document?: number | null
  /**
   * @minLength 1
   * @maxLength 256
   */
  organisation: string
  /** @format date */
  date: string
  /** @format date */
  date_to: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentInternshipsUpdate = {
  internships: StudentInternshipUpdate[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentInternshipsUpdateRequest = {
  internships: StudentInternshipUpdateRequest[]
}
/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentList = {
  /** @format uuid */
  id: string
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string
  /** @maxLength 256 */
  nickname?: string
  /** @maxLength 128 */
  first_name: string
  /** @maxLength 128 */
  last_name: string
  full_name: string
  /** @maxLength 256 */
  name?: string
  current_grade_level: number
  current_grade_level_str: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  /** @maxItems 249 */
  nationalities: (NationalityEnum | BlankEnum)[]
  native_language?: NativeLanguageEnum | BlankEnum
  education_details?: string
  /** @format date */
  date_of_birth?: string | null
  two_factor_auth?: boolean
  gender?: GenderEnum | BlankEnum
  is_at_home?: boolean
  is_vaccinated: boolean
  has_allergy: boolean
  has_chronic_condition: boolean
  homework_status: string
  accommodation: StudentAccommodation
  category: string
  week_ago_category: string
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  disable_access_to_idp?: boolean
  saa_student?: boolean
  is_statemented?: boolean
  /**
   * @min -2147483648
   * @max 2147483647
   */
  exam_extra_time?: ExamExtraTimeEnum | NullEnum | null
  access_arrangements?: AccessArrangementsEnum[]
  is_draft?: boolean
  external_id_in_invoice_system?: string
  searchable_content?: string
  /** @format date-time */
  report_card_sent_at: string | null
  account_overdue?: AccountOverdueEnum
  boarding_comments: BoardingCommentSimple[]
  age: string
  number_of_weeks_in_camps: number
  week_of_camps: string[]
  /** via annotate */
  summer_certificate_rules_met: boolean
  /**
   * * `standard` - Standard
   * * `special` - Special
   */
  package_option: PackageOptionEnum
  is_new: boolean
  /** @format date */
  date_of_entry?: string | null
  entry_quarter: string
  is_entry_quarter_current_or_upcoming: boolean
  /** @format date-time */
  date_joined?: string
}

export type StudentMealPreference = {
  /**
   * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
   * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
   * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
   * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
   */
  diet_type?: DietTypeEnum
  other_requirements?: string
}
/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentPic = {
  id: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  /** @maxLength 50 */
  title?: string
  student_str: string
}

export type StudentProfile = {
  /** @format uuid */
  id: string
  first_name: string
  last_name: string
  name: string
  full_name: string
  /**
   * mainly student's app usage
   * -> we're considering only first name given
   */
  reduced_full_name: string
  /**
   * Email address
   * @format email
   */
  email: string
  nationalities: NationalityEnum[]
  /**
   * This field is deprecated and will be removed in a future release.
   *
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia (Federated States of)
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  nationality: NationalityEnum
  nickname: string
  /** @format date */
  date_of_birth: string | null
  current_grade_level_str: string
  /** @format uri */
  avatar: string | null
  /** @format double */
  merit_points: number
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  favourites?: string[]
}

export type StudentProfileRequest = {
  /** @format double */
  merit_points: number
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  favourites?: string[]
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentPsychologicalCare = {
  has_any_mental_health_issues?: boolean
  has_regular_psychological_care?: boolean
  summer_mental_health_issues: SummerMentalHealthIssue[]
  medical_reports: MedicalReportAttachment[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentPsychologicalCareRequest = {
  has_any_mental_health_issues?: boolean
  has_regular_psychological_care?: boolean
  summer_mental_health_issues: SummerMentalHealthIssueRequest[]
}
export type StudentSimple = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  full_name: string
  /** @maxLength 256 */
  name?: string
  /**
   * mainly student's app usage
   * -> we're considering only first name given
   */
  reduced_full_name: string
}
export type StudentStringOption = {
  label: string
  value: string
  searchable_content: string
}

export type StudentSummerCourseSummary = {
  course_id: string
  course_name: string
  rules_met: boolean
  number_of_positive_days: number
}

export type StudentSummerSummary = {
  focus_courses_data: StudentSummerCourseSummary[]
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
}
export type StudentTAEExperience = {
  id: number
  experience_str: string
  experience: number
  /** @format date */
  date: string
}

export type StudentTAEExperienceRequest = {
  experience: number
  /** @format date */
  date: string
}

export type StudentTAEProject = {
  id: number
  /** @maxLength 256 */
  name: string
  /** @format date */
  expected_completion_date: string
  /** @format date */
  completion_date?: string | null
}

export type StudentTAEProjectRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
  /** @format date */
  expected_completion_date: string
  /** @format date */
  completion_date?: string | null
}

export type StudentTAESportPackage = {
  id: number
  /**
   * * `hf_member` - H&F Membership
   * * `sport_package` - Sports Package ++
   * * `sport_package_plus` - Sports Package Plus ++
   */
  package: StudentTAESportPackagePackageEnum
  quarter: number
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
}

/**
 * * `hf_member` - H&F Membership
 * * `sport_package` - Sports Package ++
 * * `sport_package_plus` - Sports Package Plus ++
 */
export type StudentTAESportPackagePackageEnum =
  | 'hf_member'
  | 'sport_package'
  | 'sport_package_plus'

export type StudentTAESportPackageRequest = {
  /**
   * * `hf_member` - H&F Membership
   * * `sport_package` - Sports Package ++
   * * `sport_package_plus` - Sports Package Plus ++
   */
  package: StudentTAESportPackagePackageEnum
  quarter: number
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
}
/** mainly student's app usage */
export type StudentWithAvatar = {
  /** @format uuid */
  id: string
  full_name: string
  /** @format uri */
  avatar: string | null
  /**
   * mainly student's app usage
   * -> we're considering only first name given
   */
  reduced_full_name: string
}

export type StudentsAvailableFilters = {
  id: AvailableIntegerFilterStudentsStudentId
  house: AvailableStringFilter
  is_accommodated: AvailableIntegerFilter
  room: AvailableStringFilter
  room_code: AvailableStringFilter
}

export type StudentsClashesForDateParamsRequest = {
  student_ids: string[]
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  /** @minLength 1 */
  exclude_lesson_id?: string | null
  /** @minLength 1 */
  exclude_exam_id?: string | null
  /** @minLength 1 */
  exclude_programme_id?: string | null
  /** @minLength 1 */
  exclude_leave_request_id?: string | null
}
export type StudentsStudentIDOption = {
  label: string
  value: number
  searchable_content: string
}

/**
 * * `fine-arts` - Fine Arts
 * * `performing-arts` - Performing Arts
 * * `leadership` - Diplomacy & Activism
 * * `robotics` - Robotics & Engineering
 * * `environmental-science` - Environmental Science & Sustainability
 * * `fashion` - Fashion & Textile Design
 * * `informatics` - Informatics & Coding
 * * `intensive-english` - Intensive English
 * * `intensive-german` - Intensive German
 * * `rosenberg-x-crimson-university-admission` - Rosenberg x Crimson University Admission Focus Course
 * * `english` - English
 * * `german` - German
 * * `tennis` - Tennis
 * * `personal-training` - Personal Training
 * * `horse-riding` - Horse Riding
 * * `golf` - Golf
 */
export type SubjectNameEnum =
  | 'fine-arts'
  | 'performing-arts'
  | 'leadership'
  | 'robotics'
  | 'environmental-science'
  | 'fashion'
  | 'informatics'
  | 'intensive-english'
  | 'intensive-german'
  | 'rosenberg-x-crimson-university-admission'
  | 'english'
  | 'german'
  | 'tennis'
  | 'personal-training'
  | 'horse-riding'
  | 'golf'

/**
 * * `lesson` - Lesson
 * * `training` - Training
 * * `focus_course` - Focus Course
 */
export type SubjectTypeEnum = 'lesson' | 'training' | 'focus_course'

export type Subtask = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  title: string
  is_done: boolean
}

export type SubtaskRequest = {
  /** @minLength 1 */
  title: string
}

/**
 * * `attitude` - Attitude
 * * `kindness` - Kindness
 * * `service` - Service
 * * `language` - Language
 * * `electronics` - Electronics
 * * `dress` - Dress
 * * `late` - Late
 * * `night` - Night
 * * `room` - Room
 * * `absence` - Absence
 * * `leave_request` - Leave Request
 * * `ausgang` - Ausgang
 * * `deception` - Deception
 * * `transport` - Transport
 * * `private_home` - Private Home
 * * `deliveries` - Deliveries
 * * `safety` - Safety
 * * `facilities` - Facilities
 * * `aggression` - Aggression
 * * `recklessness` - Recklessness
 * * `covid` - Covid
 * * `smoking` - Smoking
 * * `alcohol` - Alcohol
 * * `drugs` - Drugs
 * * `possession` - Possession
 * * `reputation` - Reputation
 * * `refusal` - Refusal
 * * `abusive_behaviour` - Abusive Behaviour
 */
export type SubtypeEnum =
  | 'attitude'
  | 'kindness'
  | 'service'
  | 'language'
  | 'electronics'
  | 'dress'
  | 'late'
  | 'night'
  | 'room'
  | 'absence'
  | 'leave_request'
  | 'ausgang'
  | 'deception'
  | 'transport'
  | 'private_home'
  | 'deliveries'
  | 'safety'
  | 'facilities'
  | 'aggression'
  | 'recklessness'
  | 'covid'
  | 'smoking'
  | 'alcohol'
  | 'drugs'
  | 'possession'
  | 'reputation'
  | 'refusal'
  | 'abusive_behaviour'

export type SuggesterExternalEvent = {
  event_data: Record<string, any>
  /**
   * * `draft_activity_suggester_task_succeeded` - DRAFT_ACTIVITY_SUGGESTER_TASK_SUCCEEDED
   * * `draft_activity_suggester_task_failed` - DRAFT_ACTIVITY_SUGGESTER_TASK_FAILED
   */
  event_type: SuggesterExternalEventEventTypeEnum
}

/**
 * * `draft_activity_suggester_task_succeeded` - DRAFT_ACTIVITY_SUGGESTER_TASK_SUCCEEDED
 * * `draft_activity_suggester_task_failed` - DRAFT_ACTIVITY_SUGGESTER_TASK_FAILED
 */
export type SuggesterExternalEventEventTypeEnum =
  | 'draft_activity_suggester_task_succeeded'
  | 'draft_activity_suggester_task_failed'

export type SuggesterExternalEventRequest = {
  event_data: Record<string, any>
  /**
   * * `draft_activity_suggester_task_succeeded` - DRAFT_ACTIVITY_SUGGESTER_TASK_SUCCEEDED
   * * `draft_activity_suggester_task_failed` - DRAFT_ACTIVITY_SUGGESTER_TASK_FAILED
   */
  event_type: SuggesterExternalEventEventTypeEnum
}

export type SuggesterTaskConfig = {
  suggester_process_timeout: number
}

export type Suggestions = {
  school_year: number
  /** @format uuid */
  student: string
  courses_in_baskets?: Record<string, any>
}

export type SuggestionsRequest = {
  school_year: number
  /** @format uuid */
  student: string
  courses_in_baskets?: Record<string, any>
}

export type SuggestionsRequestRequest = {
  rota_timetable_id: number
  room_id?: number
  users: string[]
  /**
   * @min 0
   * @max 6
   */
  day?: number
  /** @format time */
  start_hour?: string
  /** @format time */
  end_hour?: string
}

export type SuggestionsResponse = {
  day: number
  /** @format time */
  start_hour: string
  /** @format time */
  end_hour: string
}

export type SummerAssignOptionsResponse = {
  levels: StringOption[]
  types: StringOption[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type SummerCampStudent = {
  /**
   * * `standard` - Standard
   * * `special` - Special
   */
  package?: Package0D4Enum
  package_str: string
  duration: number | null
  /** @format uuid */
  student_id: string
  current_aim_str: string
  level_str: string
  age: string
  /** @format date */
  date_of_birth: string | null
  has_tetanus_vaccination?: boolean
  /** @format date */
  tetanus_vaccination_date?: string | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type SummerCampStudentAssigns = {
  /** Course booking additional comment */
  additional_comments?: string
  summer_assigns: SummerSubjectAssign[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type SummerCampStudentAssignsRequest = {
  /** Course booking additional comment */
  additional_comments?: string
  summer_assigns: SummerSubjectAssignRequest[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type SummerCampStudentRequest = {
  /**
   * * `standard` - Standard
   * * `special` - Special
   */
  package?: Package0D4Enum
  has_tetanus_vaccination?: boolean
  /** @format date */
  tetanus_vaccination_date?: string | null
}

export type SummerCertificateAttachment = {
  /** @format uri */
  file: string
}

export type SummerCertificateCeremonyStudentSummary = {
  /** @format uuid */
  id: string
  full_name: string
}

export type SummerCertificateCeremonySummary = {
  /** @format date */
  week_end_date: string
  students_without_broken_rules: SummerCertificateCeremonyStudentSummary[]
  students_with_broken_rules: SummerCertificateCeremonyStudentSummary[]
}

export type SummerCertificateDraft = {
  /** It's a helper field for storing data only for frontend purposes. */
  draft?: Record<string, any>
}

export type SummerCertificateDraftRequest = {
  /** It's a helper field for storing data only for frontend purposes. */
  draft?: Record<string, any>
}

export type SummerMentalHealthIssue = {
  id: string
  issue_name: string
}

export type SummerMentalHealthIssueRequest = {
  /** @minLength 1 */
  issue_name: string
}

export type SummerRosenbergLegacy = {
  id: string
  legacy_type: LegacyTypeEnum
  legacy_type_str: string
  first_name: string
  last_name: string
  other_comment: string
}

export type SummerSubjectAssign = {
  id: string
  /** for frontend purposes */
  key: string
  /**
   * * `fine-arts` - Fine Arts
   * * `performing-arts` - Performing Arts
   * * `leadership` - Diplomacy & Activism
   * * `robotics` - Robotics & Engineering
   * * `environmental-science` - Environmental Science & Sustainability
   * * `fashion` - Fashion & Textile Design
   * * `informatics` - Informatics & Coding
   * * `intensive-english` - Intensive English
   * * `intensive-german` - Intensive German
   * * `rosenberg-x-crimson-university-admission` - Rosenberg x Crimson University Admission Focus Course
   * * `english` - English
   * * `german` - German
   * * `tennis` - Tennis
   * * `personal-training` - Personal Training
   * * `horse-riding` - Horse Riding
   * * `golf` - Golf
   */
  subject_name: SubjectNameEnum
  /**
   * * `lesson` - Lesson
   * * `training` - Training
   * * `focus_course` - Focus Course
   */
  subject_type: SubjectTypeEnum
  subject_type_str: string
  level?: LevelEnum | BlankEnum
  level_str: string
  number_of_weeks: number | null
  lessons_per_week: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

export type SummerSubjectAssignRequest = {
  /**
   * * `fine-arts` - Fine Arts
   * * `performing-arts` - Performing Arts
   * * `leadership` - Diplomacy & Activism
   * * `robotics` - Robotics & Engineering
   * * `environmental-science` - Environmental Science & Sustainability
   * * `fashion` - Fashion & Textile Design
   * * `informatics` - Informatics & Coding
   * * `intensive-english` - Intensive English
   * * `intensive-german` - Intensive German
   * * `rosenberg-x-crimson-university-admission` - Rosenberg x Crimson University Admission Focus Course
   * * `english` - English
   * * `german` - German
   * * `tennis` - Tennis
   * * `personal-training` - Personal Training
   * * `horse-riding` - Horse Riding
   * * `golf` - Golf
   */
  subject_name: SubjectNameEnum
  /**
   * * `lesson` - Lesson
   * * `training` - Training
   * * `focus_course` - Focus Course
   */
  subject_type: SubjectTypeEnum
  level?: LevelEnum | BlankEnum
  number_of_weeks: number | null
  lessons_per_week: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

/**
 * * `cold` - Cold
 * * `flu` - Flu
 * * `stomach` - Stomach
 * * `allergy` - Allergy
 * * `teeth` - Teeth
 * * `headache` - Headache
 * * `fatigue` - Fatigue
 * * `nausea` - Nausea
 * * `menstruation_pain` - Menstruation pain
 * * `fever` - Fever
 * * `covid_isolation` - Covid - isolation
 * * `covid_quarantine` - Covid - quarantine
 * * `other` - Other
 */
export type SymptomsEnum =
  | 'cold'
  | 'flu'
  | 'stomach'
  | 'allergy'
  | 'teeth'
  | 'headache'
  | 'fatigue'
  | 'nausea'
  | 'menstruation_pain'
  | 'fever'
  | 'covid_isolation'
  | 'covid_quarantine'
  | 'other'

export type TAEDraftActivity = {
  /** @format uuid */
  id: string
  course: SimpleCourse
  students: SimpleStudentWithGradeLevel[]
  teacher: SimpleUser
  co_teacher_1: SimpleUser | null
  co_teacher_2: SimpleUser | null
  room: SimpleRoom | null
  /**
   * @min 0
   * @max 2147483647
   */
  day: RotaDayEnum
  start_hour: string
  end_hour: string
}

export type TAEDraftActivityAvailableFilters = {
  students: AvailableStringFilter
  course: AvailableStringFilter
  room: AvailableStringFilter
  teacher: AvailableStringFilter
}

export type TAEDraftActivityList = {
  /** @format uuid */
  id: string
  course_name: string
  teacher: SimpleUser
  /**
   * @min 0
   * @max 2147483647
   */
  day: RotaDayEnum
  start_hour: string
  end_hour: string
}

export type TAEExperience = {
  id: number
  /** @maxLength 256 */
  name: string
}

export type TAEGeneratorTask = {
  id: number
  actor: SimpleUser
  timetable: number
  /** @format date-time */
  started_at?: string | null
  /**
   * * `pending` - Pending
   * * `running` - Running
   * * `succeeded` - Succeeded
   * * `failed` - Failed
   * * `cancelled` - Cancelled
   * * `discarded` - Discarded
   * * `discarded_after_fail` - Discarded after fail
   * * `applying_in_progress` - Applying in progress
   * * `applied` - Applied
   */
  status?: TAEGeneratorTaskStatusEnum
  infeasible_section_ids: number[] | null
}

/**
 * * `pending` - Pending
 * * `running` - Running
 * * `succeeded` - Succeeded
 * * `failed` - Failed
 * * `cancelled` - Cancelled
 * * `discarded` - Discarded
 * * `discarded_after_fail` - Discarded after fail
 * * `applying_in_progress` - Applying in progress
 * * `applied` - Applied
 */
export type TAEGeneratorTaskStatusEnum =
  | 'pending'
  | 'running'
  | 'succeeded'
  | 'failed'
  | 'cancelled'
  | 'discarded'
  | 'discarded_after_fail'
  | 'applying_in_progress'
  | 'applied'

export type TargetGrade = {
  id: number
  target_grade: string
}

export type TargetGradeRequest = {
  /** @minLength 1 */
  target_grade: string
}

/**
 * * `pending` - Pending
 * * `much_above` - Much Above
 * * `above` - Above
 * * `below` - Below
 * * `much_below` - Much Below
 */
export type TargetGradeStatusEnum =
  | 'pending'
  | 'much_above'
  | 'above'
  | 'below'
  | 'much_below'

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type Task = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  title: string
  description?: string
  /**
   * @format uri
   * @maxLength 200
   */
  link?: string
  /** @format date-time */
  start: string | null
  /** @format date-time */
  deadline?: string | null
  is_done: boolean
  created_by?: string
  created_by_str: string
  /** @format uuid */
  assignee?: string | null
  assignee_str: string
  /** @format uuid */
  team?: string | null
  team_str: string
  is_recurring: boolean
  subtasks?: Subtask[]
  status: string
  is_marked_done_manually: boolean
  done_comment?: string
  allow_to_close_subtask: boolean
  complete_automatically: boolean
  is_editable: string
  recurring?: Recurring | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TaskRequest = {
  /** @minLength 1 */
  title: string
  description?: string
  /**
   * @format uri
   * @maxLength 200
   */
  link?: string
  /** @format date-time */
  deadline?: string | null
  created_by?: string
  /** @format uuid */
  assignee?: string | null
  /** @format uuid */
  team?: string | null
  subtasks?: SubtaskRequest[]
  done_comment?: string
  recurring?: RecurringRequest | null
  is_cloned?: boolean
  /** @minLength 1 */
  cloned_from?: string | null
}

export type TaskToggleIsDone = {
  done_comment?: string
}

export type TaskToggleIsDoneRequest = {
  done_comment?: string
}

export type TaskToggleSubtaskIsDone = {
  subtask: string
}

export type TaskToggleSubtaskIsDoneRequest = {
  /** @minLength 1 */
  subtask: string
}
export type TeacherTimetableCourse = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  types: string[]
  levels: string[]
  /** @format double */
  credits: number | null
  departments: string[]
  /** @format uri */
  outline_file?: string | null
  outline_file_name: string | null
}

export type TeacherTimetableLesson = {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  start: string
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  /** @format double */
  duration?: number
  day: RotaDayEnum
  end: string
  display_hour: string
  course: TeacherTimetableCourse
  quarter: number
  room?: number | null
  students: SimpleUser[]
  teacher: SimpleUser
  is_archived?: boolean
  teacher_cover?: SimpleUser | null
  co_teacher_1: SimpleUser | null
  co_teacher_2: SimpleUser | null
}

export type TeacherTimetableListLesson = {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  start: string
  end: string
  display_hour: string
  course: SimpleCourse
  room: TeacherTimetableRoom
  quarter: number
  /** @format uuid */
  teacher: string
  /** @format uuid */
  teacher_cover?: string | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
}

export type TeacherTimetableRoom = {
  id: number
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
}

export type TeachersPerGrade = {
  id: number
  teachers: string[]
  grade_level: number
  mentoring?: number
}

export type TeachersPerGradeRequest = {
  teachers: string[]
  grade_level: number
  mentoring?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type Team = {
  /** @format uuid */
  id: string
  name: string
  house?: number | null
  house_name: string
  members: TeamMember[]
  is_predefined: boolean
  include_in_boarding_rota?: boolean
}

export type TeamMember = {
  /** @format uuid */
  id: string
  /** @format uuid */
  user: string
  /**
   * @min 0
   * @max 32767
   */
  role: TeamMemberRoleEnum
  role_display: string
  user_first_name: string
  user_last_name: string
  rota_roles?: BoardingArtisanRoleEnum[]
}

export type TeamMemberRequest = {
  /** @format uuid */
  user: string
  /**
   * @min 0
   * @max 32767
   */
  role: TeamMemberRoleEnum
  rota_roles?: BoardingArtisanRoleEnum[]
}

/**
 * * `1` - Semester 1
 * * `2` - Semester 2
 */
export type TeamMemberRoleEnum = 1 | 2

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TeamRequest = {
  /** @minLength 1 */
  name: string
  house?: number | null
  members: TeamMemberRequest[]
  include_in_boarding_rota?: boolean
}

export type TemperatureCheck = {
  id: number
  /** @format uuid */
  health_check: string
  /** @format double */
  temperature: number
}

export type TemperatureCheckRequest = {
  /** @format uuid */
  health_check: string
  /** @format double */
  temperature: number
}

/**
 * * `isolation` - Isolation
 * * `quarantine` - Quarantine
 * * `sleepover` - Sleepover
 */
export type TemporaryMovementTypeEnum = 'isolation' | 'quarantine' | 'sleepover'

/**
 * * `quarter` - Quarter
 * * `school_year` - School Year
 */
export type TimeframeTypeEnum = 'quarter' | 'school_year'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Timetable = {
  id: number
  /** @format date-time */
  created: string
  total_gaps: number | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  quarter: number
  total_activities: number
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: Status97AEnum
  status_display: string
  generation_task: number
  quarter_str: string
  quarter_short_name: QuarterShortNameEnum
  idp_plans: number[]
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
  selected_rota_timetable: string
  total_tae_activities: number
}

export type TimetableAutoImportConfig = {
  is_enabled: boolean
  is_daily: boolean
  days: RotaDayEnum[]
  /** @format time */
  time: string
  /** @format date-time */
  next_autoimport_date: string | null
}

export type TimetableAutoImportConfigUpdate = {
  is_enabled: boolean
  is_daily: boolean
  days: RotaDayEnum[]
  /** @format time */
  time: string
  /** @format date-time */
  next_autoimport_date: string | null
  disabled_autoimport: DisabledAutoimportTimetable | null
}

export type TimetableCampusAbsence = {
  /** @format uuid */
  id: string
  /** @format date-time */
  start: string
  /** @format date-time */
  end: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   *  type
   * @default "campus_absence"
   */
  _type: TimetableCampusAbsenceTypeEnum
  campus_absence_type: CampusAbsenceTypeEnum
  campus_absence_type_label: string
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  campus_absence_subtype_label: string
  students: StudentWithAvatar[]
  leave_details: string
  can_cancel: boolean
  can_edit: boolean
  created_by?: string
  merit_reward: string
  resourcetype: MeritRewardResourcetypeEnum | null
  custom_name: string
}

/** * `campus_absence` - campus_absence */
export type TimetableCampusAbsenceTypeEnum = 'campus_absence'

export type TimetableDoctorAppointment = {
  id: number
  /** @format date-time */
  start: string
  /** @format date-time */
  end: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   *  type
   * @default "doctor_appointment"
   */
  _type: TimetableDoctorAppointmentTypeEnum
  student_id: string
  doctor_name: string
}

/** * `doctor_appointment` - doctor_appointment */
export type TimetableDoctorAppointmentTypeEnum = 'doctor_appointment'

export type TimetableExam = {
  id: string
  start: string
  end: string
  start_date: string
  end_date: string
  /**
   *  type
   * @default "exam"
   */
  _type: TimetableExamTypeEnum
  /** @format date */
  date: string | null
  /** @format uuid */
  course: string
  course_str: string
  users: string
  location_str: string
  test_invigilators: SimpleUserWithAvatar[]
  saa_invigilators: SimpleUserWithAvatar[]
  teachers: SimpleUserWithAvatar[]
  exam_session_id: string
  is_allowed: string
  /** via annotate */
  school_year_id: number
  /**
   * * `Exam` - Exam
   * * `Lesson` - Lesson
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: AdminTimetableResourcetypeEnum
}

/** * `exam` - exam */
export type TimetableExamTypeEnum = 'exam'

export type TimetableLeaveCheck = {
  /** @format uuid */
  id: string
  /** @maxLength 1024 */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /** @maxLength 64 */
  contact_person: string
  /** @maxLength 128 */
  contact_person_phone: string
  is_completed?: boolean
  is_driver_needed?: boolean
}

export type TimetableLeaveRequest = {
  /** @format uuid */
  id: string
  /** @format date-time */
  start: string
  /** @format date-time */
  end: string
  start_date: string
  end_date: string
  /**
   *  type
   * @default "leave_request"
   */
  _type: TimetableLeaveTypeEnum
  /** @format uuid */
  student_id: string
  leave_type: LeaveRequestTypeEnum
  leave_type_label: string
  check_in: TimetableLeaveCheck
  check_out: TimetableLeaveCheck
  can_edit: boolean
  can_cancel: boolean
}

/** * `leave_request` - leave_request */
export type TimetableLeaveTypeEnum = 'leave_request'

export type TimetableLesson = {
  /** @format uuid */
  id: string
  start: string
  end: string
  start_date: string
  end_date: string
  /**
   *  type
   * @default "lesson"
   */
  _type: TimetableLessonTypeEnum
  /** @format date */
  date: string
  /** @format uuid */
  course: string
  course_str: string
  users: SimpleUserWithAvatar[]
  room_str: string
  teacher: SimpleUserWithAvatar
  teacher_cover: SimpleUserWithAvatar | null
  co_teacher_1: SimpleUserWithAvatar | null
  co_teacher_2: SimpleUserWithAvatar | null
  is_allowed: boolean
  homework_count: number
  /** via annotate */
  school_year_id: number
  /**
   * * `Exam` - Exam
   * * `Lesson` - Lesson
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: AdminTimetableResourcetypeEnum
}

/** * `lesson` - lesson */
export type TimetableLessonTypeEnum = 'lesson'

export type TimetableLog = {
  id: number
  /** @format date-time */
  created: string
  message: string
  changed_fields: string
  timetable: number
  /**
   * * `create` - Create
   * * `delete` - Delete
   * * `update` - Update
   */
  log_type: TimetableLogLogTypeEnum
}

/**
 * * `create` - Create
 * * `delete` - Delete
 * * `update` - Update
 */
export type TimetableLogLogTypeEnum = 'create' | 'delete' | 'update'

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TimetableMentoring = {
  id: number
  ignored_students?: string[]
  timetable: number
  grades_to_assign: number[]
  /**
   * @min 0
   * @max 2147483647
   */
  students_in_section?: number
  is_applied: boolean
  copy_from_previous_quarter?: boolean
  teachers_per_grade: TeachersPerGrade[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TimetableMentoringRequest = {
  ignored_students?: string[]
  timetable: number
  grades_to_assign: number[]
  /**
   * @min 0
   * @max 2147483647
   */
  students_in_section?: number
  copy_from_previous_quarter?: boolean
  teachers_per_grade: TeachersPerGradeRequest[]
}

export type TimetableMeritReward = {
  id: string
  /** @format date */
  start: string
  /** @format date */
  end: string
  start_date: string
  end_date: string
  /**
   *  type
   * @default "merit_reward"
   */
  _type: TimetableMeritRewardTypeEnum
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

/** * `merit_reward` - merit_reward */
export type TimetableMeritRewardTypeEnum = 'merit_reward'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type TimetableRequest = {
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: Status97AEnum
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type TimetableRetrieve = {
  id: number
  /** @format date-time */
  created: string
  total_gaps: number | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  quarter: number
  total_activities: number
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: Status97AEnum
  status_display: string
  generation_task: number
  quarter_str: string
  quarter_short_name: QuarterShortNameEnum
  idp_plans: number[]
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
  selected_rota_timetable: string
  total_tae_activities: number
  number_of_changes: number
  number_of_rota_changes: number
  autoimport_config: TimetableAutoImportConfig
  blocking_tae_generator_task: TAEGeneratorTask | null
}

export type TimetableRotaActivity = {
  id: string
  /** @format date-time */
  start: string
  /** @format date-time */
  end: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   *  type
   * @default "rota"
   */
  _type: TimetableRotaActivityTypeEnum
  role: string
  role_str: string
  /** @default "" */
  house_name: string
  user: string
  user_str: string
  rota_type: RotaTypeEnum
  /** @format uuid */
  team: string | null
  team_str: string
  date: string
  covered_by: SimpleUser[] | null
  covering: SimpleUser[]
  users_str: string
  /**
   * * `Exam` - Exam
   * * `Lesson` - Lesson
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: AdminTimetableResourcetypeEnum
}

/** * `rota` - rota */
export type TimetableRotaActivityTypeEnum = 'rota'

export type TimetableSchoolBreak = {
  id: number
  /** @format date */
  start: string
  /** @format date */
  end: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  /**
   *  type
   * @default "school_break"
   */
  _type: TimetableSchoolBreakTypeEnum
  name: string
  related_notification_id: string
}

/** * `school_break` - school_break */
export type TimetableSchoolBreakTypeEnum = 'school_break'

export type TimetableSchoolProgramme = {
  id: string
  /** @format date-time */
  start: string
  /** @format date-time */
  end: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   *  type
   * @default "school_programme"
   */
  _type: TimetableSchoolProgrammeTypeEnum
  title: string
  is_allowed: boolean
  leaders_data: SimpleUser[]
  type: SchoolProgrammeTypeEnum
  is_recovery_type: boolean
  recovery_location: string | null
  recovery_id: string | null
  /**
   * * `Exam` - Exam
   * * `Lesson` - Lesson
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: AdminTimetableResourcetypeEnum
}

/** * `school_programme` - school_programme */
export type TimetableSchoolProgrammeTypeEnum = 'school_programme'

export type TimetableStats = {
  id: number
  /** @format date-time */
  created: string
  total_gaps: number | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  total_activities: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  generation_task: GenerationTask
  late_activities: string
  students_with_most_hours: string
  teachers_with_most_hours: string
  generation_time: string
}

export type TogglePublish = {
  feed: boolean
  infoboards: boolean
}

export type TogglePublishRequest = {
  feed: boolean
  infoboards: boolean
}
/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TranscriptOfGrades = {
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  /**
   * Credtis from other schools than Rosen, added manually.
   * @format double
   */
  external_credits?: number
  items: TranscriptOfGradesItem[]
  status: TranscriptOfGradesStatusEnum
  /**
   * Internal credits from Rosen, sum of the credits fromtranscript items.
   * @format double
   */
  internal_credits: number
  /** @format double */
  total_credits: number
  grade_level: number
}

export type TranscriptOfGradesItem = {
  /** @format uuid */
  id?: string
  /**
   * Indicates the order of items in the form and file
   * @min -2147483648
   * @max 2147483647
   */
  ordering: number
  /**
   * Course official name
   * @maxLength 150
   */
  official_name: string
  courses: string[]
  /**
   * Student results average percentage
   * @min 0
   * @max 2147483647
   */
  average?: number | null
  /** @format double */
  credits: number
  /** @maxLength 32 */
  grade: string
  /**
   * Course difficulty
   * @maxLength 32
   */
  level: string
  /**
   * * `CCU` - CCU
   * * `SPORTS` - Sports
   * * `OTHER` - Other
   */
  group: TranscriptOfGradesItemGroupEnum
}

export type TranscriptOfGradesItemCourse = {
  /** @format uuid */
  id: string
  name: string
  aim: number
  /** @format double */
  credits: number
  average: number
  difficulty: string
  is_dropped: boolean
  /**
   * * `CCU` - CCU
   * * `SPORTS` - Sports
   * * `OTHER` - Other
   */
  group: TranscriptOfGradesItemGroupEnum
}

/**
 * * `CCU` - CCU
 * * `SPORTS` - Sports
 * * `OTHER` - Other
 */
export type TranscriptOfGradesItemGroupEnum = 'CCU' | 'SPORTS' | 'OTHER'

export type TranscriptOfGradesItemRequest = {
  /** @format uuid */
  id?: string
  /**
   * Indicates the order of items in the form and file
   * @min -2147483648
   * @max 2147483647
   */
  ordering: number
  /**
   * Course official name
   * @minLength 1
   * @maxLength 150
   */
  official_name: string
  courses: string[]
  /**
   * Student results average percentage
   * @min 0
   * @max 2147483647
   */
  average?: number | null
  /** @format double */
  credits: number
  /**
   * @minLength 1
   * @maxLength 32
   */
  grade: string
  /**
   * Course difficulty
   * @minLength 1
   * @maxLength 32
   */
  level: string
  /**
   * * `CCU` - CCU
   * * `SPORTS` - Sports
   * * `OTHER` - Other
   */
  group: TranscriptOfGradesItemGroupEnum
}

export type TranscriptOfGradesList = {
  results: TranscriptOfGradesListItem[]
  grade_level: GradeLevel
}

export type TranscriptOfGradesListItem = {
  /** @format uuid */
  id: string
  /** @maxLength 255 */
  name?: string
  /** @format date-time */
  modified: string
  /**
   * * `active` - Active
   * * `complete` - Complete
   * * `official` - Official
   */
  status?: TranscriptOfGradesStatusEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TranscriptOfGradesRequest = {
  /** @format uuid */
  student: string
  /**
   * Credtis from other schools than Rosen, added manually.
   * @format double
   */
  external_credits?: number
}

/**
 * * `active` - Active
 * * `complete` - Complete
 * * `official` - Official
 */
export type TranscriptOfGradesStatusEnum = 'active' | 'complete' | 'official'

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TranscriptOfGradesUpdate = {
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  /**
   * Credtis from other schools than Rosen, added manually.
   * @format double
   */
  external_credits: number
  items: TranscriptOfGradesItem[]
  /**
   * * `active` - Active
   * * `complete` - Complete
   * * `official` - Official
   */
  status: TranscriptOfGradesStatusEnum
  /**
   * Internal credits from Rosen, sum of the credits fromtranscript items.
   * @format double
   */
  internal_credits: number
  /** @format double */
  total_credits: number
  grade_level: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TranscriptOfGradesUpdateRequest = {
  /**
   * Credtis from other schools than Rosen, added manually.
   * @format double
   */
  external_credits: number
  items: TranscriptOfGradesItemRequest[]
  /**
   * * `active` - Active
   * * `complete` - Complete
   * * `official` - Official
   */
  status: TranscriptOfGradesStatusEnum
}

export type TreatmentHistoryItem = {
  id: string
  symptoms: string
  status: string
  /** @format date-time */
  created: string
  /** @format date-time */
  closed_at?: string | null
  absent_now: string
}

export type Tutor = {
  /** @format uuid */
  id: string
  full_name: string
  profiles: string[]
}

export type TwoFactorLogin =
  | TwoFactorLoginResponse
  | TwoFactorLoginCodeResponse
  | TwoFactorLoginUserResponse

export type TwoFactorLoginCodeResponse = {
  code: number
  user: LoginUserResponse
}

export type TwoFactorLoginResponse = {
  token: string
  expiry: string
  user: LoginUserResponse
}

export type TwoFactorLoginUserResponse = {
  user: LoginUserResponse
}

/**
 * * `4` - 4
 * * `3` - 3
 * * `2` - 2
 * * `1` - 1
 * * `0` - 0
 */
export type TwoWeeksAgoCategoryEnum = 4 | 3 | 2 | 1 | 0

export type UpdateActivitiesRooms = {
  /**
   * @format uri
   * @pattern (?:csv)$
   */
  file: string
}

export type UpdateActivitiesRoomsRequest = {
  /**
   * @format binary
   * @pattern (?:csv)$
   */
  file: File
}

/**
 * * `this` - This
 * * `this_and_next` - This and following
 * * `next` - Following
 */
export type UpdateTypeEnum = 'this' | 'this_and_next' | 'next'

export type User = {
  /** @format uuid */
  id: string
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  full_name: string
  /** @maxLength 256 */
  name?: string
  permissions: string
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  profiles: string[]
  staff_type?: StaffTypeEnum | BlankEnum
  /** @format uri */
  avatar: string | null
  has_perms_to_own_objects_only: string
  can_report_bug: boolean
}

/** Collects and raises validation errors in bulk */
export type UserAcademicAdminProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type UserAcademicAdminProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type UserAcademicHODProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type UserAcademicHODProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type UserAdminProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type UserAdminProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type UserBoardingDirectorProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type UserBoardingDirectorProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type UserHealthCoordinatorProfile = {
  id: string
  /** @format uuid */
  user?: string | null
  excluded_from_worse_condition_email?: boolean
}

/** Collects and raises validation errors in bulk */
export type UserHealthCoordinatorProfileRequest = {
  /** @format uuid */
  user?: string | null
  excluded_from_worse_condition_email?: boolean
}

/** Collects and raises validation errors in bulk */
export type UserIdpAdminProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type UserIdpAdminProfileRequest = {
  /** @format uuid */
  user?: string | null
}
/** Collects and raises validation errors in bulk */
export type UserTeacherProfile = {
  id: string
  /** @maxLength 16 */
  acronym: string
  departments?: number[]
  preferred_room?: number | null
  preferred_rooms_str: string[]
  departments_str: string[]
  /** Teacher hidden in timetable generator */
  is_hidden_in_generator?: boolean
  preferred_rooms?: number[]
  custom_schedule?: Record<string, any>
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type UserTeacherProfileRequest = {
  /**
   * @minLength 1
   * @maxLength 16
   */
  acronym: string
  departments?: number[]
  preferred_room?: number | null
  /** Teacher hidden in timetable generator */
  is_hidden_in_generator?: boolean
  preferred_rooms?: number[]
  custom_schedule?: Record<string, any>
  /** @format uuid */
  user?: string | null
}

export type UserTimetable =
  | TimetableCampusAbsence
  | TimetableDoctorAppointment
  | TimetableExam
  | TimetableLeaveRequest
  | TimetableLesson
  | TimetableSchoolBreak
  | TimetableSchoolProgramme
  | TimetableRotaActivity
  | TimetableMeritReward

export type UsersClashesForDateParamsRequest = {
  user_ids: string[]
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  /** @minLength 1 */
  exclude_lesson_id?: string | null
  /** @minLength 1 */
  exclude_exam_id?: string | null
  /** @minLength 1 */
  exclude_programme_id?: string | null
}

export type ValidateCodeRequest = {
  code: number
}

export type ValidateLoginLinkRequest = {
  /** @minLength 1 */
  token: string
}

/**
 * * `wreath` - Wreath
 * * `cap` - Cap
 */
export type VariantEnum = 'wreath' | 'cap'

/** Collects and raises validation errors in bulk */
export type ViewerProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/** Collects and raises validation errors in bulk */
export type ViewerProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/**
 * * `shortness of breath` - Shortness of Breath
 * * `wheezing` - Wheezing
 * * `anxiety` - Anxiety
 * * `confusion` - Confusion
 * * `sudden or severe tiredness` - Sudden or Severe Tiredness
 * * `exhaustion` - Exhaustion
 * * `pale skin` - Pale Skin
 * * `skin rash` - Skin Rash
 * * `skin hive` - Skin Hive
 * * `nausea` - Nausea
 * * `faintness` - Faintness
 * * `inability to concentrate` - Inability to Concentrate
 * * `heart palpitations` - Heart Palpitations
 * * `seizure` - Seizure
 * * `unusual behaviour` - Unusual Behaviour
 * * `sensations` - Sensations
 * * `compulsive bahviour` - Compulsive Behaviour
 * * `obsessive behaviour` - Obsessive Behaviour
 * * `headaches` - Headaches
 * * `infection` - Infection
 * * `body ache or pain` - Body Ache or Pain
 * * `sadness` - Sadness
 * * `depression` - Depression
 */
export type WatchOutForEnum =
  | 'shortness of breath'
  | 'wheezing'
  | 'anxiety'
  | 'confusion'
  | 'sudden or severe tiredness'
  | 'exhaustion'
  | 'pale skin'
  | 'skin rash'
  | 'skin hive'
  | 'nausea'
  | 'faintness'
  | 'inability to concentrate'
  | 'heart palpitations'
  | 'seizure'
  | 'unusual behaviour'
  | 'sensations'
  | 'compulsive bahviour'
  | 'obsessive behaviour'
  | 'headaches'
  | 'infection'
  | 'body ache or pain'
  | 'sadness'
  | 'depression'

export type Week = {
  name: string
  short_name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
}

/**
 * * `4` - 4
 * * `3` - 3
 * * `2` - 2
 * * `1` - 1
 * * `0` - 0
 */
export type WeekAgoCategoryEnum = 4 | 3 | 2 | 1 | 0

export type WeekDay = {
  /**
   * * `0` - Monday
   * * `1` - Tuesday
   * * `2` - Wednesday
   * * `3` - Thursday
   * * `4` - Friday
   * * `5` - Saturday
   * * `6` - Sunday
   */
  value: RotaDayEnum
  /**
   * * `Monday` - Monday
   * * `Tuesday` - Tuesday
   * * `Wednesday` - Wednesday
   * * `Thursday` - Thursday
   * * `Friday` - Friday
   * * `Saturday` - Saturday
   * * `Sunday` - Sunday
   */
  label: WeekDayLabelEnum
}

/**
 * * `0` - Monday
 * * `1` - Tuesday
 * * `2` - Wednesday
 * * `3` - Thursday
 * * `4` - Friday
 */
export type WeekDayEnum = 0 | 1 | 2 | 3 | 4

/**
 * * `Monday` - Monday
 * * `Tuesday` - Tuesday
 * * `Wednesday` - Wednesday
 * * `Thursday` - Thursday
 * * `Friday` - Friday
 * * `Saturday` - Saturday
 * * `Sunday` - Sunday
 */
export type WeekDayLabelEnum =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday'

export type WeekResults = {
  results: Week[]
  current_period?: Week | null
}

export type WeeklyComment = {
  id: number
  author: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 2000 */
  content: string
  /** @format uuid */
  course: string
  /** @format uuid */
  student: string
}

export type WeeklyCommentRequest = {
  /**
   * @minLength 1
   * @maxLength 2000
   */
  content: string
  /** @format uuid */
  course: string
  /** @format uuid */
  student: string
}

/**
 * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
 * * `CelebrationWidget` - CelebrationWidget
 * * `EventAgendaWidget` - EventAgendaWidget
 * * `CongratulationsWidget` - CongratulationsWidget
 * * `PosterWidget` - PosterWidget
 * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
 * * `EventInfoWidget` - EventInfoWidget
 * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
 * * `JournalWidget` - JournalWidget
 * * `MysteryLinkWidget` - MysteryLinkWidget
 * * `MenuWidget` - MenuWidget
 * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
 * * `StudentsPicWidget` - StudentsPicWidget
 * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
 * * `ClockWidget` - ClockWidget
 */
export type WidgetResourcetypeEnum =
  | 'BoardingAnnouncementWidget'
  | 'CelebrationWidget'
  | 'EventAgendaWidget'
  | 'CongratulationsWidget'
  | 'PosterWidget'
  | 'StudentArtGalleryWidget'
  | 'EventInfoWidget'
  | 'EventInfoWithPhotoWidget'
  | 'JournalWidget'
  | 'MysteryLinkWidget'
  | 'MenuWidget'
  | 'StudentsAnnouncementWidget'
  | 'StudentsPicWidget'
  | 'VariantManualCongratulationsWidget'
  | 'ClockWidget'

/**
 * * `unpublished` - Unpublished
 * * `published` - Published
 * * `scheduled` - Scheduled
 * * `draft` - Draft
 */
export type WidgetStatusEnum =
  | 'unpublished'
  | 'published'
  | 'scheduled'
  | 'draft'

import type { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || ''
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {})
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path
      })
      .then(response => response.data)
  }
}

type Flatten<T> = T extends any[] ? T[number] : T
type ChangeTypeOfKeys<T extends object, Keys extends keyof T, NewType> = {
  [key in keyof T]: key extends Keys ? NewType : T[key]
}
type MapObjectKeysToTrue<ObjectType extends Record<PropertyKey, any>> = {
  [key in keyof ObjectType]?: ObjectType[key] extends Record<PropertyKey, any>
    ? true | MapObjectKeysToTrue<Flatten<ObjectType[key]>>
    : true
}
type FetchKeysType = { [key in PropertyKey]: true | FetchKeysType }
type FetchKeys<ResponseModel> = ResponseModel extends {
  count?: number
  results?: Array<infer DataModel>
}
  ? DataModel extends Record<PropertyKey, any>
    ? MapObjectKeysToTrue<DataModel>
    : DataModel
  : ResponseModel extends Record<PropertyKey, any>
  ? MapObjectKeysToTrue<Flatten<ResponseModel>>
  : ResponseModel

type NestedSafePick<ResponseModel, FetchKeysType> = ResponseModel extends Array<
  infer Model
>
  ? NestedSafePick<Model, FetchKeysType>[]
  : {
      [key in keyof FetchKeysType]: FetchKeysType[key] extends Record<
        PropertyKey,
        any
      >
        ? key extends keyof ResponseModel
          ? ResponseModel[key] extends Array<infer DataModel>
            ? NestedSafePick<DataModel, FetchKeysType[key]>[]
            : NestedSafePick<ResponseModel[key], FetchKeysType[key]>
          : never
        : key extends keyof ResponseModel
        ? ResponseModel[key] extends Array<infer DataModel>
          ? DataModel[]
          : ResponseModel[key]
        : never
    }

type PickKeys<ResponseModel, FetchKeysType> = {} extends FetchKeysType
  ? ResponseModel
  : ResponseModel extends {
      count?: number
      results?: Array<infer DataModel>
    }
  ? ChangeTypeOfKeys<
      ResponseModel,
      'results',
      NestedSafePick<DataModel, FetchKeysType>[]
    >
  : ResponseModel extends Array<infer DataModel>
  ? NestedSafePick<DataModel, FetchKeysType>[]
  : NestedSafePick<ResponseModel, FetchKeysType>

function postprocessQuery(query?: any) {
  if (!query) return query

  const parsedQuery = query
  if (query.fetchKeys) {
    function getStringifiedQueryKeys(keys: FetchKeysType) {
      let query = '{'
      Object.entries(keys).forEach(([key, value], index) => {
        if (typeof value === 'object') {
          const keyToAdd = `${key}${getStringifiedQueryKeys(value)}`
          query += index > 0 ? `,${keyToAdd}` : keyToAdd
        } else {
          query += index > 0 ? `,${key}` : key
        }
      })
      return query + '}'
    }

    parsedQuery.query = getStringifiedQueryKeys(query.fetchKeys)
  }

  delete parsedQuery.fetchKeys
  return parsedQuery
}

/**
 * @title API* @version 1.0.0 (v1)*/
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  achievements = {}
  activities = {}
  adminTimetable = {
    /**
     * @description [WARNING] AdminTimetableViewSet inherits from this!
     *
     * @tags admin-timetable
     * @name AdminTimetableList
     * @request GET:/admin-timetable/
     */
    adminTimetableList: <T extends FetchKeys<AdminTimetable[]>>(
      query?: {
        /** @format date */
        date?: string
        students?: string[]
        teachers?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetable[], T>, any>({
        path: `/admin-timetable/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description [WARNING] AdminTimetableViewSet inherits from this!
     *
     * @tags admin-timetable
     * @name AdminTimetablePdfRetrieve
     * @request GET:/admin-timetable/pdf/
     */ adminTimetablePdfRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin-timetable/pdf/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
  }
  admin = {
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonList
     * @request GET:/admin/activities/lesson/
     */
    adminActivitiesLessonList: <
      T extends FetchKeys<PaginatedAdminTimetableLessonList>
    >(
      query?: {
        /** @format uuid */
        activity__course?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        during_exams?: string[]
        hide_past_lessons?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        student?: string
        students?: string[]
        /** @format uuid */
        teacher?: string
        /** @format uuid */
        teacher_cover?: string
        teacher_cover__isnull?: boolean
        /** @format uuid */
        teacher_covered?: string
        teachers?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminTimetableLessonList, T>, any>({
        path: `/admin/activities/lesson/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonRetrieve
     * @request GET:/admin/activities/lesson/{id}/
     */ adminActivitiesLessonRetrieve: <
      T extends FetchKeys<AdminTimetableLesson>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetableLesson, T>, any>({
        path: `/admin/activities/lesson/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonUpdate
     * @request PUT:/admin/activities/lesson/{id}/
     */ adminActivitiesLessonUpdate: <
      T extends FetchKeys<AdminTimetableLessonUpdate>
    >(
      id: string,
      data: AdminTimetableLessonUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetableLessonUpdate, T>, any>({
        path: `/admin/activities/lesson/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonPartialUpdate
     * @request PATCH:/admin/activities/lesson/{id}/
     */ adminActivitiesLessonPartialUpdate: <
      T extends FetchKeys<AdminTimetableLessonUpdate>
    >(
      id: string,
      data: PatchedAdminTimetableLessonUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetableLessonUpdate, T>, any>({
        path: `/admin/activities/lesson/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonAssignRoomUpdate
     * @request PUT:/admin/activities/lesson/{id}/assign-room/
     */ adminActivitiesLessonAssignRoomUpdate: <
      T extends FetchKeys<AdminTimetableLessonRoomAssignment>
    >(
      id: string,
      data: AdminTimetableLessonRoomAssignmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetableLessonRoomAssignment, T>, any>({
        path: `/admin/activities/lesson/${id}/assign-room/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonSplitLessonCreate
     * @request POST:/admin/activities/lesson/{id}/split-lesson/
     */ adminActivitiesLessonSplitLessonCreate: <
      T extends FetchKeys<AdminTimetableLesson>
    >(
      id: string,
      data: AdminTimetableLessonRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetableLesson, T>, any>({
        path: `/admin/activities/lesson/${id}/split-lesson/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesList
     * @request GET:/admin/attendances/absences/
     */ adminAttendancesAbsencesList: <
      T extends FetchKeys<PaginatedAbsenceList>
    >(
      query?: {
        /**
         * * `late` - Late
         * * `absent_excused` - Absent excused
         * * `absent_unexcused` - Absent unexcused
         * * `sick` - Sick
         */
        absence_type?: 'absent_excused' | 'absent_unexcused' | 'late' | 'sick'
        /** @format date */
        end_date_after?: string
        /** @format date */
        end_date_before?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format date */
        start_date_after?: string
        /** @format date */
        start_date_before?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAbsenceList, T>, any>({
        path: `/admin/attendances/absences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesCreate
     * @request POST:/admin/attendances/absences/
     */ adminAttendancesAbsencesCreate: <T extends FetchKeys<Absence>>(
      data: AbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Absence, T>, any>({
        path: `/admin/attendances/absences/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesRetrieve
     * @request GET:/admin/attendances/absences/{id}/
     */ adminAttendancesAbsencesRetrieve: <T extends FetchKeys<Absence>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Absence, T>, any>({
        path: `/admin/attendances/absences/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesUpdate
     * @request PUT:/admin/attendances/absences/{id}/
     */ adminAttendancesAbsencesUpdate: <T extends FetchKeys<Absence>>(
      id: string,
      data: AbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Absence, T>, any>({
        path: `/admin/attendances/absences/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesPartialUpdate
     * @request PATCH:/admin/attendances/absences/{id}/
     */ adminAttendancesAbsencesPartialUpdate: <T extends FetchKeys<Absence>>(
      id: string,
      data: PatchedAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Absence, T>, any>({
        path: `/admin/attendances/absences/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesDestroy
     * @request DELETE:/admin/attendances/absences/{id}/
     */ adminAttendancesAbsencesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/attendances/absences/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags admin
     * @name AdminAttendancesAlertsList
     * @request GET:/admin/attendances/alerts/
     */ adminAttendancesAlertsList: <
      T extends FetchKeys<PaginatedAcademicAttendanceAlertList>
    >(
      query?: {
        /** @format uuid */
        course?: string
        is_notification_sent?: boolean
        is_read?: boolean
        /** @format date */
        lesson_date?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `unresolved` - Unresolved
         * * `resolved` - Resolved
         */
        status?: 'resolved' | 'unresolved'
        /** @format uuid */
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAcademicAttendanceAlertList, T>, any>({
        path: `/admin/attendances/alerts/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags admin
     * @name AdminAttendancesAlertsRetrieve
     * @request GET:/admin/attendances/alerts/{id}/
     */ adminAttendancesAlertsRetrieve: <
      T extends FetchKeys<AcademicAttendanceAlert>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendanceAlert, T>, any>({
        path: `/admin/attendances/alerts/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags admin
     * @name AdminAttendancesAlertsMarkAsReadCreate
     * @request POST:/admin/attendances/alerts/{id}/mark-as-read/
     */ adminAttendancesAlertsMarkAsReadCreate: <
      T extends FetchKeys<AcademicAttendanceAlert>
    >(
      id: string,
      data: AcademicAttendanceAlertRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendanceAlert, T>, any>({
        path: `/admin/attendances/alerts/${id}/mark-as-read/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags admin
     * @name AdminAttendancesAlertsSendNotificationCreate
     * @request POST:/admin/attendances/alerts/{id}/send-notification/
     */ adminAttendancesAlertsSendNotificationCreate: <
      T extends FetchKeys<AcademicAttendanceAlert>
    >(
      id: string,
      data: AcademicAttendanceAlertRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendanceAlert, T>, any>({
        path: `/admin/attendances/alerts/${id}/send-notification/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags admin
     * @name AdminAttendancesAlertsAvailableFiltersRetrieve
     * @request GET:/admin/attendances/alerts/available-filters/
     */ adminAttendancesAlertsAvailableFiltersRetrieve: <
      T extends FetchKeys<AcademicAttendanceAlert>
    >(
      query?: {
        /** @format uuid */
        course?: string
        is_notification_sent?: boolean
        is_read?: boolean
        /** @format date */
        lesson_date?: string
        /**
         * * `unresolved` - Unresolved
         * * `resolved` - Resolved
         */
        status?: 'resolved' | 'unresolved'
        /** @format uuid */
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendanceAlert, T>, any>({
        path: `/admin/attendances/alerts/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarList
     * @request GET:/admin/core/calendar/
     */ adminCoreCalendarList: <
      T extends FetchKeys<PaginatedCalendarEventList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCalendarEventList, T>, any>({
        path: `/admin/core/calendar/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarCreate
     * @request POST:/admin/core/calendar/
     */ adminCoreCalendarCreate: <T extends FetchKeys<CalendarEvent>>(
      data: CalendarEventRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CalendarEvent, T>, any>({
        path: `/admin/core/calendar/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarRetrieve
     * @request GET:/admin/core/calendar/{id}/
     */ adminCoreCalendarRetrieve: <T extends FetchKeys<CalendarEvent>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CalendarEvent, T>, any>({
        path: `/admin/core/calendar/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarUpdate
     * @request PUT:/admin/core/calendar/{id}/
     */ adminCoreCalendarUpdate: <T extends FetchKeys<CalendarEvent>>(
      id: number,
      data: CalendarEventRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CalendarEvent, T>, any>({
        path: `/admin/core/calendar/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarPartialUpdate
     * @request PATCH:/admin/core/calendar/{id}/
     */ adminCoreCalendarPartialUpdate: <T extends FetchKeys<CalendarEvent>>(
      id: number,
      data: PatchedCalendarEventRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CalendarEvent, T>, any>({
        path: `/admin/core/calendar/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarDestroy
     * @request DELETE:/admin/core/calendar/{id}/
     */ adminCoreCalendarDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/core/calendar/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarTypesRetrieve
     * @request GET:/admin/core/calendar/types/
     */ adminCoreCalendarTypesRetrieve: <T extends FetchKeys<CalendarEvent>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CalendarEvent, T>, any>({
        path: `/admin/core/calendar/types/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreSettingsRetrieve
     * @request GET:/admin/core/settings/
     */ adminCoreSettingsRetrieve: <T extends FetchKeys<AdminSiteSettings>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSiteSettings, T>, any>({
        path: `/admin/core/settings/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreSettingsUpdate
     * @request PUT:/admin/core/settings/
     */ adminCoreSettingsUpdate: <T extends FetchKeys<AdminSiteSettings>>(
      data: AdminSiteSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSiteSettings, T>, any>({
        path: `/admin/core/settings/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreSettingsPartialUpdate
     * @request PATCH:/admin/core/settings/
     */ adminCoreSettingsPartialUpdate: <
      T extends FetchKeys<AdminSiteSettings>
    >(
      data: PatchedAdminSiteSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSiteSettings, T>, any>({
        path: `/admin/core/settings/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminDashboardContentCountersRetrieve
     * @request GET:/admin/dashboard/content-counters/
     */ adminDashboardContentCountersRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/dashboard/content-counters/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminDashboardUserRetrieve
     * @request GET:/admin/dashboard/user/
     */ adminDashboardUserRetrieve: <T extends FetchKeys<AdminDashboardUser>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminDashboardUser, T>, any>({
        path: `/admin/dashboard/user/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminImportingCeleryTaskStatusList
     * @request GET:/admin/importing/celery-task-status/
     */ adminImportingCeleryTaskStatusList: <
      T extends FetchKeys<PaginatedAdminCeleryTaskStatusListList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `PENDING` - PENDING
         * * `RECEIVED` - RECEIVED
         * * `STARTED` - STARTED
         * * `PROGRESS` - PROGRESS
         * * `FINALIZE` - FINALIZE
         * * `SUCCESS` - SUCCESS
         * * `FAILURE` - FAILURE
         * * `REVOKED` - REVOKED
         * * `REJECTED` - REJECTED
         * * `RETRY` - RETRY
         * * `IGNORED` - IGNORED
         */
        state?:
          | 'FAILURE'
          | 'FINALIZE'
          | 'IGNORED'
          | 'PENDING'
          | 'PROGRESS'
          | 'RECEIVED'
          | 'REJECTED'
          | 'RETRY'
          | 'REVOKED'
          | 'STARTED'
          | 'SUCCESS'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminCeleryTaskStatusListList, T>, any>({
        path: `/admin/importing/celery-task-status/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminImportingCeleryTaskStatusRetrieve
     * @request GET:/admin/importing/celery-task-status/{id}/
     */ adminImportingCeleryTaskStatusRetrieve: <
      T extends FetchKeys<AdminCeleryTaskStatus>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminCeleryTaskStatus, T>, any>({
        path: `/admin/importing/celery-task-status/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminImportingCeleryTaskStatusRevokeTaskCreate
     * @request POST:/admin/importing/celery-task-status/{id}/revoke-task/
     */ adminImportingCeleryTaskStatusRevokeTaskCreate: <
      T extends FetchKeys<AdminCeleryTaskStatus>
    >(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminCeleryTaskStatus, T>, any>({
        path: `/admin/importing/celery-task-status/${id}/revoke-task/`,
        method: 'POST',
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminImportingImportStatusRetrieve
     * @request GET:/admin/importing/import-status/
     */ adminImportingImportStatusRetrieve: <
      T extends FetchKeys<ImportStatusCheckResponse>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ImportStatusCheckResponse, T>, any>({
        path: `/admin/importing/import-status/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminImportingImportTimetableCreate
     * @request POST:/admin/importing/import-timetable/
     */ adminImportingImportTimetableCreate: <
      T extends FetchKeys<AdminImportResponse>
    >(
      data: AdminImportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminImportResponse, T>, any>({
        path: `/admin/importing/import-timetable/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsList
     * @request GET:/admin/periods/years/
     */ adminPeriodsYearsList: <
      T extends FetchKeys<PaginatedAdminSchoolYearList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminSchoolYearList, T>, any>({
        path: `/admin/periods/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsCreate
     * @request POST:/admin/periods/years/
     */ adminPeriodsYearsCreate: <T extends FetchKeys<AdminSchoolYear>>(
      data: AdminSchoolYearRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSchoolYear, T>, any>({
        path: `/admin/periods/years/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsRetrieve
     * @request GET:/admin/periods/years/{id}/
     */ adminPeriodsYearsRetrieve: <T extends FetchKeys<AdminSchoolYear>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSchoolYear, T>, any>({
        path: `/admin/periods/years/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsUpdate
     * @request PUT:/admin/periods/years/{id}/
     */ adminPeriodsYearsUpdate: <T extends FetchKeys<AdminSchoolYear>>(
      id: number,
      data: AdminSchoolYearRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSchoolYear, T>, any>({
        path: `/admin/periods/years/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsPartialUpdate
     * @request PATCH:/admin/periods/years/{id}/
     */ adminPeriodsYearsPartialUpdate: <T extends FetchKeys<AdminSchoolYear>>(
      id: number,
      data: PatchedAdminSchoolYearRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSchoolYear, T>, any>({
        path: `/admin/periods/years/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsDestroy
     * @request DELETE:/admin/periods/years/{id}/
     */ adminPeriodsYearsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/periods/years/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminStudentsClashesCreate
     * @request POST:/admin/students/clashes/
     */ adminStudentsClashesCreate: <T extends FetchKeys<ManyStudentsClashes>>(
      data: StudentsClashesForDateParamsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyStudentsClashes, T>, any>({
        path: `/admin/students/clashes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminStudentsClashesHaveStudentsClashesCreate
     * @request POST:/admin/students/clashes/have-students-clashes/
     */ adminStudentsClashesHaveStudentsClashesCreate: <
      T extends FetchKeys<HaveStudentsClashes>
    >(
      data: StudentsClashesForDateParamsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HaveStudentsClashes, T>, any>({
        path: `/admin/students/clashes/have-students-clashes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersList
     * @request GET:/admin/teachers/
     */ adminTeachersList: <T extends FetchKeys<PaginatedAdminTeacherList>>(
      query?: {
        /** Multiple values may be separated by commas. */
        available_in_gap?: string[]
        departments?: number[]
        has_activities_in_current_quarter?: boolean
        is_hidden_in_generator?: boolean
        order_by_department?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string
        /** A search term. */
        search?: string
        /**
         * * `academic` - Academic
         * * `boarding` - Boarding
         * * `tae` - TAE
         * * `other` - Other
         */
        user__staff_type?: 'academic' | 'boarding' | 'other' | 'tae'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminTeacherList, T>, any>({
        path: `/admin/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersCreate
     * @request POST:/admin/teachers/
     */ adminTeachersCreate: <T extends FetchKeys<AdminTeacher>>(
      data: AdminTeacherRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTeacher, T>, any>({
        path: `/admin/teachers/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersRetrieve
     * @request GET:/admin/teachers/{user}/
     */ adminTeachersRetrieve: <T extends FetchKeys<AdminTeacher>>(
      user: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTeacher, T>, any>({
        path: `/admin/teachers/${user}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersUpdate
     * @request PUT:/admin/teachers/{user}/
     */ adminTeachersUpdate: <T extends FetchKeys<AdminTeacher>>(
      user: string,
      data: AdminTeacherRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTeacher, T>, any>({
        path: `/admin/teachers/${user}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersPartialUpdate
     * @request PATCH:/admin/teachers/{user}/
     */ adminTeachersPartialUpdate: <T extends FetchKeys<AdminTeacher>>(
      user: string,
      data: PatchedAdminTeacherRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTeacher, T>, any>({
        path: `/admin/teachers/${user}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersDestroy
     * @request DELETE:/admin/teachers/{user}/
     */ adminTeachersDestroy: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/teachers/${user}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersCountedLessonsRetrieve
     * @request GET:/admin/teachers/counted-lessons/
     */ adminTeachersCountedLessonsRetrieve: <
      T extends FetchKeys<CountedLessonsParams>
    >(
      query?: {
        /** Multiple values may be separated by commas. */
        available_in_gap?: string[]
        departments?: number[]
        has_activities_in_current_quarter?: boolean
        is_hidden_in_generator?: boolean
        order_by_department?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: string
        /** A search term. */
        search?: string
        /**
         * * `academic` - Academic
         * * `boarding` - Boarding
         * * `tae` - TAE
         * * `other` - Other
         */
        user__staff_type?: 'academic' | 'boarding' | 'other' | 'tae'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CountedLessonsParams, T>, any>({
        path: `/admin/teachers/counted-lessons/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersList
     * @request GET:/admin/users/
     */ adminUsersList: <T extends FetchKeys<PaginatedAdminUserListList>>(
      query?: {
        groups?: number[]
        is_active?: boolean
        is_staff?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        profile?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminUserListList, T>, any>({
        path: `/admin/users/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersCreate
     * @request POST:/admin/users/
     */ adminUsersCreate: <T extends FetchKeys<AdminUser>>(
      data: AdminUserChangeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUser, T>, any>({
        path: `/admin/users/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersRetrieve
     * @request GET:/admin/users/{id}/
     */ adminUsersRetrieve: <T extends FetchKeys<AdminUser>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUser, T>, any>({
        path: `/admin/users/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersUpdate
     * @request PUT:/admin/users/{id}/
     */ adminUsersUpdate: <T extends FetchKeys<AdminUser>>(
      id: string,
      data: AdminUserChangeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUser, T>, any>({
        path: `/admin/users/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersPartialUpdate
     * @request PATCH:/admin/users/{id}/
     */ adminUsersPartialUpdate: <T extends FetchKeys<AdminUser>>(
      id: string,
      data: PatchedAdminUserRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUser, T>, any>({
        path: `/admin/users/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersDestroy
     * @request DELETE:/admin/users/{id}/
     */ adminUsersDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/users/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersSetPasswordCreate
     * @request POST:/admin/users/{id}/set-password/
     */ adminUsersSetPasswordCreate: <
      T extends FetchKeys<AdminUserPasswordChange>
    >(
      id: string,
      data: AdminUserPasswordChangeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUserPasswordChange, T>, any>({
        path: `/admin/users/${id}/set-password/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersToggleActiveCreate
     * @request POST:/admin/users/{id}/toggle-active/
     */ adminUsersToggleActiveCreate: <T extends FetchKeys<AdminUser>>(
      id: string,
      data: AdminUserRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUser, T>, any>({
        path: `/admin/users/${id}/toggle-active/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminUsersClashesCreate
     * @request POST:/admin/users/clashes/
     */ adminUsersClashesCreate: <T extends FetchKeys<ManyStaffUsersClashes>>(
      data: UsersClashesForDateParamsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyStaffUsersClashes, T>, any>({
        path: `/admin/users/clashes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminUsersClashesHaveStaffUsersClashesCreate
     * @request POST:/admin/users/clashes/have-staff-users-clashes/
     */ adminUsersClashesHaveStaffUsersClashesCreate: <
      T extends FetchKeys<HaveStaffUsersClashes>
    >(
      data: UsersClashesForDateParamsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HaveStaffUsersClashes, T>, any>({
        path: `/admin/users/clashes/have-staff-users-clashes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersGendersList
     * @request GET:/admin/users/genders/
     */ adminUsersGendersList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        groups?: number[]
        is_active?: boolean
        is_staff?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        profile?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/admin/users/genders/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersProfilesList
     * @request GET:/admin/users/profiles/
     */ adminUsersProfilesList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        groups?: number[]
        is_active?: boolean
        is_staff?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        profile?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/admin/users/profiles/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersTeachersAndTutorsRetrieve
     * @request GET:/admin/users/teachers-and-tutors/
     */ adminUsersTeachersAndTutorsRetrieve: <T extends FetchKeys<SimpleUser>>(
      query?: {
        groups?: number[]
        is_active?: boolean
        is_staff?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        profile?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SimpleUser, T>, any>({
        path: `/admin/users/teachers-and-tutors/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersTypesList
     * @request GET:/admin/users/types/
     */ adminUsersTypesList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        groups?: number[]
        is_active?: boolean
        is_staff?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        profile?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/admin/users/types/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  aims = {
    /**
     * No description
     *
     * @tags aims
     * @name AimsList
     * @request GET:/aims/
     */
    aimsList: <T extends FetchKeys<PaginatedAimList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAimList, T>, any>({
        path: `/aims/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsAimForCourseRetrieve
     * @request GET:/aims/aim-for-course/
     */ aimsAimForCourseRetrieve: <T extends FetchKeys<StudentAimForCourse>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAimForCourse, T>, any>({
        path: `/aims/aim-for-course/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsAimForCourseUpdate
     * @request PUT:/aims/aim-for-course/
     */ aimsAimForCourseUpdate: <T extends FetchKeys<StudentAimForCourse>>(
      data: StudentAimForCourseRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAimForCourse, T>, any>({
        path: `/aims/aim-for-course/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsAimForCoursePartialUpdate
     * @request PATCH:/aims/aim-for-course/
     */ aimsAimForCoursePartialUpdate: <
      T extends FetchKeys<StudentAimForCourse>
    >(
      data: PatchedStudentAimForCourseRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAimForCourse, T>, any>({
        path: `/aims/aim-for-course/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsCalcGradeCreate
     * @request POST:/aims/calc-grade/
     */ aimsCalcGradeCreate: <T extends FetchKeys<void>>(
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/aims/calc-grade/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsForStudentList
     * @request GET:/aims/for-student/
     */ aimsForStudentList: <T extends FetchKeys<PaginatedAimList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAimList, T>, any>({
        path: `/aims/for-student/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsForStudentRetrieve
     * @request GET:/aims/for-student/{id}/
     */ aimsForStudentRetrieve: <T extends FetchKeys<Aim>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Aim, T>, any>({
        path: `/aims/for-student/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsScoringsRetrieve
     * @request GET:/aims/scorings/
     */ aimsScoringsRetrieve: <T extends FetchKeys<Scorings>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Scorings, T>, any>({
        path: `/aims/scorings/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  allergies = {
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesList
     * @request GET:/allergies/student-allergies/
     */
    allergiesStudentAllergiesList: <T extends FetchKeys<StudentAllergy[]>>(
      query?: {
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAllergy[], T>, any>({
        path: `/allergies/student-allergies/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesCreate
     * @request POST:/allergies/student-allergies/
     */ allergiesStudentAllergiesCreate: <T extends FetchKeys<StudentAllergy>>(
      data: StudentAllergyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAllergy, T>, any>({
        path: `/allergies/student-allergies/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesRetrieve
     * @request GET:/allergies/student-allergies/{id}/
     */ allergiesStudentAllergiesRetrieve: <
      T extends FetchKeys<StudentAllergy>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAllergy, T>, any>({
        path: `/allergies/student-allergies/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesUpdate
     * @request PUT:/allergies/student-allergies/{id}/
     */ allergiesStudentAllergiesUpdate: <T extends FetchKeys<StudentAllergy>>(
      id: number,
      data: StudentAllergyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAllergy, T>, any>({
        path: `/allergies/student-allergies/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesPartialUpdate
     * @request PATCH:/allergies/student-allergies/{id}/
     */ allergiesStudentAllergiesPartialUpdate: <
      T extends FetchKeys<StudentAllergy>
    >(
      id: number,
      data: PatchedStudentAllergyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAllergy, T>, any>({
        path: `/allergies/student-allergies/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesDestroy
     * @request DELETE:/allergies/student-allergies/{id}/
     */ allergiesStudentAllergiesDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/allergies/student-allergies/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesChoicesRetrieve
     * @request GET:/allergies/student-allergies/choices/
     */ allergiesStudentAllergiesChoicesRetrieve: <
      T extends FetchKeys<AllergyChoicesResponse>
    >(
      query?: {
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AllergyChoicesResponse, T>, any>({
        path: `/allergies/student-allergies/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  assignments = {
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsList
     * @request GET:/assignments/
     */
    assignmentsList: <T extends FetchKeys<PaginatedAssignmentList>>(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number
        quarter__semester?: number
        /** @format uuid */
        student_items__student?: string
        students?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAssignmentList, T>, any>({
        path: `/assignments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsCreate
     * @request POST:/assignments/
     */ assignmentsCreate: <T extends FetchKeys<Assignment>>(
      data: AssignmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Assignment, T>, any>({
        path: `/assignments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsRetrieve
     * @request GET:/assignments/{id}/
     */ assignmentsRetrieve: <T extends FetchKeys<Assignment>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Assignment, T>, any>({
        path: `/assignments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsUpdate
     * @request PUT:/assignments/{id}/
     */ assignmentsUpdate: <T extends FetchKeys<Assignment>>(
      id: string,
      data: AssignmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Assignment, T>, any>({
        path: `/assignments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsPartialUpdate
     * @request PATCH:/assignments/{id}/
     */ assignmentsPartialUpdate: <T extends FetchKeys<Assignment>>(
      id: string,
      data: PatchedAssignmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Assignment, T>, any>({
        path: `/assignments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsDestroy
     * @request DELETE:/assignments/{id}/
     */ assignmentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/assignments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsList
     * @request GET:/assignments/items/
     */ assignmentsItemsList: <
      T extends FetchKeys<PaginatedAssignmentGradeList>
    >(
      query?: {
        /** @format uuid */
        assignment__course?: string
        assignment__quarter?: number
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAssignmentGradeList, T>, any>({
        path: `/assignments/items/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsCreate
     * @request POST:/assignments/items/
     */ assignmentsItemsCreate: <T extends FetchKeys<AssignmentGrade>>(
      data: AssignmentGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AssignmentGrade, T>, any>({
        path: `/assignments/items/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsRetrieve
     * @request GET:/assignments/items/{id}/
     */ assignmentsItemsRetrieve: <T extends FetchKeys<AssignmentGrade>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AssignmentGrade, T>, any>({
        path: `/assignments/items/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsUpdate
     * @request PUT:/assignments/items/{id}/
     */ assignmentsItemsUpdate: <T extends FetchKeys<AssignmentGrade>>(
      id: string,
      data: AssignmentGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AssignmentGrade, T>, any>({
        path: `/assignments/items/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsPartialUpdate
     * @request PATCH:/assignments/items/{id}/
     */ assignmentsItemsPartialUpdate: <T extends FetchKeys<AssignmentGrade>>(
      id: string,
      data: PatchedAssignmentGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AssignmentGrade, T>, any>({
        path: `/assignments/items/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsDestroy
     * @request DELETE:/assignments/items/{id}/
     */ assignmentsItemsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/assignments/items/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsReportsRetrieve
     * @request GET:/assignments/reports/
     */ assignmentsReportsRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/assignments/reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsTypesRetrieve
     * @request GET:/assignments/types/
     */ assignmentsTypesRetrieve: <T extends FetchKeys<AssignmentType>>(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        quarter?: number
        quarter__semester?: number
        /** @format uuid */
        student_items__student?: string
        students?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AssignmentType, T>, any>({
        path: `/assignments/types/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  attachments = {
    /**
     * No description
     *
     * @tags attachments
     * @name AttachmentsList
     * @request GET:/attachments/{attachmentType}/
     */
    attachmentsList: <T extends FetchKeys<PaginatedAttachmentList>>(
      attachmentType: string,
      query?: {
        /** Multiple values may be separated by commas. */
        id__in?: number[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAttachmentList, T>, any>({
        path: `/attachments/${attachmentType}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags attachments
     * @name AttachmentsCreate
     * @request POST:/attachments/{attachmentType}/
     */ attachmentsCreate: <T extends FetchKeys<Attachment>>(
      attachmentType: string,
      data: AttachmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Attachment, T>, any>({
        path: `/attachments/${attachmentType}/`,
        method: 'POST',
        body: data,
        type: ContentType.UrlEncoded,
        format: 'json',
        ...params
      })
  }
  attendances = {
    /**
     * No description
     *
     * @tags attendances
     * @name AttendancesList
     * @request GET:/attendances/
     */
    attendancesList: <T extends FetchKeys<PaginatedAcademicAttendanceList>>(
      query?: {
        /** @format uuid */
        lesson?: string
        /** @format uuid */
        lesson__activity__course?: string
        /** @format date */
        lesson__date_after?: string
        /** @format date */
        lesson__date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAcademicAttendanceList, T>, any>({
        path: `/attendances/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags attendances
     * @name AttendancesUpdate
     * @request PUT:/attendances/{id}/
     */ attendancesUpdate: <T extends FetchKeys<AcademicAttendance>>(
      id: string,
      data: AcademicAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendance, T>, any>({
        path: `/attendances/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags attendances
     * @name AttendancesPartialUpdate
     * @request PATCH:/attendances/{id}/
     */ attendancesPartialUpdate: <T extends FetchKeys<AcademicAttendance>>(
      id: string,
      data: PatchedAcademicAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendance, T>, any>({
        path: `/attendances/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Return attendances in cumulative format. Requires student in query params. :return response [ { "course": course_name, "attendances_count": 5, "is_present": 2, "is_late": 2, "is_absent_excused": 0, "is_absent_unexcused": 0, "is_sick": 1, "missed_lessons_count": 1, "missed_lessons_percentage": 20.0 } ]
     *
     * @tags attendances
     * @name AttendancesCumulativeRetrieve
     * @request GET:/attendances/cumulative/
     */ attendancesCumulativeRetrieve: <
      T extends FetchKeys<CumulativeResponse>
    >(
      query?: {
        /** @format uuid */
        lesson?: string
        /** @format uuid */
        lesson__activity__course?: string
        /** @format date */
        lesson__date_after?: string
        /** @format date */
        lesson__date_before?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CumulativeResponse, T>, any>({
        path: `/attendances/cumulative/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags attendances
     * @name AttendancesReportsRetrieve
     * @request GET:/attendances/reports/
     */ attendancesReportsRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/attendances/reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
  }
  billings = {
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsList
     * @request GET:/billings/reports/
     */
    billingsReportsList: <T extends FetchKeys<PaginatedBillingReportList>>(
      query?: {
        /** @format uuid */
        author?: string
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number
        school_year?: number
        /** A search term. */
        search?: string
        /**
         * * `quarter` - Quarter
         * * `school_year` - School Year
         */
        timeframe_type?: 'quarter' | 'school_year'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedBillingReportList, T>, any>({
        path: `/billings/reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsCreate
     * @request POST:/billings/reports/
     */ billingsReportsCreate: <T extends FetchKeys<BillingReportCreate>>(
      data: BillingReportCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReportCreate, T>, any>({
        path: `/billings/reports/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsRetrieve
     * @request GET:/billings/reports/{id}/
     */ billingsReportsRetrieve: <T extends FetchKeys<BillingReport>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsUpdate
     * @request PUT:/billings/reports/{id}/
     */ billingsReportsUpdate: <T extends FetchKeys<BillingReport>>(
      id: string,
      data: BillingReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsPartialUpdate
     * @request PATCH:/billings/reports/{id}/
     */ billingsReportsPartialUpdate: <T extends FetchKeys<BillingReport>>(
      id: string,
      data: PatchedBillingReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsDestroy
     * @request DELETE:/billings/reports/{id}/
     */ billingsReportsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/billings/reports/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsDownloadRetrieve
     * @request GET:/billings/reports/{id}/download/
     */ billingsReportsDownloadRetrieve: <T extends FetchKeys<BillingReport>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/${id}/download/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsItemsRetrieve
     * @request GET:/billings/reports/{id}/items/
     */ billingsReportsItemsRetrieve: <T extends FetchKeys<BillingReport>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/${id}/items/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsAuthorsRetrieve
     * @request GET:/billings/reports/authors/
     */ billingsReportsAuthorsRetrieve: <T extends FetchKeys<BillingReport>>(
      query?: {
        /** @format uuid */
        author?: string
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        school_year?: number
        /** A search term. */
        search?: string
        /**
         * * `quarter` - Quarter
         * * `school_year` - School Year
         */
        timeframe_type?: 'quarter' | 'school_year'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/authors/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Handler method for HTTP 'OPTIONS' request.
     *
     * @tags billings
     * @name BillingsReportsOptionsRetrieve
     * @request GET:/billings/reports/options/
     */ billingsReportsOptionsRetrieve: <T extends FetchKeys<BillingReport>>(
      query?: {
        /** @format uuid */
        author?: string
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        school_year?: number
        /** A search term. */
        search?: string
        /**
         * * `quarter` - Quarter
         * * `school_year` - School Year
         */
        timeframe_type?: 'quarter' | 'school_year'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsPreviewCreate
     * @request POST:/billings/reports/preview/
     */ billingsReportsPreviewCreate: <
      T extends FetchKeys<BillingReportCreate>
    >(
      data: BillingReportCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReportCreate, T>, any>({
        path: `/billings/reports/preview/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  boarding = {
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsList
     * @request GET:/boarding/accommodations/
     */
    boardingAccommodationsList: <
      T extends FetchKeys<PaginatedAccommodationList>
    >(
      query?: {
        current_or_future?: boolean
        house?: number
        is_temporary_movement?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        room?: number
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAccommodationList, T>, any>({
        path: `/boarding/accommodations/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsCreate
     * @request POST:/boarding/accommodations/
     */ boardingAccommodationsCreate: <T extends FetchKeys<Accommodation>>(
      data: AccommodationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Accommodation, T>, any>({
        path: `/boarding/accommodations/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsRetrieve
     * @request GET:/boarding/accommodations/{id}/
     */ boardingAccommodationsRetrieve: <T extends FetchKeys<Accommodation>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Accommodation, T>, any>({
        path: `/boarding/accommodations/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsUpdate
     * @request PUT:/boarding/accommodations/{id}/
     */ boardingAccommodationsUpdate: <T extends FetchKeys<Accommodation>>(
      id: number,
      data: AccommodationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Accommodation, T>, any>({
        path: `/boarding/accommodations/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsPartialUpdate
     * @request PATCH:/boarding/accommodations/{id}/
     */ boardingAccommodationsPartialUpdate: <
      T extends FetchKeys<Accommodation>
    >(
      id: number,
      data: PatchedAccommodationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Accommodation, T>, any>({
        path: `/boarding/accommodations/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsDestroy
     * @request DELETE:/boarding/accommodations/{id}/
     */ boardingAccommodationsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/accommodations/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesList
     * @request GET:/boarding/attendances/
     */ boardingAttendancesList: <T extends FetchKeys<BoardingAttendance[]>>(
      query?: {
        /**
         * * `present` - Present
         * * `late` - Late
         * * `sick` - Sick
         * * `absent_excused` - Absent exc.
         * * `absent_unexcused` - Absent unexc.
         */
        mark?:
          | 'absent_excused'
          | 'absent_unexcused'
          | 'late'
          | 'present'
          | 'sick'
        /** Which field to use when ordering the results. */
        ordering?: string
        report?: number
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendance[], T>, any>({
        path: `/boarding/attendances/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesCreate
     * @request POST:/boarding/attendances/
     */ boardingAttendancesCreate: <T extends FetchKeys<BoardingAttendance>>(
      data: BoardingAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendance, T>, any>({
        path: `/boarding/attendances/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesRetrieve
     * @request GET:/boarding/attendances/{id}/
     */ boardingAttendancesRetrieve: <T extends FetchKeys<BoardingAttendance>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendance, T>, any>({
        path: `/boarding/attendances/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesUpdate
     * @request PUT:/boarding/attendances/{id}/
     */ boardingAttendancesUpdate: <T extends FetchKeys<BoardingAttendance>>(
      id: string,
      data: BoardingAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendance, T>, any>({
        path: `/boarding/attendances/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesPartialUpdate
     * @request PATCH:/boarding/attendances/{id}/
     */ boardingAttendancesPartialUpdate: <
      T extends FetchKeys<BoardingAttendance>
    >(
      id: string,
      data: PatchedBoardingAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendance, T>, any>({
        path: `/boarding/attendances/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesDestroy
     * @request DELETE:/boarding/attendances/{id}/
     */ boardingAttendancesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/attendances/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesMarksList
     * @request GET:/boarding/attendances/marks/
     */ boardingAttendancesMarksList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        /**
         * * `present` - Present
         * * `late` - Late
         * * `sick` - Sick
         * * `absent_excused` - Absent exc.
         * * `absent_unexcused` - Absent unexc.
         */
        mark?:
          | 'absent_excused'
          | 'absent_unexcused'
          | 'late'
          | 'present'
          | 'sick'
        /** Which field to use when ordering the results. */
        ordering?: string
        report?: number
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/boarding/attendances/marks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsList
     * @request GET:/boarding/attendances/reports/
     */ boardingAttendancesReportsList: <
      T extends FetchKeys<PaginatedBoardingAttendanceReportListList>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'
        /** @format uuid */
        tutor?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedBoardingAttendanceReportListList, T>, any>(
        {
          path: `/boarding/attendances/reports/`,
          method: 'GET',
          query: postprocessQuery(query),
          format: 'json',
          ...params
        }
      ),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsCreate
     * @request POST:/boarding/attendances/reports/
     */ boardingAttendancesReportsCreate: <
      T extends FetchKeys<BoardingAttendanceReport>
    >(
      data: BoardingAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendanceReport, T>, any>({
        path: `/boarding/attendances/reports/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsRetrieve
     * @request GET:/boarding/attendances/reports/{id}/
     */ boardingAttendancesReportsRetrieve: <
      T extends FetchKeys<BoardingAttendanceReport>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendanceReport, T>, any>({
        path: `/boarding/attendances/reports/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsUpdate
     * @request PUT:/boarding/attendances/reports/{id}/
     */ boardingAttendancesReportsUpdate: <
      T extends FetchKeys<BoardingAttendanceReport>
    >(
      id: number,
      data: BoardingAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendanceReport, T>, any>({
        path: `/boarding/attendances/reports/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsPartialUpdate
     * @request PATCH:/boarding/attendances/reports/{id}/
     */ boardingAttendancesReportsPartialUpdate: <
      T extends FetchKeys<BoardingAttendanceReport>
    >(
      id: number,
      data: PatchedBoardingAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendanceReport, T>, any>({
        path: `/boarding/attendances/reports/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsDestroy
     * @request DELETE:/boarding/attendances/reports/{id}/
     */ boardingAttendancesReportsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/attendances/reports/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsStatusesList
     * @request GET:/boarding/attendances/reports/statuses/
     */ boardingAttendancesReportsStatusesList: <
      T extends FetchKeys<StringOption[]>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'
        /** @format uuid */
        tutor?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/boarding/attendances/reports/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsList
     * @request GET:/boarding/boarding-comments/
     */ boardingBoardingCommentsList: <
      T extends FetchKeys<PaginatedBoardingCommentList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        school_year?: number
        /** @format uuid */
        student?: string
        weekly?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedBoardingCommentList, T>, any>({
        path: `/boarding/boarding-comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsCreate
     * @request POST:/boarding/boarding-comments/
     */ boardingBoardingCommentsCreate: <T extends FetchKeys<BoardingComment>>(
      data: BoardingCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingComment, T>, any>({
        path: `/boarding/boarding-comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsRetrieve
     * @request GET:/boarding/boarding-comments/{id}/
     */ boardingBoardingCommentsRetrieve: <
      T extends FetchKeys<BoardingComment>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingComment, T>, any>({
        path: `/boarding/boarding-comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsUpdate
     * @request PUT:/boarding/boarding-comments/{id}/
     */ boardingBoardingCommentsUpdate: <T extends FetchKeys<BoardingComment>>(
      id: string,
      data: BoardingCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingComment, T>, any>({
        path: `/boarding/boarding-comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsPartialUpdate
     * @request PATCH:/boarding/boarding-comments/{id}/
     */ boardingBoardingCommentsPartialUpdate: <
      T extends FetchKeys<BoardingComment>
    >(
      id: string,
      data: PatchedBoardingCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingComment, T>, any>({
        path: `/boarding/boarding-comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsDestroy
     * @request DELETE:/boarding/boarding-comments/{id}/
     */ boardingBoardingCommentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/boarding-comments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesList
     * @request GET:/boarding/houses/
     */ boardingHousesList: <T extends FetchKeys<PaginatedHouseListList>>(
      query?: {
        /**
         * * `male` - Male
         * * `female` - Female
         */
        gender?: 'female' | 'male'
        generate_night_attendance_reports?: boolean
        is_senior_campus?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedHouseListList, T>, any>({
        path: `/boarding/houses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesCreate
     * @request POST:/boarding/houses/
     */ boardingHousesCreate: <T extends FetchKeys<House>>(
      data: HouseRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<House, T>, any>({
        path: `/boarding/houses/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesRetrieve
     * @request GET:/boarding/houses/{id}/
     */ boardingHousesRetrieve: <T extends FetchKeys<House>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<House, T>, any>({
        path: `/boarding/houses/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesUpdate
     * @request PUT:/boarding/houses/{id}/
     */ boardingHousesUpdate: <T extends FetchKeys<House>>(
      id: number,
      data: HouseRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<House, T>, any>({
        path: `/boarding/houses/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesPartialUpdate
     * @request PATCH:/boarding/houses/{id}/
     */ boardingHousesPartialUpdate: <T extends FetchKeys<House>>(
      id: number,
      data: PatchedHouseRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<House, T>, any>({
        path: `/boarding/houses/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsList
     * @request GET:/boarding/houses/rooms/
     */ boardingHousesRoomsList: <
      T extends FetchKeys<PaginatedHouseRoomListList>
    >(
      query?: {
        booked_for?: string
        code?: string
        house?: number
        id?: number
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        status?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedHouseRoomListList, T>, any>({
        path: `/boarding/houses/rooms/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsRetrieve
     * @request GET:/boarding/houses/rooms/{id}/
     */ boardingHousesRoomsRetrieve: <T extends FetchKeys<HouseRoom>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HouseRoom, T>, any>({
        path: `/boarding/houses/rooms/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsUpdate
     * @request PUT:/boarding/houses/rooms/{id}/
     */ boardingHousesRoomsUpdate: <T extends FetchKeys<HouseRoom>>(
      id: number,
      data: HouseRoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HouseRoom, T>, any>({
        path: `/boarding/houses/rooms/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsPartialUpdate
     * @request PATCH:/boarding/houses/rooms/{id}/
     */ boardingHousesRoomsPartialUpdate: <T extends FetchKeys<HouseRoom>>(
      id: number,
      data: PatchedHouseRoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HouseRoom, T>, any>({
        path: `/boarding/houses/rooms/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description cancel (remove) all bookings/accommodations made for specific room, having both -> cancel only bookings
     *
     * @tags boarding
     * @name BoardingHousesRoomsCancelBookingCreate
     * @request POST:/boarding/houses/rooms/{id}/cancel-booking/
     */ boardingHousesRoomsCancelBookingCreate: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/houses/rooms/${id}/cancel-booking/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsAvailableFiltersRetrieve
     * @request GET:/boarding/houses/rooms/available-filters/
     */ boardingHousesRoomsAvailableFiltersRetrieve: <
      T extends FetchKeys<HouseRoomAvailableFilters>
    >(
      query?: {
        booked_for?: string
        code?: string
        house?: number
        id?: number
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        status?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HouseRoomAvailableFilters, T>, any>({
        path: `/boarding/houses/rooms/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsCodesList
     * @request GET:/boarding/houses/rooms/codes/
     */ boardingHousesRoomsCodesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        booked_for?: string
        code?: string
        house?: number
        id?: number
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        status?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/boarding/houses/rooms/codes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesTutorsList
     * @request GET:/boarding/houses/tutors/
     */ boardingHousesTutorsList: <T extends FetchKeys<PaginatedTutorList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTutorList, T>, any>({
        path: `/boarding/houses/tutors/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesList
     * @request GET:/boarding/night-attendances/
     */ boardingNightAttendancesList: <T extends FetchKeys<NightAttendance[]>>(
      query?: {
        house?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        report?: number
        /**
         * * `male` - Male
         * * `female` - Female
         */
        student__gender?: 'female' | 'male'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendance[], T>, any>({
        path: `/boarding/night-attendances/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCreate
     * @request POST:/boarding/night-attendances/
     */ boardingNightAttendancesCreate: <T extends FetchKeys<NightAttendance>>(
      data: NightAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendance, T>, any>({
        path: `/boarding/night-attendances/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsList
     * @request GET:/boarding/night-attendances/{attendanceId}/comments/
     */ boardingNightAttendancesCommentsList: <
      T extends FetchKeys<NightAttendanceComment[]>
    >(
      attendanceId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment[], T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsCreate
     * @request POST:/boarding/night-attendances/{attendanceId}/comments/
     */ boardingNightAttendancesCommentsCreate: <
      T extends FetchKeys<NightAttendanceComment>
    >(
      attendanceId: string,
      data: NightAttendanceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsRetrieve
     * @request GET:/boarding/night-attendances/{attendanceId}/comments/{id}/
     */ boardingNightAttendancesCommentsRetrieve: <
      T extends FetchKeys<NightAttendanceComment>
    >(
      attendanceId: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsUpdate
     * @request PUT:/boarding/night-attendances/{attendanceId}/comments/{id}/
     */ boardingNightAttendancesCommentsUpdate: <
      T extends FetchKeys<NightAttendanceComment>
    >(
      attendanceId: string,
      id: string,
      data: NightAttendanceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsPartialUpdate
     * @request PATCH:/boarding/night-attendances/{attendanceId}/comments/{id}/
     */ boardingNightAttendancesCommentsPartialUpdate: <
      T extends FetchKeys<NightAttendanceComment>
    >(
      attendanceId: string,
      id: string,
      data: PatchedNightAttendanceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsDestroy
     * @request DELETE:/boarding/night-attendances/{attendanceId}/comments/{id}/
     */ boardingNightAttendancesCommentsDestroy: <T extends FetchKeys<void>>(
      attendanceId: string,
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsVersionsRetrieve
     * @request GET:/boarding/night-attendances/{attendanceId}/comments/{id}/versions/
     */ boardingNightAttendancesCommentsVersionsRetrieve: <
      T extends FetchKeys<NightAttendanceComment>
    >(
      attendanceId: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/${id}/versions/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesRetrieve
     * @request GET:/boarding/night-attendances/{id}/
     */ boardingNightAttendancesRetrieve: <
      T extends FetchKeys<NightAttendance>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendance, T>, any>({
        path: `/boarding/night-attendances/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesUpdate
     * @request PUT:/boarding/night-attendances/{id}/
     */ boardingNightAttendancesUpdate: <T extends FetchKeys<NightAttendance>>(
      id: string,
      data: NightAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendance, T>, any>({
        path: `/boarding/night-attendances/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesPartialUpdate
     * @request PATCH:/boarding/night-attendances/{id}/
     */ boardingNightAttendancesPartialUpdate: <
      T extends FetchKeys<NightAttendance>
    >(
      id: string,
      data: PatchedNightAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendance, T>, any>({
        path: `/boarding/night-attendances/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesDestroy
     * @request DELETE:/boarding/night-attendances/{id}/
     */ boardingNightAttendancesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/night-attendances/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesMarksList
     * @request GET:/boarding/night-attendances/marks/
     */ boardingNightAttendancesMarksList: <
      T extends FetchKeys<StringOption[]>
    >(
      query?: {
        house?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        report?: number
        /**
         * * `male` - Male
         * * `female` - Female
         */
        student__gender?: 'female' | 'male'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/boarding/night-attendances/marks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportRetrieve
     * @request GET:/boarding/night-attendances/report/
     */ boardingNightAttendancesReportRetrieve: <T extends FetchKeys<void>>(
      query?: {
        house?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        report?: number
        /**
         * * `male` - Male
         * * `female` - Female
         */
        student__gender?: 'female' | 'male'
        week?: number
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/night-attendances/report/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsList
     * @request GET:/boarding/night-attendances/reports/
     */ boardingNightAttendancesReportsList: <
      T extends FetchKeys<PaginatedNightAttendanceReportListList>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        /**
         * * `male` - Male
         * * `female` - Female
         */
        for_gender?: 'female' | 'male'
        has_disruption?: boolean
        /** @format uuid */
        night_guard?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedNightAttendanceReportListList, T>, any>({
        path: `/boarding/night-attendances/reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsCreate
     * @request POST:/boarding/night-attendances/reports/
     */ boardingNightAttendancesReportsCreate: <
      T extends FetchKeys<NightAttendanceReport>
    >(
      data: NightAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceReport, T>, any>({
        path: `/boarding/night-attendances/reports/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsRetrieve
     * @request GET:/boarding/night-attendances/reports/{id}/
     */ boardingNightAttendancesReportsRetrieve: <
      T extends FetchKeys<NightAttendanceReport>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceReport, T>, any>({
        path: `/boarding/night-attendances/reports/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsUpdate
     * @request PUT:/boarding/night-attendances/reports/{id}/
     */ boardingNightAttendancesReportsUpdate: <
      T extends FetchKeys<NightAttendanceReport>
    >(
      id: number,
      data: NightAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceReport, T>, any>({
        path: `/boarding/night-attendances/reports/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsPartialUpdate
     * @request PATCH:/boarding/night-attendances/reports/{id}/
     */ boardingNightAttendancesReportsPartialUpdate: <
      T extends FetchKeys<NightAttendanceReport>
    >(
      id: number,
      data: PatchedNightAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceReport, T>, any>({
        path: `/boarding/night-attendances/reports/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsDestroy
     * @request DELETE:/boarding/night-attendances/reports/{id}/
     */ boardingNightAttendancesReportsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/night-attendances/reports/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsCreatorsList
     * @request GET:/boarding/night-attendances/reports/creators/
     */ boardingNightAttendancesReportsCreatorsList: <
      T extends FetchKeys<StringOption[]>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        /**
         * * `male` - Male
         * * `female` - Female
         */
        for_gender?: 'female' | 'male'
        has_disruption?: boolean
        /** @format uuid */
        night_guard?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/boarding/night-attendances/reports/creators/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsStatusesList
     * @request GET:/boarding/night-attendances/reports/statuses/
     */ boardingNightAttendancesReportsStatusesList: <
      T extends FetchKeys<StringOption[]>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        /**
         * * `male` - Male
         * * `female` - Female
         */
        for_gender?: 'female' | 'male'
        has_disruption?: boolean
        /** @format uuid */
        night_guard?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/boarding/night-attendances/reports/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaList
     * @request GET:/boarding/rota/
     */ boardingRotaList: <T extends FetchKeys<RotaTimetable[]>>(
      query?: {
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaTimetable[], T>, any>({
        path: `/boarding/rota/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesList
     * @request GET:/boarding/rota-activities/
     */ boardingRotaActivitiesList: <
      T extends FetchKeys<PaginatedDraftRotaActivityResponseList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        rota_timetable?: number
        timetable__enabled_preview_for_teachers?: boolean
        user?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDraftRotaActivityResponseList, T>, any>({
        path: `/boarding/rota-activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesCreate
     * @request POST:/boarding/rota-activities/
     */ boardingRotaActivitiesCreate: <T extends FetchKeys<DraftRotaActivity>>(
      data: DraftRotaActivityRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftRotaActivity, T>, any>({
        path: `/boarding/rota-activities/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesRetrieve
     * @request GET:/boarding/rota-activities/{id}/
     */ boardingRotaActivitiesRetrieve: <
      T extends FetchKeys<DraftRotaActivityResponse>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftRotaActivityResponse, T>, any>({
        path: `/boarding/rota-activities/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesUpdate
     * @request PUT:/boarding/rota-activities/{id}/
     */ boardingRotaActivitiesUpdate: <T extends FetchKeys<DraftRotaActivity>>(
      id: string,
      data: DraftRotaActivityRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftRotaActivity, T>, any>({
        path: `/boarding/rota-activities/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesPartialUpdate
     * @request PATCH:/boarding/rota-activities/{id}/
     */ boardingRotaActivitiesPartialUpdate: <
      T extends FetchKeys<DraftRotaActivity>
    >(
      id: string,
      data: PatchedDraftRotaActivityRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftRotaActivity, T>, any>({
        path: `/boarding/rota-activities/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesDestroy
     * @request DELETE:/boarding/rota-activities/{id}/
     */ boardingRotaActivitiesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/rota-activities/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesCheckConflictsUpdate
     * @request PUT:/boarding/rota-activities/{id}/check-conflicts/
     */ boardingRotaActivitiesCheckConflictsUpdate: <
      T extends FetchKeys<DraftRotaActivityConflictCheck>
    >(
      id: string,
      data: DraftRotaActivityConflictCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftRotaActivityConflictCheck, T>, any>({
        path: `/boarding/rota-activities/${id}/check-conflicts/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesCheckConflictsPartialUpdate
     * @request PATCH:/boarding/rota-activities/{id}/check-conflicts/
     */ boardingRotaActivitiesCheckConflictsPartialUpdate: <
      T extends FetchKeys<DraftRotaActivityConflictCheck>
    >(
      id: string,
      data: PatchedDraftRotaActivityConflictCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftRotaActivityConflictCheck, T>, any>({
        path: `/boarding/rota-activities/${id}/check-conflicts/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesCheckConstraintsUpdate
     * @request PUT:/boarding/rota-activities/{id}/check-constraints/
     */ boardingRotaActivitiesCheckConstraintsUpdate: <
      T extends FetchKeys<DraftRotaActivityConstraintCheck>
    >(
      id: string,
      data: DraftRotaActivityConstraintCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftRotaActivityConstraintCheck, T>, any>({
        path: `/boarding/rota-activities/${id}/check-constraints/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesCheckConstraintsDestroy
     * @request DELETE:/boarding/rota-activities/{id}/check-constraints/
     */ boardingRotaActivitiesCheckConstraintsDestroy: <
      T extends FetchKeys<void>
    >(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/rota-activities/${id}/check-constraints/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesCheckCoverConflictsUpdate
     * @request PUT:/boarding/rota-activities/{id}/check-cover-conflicts/
     */ boardingRotaActivitiesCheckCoverConflictsUpdate: <
      T extends FetchKeys<void>
    >(
      id: string,
      data: CoverConflictRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/rota-activities/${id}/check-cover-conflicts/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesCheckCoverConflictsPartialUpdate
     * @request PATCH:/boarding/rota-activities/{id}/check-cover-conflicts/
     */ boardingRotaActivitiesCheckCoverConflictsPartialUpdate: <
      T extends FetchKeys<void>
    >(
      id: string,
      data: PatchedCoverConflictRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/rota-activities/${id}/check-cover-conflicts/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesCheckConflictsCreate
     * @request POST:/boarding/rota-activities/check-conflicts/
     */ boardingRotaActivitiesCheckConflictsCreate: <
      T extends FetchKeys<DraftRotaActivityConflictCheck>
    >(
      data: DraftRotaActivityConflictCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftRotaActivityConflictCheck, T>, any>({
        path: `/boarding/rota-activities/check-conflicts/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesCheckConstraintsCreate
     * @request POST:/boarding/rota-activities/check-constraints/
     */ boardingRotaActivitiesCheckConstraintsCreate: <
      T extends FetchKeys<DraftRotaActivityConstraintCheck>
    >(
      data: DraftRotaActivityConstraintCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftRotaActivityConstraintCheck, T>, any>({
        path: `/boarding/rota-activities/check-constraints/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesSuggestionsCreate
     * @request POST:/boarding/rota-activities/suggestions/
     */ boardingRotaActivitiesSuggestionsCreate: <
      T extends FetchKeys<SuggestionsResponse[]>
    >(
      data: SuggestionsRequestRequest,
      query?: {
        rota_timetable?: number
        timetable__enabled_preview_for_teachers?: boolean
        user?: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SuggestionsResponse[], T>, any>({
        path: `/boarding/rota-activities/suggestions/`,
        method: 'POST',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaRetrieve
     * @request GET:/boarding/rota/{id}/
     */ boardingRotaRetrieve: <T extends FetchKeys<RotaTimetable>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaTimetable, T>, any>({
        path: `/boarding/rota/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaBusyUsersAndRoomsCreate
     * @request POST:/boarding/rota/{id}/busy-users-and-rooms/
     */ boardingRotaBusyUsersAndRoomsCreate: <
      T extends FetchKeys<BusyUsersResponse>
    >(
      id: number,
      data: BusyUsersRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BusyUsersResponse, T>, any>({
        path: `/boarding/rota/${id}/busy-users-and-rooms/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingRotaChangesList
     * @request GET:/boarding/rota/{id}/changes/
     */ boardingRotaChangesList: <T extends FetchKeys<PaginatedLogEntryList>>(
      id: number,
      query?: {
        is_imported?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedLogEntryList, T>, any>({
        path: `/boarding/rota/${id}/changes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaMarkAsDraftCreate
     * @request POST:/boarding/rota/{id}/mark-as-draft/
     */ boardingRotaMarkAsDraftCreate: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/rota/${id}/mark-as-draft/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaMarkAsSelectedForImportCreate
     * @request POST:/boarding/rota/{id}/mark-as-selected-for-import/
     */ boardingRotaMarkAsSelectedForImportCreate: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/rota/${id}/mark-as-selected-for-import/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingRotaStaffTeamsList
     * @request GET:/boarding/rota/{rotaTimetableId}/staff/teams/
     */ boardingRotaStaffTeamsList: <T extends FetchKeys<SimpleTeam[]>>(
      rotaTimetableId: string,
      query?: {
        /**
         * * `boarding_director` - Boarding Director
         * * `campus_head` - Campus Head
         * * `deputy_campus_head_main` - Deputy Campus Head (Main)
         * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
         * * `head_of_house` - Head of House
         * * `house_tutor` - House Tutor
         */
        roles?: (
          | 'boarding_director'
          | 'campus_head'
          | 'deputy_campus_head_main'
          | 'deputy_campus_head_senior'
          | 'head_of_house'
          | 'house_tutor'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SimpleTeam[], T>, any>({
        path: `/boarding/rota/${rotaTimetableId}/staff/teams/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingRotaStaffUsersList
     * @request GET:/boarding/rota/{rotaTimetableId}/staff/users/
     */ boardingRotaStaffUsersList: <T extends FetchKeys<StaffMember[]>>(
      rotaTimetableId: string,
      query?: {
        /**
         * * `boarding_director` - Boarding Director
         * * `campus_head` - Campus Head
         * * `deputy_campus_head_main` - Deputy Campus Head (Main)
         * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
         * * `head_of_house` - Head of House
         * * `house_tutor` - House Tutor
         */
        roles?: (
          | 'boarding_director'
          | 'campus_head'
          | 'deputy_campus_head_main'
          | 'deputy_campus_head_senior'
          | 'head_of_house'
          | 'house_tutor'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StaffMember[], T>, any>({
        path: `/boarding/rota/${rotaTimetableId}/staff/users/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaGenerationList
     * @request GET:/boarding/rota/generation/
     */ boardingRotaGenerationList: <
      T extends FetchKeys<PaginatedRotaGenerationListList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `new` - New
         * * `in_progress` - In progress
         * * `failed` - Generation has failed
         * * `success` - Success
         */
        status?: 'failed' | 'in_progress' | 'new' | 'success'
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedRotaGenerationListList, T>, any>({
        path: `/boarding/rota/generation/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaGenerationCreate
     * @request POST:/boarding/rota/generation/
     */ boardingRotaGenerationCreate: <T extends FetchKeys<RotaGenerationTask>>(
      data: RotaGenerationTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaGenerationTask, T>, any>({
        path: `/boarding/rota/generation/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingRotaGenerationCallbackCreate
     * @request POST:/boarding/rota/generation-callback/
     */ boardingRotaGenerationCallbackCreate: <
      T extends FetchKeys<RotaGenerationCallback>
    >(
      data: RotaGenerationCallbackRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaGenerationCallback, T>, any>({
        path: `/boarding/rota/generation-callback/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaGenerationRetrieve
     * @request GET:/boarding/rota/generation/{id}/
     */ boardingRotaGenerationRetrieve: <
      T extends FetchKeys<RotaGenerationTaskRetrieve>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaGenerationTaskRetrieve, T>, any>({
        path: `/boarding/rota/generation/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaGenerationActiveList
     * @request GET:/boarding/rota/generation/active/
     */ boardingRotaGenerationActiveList: <
      T extends FetchKeys<RotaGenerationTask[]>
    >(
      query?: {
        /**
         * * `new` - New
         * * `in_progress` - In progress
         * * `failed` - Generation has failed
         * * `success` - Success
         */
        status?: 'failed' | 'in_progress' | 'new' | 'success'
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaGenerationTask[], T>, any>({
        path: `/boarding/rota/generation/active/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  campusAbsences = {
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsList
     * @request GET:/campus-absences/{campusAbsenceId}/comments/
     */
    campusAbsencesCommentsList: <T extends FetchKeys<CampusAbsenceComment[]>>(
      campusAbsenceId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment[], T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsCreate
     * @request POST:/campus-absences/{campusAbsenceId}/comments/
     */ campusAbsencesCommentsCreate: <
      T extends FetchKeys<CampusAbsenceComment>
    >(
      campusAbsenceId: string,
      data: CampusAbsenceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsRetrieve
     * @request GET:/campus-absences/{campusAbsenceId}/comments/{id}/
     */ campusAbsencesCommentsRetrieve: <
      T extends FetchKeys<CampusAbsenceComment>
    >(
      campusAbsenceId: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsUpdate
     * @request PUT:/campus-absences/{campusAbsenceId}/comments/{id}/
     */ campusAbsencesCommentsUpdate: <
      T extends FetchKeys<CampusAbsenceComment>
    >(
      campusAbsenceId: string,
      id: string,
      data: CampusAbsenceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsPartialUpdate
     * @request PATCH:/campus-absences/{campusAbsenceId}/comments/{id}/
     */ campusAbsencesCommentsPartialUpdate: <
      T extends FetchKeys<CampusAbsenceComment>
    >(
      campusAbsenceId: string,
      id: string,
      data: PatchedCampusAbsenceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsDestroy
     * @request DELETE:/campus-absences/{campusAbsenceId}/comments/{id}/
     */ campusAbsencesCommentsDestroy: <T extends FetchKeys<void>>(
      campusAbsenceId: string,
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsVersionsRetrieve
     * @request GET:/campus-absences/{campusAbsenceId}/comments/{id}/versions/
     */ campusAbsencesCommentsVersionsRetrieve: <
      T extends FetchKeys<CampusAbsenceComment>
    >(
      campusAbsenceId: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/${id}/versions/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesAusgangsPerWeekRetrieve
     * @request GET:/campus-absences/ausgangs-per-week/
     */ campusAbsencesAusgangsPerWeekRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/campus-absences/ausgangs-per-week/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesList
     * @request GET:/campus-absences/campus-absences/
     */ campusAbsencesCampusAbsencesList: <
      T extends FetchKeys<PaginatedCampusAbsenceList>
    >(
      query?: {
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?:
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        check_in?: boolean
        check_out?: boolean
        /** @format date-time */
        date_from_after?: string
        /** @format date-time */
        date_to_before?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?:
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        students?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCampusAbsenceList, T>, any>({
        path: `/campus-absences/campus-absences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesCreate
     * @request POST:/campus-absences/campus-absences/
     */ campusAbsencesCampusAbsencesCreate: <
      T extends FetchKeys<CampusAbsence>
    >(
      data: CampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsence, T>, any>({
        path: `/campus-absences/campus-absences/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesRetrieve
     * @request GET:/campus-absences/campus-absences/{id}/
     */ campusAbsencesCampusAbsencesRetrieve: <
      T extends FetchKeys<CampusAbsence>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsence, T>, any>({
        path: `/campus-absences/campus-absences/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesUpdate
     * @request PUT:/campus-absences/campus-absences/{id}/
     */ campusAbsencesCampusAbsencesUpdate: <
      T extends FetchKeys<CampusAbsence>
    >(
      id: string,
      data: CampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsence, T>, any>({
        path: `/campus-absences/campus-absences/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesPartialUpdate
     * @request PATCH:/campus-absences/campus-absences/{id}/
     */ campusAbsencesCampusAbsencesPartialUpdate: <
      T extends FetchKeys<CampusAbsence>
    >(
      id: string,
      data: PatchedCampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsence, T>, any>({
        path: `/campus-absences/campus-absences/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesChangeCheckInOutUpdate
     * @request PUT:/campus-absences/campus-absences/{id}/change-check-in-out/
     */ campusAbsencesCampusAbsencesChangeCheckInOutUpdate: <
      T extends FetchKeys<CampusAbsenceCheckInOut>
    >(
      id: string,
      data: CampusAbsenceCheckInOutRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceCheckInOut, T>, any>({
        path: `/campus-absences/campus-absences/${id}/change-check-in-out/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesChangeStatusUpdate
     * @request PUT:/campus-absences/campus-absences/{id}/change-status/
     */ campusAbsencesCampusAbsencesChangeStatusUpdate: <
      T extends FetchKeys<CampusAbsenceChangeStatus>
    >(
      id: string,
      data: CampusAbsenceChangeStatusRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceChangeStatus, T>, any>({
        path: `/campus-absences/campus-absences/${id}/change-status/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesHistoryRetrieve
     * @request GET:/campus-absences/campus-absences/{id}/history/
     */ campusAbsencesCampusAbsencesHistoryRetrieve: <
      T extends FetchKeys<CampusAbsenceLog>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceLog, T>, any>({
        path: `/campus-absences/campus-absences/${id}/history/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesChoicesRetrieve
     * @request GET:/campus-absences/campus-absences/choices/
     */ campusAbsencesCampusAbsencesChoicesRetrieve: <
      T extends FetchKeys<CampusAbsence>
    >(
      query?: {
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?:
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        check_in?: boolean
        check_out?: boolean
        /** @format date-time */
        date_from_after?: string
        /** @format date-time */
        date_to_before?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?:
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        students?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsence, T>, any>({
        path: `/campus-absences/campus-absences/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesCollisionsCreate
     * @request POST:/campus-absences/campus-absences/collisions/
     */ campusAbsencesCampusAbsencesCollisionsCreate: <
      T extends FetchKeys<CampusAbsenceWarning>
    >(
      data: CampusAbsenceWarningRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceWarning, T>, any>({
        path: `/campus-absences/campus-absences/collisions/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  chronicConditions = {
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsList
     * @request GET:/chronic-conditions/
     */
    chronicConditionsList: <T extends FetchKeys<PaginatedChronicConditionList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedChronicConditionList, T>, any>({
        path: `/chronic-conditions/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsCreate
     * @request POST:/chronic-conditions/
     */ chronicConditionsCreate: <T extends FetchKeys<ChronicCondition>>(
      data: ChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChronicCondition, T>, any>({
        path: `/chronic-conditions/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsRetrieve
     * @request GET:/chronic-conditions/{id}/
     */ chronicConditionsRetrieve: <T extends FetchKeys<ChronicCondition>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChronicCondition, T>, any>({
        path: `/chronic-conditions/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsUpdate
     * @request PUT:/chronic-conditions/{id}/
     */ chronicConditionsUpdate: <T extends FetchKeys<ChronicCondition>>(
      id: number,
      data: ChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChronicCondition, T>, any>({
        path: `/chronic-conditions/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsPartialUpdate
     * @request PATCH:/chronic-conditions/{id}/
     */ chronicConditionsPartialUpdate: <T extends FetchKeys<ChronicCondition>>(
      id: number,
      data: PatchedChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChronicCondition, T>, any>({
        path: `/chronic-conditions/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsList
     * @request GET:/chronic-conditions/student-chronic-conditions/
     */ chronicConditionsStudentChronicConditionsList: <
      T extends FetchKeys<StudentChronicCondition[]>
    >(
      query?: {
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentChronicCondition[], T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsCreate
     * @request POST:/chronic-conditions/student-chronic-conditions/
     */ chronicConditionsStudentChronicConditionsCreate: <
      T extends FetchKeys<StudentChronicCondition>
    >(
      data: StudentChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentChronicCondition, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsRetrieve
     * @request GET:/chronic-conditions/student-chronic-conditions/{id}/
     */ chronicConditionsStudentChronicConditionsRetrieve: <
      T extends FetchKeys<StudentChronicCondition>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentChronicCondition, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsUpdate
     * @request PUT:/chronic-conditions/student-chronic-conditions/{id}/
     */ chronicConditionsStudentChronicConditionsUpdate: <
      T extends FetchKeys<StudentChronicCondition>
    >(
      id: number,
      data: StudentChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentChronicCondition, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsPartialUpdate
     * @request PATCH:/chronic-conditions/student-chronic-conditions/{id}/
     */ chronicConditionsStudentChronicConditionsPartialUpdate: <
      T extends FetchKeys<StudentChronicCondition>
    >(
      id: number,
      data: PatchedStudentChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentChronicCondition, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsDestroy
     * @request DELETE:/chronic-conditions/student-chronic-conditions/{id}/
     */ chronicConditionsStudentChronicConditionsDestroy: <
      T extends FetchKeys<void>
    >(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsChoicesRetrieve
     * @request GET:/chronic-conditions/student-chronic-conditions/choices/
     */ chronicConditionsStudentChronicConditionsChoicesRetrieve: <
      T extends FetchKeys<ChronicConditionChoicesResponse>
    >(
      query?: {
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChronicConditionChoicesResponse, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  core = {
    /**
     * No description
     *
     * @tags core
     * @name CoreCountriesList
     * @request GET:/core/countries/
     */
    coreCountriesList: <T extends FetchKeys<Country[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Country[], T>, any>({
        path: `/core/countries/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  courses = {
    /**
     * No description
     *
     * @tags courses
     * @name CoursesList
     * @request GET:/courses/
     */
    coursesList: <T extends FetchKeys<PaginatedCourseListList>>(
      query?: {
        is_latest_version?: boolean
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string
        /** A search term. */
        search?: string
        types__name?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCourseListList, T>, any>({
        path: `/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesRetrieve
     * @request GET:/courses/{id}/
     */ coursesRetrieve: <T extends FetchKeys<CourseDetail>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseDetail, T>, any>({
        path: `/courses/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesUpdateDescriptionPartialUpdate
     * @request PATCH:/courses/{id}/update-description/
     */ coursesUpdateDescriptionPartialUpdate: <
      T extends FetchKeys<CourseDescription>
    >(
      id: string,
      data: PatchedCourseDescriptionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseDescription, T>, any>({
        path: `/courses/${id}/update-description/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesEnrollmentsList
     * @request GET:/courses/enrollments/
     */ coursesEnrollmentsList: <T extends FetchKeys<CourseEnrollment[]>>(
      query?: {
        /** @format uuid */
        course?: string
        dropped_enrollments?: boolean
        id?: number[]
        quarter?: number
        quarter__semester?: number
        quarter__semester__year?: number
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseEnrollment[], T>, any>({
        path: `/courses/enrollments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerList
     * @request GET:/courses/planner/
     */ coursesPlannerList: <
      T extends FetchKeys<PaginatedCoursePlannerListList>
    >(
      query?: {
        activities__quarter?: number
        code?: string
        custom_constraints?: boolean
        department?: string[]
        group?: string[]
        is_latest_version?: boolean
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string
        /** A search term. */
        search?: string
        types__name?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCoursePlannerListList, T>, any>({
        path: `/courses/planner/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerCreate
     * @request POST:/courses/planner/
     */ coursesPlannerCreate: <T extends FetchKeys<CoursePlanner>>(
      data: CoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/courses/planner/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerRetrieve
     * @request GET:/courses/planner/{id}/
     */ coursesPlannerRetrieve: <T extends FetchKeys<CoursePlannerResponse>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlannerResponse, T>, any>({
        path: `/courses/planner/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerUpdate
     * @request PUT:/courses/planner/{id}/
     */ coursesPlannerUpdate: <T extends FetchKeys<CoursePlannerResponse>>(
      id: string,
      data: CoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlannerResponse, T>, any>({
        path: `/courses/planner/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerPartialUpdate
     * @request PATCH:/courses/planner/{id}/
     */ coursesPlannerPartialUpdate: <T extends FetchKeys<CoursePlanner>>(
      id: string,
      data: PatchedCoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/courses/planner/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerDestroy
     * @request DELETE:/courses/planner/{id}/
     */ coursesPlannerDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/courses/planner/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerToggleLatestVersionCreate
     * @request POST:/courses/planner/{id}/toggle-latest-version/
     */ coursesPlannerToggleLatestVersionCreate: <
      T extends FetchKeys<CoursePlanner>
    >(
      id: string,
      data: CoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/courses/planner/${id}/toggle-latest-version/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerDefaultCustomConstraintsRetrieve
     * @request GET:/courses/planner/default-custom-constraints/
     */ coursesPlannerDefaultCustomConstraintsRetrieve: <
      T extends FetchKeys<DefaultCustomConstraints>
    >(
      query?: {
        activities__quarter?: number
        code?: string
        course_group?: string
        course_levels?: string[]
        custom_constraints?: boolean
        department?: string[]
        group?: string[]
        is_latest_version?: boolean
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: string
        /** A search term. */
        search?: string
        types__name?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DefaultCustomConstraints, T>, any>({
        path: `/courses/planner/default-custom-constraints/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerSetQuarterCreate
     * @request POST:/courses/planner/set-quarter/
     */ coursesPlannerSetQuarterCreate: <T extends FetchKeys<QuarterInCourses>>(
      data: QuarterInCoursesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<QuarterInCourses, T>, any>({
        path: `/courses/planner/set-quarter/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsList
     * @request GET:/courses/weekly-comments/
     */ coursesWeeklyCommentsList: <
      T extends FetchKeys<PaginatedWeeklyCommentList>
    >(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        from_date?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        student?: string
        /** @format date */
        to_date?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedWeeklyCommentList, T>, any>({
        path: `/courses/weekly-comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsCreate
     * @request POST:/courses/weekly-comments/
     */ coursesWeeklyCommentsCreate: <T extends FetchKeys<WeeklyComment>>(
      data: WeeklyCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsRetrieve
     * @request GET:/courses/weekly-comments/{id}/
     */ coursesWeeklyCommentsRetrieve: <T extends FetchKeys<WeeklyComment>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsUpdate
     * @request PUT:/courses/weekly-comments/{id}/
     */ coursesWeeklyCommentsUpdate: <T extends FetchKeys<WeeklyComment>>(
      id: number,
      data: WeeklyCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsPartialUpdate
     * @request PATCH:/courses/weekly-comments/{id}/
     */ coursesWeeklyCommentsPartialUpdate: <
      T extends FetchKeys<WeeklyComment>
    >(
      id: number,
      data: PatchedWeeklyCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsDestroy
     * @request DELETE:/courses/weekly-comments/{id}/
     */ coursesWeeklyCommentsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/courses/weekly-comments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsCsvRetrieve
     * @request GET:/courses/weekly-comments/csv/
     */ coursesWeeklyCommentsCsvRetrieve: <T extends FetchKeys<WeeklyComment>>(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        from_date?: string
        /** @format uuid */
        student?: string
        /** @format date */
        to_date?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/csv/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsDownloadRetrieve
     * @request GET:/courses/weekly-comments/download/
     */ coursesWeeklyCommentsDownloadRetrieve: <
      T extends FetchKeys<WeeklyComment>
    >(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        from_date?: string
        /** @format uuid */
        student?: string
        /** @format date */
        to_date?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/download/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsPdfRetrieve
     * @request GET:/courses/weekly-comments/pdf/
     */ coursesWeeklyCommentsPdfRetrieve: <T extends FetchKeys<WeeklyComment>>(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        from_date?: string
        /** @format uuid */
        student?: string
        /** @format date */
        to_date?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/pdf/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  coursesMeta = {}
  curriculum = {
    /**
     * No description
     *
     * @tags curriculum
     * @name CurriculumGradeLevelsList
     * @request GET:/curriculum/grade-levels/
     */
    curriculumGradeLevelsList: <T extends FetchKeys<PaginatedGradeLevelList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedGradeLevelList, T>, any>({
        path: `/curriculum/grade-levels/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags curriculum
     * @name CurriculumGradeLevelsRetrieve
     * @request GET:/curriculum/grade-levels/{id}/
     */ curriculumGradeLevelsRetrieve: <T extends FetchKeys<GradeLevel>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GradeLevel, T>, any>({
        path: `/curriculum/grade-levels/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  departments = {
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsList
     * @request GET:/departments/
     */
    departmentsList: <T extends FetchKeys<PaginatedDepartmentList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDepartmentList, T>, any>({
        path: `/departments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsCreate
     * @request POST:/departments/
     */ departmentsCreate: <T extends FetchKeys<Department>>(
      data: DepartmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Department, T>, any>({
        path: `/departments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsRetrieve
     * @request GET:/departments/{id}/
     */ departmentsRetrieve: <T extends FetchKeys<Department>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Department, T>, any>({
        path: `/departments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsUpdate
     * @request PUT:/departments/{id}/
     */ departmentsUpdate: <T extends FetchKeys<Department>>(
      id: number,
      data: DepartmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Department, T>, any>({
        path: `/departments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsPartialUpdate
     * @request PATCH:/departments/{id}/
     */ departmentsPartialUpdate: <T extends FetchKeys<Department>>(
      id: number,
      data: PatchedDepartmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Department, T>, any>({
        path: `/departments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsDestroy
     * @request DELETE:/departments/{id}/
     */ departmentsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/departments/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  discipline = {
    /**
     * No description
     *
     * @tags discipline
     * @name DisciplineStudentCategoryReportsList
     * @request GET:/discipline/student-category-reports/
     */
    disciplineStudentCategoryReportsList: <
      T extends FetchKeys<PaginatedStudentCategoryReportListList>
    >(
      query?: {
        /** @format date */
        end_date__lte?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format date */
        start_date__gte?: string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentCategoryReportListList, T>, any>({
        path: `/discipline/student-category-reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags discipline
     * @name DisciplineStudentCategoryReportsRetrieve
     * @request GET:/discipline/student-category-reports/{reportId}/
     */ disciplineStudentCategoryReportsRetrieve: <
      T extends FetchKeys<StudentCategoryItemList>
    >(
      reportId: string,
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCategoryItemList, T>, any>({
        path: `/discipline/student-category-reports/${reportId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags discipline
     * @name DisciplineStudentCategoryReportsRetrieve2
     * @request GET:/discipline/student-category-reports/{reportId}/{categoryItemId}/
     */ disciplineStudentCategoryReportsRetrieve2: <
      T extends FetchKeys<StudentCategoryItemList>
    >(
      categoryItemId: string,
      reportId: string,
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCategoryItemList, T>, any>({
        path: `/discipline/student-category-reports/${reportId}/${categoryItemId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags discipline
     * @name DisciplineStudentCategoryReportsUpdate
     * @request PUT:/discipline/student-category-reports/{reportId}/{categoryItemId}/
     */ disciplineStudentCategoryReportsUpdate: <
      T extends FetchKeys<StudentCategoryItem>
    >(
      categoryItemId: string,
      reportId: string,
      data: StudentCategoryItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCategoryItem, T>, any>({
        path: `/discipline/student-category-reports/${reportId}/${categoryItemId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags discipline
     * @name DisciplineStudentCategoryReportsPartialUpdate
     * @request PATCH:/discipline/student-category-reports/{reportId}/{categoryItemId}/
     */ disciplineStudentCategoryReportsPartialUpdate: <
      T extends FetchKeys<StudentCategoryItem>
    >(
      categoryItemId: string,
      reportId: string,
      data: PatchedStudentCategoryItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCategoryItem, T>, any>({
        path: `/discipline/student-category-reports/${reportId}/${categoryItemId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  doctors = {
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsList
     * @request GET:/doctors/
     */
    doctorsList: <T extends FetchKeys<PaginatedDoctorList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDoctorList, T>, any>({
        path: `/doctors/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsCreate
     * @request POST:/doctors/
     */ doctorsCreate: <T extends FetchKeys<Doctor>>(
      data: DoctorRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Doctor, T>, any>({
        path: `/doctors/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsRetrieve
     * @request GET:/doctors/{id}/
     */ doctorsRetrieve: <T extends FetchKeys<Doctor>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Doctor, T>, any>({
        path: `/doctors/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsUpdate
     * @request PUT:/doctors/{id}/
     */ doctorsUpdate: <T extends FetchKeys<Doctor>>(
      id: number,
      data: DoctorRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Doctor, T>, any>({
        path: `/doctors/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsPartialUpdate
     * @request PATCH:/doctors/{id}/
     */ doctorsPartialUpdate: <T extends FetchKeys<Doctor>>(
      id: number,
      data: PatchedDoctorRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Doctor, T>, any>({
        path: `/doctors/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsDestroy
     * @request DELETE:/doctors/{id}/
     */ doctorsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/doctors/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  exams = {
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamClashesList
     * @request GET:/exams/exam-planner/exam-clashes/
     */
    examsExamPlannerExamClashesList: <T extends FetchKeys<ExamClash[]>>(
      query?: {
        exams?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamClash[], T>, any>({
        path: `/exams/exam-planner/exam-clashes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamClashesExamClashPreviewCreate
     * @request POST:/exams/exam-planner/exam-clashes/exam-clash-preview/
     */ examsExamPlannerExamClashesExamClashPreviewCreate: <
      T extends FetchKeys<ExamClashPreview>
    >(
      data: ExamClashPreviewRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamClashPreview, T>, any>({
        path: `/exams/exam-planner/exam-clashes/exam-clash-preview/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionList
     * @request GET:/exams/exam-planner/exam-session/
     */ examsExamPlannerExamSessionList: <
      T extends FetchKeys<ExamSessionList[]>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSessionList[], T>, any>({
        path: `/exams/exam-planner/exam-session/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionCreate
     * @request POST:/exams/exam-planner/exam-session/
     */ examsExamPlannerExamSessionCreate: <T extends FetchKeys<ExamSession>>(
      data: ExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsList
     * @request GET:/exams/exam-planner/exam-session/{examSessionId}/exams/
     */ examsExamPlannerExamSessionExamsList: <
      T extends FetchKeys<PaginatedExamList>
    >(
      examSessionId: string,
      query?: {
        course?: string[]
        /** @format date */
        date?: string
        departments?: string[]
        end_time__isnull?: boolean
        grade_levels?: number[]
        has_clashes?: boolean
        is_published?: boolean
        is_scheduled?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        saa_invigilators?: string[]
        start_time__isnull?: boolean
        students?: number[]
        teachers?: string[]
        test_invigilators?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedExamList, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsCreate
     * @request POST:/exams/exam-planner/exam-session/{examSessionId}/exams/
     */ examsExamPlannerExamSessionExamsCreate: <T extends FetchKeys<ExamForm>>(
      examSessionId: string,
      data: ExamFormRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamForm, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsRetrieve
     * @request GET:/exams/exam-planner/exam-session/{examSessionId}/exams/{id}/
     */ examsExamPlannerExamSessionExamsRetrieve: <T extends FetchKeys<Exam>>(
      examSessionId: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Exam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsUpdate
     * @request PUT:/exams/exam-planner/exam-session/{examSessionId}/exams/{id}/
     */ examsExamPlannerExamSessionExamsUpdate: <T extends FetchKeys<ExamForm>>(
      examSessionId: string,
      id: string,
      data: ExamFormRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamForm, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsPartialUpdate
     * @request PATCH:/exams/exam-planner/exam-session/{examSessionId}/exams/{id}/
     */ examsExamPlannerExamSessionExamsPartialUpdate: <
      T extends FetchKeys<Exam>
    >(
      examSessionId: string,
      id: string,
      data: PatchedExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Exam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsDestroy
     * @request DELETE:/exams/exam-planner/exam-session/{examSessionId}/exams/{id}/
     */ examsExamPlannerExamSessionExamsDestroy: <T extends FetchKeys<void>>(
      examSessionId: string,
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsAllIdsRetrieve
     * @request GET:/exams/exam-planner/exam-session/{examSessionId}/exams/all-ids/
     */ examsExamPlannerExamSessionExamsAllIdsRetrieve: <
      T extends FetchKeys<Exam>
    >(
      examSessionId: string,
      query?: {
        course?: string[]
        /** @format date */
        date?: string
        departments?: string[]
        end_time__isnull?: boolean
        grade_levels?: number[]
        has_clashes?: boolean
        is_published?: boolean
        is_scheduled?: boolean
        saa_invigilators?: string[]
        start_time__isnull?: boolean
        students?: number[]
        teachers?: string[]
        test_invigilators?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Exam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/all-ids/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsAvailableFiltersRetrieve
     * @request GET:/exams/exam-planner/exam-session/{examSessionId}/exams/available-filters/
     */ examsExamPlannerExamSessionExamsAvailableFiltersRetrieve: <
      T extends FetchKeys<Exam>
    >(
      examSessionId: string,
      query?: {
        course?: string[]
        /** @format date */
        date?: string
        departments?: string[]
        end_time__isnull?: boolean
        grade_levels?: number[]
        has_clashes?: boolean
        is_published?: boolean
        is_scheduled?: boolean
        saa_invigilators?: string[]
        start_time__isnull?: boolean
        students?: number[]
        teachers?: string[]
        test_invigilators?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Exam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsDatesRetrieve
     * @request GET:/exams/exam-planner/exam-session/{examSessionId}/exams/dates/
     */ examsExamPlannerExamSessionExamsDatesRetrieve: <
      T extends FetchKeys<Exam>
    >(
      examSessionId: string,
      query?: {
        course?: string[]
        /** @format date */
        date?: string
        departments?: string[]
        end_time__isnull?: boolean
        grade_levels?: number[]
        has_clashes?: boolean
        is_published?: boolean
        is_scheduled?: boolean
        saa_invigilators?: string[]
        start_time__isnull?: boolean
        students?: number[]
        teachers?: string[]
        test_invigilators?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Exam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/dates/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsRemoveMultipleCreate
     * @request POST:/exams/exam-planner/exam-session/{examSessionId}/exams/remove-multiple/
     */ examsExamPlannerExamSessionExamsRemoveMultipleCreate: <
      T extends FetchKeys<MultipleExam>
    >(
      examSessionId: string,
      data: MultipleExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MultipleExam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/remove-multiple/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/
     */ examsExamPlannerExamSessionRetrieve: <T extends FetchKeys<ExamSession>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionUpdate
     * @request PUT:/exams/exam-planner/exam-session/{id}/
     */ examsExamPlannerExamSessionUpdate: <T extends FetchKeys<ExamSession>>(
      id: string,
      data: ExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionPartialUpdate
     * @request PATCH:/exams/exam-planner/exam-session/{id}/
     */ examsExamPlannerExamSessionPartialUpdate: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      data: PatchedExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionDestroy
     * @request DELETE:/exams/exam-planner/exam-session/{id}/
     */ examsExamPlannerExamSessionDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionAvailableExamCoursesRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/available-exam-courses/
     */ examsExamPlannerExamSessionAvailableExamCoursesRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/available-exam-courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionChangesPreviewCreate
     * @request POST:/exams/exam-planner/exam-session/{id}/changes-preview/
     */ examsExamPlannerExamSessionChangesPreviewCreate: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      data: ExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/changes-preview/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionCoursesInExamSessionRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/courses-in-exam-session/
     */ examsExamPlannerExamSessionCoursesInExamSessionRetrieve: <
      T extends FetchKeys<CourseInExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseInExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/courses-in-exam-session/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsReadyToPublishRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/exams-ready-to-publish/
     */ examsExamPlannerExamSessionExamsReadyToPublishRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/exams-ready-to-publish/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionPublishExamsCreate
     * @request POST:/exams/exam-planner/exam-session/{id}/publish-exams/
     */ examsExamPlannerExamSessionPublishExamsCreate: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      data: ExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/publish-exams/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionPublishReadyExamsCreate
     * @request POST:/exams/exam-planner/exam-session/{id}/publish-ready-exams/
     */ examsExamPlannerExamSessionPublishReadyExamsCreate: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      data: ExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/publish-ready-exams/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionStudentsForExamRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/students-for-exam/
     */ examsExamPlannerExamSessionStudentsForExamRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/students-for-exam/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionStudentsFromCoursesRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/students-from-courses/
     */ examsExamPlannerExamSessionStudentsFromCoursesRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/students-from-courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionTeachersFromCoursesRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/teachers-from-courses/
     */ examsExamPlannerExamSessionTeachersFromCoursesRetrieve: <
      T extends FetchKeys<void>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/teachers-from-courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionXlsxRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/xlsx/
     */ examsExamPlannerExamSessionXlsxRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/xlsx/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionAvailableUsersRetrieve
     * @request GET:/exams/exam-planner/exam-session/available-users/
     */ examsExamPlannerExamSessionAvailableUsersRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/available-users/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionHasExamsInDateRangeRetrieve
     * @request GET:/exams/exam-planner/exam-session/has-exams-in-date-range/
     */ examsExamPlannerExamSessionHasExamsInDateRangeRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/has-exams-in-date-range/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionSimpleListRetrieve
     * @request GET:/exams/exam-planner/exam-session/simple-list/
     */ examsExamPlannerExamSessionSimpleListRetrieve: <
      T extends FetchKeys<ExamSessionSimpleList>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSessionSimpleList, T>, any>({
        path: `/exams/exam-planner/exam-session/simple-list/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionStudentsRetrieve
     * @request GET:/exams/exam-planner/exam-session/students/
     */ examsExamPlannerExamSessionStudentsRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionSuggestedRoomsRetrieve
     * @request GET:/exams/exam-planner/exam-session/suggested-rooms/
     */ examsExamPlannerExamSessionSuggestedRoomsRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/suggested-rooms/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsStudentsList
     * @request GET:/exams/students/{studentId}/
     */ examsStudentsList: <
      T extends FetchKeys<PaginatedStudentExternalExamList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentExternalExamList, T>, any>({
        path: `/exams/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsStudentsUpdate
     * @request PUT:/exams/students/{studentId}/
     */ examsStudentsUpdate: <T extends FetchKeys<StudentExternalExam>>(
      studentId: string,
      data: StudentExternalExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentExternalExam, T>, any>({
        path: `/exams/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsStudentsPartialUpdate
     * @request PATCH:/exams/students/{studentId}/
     */ examsStudentsPartialUpdate: <T extends FetchKeys<StudentExternalExam>>(
      studentId: string,
      data: PatchedStudentExternalExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentExternalExam, T>, any>({
        path: `/exams/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  favouriteWidgets = {}
  filesForStudents = {
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsList
     * @request GET:/files-for-students/
     */
    filesForStudentsList: <T extends FetchKeys<PaginatedFileForStudentList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedFileForStudentList, T>, any>({
        path: `/files-for-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsCreate
     * @request POST:/files-for-students/
     */ filesForStudentsCreate: <T extends FetchKeys<FileForStudent>>(
      data: FileForStudentSwaggerRequest[],
      query?: {
        replace_duplicated?: boolean
        save_duplicated?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FileForStudent, T>, any>({
        path: `/files-for-students/`,
        method: 'POST',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsRetrieve
     * @request GET:/files-for-students/{id}/
     */ filesForStudentsRetrieve: <T extends FetchKeys<FileForStudent>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FileForStudent, T>, any>({
        path: `/files-for-students/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsUpdate
     * @request PUT:/files-for-students/{id}/
     */ filesForStudentsUpdate: <T extends FetchKeys<FileForStudent>>(
      id: number,
      data: FileForStudentSwaggerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FileForStudent, T>, any>({
        path: `/files-for-students/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsPartialUpdate
     * @request PATCH:/files-for-students/{id}/
     */ filesForStudentsPartialUpdate: <T extends FetchKeys<FileForStudent>>(
      id: number,
      data: PatchedFileForStudentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FileForStudent, T>, any>({
        path: `/files-for-students/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsDestroy
     * @request DELETE:/files-for-students/{id}/
     */ filesForStudentsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/files-for-students/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsChoicesRetrieve
     * @request GET:/files-for-students/choices/
     */ filesForStudentsChoicesRetrieve: <
      T extends FetchKeys<FileForStudentChoices>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FileForStudentChoices, T>, any>({
        path: `/files-for-students/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsSaveOrderCreate
     * @request POST:/files-for-students/save-order/
     */ filesForStudentsSaveOrderCreate: <
      T extends FetchKeys<SaveStudentsFilesOrder>
    >(
      data: SaveStudentsFilesOrderRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SaveStudentsFilesOrder, T>, any>({
        path: `/files-for-students/save-order/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  grades = {
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccList
     * @request GET:/grades/acc/
     */
    gradesAccList: <T extends FetchKeys<PaginatedAccGradeList>>(
      query?: {
        below_target?: boolean
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        dropped_grades?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number
        semester?: number
        /** @format uuid */
        student?: string
        /**
         * * `pending` - Pending
         * * `much_above` - Much Above
         * * `above` - Above
         * * `below` - Below
         * * `much_below` - Much Below
         */
        target_grade_status?:
          | 'above'
          | 'below'
          | 'much_above'
          | 'much_below'
          | 'pending'
        /** @format uuid */
        teacher_id?: string
        year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAccGradeList, T>, any>({
        path: `/grades/acc/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccCreate
     * @request POST:/grades/acc/
     */ gradesAccCreate: <T extends FetchKeys<AccGrade>>(
      data: AccGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccRetrieve
     * @request GET:/grades/acc/{id}/
     */ gradesAccRetrieve: <T extends FetchKeys<AccGrade>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccUpdate
     * @request PUT:/grades/acc/{id}/
     */ gradesAccUpdate: <T extends FetchKeys<AccGrade>>(
      id: number,
      data: AccGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccPartialUpdate
     * @request PATCH:/grades/acc/{id}/
     */ gradesAccPartialUpdate: <T extends FetchKeys<AccGrade>>(
      id: number,
      data: PatchedAccGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccDestroy
     * @request DELETE:/grades/acc/{id}/
     */ gradesAccDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/grades/acc/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccSetTargetCreate
     * @request POST:/grades/acc/{id}/set-target/
     */ gradesAccSetTargetCreate: <T extends FetchKeys<TargetGrade>>(
      id: number,
      data: TargetGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TargetGrade, T>, any>({
        path: `/grades/acc/${id}/set-target/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccAllGradesRetrieve
     * @request GET:/grades/acc/all-grades/
     */ gradesAccAllGradesRetrieve: <T extends FetchKeys<AccGrade>>(
      query?: {
        below_target?: boolean
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        dropped_grades?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        semester?: number
        /** @format uuid */
        student?: string
        /**
         * * `pending` - Pending
         * * `much_above` - Much Above
         * * `above` - Above
         * * `below` - Below
         * * `much_below` - Much Below
         */
        target_grade_status?:
          | 'above'
          | 'below'
          | 'much_above'
          | 'much_below'
          | 'pending'
        /** @format uuid */
        teacher_id?: string
        year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/all-grades/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccCsvRetrieve
     * @request GET:/grades/acc/csv/
     */ gradesAccCsvRetrieve: <T extends FetchKeys<AccGrade>>(
      query?: {
        below_target?: boolean
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        dropped_grades?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        semester?: number
        /** @format uuid */
        student?: string
        /**
         * * `pending` - Pending
         * * `much_above` - Much Above
         * * `above` - Above
         * * `below` - Below
         * * `much_below` - Much Below
         */
        target_grade_status?:
          | 'above'
          | 'below'
          | 'much_above'
          | 'much_below'
          | 'pending'
        /** @format uuid */
        teacher_id?: string
        year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/csv/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccCsvForCurrentQuarterRetrieve
     * @request GET:/grades/acc/csv-for-current-quarter/
     */ gradesAccCsvForCurrentQuarterRetrieve: <T extends FetchKeys<AccGrade>>(
      query?: {
        below_target?: boolean
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        dropped_grades?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        semester?: number
        /** @format uuid */
        student?: string
        /**
         * * `pending` - Pending
         * * `much_above` - Much Above
         * * `above` - Above
         * * `below` - Below
         * * `much_below` - Much Below
         */
        target_grade_status?:
          | 'above'
          | 'below'
          | 'much_above'
          | 'much_below'
          | 'pending'
        /** @format uuid */
        teacher_id?: string
        year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/csv-for-current-quarter/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Return grades in IDP format (columns with Q1, S1 etc.) :return response [ { student: 1, courses: [ { "course": course_id, "grades": { "Q1": {"id": 1, "grade": "A+", "comment": "X"}, "Q2": {"id": 1, "grade": "A+", "comment": "X"}, "S1": {"id": 1, "grade": "A+", "comment": "X"}, } } ] } ]
     *
     * @tags grades
     * @name GradesAccIdpTableRetrieve
     * @request GET:/grades/acc/idp-table/
     */ gradesAccIdpTableRetrieve: <T extends FetchKeys<AccGrade>>(
      query?: {
        below_target?: boolean
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        dropped_grades?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        semester?: number
        /** @format uuid */
        student?: string
        /**
         * * `pending` - Pending
         * * `much_above` - Much Above
         * * `above` - Above
         * * `below` - Below
         * * `much_below` - Much Below
         */
        target_grade_status?:
          | 'above'
          | 'below'
          | 'much_above'
          | 'much_below'
          | 'pending'
        /** @format uuid */
        teacher_id?: string
        year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/idp-table/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesCustomGradesRetrieve
     * @request GET:/grades/custom-grades/
     */ gradesCustomGradesRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/grades/custom-grades/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesHighSchoolGpaRetrieve
     * @request GET:/grades/high-school-gpa/{studentId}/
     */ gradesHighSchoolGpaRetrieve: <T extends FetchKeys<HighSchoolGPA>>(
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HighSchoolGPA, T>, any>({
        path: `/grades/high-school-gpa/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesPeriodGpasRetrieve
     * @request GET:/grades/period-gpas/{studentId}/
     */ gradesPeriodGpasRetrieve: <
      T extends FetchKeys<ManyPeriodGPASerializer>
    >(
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyPeriodGPASerializer, T>, any>({
        path: `/grades/period-gpas/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesTargetGradeStatusRetrieve
     * @request GET:/grades/target-grade-status/
     */ gradesTargetGradeStatusRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/grades/target-grade-status/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
  }
  health = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksList
     * @request GET:/health/checks/
     */
    healthChecksList: <T extends FetchKeys<PaginatedHealthCheckListList>>(
      query?: {
        /**
         * * `room` - room check
         * * `campus` - campus check
         */
        check_type?: 'campus' | 'room'
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        sick_report?: string
        /** @format time */
        start_time?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedHealthCheckListList, T>, any>({
        path: `/health/checks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksRetrieve
     * @request GET:/health/checks/{id}/
     */ healthChecksRetrieve: <T extends FetchKeys<HealthCheck>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheck, T>, any>({
        path: `/health/checks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksMedicinesRetrieve
     * @request GET:/health/checks/{id}/medicines/
     */ healthChecksMedicinesRetrieve: <
      T extends FetchKeys<MedicinesInHealthCheck>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicinesInHealthCheck, T>, any>({
        path: `/health/checks/${id}/medicines/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksOptionalMedicinesRetrieve
     * @request GET:/health/checks/{id}/optional-medicines/
     */ healthChecksOptionalMedicinesRetrieve: <
      T extends FetchKeys<OptionalMedicinesInHealthCheck>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<OptionalMedicinesInHealthCheck, T>, any>({
        path: `/health/checks/${id}/optional-medicines/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksAvailableFiltersRetrieve
     * @request GET:/health/checks/available-filters/
     */ healthChecksAvailableFiltersRetrieve: <
      T extends FetchKeys<HealthCheckAvailableFilters>
    >(
      query?: {
        /**
         * * `room` - room check
         * * `campus` - campus check
         */
        check_type?: 'campus' | 'room'
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        sick_report?: string
        /** @format time */
        start_time?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckAvailableFilters, T>, any>({
        path: `/health/checks/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsList
     * @request GET:/health/checks/comments/
     */ healthChecksCommentsList: <T extends FetchKeys<HealthCheckComment[]>>(
      query?: {
        /** @format uuid */
        health_check?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckComment[], T>, any>({
        path: `/health/checks/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsCreate
     * @request POST:/health/checks/comments/
     */ healthChecksCommentsCreate: <T extends FetchKeys<HealthCheckComment>>(
      data: HealthCheckCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckComment, T>, any>({
        path: `/health/checks/comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsRetrieve
     * @request GET:/health/checks/comments/{healthCheckPk}/
     */ healthChecksCommentsRetrieve: <T extends FetchKeys<HealthCheckComment>>(
      healthCheckPk: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckComment, T>, any>({
        path: `/health/checks/comments/${healthCheckPk}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsUpdate
     * @request PUT:/health/checks/comments/{healthCheckPk}/
     */ healthChecksCommentsUpdate: <T extends FetchKeys<HealthCheckComment>>(
      healthCheckPk: string,
      data: HealthCheckCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckComment, T>, any>({
        path: `/health/checks/comments/${healthCheckPk}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsPartialUpdate
     * @request PATCH:/health/checks/comments/{healthCheckPk}/
     */ healthChecksCommentsPartialUpdate: <
      T extends FetchKeys<HealthCheckComment>
    >(
      healthCheckPk: string,
      data: PatchedHealthCheckCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckComment, T>, any>({
        path: `/health/checks/comments/${healthCheckPk}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsDestroy
     * @request DELETE:/health/checks/comments/{healthCheckPk}/
     */ healthChecksCommentsDestroy: <T extends FetchKeys<void>>(
      healthCheckPk: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/comments/${healthCheckPk}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsList
     * @request GET:/health/checks/conditions/
     */ healthChecksConditionsList: <T extends FetchKeys<Condition[]>>(
      query?: {
        /** @format uuid */
        health_check?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Condition[], T>, any>({
        path: `/health/checks/conditions/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsCreate
     * @request POST:/health/checks/conditions/
     */ healthChecksConditionsCreate: <T extends FetchKeys<Condition>>(
      data: ConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Condition, T>, any>({
        path: `/health/checks/conditions/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsRetrieve
     * @request GET:/health/checks/conditions/{healthCheckPk}/
     */ healthChecksConditionsRetrieve: <T extends FetchKeys<Condition>>(
      healthCheckPk: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Condition, T>, any>({
        path: `/health/checks/conditions/${healthCheckPk}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsUpdate
     * @request PUT:/health/checks/conditions/{healthCheckPk}/
     */ healthChecksConditionsUpdate: <T extends FetchKeys<Condition>>(
      healthCheckPk: string,
      data: ConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Condition, T>, any>({
        path: `/health/checks/conditions/${healthCheckPk}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsPartialUpdate
     * @request PATCH:/health/checks/conditions/{healthCheckPk}/
     */ healthChecksConditionsPartialUpdate: <T extends FetchKeys<Condition>>(
      healthCheckPk: string,
      data: PatchedConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Condition, T>, any>({
        path: `/health/checks/conditions/${healthCheckPk}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsDestroy
     * @request DELETE:/health/checks/conditions/{healthCheckPk}/
     */ healthChecksConditionsDestroy: <T extends FetchKeys<void>>(
      healthCheckPk: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/conditions/${healthCheckPk}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGiveList
     * @request GET:/health/checks/drink-give/
     */ healthChecksDrinkGiveList: <T extends FetchKeys<DrinkGive[]>>(
      query?: {
        /** @format date-time */
        created?: string
        /** @format uuid */
        health_check?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DrinkGive[], T>, any>({
        path: `/health/checks/drink-give/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGiveCreate
     * @request POST:/health/checks/drink-give/
     */ healthChecksDrinkGiveCreate: <T extends FetchKeys<DrinkGive>>(
      data: DrinkGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DrinkGive, T>, any>({
        path: `/health/checks/drink-give/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGiveRetrieve
     * @request GET:/health/checks/drink-give/{healthCheckPk}/
     */ healthChecksDrinkGiveRetrieve: <T extends FetchKeys<DrinkGive>>(
      healthCheckPk: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DrinkGive, T>, any>({
        path: `/health/checks/drink-give/${healthCheckPk}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGiveUpdate
     * @request PUT:/health/checks/drink-give/{healthCheckPk}/
     */ healthChecksDrinkGiveUpdate: <T extends FetchKeys<DrinkGive>>(
      healthCheckPk: string,
      data: DrinkGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DrinkGive, T>, any>({
        path: `/health/checks/drink-give/${healthCheckPk}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGivePartialUpdate
     * @request PATCH:/health/checks/drink-give/{healthCheckPk}/
     */ healthChecksDrinkGivePartialUpdate: <T extends FetchKeys<DrinkGive>>(
      healthCheckPk: string,
      data: PatchedDrinkGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DrinkGive, T>, any>({
        path: `/health/checks/drink-give/${healthCheckPk}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGiveDestroy
     * @request DELETE:/health/checks/drink-give/{healthCheckPk}/
     */ healthChecksDrinkGiveDestroy: <T extends FetchKeys<void>>(
      healthCheckPk: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/drink-give/${healthCheckPk}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGiveList
     * @request GET:/health/checks/food-give/
     */ healthChecksFoodGiveList: <T extends FetchKeys<FoodGive[]>>(
      query?: {
        /** @format date-time */
        created?: string
        /** @format uuid */
        health_check?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FoodGive[], T>, any>({
        path: `/health/checks/food-give/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGiveCreate
     * @request POST:/health/checks/food-give/
     */ healthChecksFoodGiveCreate: <T extends FetchKeys<FoodGive>>(
      data: FoodGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FoodGive, T>, any>({
        path: `/health/checks/food-give/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGiveRetrieve
     * @request GET:/health/checks/food-give/{healthCheckPk}/
     */ healthChecksFoodGiveRetrieve: <T extends FetchKeys<FoodGive>>(
      healthCheckPk: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FoodGive, T>, any>({
        path: `/health/checks/food-give/${healthCheckPk}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGiveUpdate
     * @request PUT:/health/checks/food-give/{healthCheckPk}/
     */ healthChecksFoodGiveUpdate: <T extends FetchKeys<FoodGive>>(
      healthCheckPk: string,
      data: FoodGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FoodGive, T>, any>({
        path: `/health/checks/food-give/${healthCheckPk}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGivePartialUpdate
     * @request PATCH:/health/checks/food-give/{healthCheckPk}/
     */ healthChecksFoodGivePartialUpdate: <T extends FetchKeys<FoodGive>>(
      healthCheckPk: string,
      data: PatchedFoodGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FoodGive, T>, any>({
        path: `/health/checks/food-give/${healthCheckPk}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGiveDestroy
     * @request DELETE:/health/checks/food-give/{healthCheckPk}/
     */ healthChecksFoodGiveDestroy: <T extends FetchKeys<void>>(
      healthCheckPk: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/food-give/${healthCheckPk}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksLogsList
     * @request GET:/health/checks/logs/
     */ healthChecksLogsList: <T extends FetchKeys<HealthCheckLog[]>>(
      query?: {
        /** @format date */
        date?: string
        health_check__sick_report?: string
        /** @format uuid */
        health_check__student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckLog[], T>, any>({
        path: `/health/checks/logs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksLogsRetrieve
     * @request GET:/health/checks/logs/{id}/
     */ healthChecksLogsRetrieve: <T extends FetchKeys<HealthCheckLog>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckLog, T>, any>({
        path: `/health/checks/logs/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenList
     * @request GET:/health/checks/medicine-given/
     */ healthChecksMedicineGivenList: <T extends FetchKeys<MedicineGiven[]>>(
      query?: {
        /** @format uuid */
        health_check?: string
        is_regular_medicine?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineGiven[], T>, any>({
        path: `/health/checks/medicine-given/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenCreate
     * @request POST:/health/checks/medicine-given/
     */ healthChecksMedicineGivenCreate: <T extends FetchKeys<MedicineGiven>>(
      data: MedicineGivenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineGiven, T>, any>({
        path: `/health/checks/medicine-given/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenRetrieve
     * @request GET:/health/checks/medicine-given/{id}/
     */ healthChecksMedicineGivenRetrieve: <T extends FetchKeys<MedicineGiven>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineGiven, T>, any>({
        path: `/health/checks/medicine-given/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenUpdate
     * @request PUT:/health/checks/medicine-given/{id}/
     */ healthChecksMedicineGivenUpdate: <T extends FetchKeys<MedicineGiven>>(
      id: string,
      data: MedicineGivenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineGiven, T>, any>({
        path: `/health/checks/medicine-given/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenPartialUpdate
     * @request PATCH:/health/checks/medicine-given/{id}/
     */ healthChecksMedicineGivenPartialUpdate: <
      T extends FetchKeys<MedicineGiven>
    >(
      id: string,
      data: PatchedMedicineGivenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineGiven, T>, any>({
        path: `/health/checks/medicine-given/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenDestroy
     * @request DELETE:/health/checks/medicine-given/{id}/
     */ healthChecksMedicineGivenDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/medicine-given/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksMinAndMaxDateRetrieve
     * @request GET:/health/checks/min-and-max-date/
     */ healthChecksMinAndMaxDateRetrieve: <T extends FetchKeys<HealthCheck>>(
      query?: {
        /**
         * * `room` - room check
         * * `campus` - campus check
         */
        check_type?: 'campus' | 'room'
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        sick_report?: string
        /** @format time */
        start_time?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheck, T>, any>({
        path: `/health/checks/min-and-max-date/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksSlotsRetrieve
     * @request GET:/health/checks/slots/
     */ healthChecksSlotsRetrieve: <T extends FetchKeys<HealthCheck>>(
      query?: {
        /**
         * * `room` - room check
         * * `campus` - campus check
         */
        check_type?: 'campus' | 'room'
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        sick_report?: string
        /** @format time */
        start_time?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheck, T>, any>({
        path: `/health/checks/slots/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperatureList
     * @request GET:/health/checks/temperature/
     */ healthChecksTemperatureList: <T extends FetchKeys<TemperatureCheck[]>>(
      query?: {
        /** @format uuid */
        health_check?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TemperatureCheck[], T>, any>({
        path: `/health/checks/temperature/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperatureCreate
     * @request POST:/health/checks/temperature/
     */ healthChecksTemperatureCreate: <T extends FetchKeys<TemperatureCheck>>(
      data: TemperatureCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TemperatureCheck, T>, any>({
        path: `/health/checks/temperature/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperatureRetrieve
     * @request GET:/health/checks/temperature/{healthCheckPk}/
     */ healthChecksTemperatureRetrieve: <
      T extends FetchKeys<TemperatureCheck>
    >(
      healthCheckPk: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TemperatureCheck, T>, any>({
        path: `/health/checks/temperature/${healthCheckPk}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperatureUpdate
     * @request PUT:/health/checks/temperature/{healthCheckPk}/
     */ healthChecksTemperatureUpdate: <T extends FetchKeys<TemperatureCheck>>(
      healthCheckPk: string,
      data: TemperatureCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TemperatureCheck, T>, any>({
        path: `/health/checks/temperature/${healthCheckPk}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperaturePartialUpdate
     * @request PATCH:/health/checks/temperature/{healthCheckPk}/
     */ healthChecksTemperaturePartialUpdate: <
      T extends FetchKeys<TemperatureCheck>
    >(
      healthCheckPk: string,
      data: PatchedTemperatureCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TemperatureCheck, T>, any>({
        path: `/health/checks/temperature/${healthCheckPk}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperatureDestroy
     * @request DELETE:/health/checks/temperature/{healthCheckPk}/
     */ healthChecksTemperatureDestroy: <T extends FetchKeys<void>>(
      healthCheckPk: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/temperature/${healthCheckPk}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicineList
     * @request GET:/health/medicine/
     */ healthMedicineList: <T extends FetchKeys<PaginatedMedicineList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMedicineList, T>, any>({
        path: `/health/medicine/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicineCreate
     * @request POST:/health/medicine/
     */ healthMedicineCreate: <T extends FetchKeys<Medicine>>(
      data: MedicineRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Medicine, T>, any>({
        path: `/health/medicine/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicineInPlanList
     * @request GET:/health/medicine-plan/medicine-in-plan/
     */ healthMedicinePlanMedicineInPlanList: <
      T extends FetchKeys<PaginatedMedicineInPlanList>
    >(
      query?: {
        /** @format date */
        end_date_before?: string
        is_archived?: boolean
        is_temporary?: boolean
        my_house_students?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format date */
        scheduled_medicine_for?: string
        /** A search term. */
        search?: string
        /** @format date */
        start_date_after?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMedicineInPlanList, T>, any>({
        path: `/health/medicine-plan/medicine-in-plan/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicineInPlanCreate
     * @request POST:/health/medicine-plan/medicine-in-plan/
     */ healthMedicinePlanMedicineInPlanCreate: <
      T extends FetchKeys<MedicineInPlan>
    >(
      data: MedicineInPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineInPlan, T>, any>({
        path: `/health/medicine-plan/medicine-in-plan/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicineInPlanChangeEndDateUpdate
     * @request PUT:/health/medicine-plan/medicine-in-plan/{id}/change-end-date/
     */ healthMedicinePlanMedicineInPlanChangeEndDateUpdate: <
      T extends FetchKeys<ChangeEndDateMedicineInPlan>
    >(
      id: string,
      data: ChangeEndDateMedicineInPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChangeEndDateMedicineInPlan, T>, any>({
        path: `/health/medicine-plan/medicine-in-plan/${id}/change-end-date/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicineInPlanGetWarningsChangeEndDateCreate
     * @request POST:/health/medicine-plan/medicine-in-plan/{id}/get-warnings-change-end-date/
     */ healthMedicinePlanMedicineInPlanGetWarningsChangeEndDateCreate: <
      T extends FetchKeys<ChangeEndDateMedicineInPlan>
    >(
      id: string,
      data: ChangeEndDateMedicineInPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChangeEndDateMedicineInPlan, T>, any>({
        path: `/health/medicine-plan/medicine-in-plan/${id}/get-warnings-change-end-date/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicinePlanList
     * @request GET:/health/medicine-plan/medicine-plan/
     */ healthMedicinePlanMedicinePlanList: <
      T extends FetchKeys<PaginatedMedicinePlanList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        sick_report?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMedicinePlanList, T>, any>({
        path: `/health/medicine-plan/medicine-plan/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicinePlanCreate
     * @request POST:/health/medicine-plan/medicine-plan/
     */ healthMedicinePlanMedicinePlanCreate: <
      T extends FetchKeys<MedicinePlan>
    >(
      data: MedicinePlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicinePlan, T>, any>({
        path: `/health/medicine-plan/medicine-plan/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicineRetrieve
     * @request GET:/health/medicine/{id}/
     */ healthMedicineRetrieve: <T extends FetchKeys<Medicine>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Medicine, T>, any>({
        path: `/health/medicine/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  images = {}
  importer = {
    /**
     * No description
     *
     * @tags importer
     * @name ImporterCourseConstraintsRetrieve
     * @request GET:/importer/course-constraints/
     */
    importerCourseConstraintsRetrieve: <T extends FetchKeys<string>>(
      query: {
        /**
         * * `ccu_ath` - ccu_ath
         * * `acd_add` - acd_add
         * @minLength 1
         */
        export_type: 'ccu_ath' | 'acd_add'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<string, T>, any>({
        path: `/importer/course-constraints/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags importer
     * @name ImporterCourseConstraintsCreate
     * @request POST:/importer/course-constraints/
     */ importerCourseConstraintsCreate: <
      T extends FetchKeys<ComparisonTableRow[]>
    >(
      data: BaseCSVFileRequest,
      query?: {
        /** @default true */
        return_only_changed?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ComparisonTableRow[], T>, any>({
        path: `/importer/course-constraints/`,
        method: 'POST',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags importer
     * @name ImporterCourseConstraintsApplyCreate
     * @request POST:/importer/course-constraints/apply/
     */ importerCourseConstraintsApplyCreate: <
      T extends FetchKeys<ApplyChanges>
    >(
      data: ComparisonTableRowRequest[],
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ApplyChanges, T>, any>({
        path: `/importer/course-constraints/apply/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  infoboards = {
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsList
     * @request GET:/infoboards/boards/
     */
    infoboardsBoardsList: <T extends FetchKeys<PaginatedAdminBoardList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /**
         * * `unpublished` - Unpublished
         * * `published` - Published
         * * `scheduled` - Scheduled
         * * `draft` - Draft
         */
        status?: 'draft' | 'published' | 'scheduled' | 'unpublished'
        widget_type?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminBoardList, T>, any>({
        path: `/infoboards/boards/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsCreate
     * @request POST:/infoboards/boards/
     */ infoboardsBoardsCreate: <T extends FetchKeys<AdminBoard>>(
      data: AdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsRetrieve
     * @request GET:/infoboards/boards/{id}/
     */ infoboardsBoardsRetrieve: <T extends FetchKeys<AdminBoard>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsUpdate
     * @request PUT:/infoboards/boards/{id}/
     */ infoboardsBoardsUpdate: <T extends FetchKeys<AdminBoard>>(
      id: string,
      data: AdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsPartialUpdate
     * @request PATCH:/infoboards/boards/{id}/
     */ infoboardsBoardsPartialUpdate: <T extends FetchKeys<AdminBoard>>(
      id: string,
      data: PatchedAdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsDestroy
     * @request DELETE:/infoboards/boards/{id}/
     */ infoboardsBoardsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/infoboards/boards/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsPublishUpdate
     * @request PUT:/infoboards/boards/{id}/publish/
     */ infoboardsBoardsPublishUpdate: <T extends FetchKeys<PublishBoard>>(
      id: string,
      data: PublishBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublishBoard, T>, any>({
        path: `/infoboards/boards/${id}/publish/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsPublishPartialUpdate
     * @request PATCH:/infoboards/boards/{id}/publish/
     */ infoboardsBoardsPublishPartialUpdate: <
      T extends FetchKeys<PublishBoard>
    >(
      id: string,
      data: PatchedPublishBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublishBoard, T>, any>({
        path: `/infoboards/boards/${id}/publish/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsTogglePublishCreate
     * @request POST:/infoboards/boards/{id}/toggle-publish/
     */ infoboardsBoardsTogglePublishCreate: <T extends FetchKeys<AdminBoard>>(
      id: string,
      data: AdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/${id}/toggle-publish/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsUnpublishCreate
     * @request POST:/infoboards/boards/{id}/unpublish/
     */ infoboardsBoardsUnpublishCreate: <T extends FetchKeys<TogglePublish>>(
      id: string,
      data: TogglePublishRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TogglePublish, T>, any>({
        path: `/infoboards/boards/${id}/unpublish/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsAutomatedWidgetsStatusList
     * @request GET:/infoboards/boards/automated-widgets-status/
     */ infoboardsBoardsAutomatedWidgetsStatusList: <
      T extends FetchKeys<AutomatedWidgetsStatus[]>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `unpublished` - Unpublished
         * * `published` - Published
         * * `scheduled` - Scheduled
         * * `draft` - Draft
         */
        status?: 'draft' | 'published' | 'scheduled' | 'unpublished'
        widget_type?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AutomatedWidgetsStatus[], T>, any>({
        path: `/infoboards/boards/automated-widgets-status/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsChangeOrderCreate
     * @request POST:/infoboards/boards/change-order/
     */ infoboardsBoardsChangeOrderCreate: <T extends FetchKeys<ChangeOrder>>(
      data: ChangeOrderRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChangeOrder, T>, any>({
        path: `/infoboards/boards/change-order/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsCountRetrieve
     * @request GET:/infoboards/boards/count/
     */ infoboardsBoardsCountRetrieve: <T extends FetchKeys<AdminBoard>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `unpublished` - Unpublished
         * * `published` - Published
         * * `scheduled` - Scheduled
         * * `draft` - Draft
         */
        status?: 'draft' | 'published' | 'scheduled' | 'unpublished'
        widget_type?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/count/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsDraftsList
     * @request GET:/infoboards/boards/drafts/
     */ infoboardsBoardsDraftsList: <
      T extends FetchKeys<PaginatedAdminBoardDraftListList>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminBoardDraftListList, T>, any>({
        path: `/infoboards/boards/drafts/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsDraftsCreate
     * @request POST:/infoboards/boards/drafts/
     */ infoboardsBoardsDraftsCreate: <T extends FetchKeys<AdminBoardDraft>>(
      data: AdminBoardDraftRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoardDraft, T>, any>({
        path: `/infoboards/boards/drafts/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsDraftsRetrieve
     * @request GET:/infoboards/boards/drafts/{id}/
     */ infoboardsBoardsDraftsRetrieve: <T extends FetchKeys<AdminBoardDraft>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoardDraft, T>, any>({
        path: `/infoboards/boards/drafts/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsDraftsUpdate
     * @request PUT:/infoboards/boards/drafts/{id}/
     */ infoboardsBoardsDraftsUpdate: <T extends FetchKeys<AdminBoardDraft>>(
      id: string,
      data: AdminBoardDraftRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoardDraft, T>, any>({
        path: `/infoboards/boards/drafts/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsDraftsPartialUpdate
     * @request PATCH:/infoboards/boards/drafts/{id}/
     */ infoboardsBoardsDraftsPartialUpdate: <
      T extends FetchKeys<AdminBoardDraft>
    >(
      id: string,
      data: PatchedAdminBoardDraftRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoardDraft, T>, any>({
        path: `/infoboards/boards/drafts/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsDraftsDestroy
     * @request DELETE:/infoboards/boards/drafts/{id}/
     */ infoboardsBoardsDraftsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/infoboards/boards/drafts/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsPublishMultipleCreate
     * @request POST:/infoboards/boards/publish-multiple/
     */ infoboardsBoardsPublishMultipleCreate: <
      T extends FetchKeys<AdminBoard>
    >(
      data: AdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/publish-multiple/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsUnpublishMultipleCreate
     * @request POST:/infoboards/boards/unpublish-multiple/
     */ infoboardsBoardsUnpublishMultipleCreate: <
      T extends FetchKeys<AdminBoard>
    >(
      data: AdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/unpublish-multiple/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentList
     * @request GET:/infoboards/student-content/
     */ infoboardsStudentContentList: <
      T extends FetchKeys<PaginatedAdminStudentContentList>
    >(
      query?: {
        /**
         * Ordering
         *
         * * `created` - Created
         * * `-created` - Created (descending)
         * * `modified` - Modified
         * * `-modified` - Modified (descending)
         * * `published_at` - Published at
         * * `-published_at` - Published at (descending)
         * * `display_to` - Display to
         * * `-display_to` - Display to (descending)
         * * `status` - Status
         * * `-status` - Status (descending)
         */
        ordering?: (
          | '-created'
          | '-display_to'
          | '-modified'
          | '-published_at'
          | '-status'
          | 'created'
          | 'display_to'
          | 'modified'
          | 'published_at'
          | 'status'
        )[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /**
         * * `pending` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `deleted` - Deleted
         * * `archived` - Archived
         */
        status?: 'approved' | 'archived' | 'deleted' | 'pending' | 'rejected'
        /**
         * * `StudentMysteryLink` - None
         * * `StudentAnnouncement` - None
         * * `StudentPic` - None
         */
        student_content_type?:
          | 'StudentAnnouncement'
          | 'StudentMysteryLink'
          | 'StudentPic'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminStudentContentList, T>, any>({
        path: `/infoboards/student-content/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentRetrieve
     * @request GET:/infoboards/student-content/{id}/
     */ infoboardsStudentContentRetrieve: <
      T extends FetchKeys<AdminStudentContent>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminStudentContent, T>, any>({
        path: `/infoboards/student-content/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentDestroy
     * @request DELETE:/infoboards/student-content/{id}/
     */ infoboardsStudentContentDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/infoboards/student-content/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentApprovePartialUpdate
     * @request PATCH:/infoboards/student-content/{id}/approve/
     */ infoboardsStudentContentApprovePartialUpdate: <
      T extends FetchKeys<ApproveStudentContent>
    >(
      id: string,
      data: PatchedApproveStudentContentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ApproveStudentContent, T>, any>({
        path: `/infoboards/student-content/${id}/approve/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentRejectPartialUpdate
     * @request PATCH:/infoboards/student-content/{id}/reject/
     */ infoboardsStudentContentRejectPartialUpdate: <
      T extends FetchKeys<AdminStudentContent>
    >(
      id: string,
      data: PatchedAdminStudentContentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminStudentContent, T>, any>({
        path: `/infoboards/student-content/${id}/reject/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentPendingItemsRetrieve
     * @request GET:/infoboards/student-content/pending-items/
     */ infoboardsStudentContentPendingItemsRetrieve: <
      T extends FetchKeys<AdminStudentContent>
    >(
      query?: {
        /**
         * Ordering
         *
         * * `created` - Created
         * * `-created` - Created (descending)
         * * `modified` - Modified
         * * `-modified` - Modified (descending)
         * * `published_at` - Published at
         * * `-published_at` - Published at (descending)
         * * `display_to` - Display to
         * * `-display_to` - Display to (descending)
         * * `status` - Status
         * * `-status` - Status (descending)
         */
        ordering?: (
          | '-created'
          | '-display_to'
          | '-modified'
          | '-published_at'
          | '-status'
          | 'created'
          | 'display_to'
          | 'modified'
          | 'published_at'
          | 'status'
        )[]
        /** A search term. */
        search?: string
        /**
         * * `pending` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `deleted` - Deleted
         * * `archived` - Archived
         */
        status?: 'approved' | 'archived' | 'deleted' | 'pending' | 'rejected'
        /**
         * * `StudentMysteryLink` - None
         * * `StudentAnnouncement` - None
         * * `StudentPic` - None
         */
        student_content_type?:
          | 'StudentAnnouncement'
          | 'StudentMysteryLink'
          | 'StudentPic'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminStudentContent, T>, any>({
        path: `/infoboards/student-content/pending-items/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsList
     * @request GET:/infoboards/widgets/
     */ infoboardsWidgetsList: <T extends FetchKeys<PaginatedAdminWidgetList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        polymorphic_ctype?: number
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminWidgetList, T>, any>({
        path: `/infoboards/widgets/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsCreate
     * @request POST:/infoboards/widgets/
     */ infoboardsWidgetsCreate: <T extends FetchKeys<AdminWidget>>(
      data: AdminWidgetRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminWidget, T>, any>({
        path: `/infoboards/widgets/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsRetrieve
     * @request GET:/infoboards/widgets/{id}/
     */ infoboardsWidgetsRetrieve: <T extends FetchKeys<AdminWidget>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminWidget, T>, any>({
        path: `/infoboards/widgets/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsUpdate
     * @request PUT:/infoboards/widgets/{id}/
     */ infoboardsWidgetsUpdate: <T extends FetchKeys<AdminWidget>>(
      id: string,
      data: AdminWidgetRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminWidget, T>, any>({
        path: `/infoboards/widgets/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsPartialUpdate
     * @request PATCH:/infoboards/widgets/{id}/
     */ infoboardsWidgetsPartialUpdate: <T extends FetchKeys<AdminWidget>>(
      id: string,
      data: PatchedAdminWidgetRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminWidget, T>, any>({
        path: `/infoboards/widgets/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsDestroy
     * @request DELETE:/infoboards/widgets/{id}/
     */ infoboardsWidgetsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/infoboards/widgets/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsAvailableRetrieve
     * @request GET:/infoboards/widgets/available/
     */ infoboardsWidgetsAvailableRetrieve: <T extends FetchKeys<AdminWidget>>(
      query?: {
        polymorphic_ctype?: number
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminWidget, T>, any>({
        path: `/infoboards/widgets/available/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsTypesList
     * @request GET:/infoboards/widgets/types/
     */ infoboardsWidgetsTypesList: <T extends FetchKeys<AdminTypes[]>>(
      query?: {
        polymorphic_ctype?: number
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTypes[], T>, any>({
        path: `/infoboards/widgets/types/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  internships = {
    /**
     * No description
     *
     * @tags internships
     * @name InternshipsList
     * @request GET:/internships/
     */
    internshipsList: <T extends FetchKeys<PaginatedInternshipList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedInternshipList, T>, any>({
        path: `/internships/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags internships
     * @name InternshipsRetrieve
     * @request GET:/internships/{id}/
     */ internshipsRetrieve: <T extends FetchKeys<Internship>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Internship, T>, any>({
        path: `/internships/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags internships
     * @name InternshipsStudentsList
     * @request GET:/internships/students/{studentId}/
     */ internshipsStudentsList: <
      T extends FetchKeys<PaginatedStudentInternshipList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentInternshipList, T>, any>({
        path: `/internships/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags internships
     * @name InternshipsStudentsUpdate
     * @request PUT:/internships/students/{studentId}/
     */ internshipsStudentsUpdate: <
      T extends FetchKeys<StudentInternshipsUpdate>
    >(
      studentId: string,
      data: StudentInternshipsUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentInternshipsUpdate, T>, any>({
        path: `/internships/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags internships
     * @name InternshipsStudentsPartialUpdate
     * @request PATCH:/internships/students/{studentId}/
     */ internshipsStudentsPartialUpdate: <
      T extends FetchKeys<StudentInternshipsUpdate>
    >(
      studentId: string,
      data: PatchedStudentInternshipsUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentInternshipsUpdate, T>, any>({
        path: `/internships/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  kitchen = {
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMealAttendanceRetrieve
     * @request GET:/kitchen/meal-attendance/
     */
    kitchenMealAttendanceRetrieve: <
      T extends FetchKeys<MealAttendanceResponse>
    >(
      query?: {
        /** @format date */
        date?: string
        diet_type?: 'l1' | 'l2' | 'l3' | 'l4'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MealAttendanceResponse, T>, any>({
        path: `/kitchen/meal-attendance/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuList
     * @request GET:/kitchen/menu/
     */ kitchenMenuList: <T extends FetchKeys<PaginatedDayMenuList>>(
      query?: {
        /** @format date */
        date?: string
        is_filled?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        week?: number
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDayMenuList, T>, any>({
        path: `/kitchen/menu/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuCreate
     * @request POST:/kitchen/menu/
     */ kitchenMenuCreate: <T extends FetchKeys<DayMenu>>(
      data: DayMenuRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuRetrieve
     * @request GET:/kitchen/menu/{id}/
     */ kitchenMenuRetrieve: <T extends FetchKeys<DayMenu>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuUpdate
     * @request PUT:/kitchen/menu/{id}/
     */ kitchenMenuUpdate: <T extends FetchKeys<DayMenu>>(
      id: number,
      data: DayMenuRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuPartialUpdate
     * @request PATCH:/kitchen/menu/{id}/
     */ kitchenMenuPartialUpdate: <T extends FetchKeys<DayMenu>>(
      id: number,
      data: PatchedDayMenuRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuIsPublishedRetrieve
     * @request GET:/kitchen/menu/is-published/{week}/{year}/
     */ kitchenMenuIsPublishedRetrieve: <T extends FetchKeys<DayMenu>>(
      week: string,
      year: string,
      query?: {
        /** @format date */
        date?: string
        is_filled?: boolean
        week?: number
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/is-published/${week}/${year}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuPublishCreate
     * @request POST:/kitchen/menu/publish/{week}/{year}/
     */ kitchenMenuPublishCreate: <T extends FetchKeys<DayMenu>>(
      week: string,
      year: string,
      data: DayMenuRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/publish/${week}/${year}/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuUnpublishCreate
     * @request POST:/kitchen/menu/unpublish/{week}/{year}/
     */ kitchenMenuUnpublishCreate: <T extends FetchKeys<DayMenu>>(
      week: string,
      year: string,
      data: DayMenuRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/unpublish/${week}/${year}/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  leaves = {
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesChecksCreate
     * @request POST:/leaves/checks/
     */
    leavesChecksCreate: <T extends FetchKeys<LeaveCheck>>(
      data: LeaveCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveCheck, T>, any>({
        path: `/leaves/checks/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesChecksRetrieve
     * @request GET:/leaves/checks/{id}/
     */ leavesChecksRetrieve: <T extends FetchKeys<LeaveCheck>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveCheck, T>, any>({
        path: `/leaves/checks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesChecksUpdate
     * @request PUT:/leaves/checks/{id}/
     */ leavesChecksUpdate: <T extends FetchKeys<LeaveCheck>>(
      id: string,
      data: LeaveCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveCheck, T>, any>({
        path: `/leaves/checks/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesChecksPartialUpdate
     * @request PATCH:/leaves/checks/{id}/
     */ leavesChecksPartialUpdate: <T extends FetchKeys<LeaveCheck>>(
      id: string,
      data: PatchedLeaveCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveCheck, T>, any>({
        path: `/leaves/checks/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesChecksCompleteCreate
     * @request POST:/leaves/checks/{id}/complete/
     */ leavesChecksCompleteCreate: <T extends FetchKeys<LeaveCheckComplete>>(
      id: string,
      data: LeaveCheckCompleteRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveCheckComplete, T>, any>({
        path: `/leaves/checks/${id}/complete/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsList
     * @request GET:/leaves/requests/
     */ leavesRequestsList: <T extends FetchKeys<PaginatedLeaveRequestList>>(
      query?: {
        current_year_only?: boolean
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        house?: string
        is_completed?: string
        is_driver_needed?: boolean
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        show_todays?: boolean
        /**
         * * `tentative` - Pending parental
         * * `confirmed_contact_person` - Pending academic
         * * `confirmed_academic` - Pending boarding
         * * `changed` - Changed
         * * `confirmed` - Approved
         * * `declined` - Rejected
         * * `declined_contact_person` - Rejected by contact person
         * * `declined_academic` - Rejected by academic
         * * `canceled` - Canceled by student
         * * `expired` - Expired
         */
        status?:
          | 'canceled'
          | 'changed'
          | 'confirmed'
          | 'confirmed_academic'
          | 'confirmed_contact_person'
          | 'declined'
          | 'declined_academic'
          | 'declined_contact_person'
          | 'expired'
          | 'tentative'
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedLeaveRequestList, T>, any>({
        path: `/leaves/requests/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsCreate
     * @request POST:/leaves/requests/
     */ leavesRequestsCreate: <T extends FetchKeys<LeaveRequest>>(
      data: LeaveRequestRequest,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveRequest, T>, any>({
        path: `/leaves/requests/`,
        method: 'POST',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsRetrieve
     * @request GET:/leaves/requests/{id}/
     */ leavesRequestsRetrieve: <T extends FetchKeys<LeaveRequest>>(
      id: string,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveRequest, T>, any>({
        path: `/leaves/requests/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsUpdate
     * @request PUT:/leaves/requests/{id}/
     */ leavesRequestsUpdate: <T extends FetchKeys<LeaveRequest>>(
      id: string,
      data: LeaveRequestRequest,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveRequest, T>, any>({
        path: `/leaves/requests/${id}/`,
        method: 'PUT',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsPartialUpdate
     * @request PATCH:/leaves/requests/{id}/
     */ leavesRequestsPartialUpdate: <T extends FetchKeys<LeaveRequest>>(
      id: string,
      data: PatchedLeaveRequestRequest,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveRequest, T>, any>({
        path: `/leaves/requests/${id}/`,
        method: 'PATCH',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description This endpoint is deprecated. Please use /admin/students/clashes/
     *
     * @tags leaves
     * @name LeavesRequestsClashesRetrieve
     * @request GET:/leaves/requests/{id}/clashes/
     * @deprecated
     */ leavesRequestsClashesRetrieve: <T extends FetchKeys<StudentClashes>>(
      id: string,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentClashes, T>, any>({
        path: `/leaves/requests/${id}/clashes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsReviewByAcademicCreate
     * @request POST:/leaves/requests/{id}/review-by-academic/
     */ leavesRequestsReviewByAcademicCreate: <
      T extends FetchKeys<ReviewLeaveRequest>
    >(
      id: string,
      data: ReviewLeaveRequestRequest,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ReviewLeaveRequest, T>, any>({
        path: `/leaves/requests/${id}/review-by-academic/`,
        method: 'POST',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsReviewByBoardingCreate
     * @request POST:/leaves/requests/{id}/review-by-boarding/
     */ leavesRequestsReviewByBoardingCreate: <
      T extends FetchKeys<ReviewLeaveRequest>
    >(
      id: string,
      data: ReviewLeaveRequestRequest,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ReviewLeaveRequest, T>, any>({
        path: `/leaves/requests/${id}/review-by-boarding/`,
        method: 'POST',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsStatusesList
     * @request GET:/leaves/requests/statuses/
     */ leavesRequestsStatusesList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        current_year_only?: boolean
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        house?: string
        is_completed?: string
        is_driver_needed?: boolean
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
        show_todays?: boolean
        /**
         * * `tentative` - Pending parental
         * * `confirmed_contact_person` - Pending academic
         * * `confirmed_academic` - Pending boarding
         * * `changed` - Changed
         * * `confirmed` - Approved
         * * `declined` - Rejected
         * * `declined_contact_person` - Rejected by contact person
         * * `declined_academic` - Rejected by academic
         * * `canceled` - Canceled by student
         * * `expired` - Expired
         */
        status?:
          | 'canceled'
          | 'changed'
          | 'confirmed'
          | 'confirmed_academic'
          | 'confirmed_contact_person'
          | 'declined'
          | 'declined_academic'
          | 'declined_contact_person'
          | 'expired'
          | 'tentative'
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/leaves/requests/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  lessonCancellation = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags lesson-cancellation
     * @name LessonCancellationCancelLessonList
     * @request GET:/lesson-cancellation/cancel-lesson/
     */
    lessonCancellationCancelLessonList: <
      T extends FetchKeys<PaginatedCancelLessonList>
    >(
      query?: {
        /**
         * * `TEACHER_HAS_EXAM` - Artisan has exam
         * * `COVER_TEACHER_HAS_EXAM` - Cover artisan has exam
         * * `TEACHER_HAS_SCHOOL_PROGRAMME` - Artisan has school programme
         * * `COVER_TEACHER_HAS_SCHOOL_PROGRAMME` - Cover artisan has school programme
         * * `ALL_STUDENTS_CANCELLED` - All students have cancelled lesson
         * * `SCHOOL_BREAK_PERIOD` - Lesson is cancelled due to school's break
         */
        cancellation_reason?:
          | 'ALL_STUDENTS_CANCELLED'
          | 'COVER_TEACHER_HAS_EXAM'
          | 'COVER_TEACHER_HAS_SCHOOL_PROGRAMME'
          | 'SCHOOL_BREAK_PERIOD'
          | 'TEACHER_HAS_EXAM'
          | 'TEACHER_HAS_SCHOOL_PROGRAMME'
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number
        /** A search term. */
        search?: string
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCancelLessonList, T>, any>({
        path: `/lesson-cancellation/cancel-lesson/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags lesson-cancellation
     * @name LessonCancellationCancelLessonRetrieve
     * @request GET:/lesson-cancellation/cancel-lesson/{id}/
     */ lessonCancellationCancelLessonRetrieve: <
      T extends FetchKeys<CancelLesson>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CancelLesson, T>, any>({
        path: `/lesson-cancellation/cancel-lesson/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags lesson-cancellation
     * @name LessonCancellationCancelLessonAvailableFiltersRetrieve
     * @request GET:/lesson-cancellation/cancel-lesson/available-filters/
     */ lessonCancellationCancelLessonAvailableFiltersRetrieve: <
      T extends FetchKeys<CancelLesson>
    >(
      query?: {
        /**
         * * `TEACHER_HAS_EXAM` - Artisan has exam
         * * `COVER_TEACHER_HAS_EXAM` - Cover artisan has exam
         * * `TEACHER_HAS_SCHOOL_PROGRAMME` - Artisan has school programme
         * * `COVER_TEACHER_HAS_SCHOOL_PROGRAMME` - Cover artisan has school programme
         * * `ALL_STUDENTS_CANCELLED` - All students have cancelled lesson
         * * `SCHOOL_BREAK_PERIOD` - Lesson is cancelled due to school's break
         */
        cancellation_reason?:
          | 'ALL_STUDENTS_CANCELLED'
          | 'COVER_TEACHER_HAS_EXAM'
          | 'COVER_TEACHER_HAS_SCHOOL_PROGRAMME'
          | 'SCHOOL_BREAK_PERIOD'
          | 'TEACHER_HAS_EXAM'
          | 'TEACHER_HAS_SCHOOL_PROGRAMME'
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        quarter?: number
        /** A search term. */
        search?: string
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CancelLesson, T>, any>({
        path: `/lesson-cancellation/cancel-lesson/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  logs = {
    /**
     * No description
     *
     * @tags logs
     * @name LogsList
     * @request GET:/logs/
     */
    logsList: <T extends FetchKeys<PaginatedLogEntryList>>(
      query?: {
        /**
         * * `0` - create
         * * `1` - update
         * * `2` - delete
         */
        action?: '0' | '1' | '2' | null
        /** @format uuid */
        actor?: string
        content_type?: number
        /**
         * * `auth.permission` - permission
         * * `auth.group` - group
         * * `contenttypes.contenttype` - contenttype
         * * `flatpages.flatpage` - flatpage
         * * `sessions.session` - session
         * * `sites.site` - site
         * * `authtoken.token` - token
         * * `axes.accessattempt` - accessattempt
         * * `axes.accesslog` - accesslog
         * * `gdpr_logs.log` - log
         * * `users.user` - user
         * * `emails.emailmessagestored` - emailmessagestored
         * * `core.sitesettings` - sitesettings
         * * `images.image` - image
         * * `periods.semester` - semester
         * * `aims.aim` - aim
         * * `curriculum.gradelevel` - gradelevel
         * * `departments.department` - department
         * * `rooms.room` - room
         * * `courses.courselevel` - courselevel
         * * `courses.coursetype` - coursetype
         * * `courses.course` - course
         * * `students.student` - student
         * * `teachers.teacher` - teacher
         * * `sections.section` - section
         * * `sections.studentsectionenrollmentlog` - studentsectionenrollmentlog
         * * `activities.activity` - activity
         * * `activities.lesson` - lesson
         * * `assignments.assignment` - assignment
         * * `assignments.assignmenttype` - assignmenttype
         * * `assignments.studentassignmentitem` - studentassignmentitem
         * * `surveys.surveyresponse` - surveyresponse
         * * `webhooks.webhooklog` - webhooklog
         * * `importing.celerytaskstatus` - celerytaskstatus
         * * `aims.studentaimforcourse` - studentaimforcourse
         * * `importing.importsettings` - importsettings
         * * `aims.commenthistory` - commenthistory
         * * `periods.schoolyear` - schoolyear
         * * `periods.quarter` - quarter
         * * `importing.importedtimetable` - importedtimetable
         * * `two_factor.onetimecode` - onetimecode
         * * `merits.merit` - merit
         * * `courses.weeklycomment` - weeklycomment
         * * `grades.accgrade` - accgrade
         * * `grades.accgradecommenthistory` - accgradecommenthistory
         * * `courses_meta.coursefamily` - coursefamily
         * * `courses_meta.coursegroup` - coursegroup
         * * `courses_meta.courseintelligence` - courseintelligence
         * * `courses_meta.courselevel` - courselevel
         * * `courses_meta.coursesubject` - coursesubject
         * * `courses_meta.coursetype` - coursetype
         * * `attendances.absence` - absence
         * * `aims.studentdefaultaim` - studentdefaultaim
         * * `authtoken.tokenproxy` - tokenproxy
         * * `courses.previousnextcourse` - previousnextcourse
         * * `teachers.academichod` - academichod
         * * `timetable.timetable` - timetable
         * * `timetable.sourceidpplan` - sourceidpplan
         * * `permissions.custompermissions` - custompermissions
         * * `remote_generator.remoteserver` - remoteserver
         * * `remote_generator.generationtask` - generationtask
         * * `draft_activities.draftactivity` - draftactivity
         * * `potential_optimizations.potentialoptimization` - potentialoptimization
         * * `sections.sectionenrollment` - sectionenrollment
         * * `logs.timetablelog` - timetablelog
         * * `students_idp.basket` - basket
         * * `students_idp.courseinbasket` - courseinbasket
         * * `students_idp.requestedcourse` - requestedcourse
         * * `students_idp.studentidpplan` - studentidpplan
         * * `students_idp.parentwarning` - parentwarning
         * * `students_idp.examscore` - examscore
         * * `students_idp.idpsectionenrollment` - idpsectionenrollment
         * * `idp_admin.idpadmin` - idpadmin
         * * `admin_profile.admin` - admin
         * * `courses.courseenrollment` - courseenrollment
         * * `students_idp.idpplanpayloadhistory` - idpplanpayloadhistory
         * * `students_idp.idpplanstatushistory` - idpplanstatushistory
         * * `students_idp.idpsectionenrollmenthistory` - idpsectionenrollmenthistory
         * * `teachers.academicadmin` - academicadmin
         * * `profiles.boardingdirector` - boardingdirector
         * * `profiles.healthcoordinator` - healthcoordinator
         * * `reversion.revision` - revision
         * * `reversion.version` - version
         * * `students.studentgradelevelchange` - studentgradelevelchange
         * * `mission_control.competency` - competency
         * * `mission_control.degreeaim` - degreeaim
         * * `mission_control.interest` - interest
         * * `mission_control.skill` - skill
         * * `mission_control.missioncontrol` - missioncontrol
         * * `mission_control.taeexperience` - taeexperience
         * * `mission_control.taeproject` - taeproject
         * * `mission_control.studenttaeexperience` - studenttaeexperience
         * * `achievements.achievement` - achievement
         * * `achievements.studentachievement` - studentachievement
         * * `internships.internship` - internship
         * * `internships.studentinternship` - studentinternship
         * * `attachments.certificateattachment` - certificateattachment
         * * `attachments.examcertificateattachment` - examcertificateattachment
         * * `exams.exam` - exam
         * * `exams.transcript` - transcript
         * * `attendances.academicattendance` - academicattendance
         * * `attendances.academicattendancealert` - academicattendancealert
         * * `requirements.importedstudentcreditshistory` - importedstudentcreditshistory
         * * `requirements.importedcredit` - importedcredit
         * * `requirements.coursegraderequirement` - coursegraderequirement
         * * `leaves.leaverequest` - leaverequest
         * * `leaves.leaverequesthistoryitem` - leaverequesthistoryitem
         * * `leaves.leavecheck` - leavecheck
         * * `boarding_attendances.boardingattendance` - boardingattendance
         * * `boarding_attendances.boardingattendancereport` - boardingattendancereport
         * * `houses.house` - house
         * * `houses.studenthouse` - studenthouse
         * * `houses.houseroom` - houseroom
         * * `boarding_night_attendances.nightguard` - nightguard
         * * `boarding_night_attendances.nightattendancereport` - nightattendancereport
         * * `boarding_night_attendances.nightattendance` - nightattendance
         * * `sick_reports.firstcontact` - firstcontact
         * * `sick_reports.medicineplan` - medicineplan
         * * `sick_reports.sickstudentcheck` - sickstudentcheck
         * * `sick_reports.sickreport` - sickreport
         * * `doctors.doctor` - doctor
         * * `allergies.studentallergy` - studentallergy
         * * `user_preferences.favouritestudentsviewsetting` - favouritestudentsviewsetting
         * * `boarding_night_attendances.nightattendancecomment` - nightattendancecomment
         * * `discipline.studentcategoryreport` - studentcategoryreport
         * * `discipline.studentcategoryitem` - studentcategoryitem
         * * `sick_reports.sickreportcomment` - sickreportcomment
         * * `medicine.medicine` - medicine
         * * `medicine_plan.medicineinplan` - medicineinplan
         * * `medicine_plan.medicineplan` - medicineplan
         * * `medicine_plan.medicineintakeplan` - medicineintakeplan
         * * `managers.housetutor` - housetutor
         * * `chronic_conditions.studentchroniccondition` - studentchroniccondition
         * * `sick_reports.appointment` - appointment
         * * `draft_activities.talentenrichmentcopy` - talentenrichmentcopy
         * * `mentorings.mentoringsections` - mentoringsections
         * * `mentorings.timetablementoring` - timetablementoring
         * * `mentorings.teacherspergrade` - teacherspergrade
         * * `scheduled_medicine.scheduledmedicine` - scheduledmedicine
         * * `health_checks.healthcheck` - healthcheck
         * * `health_checks.temperaturecheck` - temperaturecheck
         * * `health_checks.optionalmedicinegiven` - optionalmedicinegiven
         * * `health_checks.healthchecklog` - healthchecklog
         * * `health_checks.healthcheckcomment` - healthcheckcomment
         * * `health_checks.foodgive` - foodgive
         * * `health_checks.drinkgive` - drinkgive
         * * `health_checks.condition` - condition
         * * `health_checks.medicinegiven` - medicinegiven
         * * `sick_reports.sickreportabsencedates` - sickreportabsencedates
         * * `boarding_accommodations.accommodation` - accommodation
         * * `boarding_comments.boardingcomment` - boardingcomment
         * * `core.calendarevent` - calendarevent
         * * `attendances.academicattendancehistory` - academicattendancehistory
         * * `teams.team` - team
         * * `teams.teammember` - teammember
         * * `profiles.campusheadteam` - campusheadteam
         * * `billings.billingreport` - billingreport
         * * `billings.billingreportitem` - billingreportitem
         * * `campus_absences.campusabsence` - campusabsence
         * * `campus_absences.campusabsencestudent` - campusabsencestudent
         * * `campus_absences.campusabsencecomment` - campusabsencecomment
         * * `progress_comments.achievementandeffortprogresscomment` - achievementandeffortprogresscomment
         * * `progress_comments.behaviourandattitudeprogresscomment` - behaviourandattitudeprogresscomment
         * * `progress_comments.interventionprogresscomment` - interventionprogresscomment
         * * `progress_comments.studenttargetprogresscomment` - studenttargetprogresscomment
         * * `progress_comments.progresscomment` - progresscomment
         * * `courses.groupedcourses` - groupedcourses
         * * `hospitals.hospital` - hospital
         * * `merits.merititem` - merititem
         * * `merits.deescalatedsanction` - deescalatedsanction
         * * `merits.demeritemailnotification` - demeritemailnotification
         * * `profiles.headofhouse` - headofhouse
         * * `tasks_management.recurring` - recurring
         * * `tasks_management.task` - task
         * * `tasks_management.usertasktitle` - usertasktitle
         * * `tasks_management.tasklog` - tasklog
         * * `tasks_management.subtask` - subtask
         * * `profiles.viewer` - viewer
         * * `files_for_students.fileforstudents` - fileforstudents
         * * `allergies.studentallergyreaction` - studentallergyreaction
         * * `attachments.privateattachment` - privateattachment
         * * `student_applications.studentapplication` - studentapplication
         * * `student_applications.studentapplicationmedication` - studentapplicationmedication
         * * `knox.authtoken` - authtoken
         * * `menu.daymenu` - daymenu
         * * `favourite_widgets.favouritewidget` - favouritewidget
         * * `students.studentcontactperson` - studentcontactperson
         * * `notifications.notification` - notification
         * * `push.registereddevice` - registereddevice
         * * `push.pushnotification` - pushnotification
         * * `email.emailnotification` - emailnotification
         * * `db.testmodel` - testmodel
         * * `exams.studentexternalexam` - studentexternalexam
         * * `school_breaks.schoolbreak` - schoolbreak
         * * `exam_planner.examsession` - examsession
         * * `exam_planner.examsessioncancellationdate` - examsessioncancellationdate
         * * `exam_planner.exam` - exam
         * * `exam_planner.examstudentnote` - examstudentnote
         * * `exam_planner.examenrolledstudent` - examenrolledstudent
         * * `exam_planner.examattendancehistory` - examattendancehistory
         * * `exam_clashes.examclash` - examclash
         * * `lesson_cancellation.cancellesson` - cancellesson
         * * `lesson_cancellation.cancelstudentinlesson` - cancelstudentinlesson
         * * `social_django.association` - association
         * * `social_django.code` - code
         * * `social_django.nonce` - nonce
         * * `social_django.usersocialauth` - usersocialauth
         * * `social_django.partial` - partial
         * * `core.appstatusversion` - appstatusversion
         * * `sick_reports.sickreportclosurerequest` - sickreportclosurerequest
         * * `auditlog.logentry` - logentry
         * * `profiles.propertymanager` - propertymanager
         * * `billings.abacusinvoiceproduct` - abacusinvoiceproduct
         * * `attachments.schoolprogrammeattachment` - schoolprogrammeattachment
         * * `school_programmes.programmeenrolment` - programmeenrolment
         * * `school_programmes.schoolprogramme` - schoolprogramme
         * * `programme_attendances.programmeattendancereport` - programmeattendancereport
         * * `programme_attendances.programmestudentattendance` - programmestudentattendance
         * * `programme_attendances.programmeattendancehistory` - programmeattendancehistory
         * * `meal_preferences.studentmealpreference` - studentmealpreference
         * * `meal_attendance.mealattendancelastupdate` - mealattendancelastupdate
         * * `profiles.infoboardeditor` - infoboardeditor
         * * `images.catalog` - catalog
         * * `infoboards.infoboardssettings` - infoboardssettings
         * * `infoboards.board` - board
         * * `infoboards.studentcontent` - studentcontent
         * * `infoboards.message` - message
         * * `infoboards.recurring` - recurring
         * * `infoboards.boardschedule` - boardschedule
         * * `infoboards.updatestatus` - updatestatus
         * * `infoboards_links.linkwithqrcode` - linkwithqrcode
         * * `widgets_base.widget` - widget
         * * `widgets_base.widgetmetadata` - widgetmetadata
         * * `boarding_announcement_widget.boardingannouncementwidget` - boardingannouncementwidget
         * * `celebration_widget.celebrationwidget` - celebrationwidget
         * * `event_agenda_widget.eventagendawidget` - eventagendawidget
         * * `event_agenda_widget.agendaitem` - agendaitem
         * * `congratulations_widget.congratulationswidget` - congratulationswidget
         * * `poster_widget.posterwidget` - posterwidget
         * * `student_art_gallery_widget.studentartgallerywidget` - studentartgallerywidget
         * * `student_art_gallery_widget.studentart` - studentart
         * * `event_info_widget.eventinfowidget` - eventinfowidget
         * * `event_info_with_photo_widget.eventinfowithphotowidget` - eventinfowithphotowidget
         * * `journal_widget.journalwidget` - journalwidget
         * * `mystery_link_widget.studentmysterylink` - studentmysterylink
         * * `mystery_link_widget.mysterylinkwidget` - mysterylinkwidget
         * * `menu_widget.menuwidget` - menuwidget
         * * `students_announcement_widget.studentannouncement` - studentannouncement
         * * `students_announcement_widget.studentsannouncementwidget` - studentsannouncementwidget
         * * `students_pic_widget.studentpic` - studentpic
         * * `students_pic_widget.studentspicwidget` - studentspicwidget
         * * `remote_generator.remotegenerationprogress` - remotegenerationprogress
         * * `remote_generator.remotegenerationprocess` - remotegenerationprocess
         * * `remote_generator.remotetimetablepayload` - remotetimetablepayload
         * * `variant_manual_congratulations_widget.variantmanualcongratulations` - variantmanualcongratulations
         * * `variant_manual_congratulations_widget.variantmanualcongratulationswidget` - variantmanualcongratulationswidget
         * * `allergies.allergy` - allergy
         * * `chronic_conditions.chroniccondition` - chroniccondition
         * * `courses.courseenrollmenthistory` - courseenrollmenthistory
         * * `journal_widget.journalitem` - journalitem
         * * `importing.celerytaskstatushistory` - celerytaskstatushistory
         * * `room_bookings.roombooking` - roombooking
         * * `exam_planner.activestudentexamenrollment` - activestudentexamenrollment
         * * `remote_generator.bestsolutionfound` - bestsolutionfound
         * * `school_programmes.programmeretrievalbystudent` - programmeretrievalbystudent
         * * `recovery.recovery` - recovery
         * * `recovery.studentrecovery` - studentrecovery
         * * `recovery.studentrecoveryreason` - studentrecoveryreason
         * * `recovery.absencerecovery` - absencerecovery
         * * `recovery.lunchtimerecovery` - lunchtimerecovery
         * * `profiles.behaviourmanagement` - behaviourmanagement
         * * `grades.periodgpa` - periodgpa
         * * `grades.highschoolgpa` - highschoolgpa
         * * `grades.gpaforcourse` - gpaforcourse
         * * `rota.requiredshifts` - requiredshifts
         * * `rota.breakrequirements` - breakrequirements
         * * `rota.rotateammember` - rotateammember
         * * `rota.rotateam` - rotateam
         * * `rota.meetingrequirement` - meetingrequirement
         * * `rota.rotageneration` - rotageneration
         * * `reports.reportcard` - reportcard
         * * `parents.parent` - parent
         * * `axes.accessfailurelog` - accessfailurelog
         * * `attachments.internshipattachment` - internshipattachment
         * * `rota.enforcedshifts` - enforcedshifts
         * * `rota.rotatimetable` - rotatimetable
         * * `rota_activities.draftrotaactivity` - draftrotaactivity
         * * `rota_activities.rotaactivity` - rotaactivity
         * * `rota_activities.rota` - rota
         * * `merits_exchange.meritpoints` - meritpoints
         * * `merits_exchange.meritrewardparticipant` - meritrewardparticipant
         * * `merits_exchange.meritreward` - meritreward
         * * `merits_exchange.casualanyday` - casualanyday
         * * `merits_exchange.roomservice` - roomservice
         * * `merits_exchange.bookthecafe` - bookthecafe
         * * `merits_exchange.citybrunchzurich` - citybrunchzurich
         * * `merits_exchange.additionalausgang` - additionalausgang
         * * `merits_exchange.categoryupgrade` - categoryupgrade
         * * `merits_exchange.stoptheclock` - stoptheclock
         * * `contact.contactmessage` - contactmessage
         * * `rota.criticalduty` - criticalduty
         * * `rota.preferredtimeforbreak` - preferredtimeforbreak
         * * `rota.usertimeofday` - usertimeofday
         * * `rota.specialdaysoffrule` - specialdaysoffrule
         * * `sections.sectiongroup` - sectiongroup
         * * `clock_widget.clockwidget` - clockwidget
         * * `parents.parentstudentconnection` - parentstudentconnection
         * * `course_drops.coursedrop` - coursedrop
         * * `discipline.studentcategoryitemdowngradehistory` - studentcategoryitemdowngradehistory
         * * `rota.avoiduserchangeon` - avoiduserchangeon
         * * `rota.skitrip` - skitrip
         * * `rota.teambackup` - teambackup
         * * `profiles.securityteam` - securityteam
         * * `web_sockets.websocketauthtoken` - websocketauthtoken
         * * `rota.rotagenerationissue` - rotagenerationissue
         * * `congratulations_widget.congratulationsitem` - congratulationsitem
         * * `infoboards.reaction` - reaction
         * * `analytics.feedsurftime` - feedsurftime
         * * `analytics.feedday` - feedday
         * * `analytics.feedclick` - feedclick
         * * `celebration_widget.celebrationitem` - celebrationitem
         * * `summer_camps.summersubjectassign` - summersubjectassign
         * * `summer_camps.summerrosenberglegacy` - summerrosenberglegacy
         * * `summer_camps.summermentalhealthissue` - summermentalhealthissue
         * * `summer_camps.summercampstudent` - summercampstudent
         * * `web_sockets.websocketnotification` - websocketnotification
         * * `mission_control.taesportpackageitem` - taesportpackageitem
         * * `suggester.draftactivitysuggestertask` - draftactivitysuggestertask
         * * `suggester.draftactivitysuggestion` - draftactivitysuggestion
         * * `suggester.draftactivitysuggestionmove` - draftactivitysuggestionmove
         * * `rota.blockedslots` - blockedslots
         * * `rota.enforcedworkingdays` - enforcedworkingdays
         * * `rota.dislikedtimeforbreak` - dislikedtimeforbreak
         * * `summer_certificates.summercertificate` - summercertificate
         * * `summer_certificates.summercertificatecourseinfo` - summercertificatecourseinfo
         * * `summer_certificates.summercertificateattachment` - summercertificateattachment
         * * `summer_certificates.summercertificateszipped` - summercertificateszipped
         * * `auditlog.archivedauditlogentry` - archivedauditlogentry
         * * `talent_and_enrichment.taegeneratortask` - taegeneratortask
         * * `talent_and_enrichment.taedraftactivity` - taedraftactivity
         * * `courses.courseallowedslot` - courseallowedslot
         * * `sections.sectionallowedslot` - sectionallowedslot
         * * `transcripts_of_grades.transcriptofgrades` - transcriptofgrades
         * * `transcripts_of_grades.transcriptofgradesitem` - transcriptofgradesitem
         * * `transcripts_of_grades.transcriptofgradesitemcourse` - transcriptofgradesitemcourse
         * * `rooms.roomallowedslot` - roomallowedslot
         * * `departments.departmenthead` - departmenthead
         */
        content_type__in?: (
          | 'achievements.achievement'
          | 'achievements.studentachievement'
          | 'activities.activity'
          | 'activities.lesson'
          | 'admin_profile.admin'
          | 'aims.aim'
          | 'aims.commenthistory'
          | 'aims.studentaimforcourse'
          | 'aims.studentdefaultaim'
          | 'allergies.allergy'
          | 'allergies.studentallergy'
          | 'allergies.studentallergyreaction'
          | 'analytics.feedclick'
          | 'analytics.feedday'
          | 'analytics.feedsurftime'
          | 'assignments.assignment'
          | 'assignments.assignmenttype'
          | 'assignments.studentassignmentitem'
          | 'attachments.certificateattachment'
          | 'attachments.examcertificateattachment'
          | 'attachments.internshipattachment'
          | 'attachments.privateattachment'
          | 'attachments.schoolprogrammeattachment'
          | 'attendances.absence'
          | 'attendances.academicattendance'
          | 'attendances.academicattendancealert'
          | 'attendances.academicattendancehistory'
          | 'auditlog.archivedauditlogentry'
          | 'auditlog.logentry'
          | 'auth.group'
          | 'auth.permission'
          | 'authtoken.token'
          | 'authtoken.tokenproxy'
          | 'axes.accessattempt'
          | 'axes.accessfailurelog'
          | 'axes.accesslog'
          | 'billings.abacusinvoiceproduct'
          | 'billings.billingreport'
          | 'billings.billingreportitem'
          | 'boarding_accommodations.accommodation'
          | 'boarding_announcement_widget.boardingannouncementwidget'
          | 'boarding_attendances.boardingattendance'
          | 'boarding_attendances.boardingattendancereport'
          | 'boarding_comments.boardingcomment'
          | 'boarding_night_attendances.nightattendance'
          | 'boarding_night_attendances.nightattendancecomment'
          | 'boarding_night_attendances.nightattendancereport'
          | 'boarding_night_attendances.nightguard'
          | 'campus_absences.campusabsence'
          | 'campus_absences.campusabsencecomment'
          | 'campus_absences.campusabsencestudent'
          | 'celebration_widget.celebrationitem'
          | 'celebration_widget.celebrationwidget'
          | 'chronic_conditions.chroniccondition'
          | 'chronic_conditions.studentchroniccondition'
          | 'clock_widget.clockwidget'
          | 'congratulations_widget.congratulationsitem'
          | 'congratulations_widget.congratulationswidget'
          | 'contact.contactmessage'
          | 'contenttypes.contenttype'
          | 'core.appstatusversion'
          | 'core.calendarevent'
          | 'core.sitesettings'
          | 'course_drops.coursedrop'
          | 'courses.course'
          | 'courses.courseallowedslot'
          | 'courses.courseenrollment'
          | 'courses.courseenrollmenthistory'
          | 'courses.courselevel'
          | 'courses.coursetype'
          | 'courses.groupedcourses'
          | 'courses.previousnextcourse'
          | 'courses.weeklycomment'
          | 'courses_meta.coursefamily'
          | 'courses_meta.coursegroup'
          | 'courses_meta.courseintelligence'
          | 'courses_meta.courselevel'
          | 'courses_meta.coursesubject'
          | 'courses_meta.coursetype'
          | 'curriculum.gradelevel'
          | 'db.testmodel'
          | 'departments.department'
          | 'departments.departmenthead'
          | 'discipline.studentcategoryitem'
          | 'discipline.studentcategoryitemdowngradehistory'
          | 'discipline.studentcategoryreport'
          | 'doctors.doctor'
          | 'draft_activities.draftactivity'
          | 'draft_activities.talentenrichmentcopy'
          | 'email.emailnotification'
          | 'emails.emailmessagestored'
          | 'event_agenda_widget.agendaitem'
          | 'event_agenda_widget.eventagendawidget'
          | 'event_info_widget.eventinfowidget'
          | 'event_info_with_photo_widget.eventinfowithphotowidget'
          | 'exam_clashes.examclash'
          | 'exam_planner.activestudentexamenrollment'
          | 'exam_planner.exam'
          | 'exam_planner.examattendancehistory'
          | 'exam_planner.examenrolledstudent'
          | 'exam_planner.examsession'
          | 'exam_planner.examsessioncancellationdate'
          | 'exam_planner.examstudentnote'
          | 'exams.exam'
          | 'exams.studentexternalexam'
          | 'exams.transcript'
          | 'favourite_widgets.favouritewidget'
          | 'files_for_students.fileforstudents'
          | 'flatpages.flatpage'
          | 'gdpr_logs.log'
          | 'grades.accgrade'
          | 'grades.accgradecommenthistory'
          | 'grades.gpaforcourse'
          | 'grades.highschoolgpa'
          | 'grades.periodgpa'
          | 'health_checks.condition'
          | 'health_checks.drinkgive'
          | 'health_checks.foodgive'
          | 'health_checks.healthcheck'
          | 'health_checks.healthcheckcomment'
          | 'health_checks.healthchecklog'
          | 'health_checks.medicinegiven'
          | 'health_checks.optionalmedicinegiven'
          | 'health_checks.temperaturecheck'
          | 'hospitals.hospital'
          | 'houses.house'
          | 'houses.houseroom'
          | 'houses.studenthouse'
          | 'idp_admin.idpadmin'
          | 'images.catalog'
          | 'images.image'
          | 'importing.celerytaskstatus'
          | 'importing.celerytaskstatushistory'
          | 'importing.importedtimetable'
          | 'importing.importsettings'
          | 'infoboards.board'
          | 'infoboards.boardschedule'
          | 'infoboards.infoboardssettings'
          | 'infoboards.message'
          | 'infoboards.reaction'
          | 'infoboards.recurring'
          | 'infoboards.studentcontent'
          | 'infoboards.updatestatus'
          | 'infoboards_links.linkwithqrcode'
          | 'internships.internship'
          | 'internships.studentinternship'
          | 'journal_widget.journalitem'
          | 'journal_widget.journalwidget'
          | 'knox.authtoken'
          | 'leaves.leavecheck'
          | 'leaves.leaverequest'
          | 'leaves.leaverequesthistoryitem'
          | 'lesson_cancellation.cancellesson'
          | 'lesson_cancellation.cancelstudentinlesson'
          | 'logs.timetablelog'
          | 'managers.housetutor'
          | 'meal_attendance.mealattendancelastupdate'
          | 'meal_preferences.studentmealpreference'
          | 'medicine.medicine'
          | 'medicine_plan.medicineinplan'
          | 'medicine_plan.medicineintakeplan'
          | 'medicine_plan.medicineplan'
          | 'mentorings.mentoringsections'
          | 'mentorings.teacherspergrade'
          | 'mentorings.timetablementoring'
          | 'menu.daymenu'
          | 'menu_widget.menuwidget'
          | 'merits.deescalatedsanction'
          | 'merits.demeritemailnotification'
          | 'merits.merit'
          | 'merits.merititem'
          | 'merits_exchange.additionalausgang'
          | 'merits_exchange.bookthecafe'
          | 'merits_exchange.casualanyday'
          | 'merits_exchange.categoryupgrade'
          | 'merits_exchange.citybrunchzurich'
          | 'merits_exchange.meritpoints'
          | 'merits_exchange.meritreward'
          | 'merits_exchange.meritrewardparticipant'
          | 'merits_exchange.roomservice'
          | 'merits_exchange.stoptheclock'
          | 'mission_control.competency'
          | 'mission_control.degreeaim'
          | 'mission_control.interest'
          | 'mission_control.missioncontrol'
          | 'mission_control.skill'
          | 'mission_control.studenttaeexperience'
          | 'mission_control.taeexperience'
          | 'mission_control.taeproject'
          | 'mission_control.taesportpackageitem'
          | 'mystery_link_widget.mysterylinkwidget'
          | 'mystery_link_widget.studentmysterylink'
          | 'notifications.notification'
          | 'parents.parent'
          | 'parents.parentstudentconnection'
          | 'periods.quarter'
          | 'periods.schoolyear'
          | 'periods.semester'
          | 'permissions.custompermissions'
          | 'poster_widget.posterwidget'
          | 'potential_optimizations.potentialoptimization'
          | 'profiles.behaviourmanagement'
          | 'profiles.boardingdirector'
          | 'profiles.campusheadteam'
          | 'profiles.headofhouse'
          | 'profiles.healthcoordinator'
          | 'profiles.infoboardeditor'
          | 'profiles.propertymanager'
          | 'profiles.securityteam'
          | 'profiles.viewer'
          | 'programme_attendances.programmeattendancehistory'
          | 'programme_attendances.programmeattendancereport'
          | 'programme_attendances.programmestudentattendance'
          | 'progress_comments.achievementandeffortprogresscomment'
          | 'progress_comments.behaviourandattitudeprogresscomment'
          | 'progress_comments.interventionprogresscomment'
          | 'progress_comments.progresscomment'
          | 'progress_comments.studenttargetprogresscomment'
          | 'push.pushnotification'
          | 'push.registereddevice'
          | 'recovery.absencerecovery'
          | 'recovery.lunchtimerecovery'
          | 'recovery.recovery'
          | 'recovery.studentrecovery'
          | 'recovery.studentrecoveryreason'
          | 'remote_generator.bestsolutionfound'
          | 'remote_generator.generationtask'
          | 'remote_generator.remotegenerationprocess'
          | 'remote_generator.remotegenerationprogress'
          | 'remote_generator.remoteserver'
          | 'remote_generator.remotetimetablepayload'
          | 'reports.reportcard'
          | 'requirements.coursegraderequirement'
          | 'requirements.importedcredit'
          | 'requirements.importedstudentcreditshistory'
          | 'reversion.revision'
          | 'reversion.version'
          | 'room_bookings.roombooking'
          | 'rooms.room'
          | 'rooms.roomallowedslot'
          | 'rota.avoiduserchangeon'
          | 'rota.blockedslots'
          | 'rota.breakrequirements'
          | 'rota.criticalduty'
          | 'rota.dislikedtimeforbreak'
          | 'rota.enforcedshifts'
          | 'rota.enforcedworkingdays'
          | 'rota.meetingrequirement'
          | 'rota.preferredtimeforbreak'
          | 'rota.requiredshifts'
          | 'rota.rotageneration'
          | 'rota.rotagenerationissue'
          | 'rota.rotateam'
          | 'rota.rotateammember'
          | 'rota.rotatimetable'
          | 'rota.skitrip'
          | 'rota.specialdaysoffrule'
          | 'rota.teambackup'
          | 'rota.usertimeofday'
          | 'rota_activities.draftrotaactivity'
          | 'rota_activities.rota'
          | 'rota_activities.rotaactivity'
          | 'scheduled_medicine.scheduledmedicine'
          | 'school_breaks.schoolbreak'
          | 'school_programmes.programmeenrolment'
          | 'school_programmes.programmeretrievalbystudent'
          | 'school_programmes.schoolprogramme'
          | 'sections.section'
          | 'sections.sectionallowedslot'
          | 'sections.sectionenrollment'
          | 'sections.sectiongroup'
          | 'sections.studentsectionenrollmentlog'
          | 'sessions.session'
          | 'sick_reports.appointment'
          | 'sick_reports.firstcontact'
          | 'sick_reports.medicineplan'
          | 'sick_reports.sickreport'
          | 'sick_reports.sickreportabsencedates'
          | 'sick_reports.sickreportclosurerequest'
          | 'sick_reports.sickreportcomment'
          | 'sick_reports.sickstudentcheck'
          | 'sites.site'
          | 'social_django.association'
          | 'social_django.code'
          | 'social_django.nonce'
          | 'social_django.partial'
          | 'social_django.usersocialauth'
          | 'student_applications.studentapplication'
          | 'student_applications.studentapplicationmedication'
          | 'student_art_gallery_widget.studentart'
          | 'student_art_gallery_widget.studentartgallerywidget'
          | 'students.student'
          | 'students.studentcontactperson'
          | 'students.studentgradelevelchange'
          | 'students_announcement_widget.studentannouncement'
          | 'students_announcement_widget.studentsannouncementwidget'
          | 'students_idp.basket'
          | 'students_idp.courseinbasket'
          | 'students_idp.examscore'
          | 'students_idp.idpplanpayloadhistory'
          | 'students_idp.idpplanstatushistory'
          | 'students_idp.idpsectionenrollment'
          | 'students_idp.idpsectionenrollmenthistory'
          | 'students_idp.parentwarning'
          | 'students_idp.requestedcourse'
          | 'students_idp.studentidpplan'
          | 'students_pic_widget.studentpic'
          | 'students_pic_widget.studentspicwidget'
          | 'suggester.draftactivitysuggestertask'
          | 'suggester.draftactivitysuggestion'
          | 'suggester.draftactivitysuggestionmove'
          | 'summer_camps.summercampstudent'
          | 'summer_camps.summermentalhealthissue'
          | 'summer_camps.summerrosenberglegacy'
          | 'summer_camps.summersubjectassign'
          | 'summer_certificates.summercertificate'
          | 'summer_certificates.summercertificateattachment'
          | 'summer_certificates.summercertificatecourseinfo'
          | 'summer_certificates.summercertificateszipped'
          | 'surveys.surveyresponse'
          | 'talent_and_enrichment.taedraftactivity'
          | 'talent_and_enrichment.taegeneratortask'
          | 'tasks_management.recurring'
          | 'tasks_management.subtask'
          | 'tasks_management.task'
          | 'tasks_management.tasklog'
          | 'tasks_management.usertasktitle'
          | 'teachers.academicadmin'
          | 'teachers.academichod'
          | 'teachers.teacher'
          | 'teams.team'
          | 'teams.teammember'
          | 'timetable.sourceidpplan'
          | 'timetable.timetable'
          | 'transcripts_of_grades.transcriptofgrades'
          | 'transcripts_of_grades.transcriptofgradesitem'
          | 'transcripts_of_grades.transcriptofgradesitemcourse'
          | 'two_factor.onetimecode'
          | 'user_preferences.favouritestudentsviewsetting'
          | 'users.user'
          | 'variant_manual_congratulations_widget.variantmanualcongratulations'
          | 'variant_manual_congratulations_widget.variantmanualcongratulationswidget'
          | 'web_sockets.websocketauthtoken'
          | 'web_sockets.websocketnotification'
          | 'webhooks.webhooklog'
          | 'widgets_base.widget'
          | 'widgets_base.widgetmetadata'
        )[]
        object?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** @format date-time */
        timestamp_after?: string
        /** @format date-time */
        timestamp_before?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedLogEntryList, T>, any>({
        path: `/logs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags logs
     * @name LogsRetrieve
     * @request GET:/logs/{id}/
     */ logsRetrieve: <T extends FetchKeys<LogEntry>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LogEntry, T>, any>({
        path: `/logs/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  merits = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsList
     * @request GET:/merits/
     */
    meritsList: <T extends FetchKeys<PaginatedMeritList>>(
      query?: {
        academic_only?: boolean
        /** @format date */
        added_on?: string
        /** @format uuid */
        author?: string
        /** @format uuid */
        course?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** @format date-time */
        datetime_after?: string
        /** @format date-time */
        datetime_before?: string
        /** @format date-time */
        escalate_until_after?: string
        /** @format date-time */
        escalate_until_before?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        escalated_sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        house?: string
        merit_iso_year_and_week?: string
        merit_item?: number
        /**
         * * `merit` - Merit
         * * `demerit` - Demerit
         */
        merit_type?: 'demerit' | 'merit'
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        show_not_reviewed?: boolean
        /**
         * * `pending` - Pending
         * * `rejected` - Rejected
         * * `confirmed` - Approved
         * * `deescalated` - De-escalated
         */
        status?: 'confirmed' | 'deescalated' | 'pending' | 'rejected'
        /** @format uuid */
        student?: string
        /** @format float */
        value?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMeritList, T>, any>({
        path: `/merits/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsCreate
     * @request POST:/merits/
     */ meritsCreate: <T extends FetchKeys<Merit>>(
      data: MeritRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Merit, T>, any>({
        path: `/merits/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsRetrieve
     * @request GET:/merits/{id}/
     */ meritsRetrieve: <T extends FetchKeys<Merit>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Merit, T>, any>({
        path: `/merits/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsUpdate
     * @request PUT:/merits/{id}/
     */ meritsUpdate: <T extends FetchKeys<Merit>>(
      id: number,
      data: MeritRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Merit, T>, any>({
        path: `/merits/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsPartialUpdate
     * @request PATCH:/merits/{id}/
     */ meritsPartialUpdate: <T extends FetchKeys<Merit>>(
      id: number,
      data: PatchedMeritRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Merit, T>, any>({
        path: `/merits/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsDestroy
     * @request DELETE:/merits/{id}/
     */ meritsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/merits/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsConfirmUpdate
     * @request PUT:/merits/{id}/confirm/
     */ meritsConfirmUpdate: <T extends FetchKeys<MeritConfirm>>(
      id: number,
      data: MeritConfirmRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritConfirm, T>, any>({
        path: `/merits/${id}/confirm/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsDeescalateCreate
     * @request POST:/merits/{id}/deescalate/
     */ meritsDeescalateCreate: <T extends FetchKeys<Merit>>(
      id: number,
      data: MeritRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Merit, T>, any>({
        path: `/merits/${id}/deescalate/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsPreviewSanctionRetrieve
     * @request GET:/merits/{id}/preview-sanction/
     */ meritsPreviewSanctionRetrieve: <
      T extends FetchKeys<MeritSanctionPreview>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritSanctionPreview, T>, PendingMeritsPreview>({
        path: `/merits/${id}/preview-sanction/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsRejectUpdate
     * @request PUT:/merits/{id}/reject/
     */ meritsRejectUpdate: <T extends FetchKeys<MeritReject>>(
      id: number,
      data: MeritRejectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritReject, T>, any>({
        path: `/merits/${id}/reject/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsBehavioursList
     * @request GET:/merits/behaviours/
     */ meritsBehavioursList: <T extends FetchKeys<MeritItemBehaviour[]>>(
      query?: {
        academic_only?: boolean
        /** @format date */
        added_on?: string
        /** @format uuid */
        author?: string
        /** @format uuid */
        course?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** @format date-time */
        datetime_after?: string
        /** @format date-time */
        datetime_before?: string
        /** @format date-time */
        escalate_until_after?: string
        /** @format date-time */
        escalate_until_before?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        escalated_sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        house?: string
        merit_iso_year_and_week?: string
        merit_item?: number
        /**
         * * `merit` - Merit
         * * `demerit` - Demerit
         */
        merit_type?: 'demerit' | 'merit'
        /** Which field to use when ordering the results. */
        ordering?: string
        show_not_reviewed?: boolean
        /**
         * * `pending` - Pending
         * * `rejected` - Rejected
         * * `confirmed` - Approved
         * * `deescalated` - De-escalated
         */
        status?: 'confirmed' | 'deescalated' | 'pending' | 'rejected'
        /** @format uuid */
        student?: string
        /** @format float */
        value?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItemBehaviour[], T>, any>({
        path: `/merits/behaviours/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsFilterOptionsRetrieve
     * @request GET:/merits/filter-options/
     */ meritsFilterOptionsRetrieve: <T extends FetchKeys<MeritFilterOptions>>(
      query?: {
        academic_only?: boolean
        /** @format date */
        added_on?: string
        /** @format uuid */
        author?: string
        /** @format uuid */
        course?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** @format date-time */
        datetime_after?: string
        /** @format date-time */
        datetime_before?: string
        /** @format date-time */
        escalate_until_after?: string
        /** @format date-time */
        escalate_until_before?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        escalated_sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        house?: string
        merit_iso_year_and_week?: string
        merit_item?: number
        /**
         * * `merit` - Merit
         * * `demerit` - Demerit
         */
        merit_type?: 'demerit' | 'merit'
        /** Which field to use when ordering the results. */
        ordering?: string
        show_not_reviewed?: boolean
        /**
         * * `pending` - Pending
         * * `rejected` - Rejected
         * * `confirmed` - Approved
         * * `deescalated` - De-escalated
         */
        status?: 'confirmed' | 'deescalated' | 'pending' | 'rejected'
        /** @format uuid */
        student?: string
        /** @format float */
        value?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritFilterOptions, T>, any>({
        path: `/merits/filter-options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags merits
     * @name MeritsItemsList
     * @request GET:/merits/items/
     */ meritsItemsList: <T extends FetchKeys<PaginatedMeritItemList>>(
      query?: {
        allow_to_select?: boolean
        /**
         * * `week` - Weekly
         * * `quarter` - Quarterly
         * * `semester` - Semesterly
         * * `year` - Yearly
         */
        escalation_expire_after?: 'quarter' | 'semester' | 'week' | 'year'
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMeritItemList, T>, any>({
        path: `/merits/items/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags merits
     * @name MeritsItemsRetrieve
     * @request GET:/merits/items/{id}/
     */ meritsItemsRetrieve: <T extends FetchKeys<MeritItem>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItem, T>, any>({
        path: `/merits/items/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags merits
     * @name MeritsItemsUpdate
     * @request PUT:/merits/items/{id}/
     */ meritsItemsUpdate: <T extends FetchKeys<MeritItem>>(
      id: number,
      data: MeritItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItem, T>, any>({
        path: `/merits/items/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags merits
     * @name MeritsItemsPartialUpdate
     * @request PATCH:/merits/items/{id}/
     */ meritsItemsPartialUpdate: <T extends FetchKeys<MeritItem>>(
      id: number,
      data: PatchedMeritItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItem, T>, any>({
        path: `/merits/items/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags merits
     * @name MeritsItemsAvailableFiltersRetrieve
     * @request GET:/merits/items/available-filters/
     */ meritsItemsAvailableFiltersRetrieve: <
      T extends FetchKeys<MeritItemAvailableFilters>
    >(
      query?: {
        allow_to_select?: boolean
        /**
         * * `week` - Weekly
         * * `quarter` - Quarterly
         * * `semester` - Semesterly
         * * `year` - Yearly
         */
        escalation_expire_after?: 'quarter' | 'semester' | 'week' | 'year'
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItemAvailableFilters, T>, any>({
        path: `/merits/items/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Handler method for HTTP 'OPTIONS' request.
     *
     * @tags merits
     * @name MeritsItemsOptionsRetrieve
     * @request GET:/merits/items/options/
     */ meritsItemsOptionsRetrieve: <T extends FetchKeys<MeritItemOptions>>(
      query?: {
        allow_to_select?: boolean
        /**
         * * `week` - Weekly
         * * `quarter` - Quarterly
         * * `semester` - Semesterly
         * * `year` - Yearly
         */
        escalation_expire_after?: 'quarter' | 'semester' | 'week' | 'year'
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItemOptions, T>, any>({
        path: `/merits/items/options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsStatisticsRetrieve
     * @request GET:/merits/statistics/
     */ meritsStatisticsRetrieve: <T extends FetchKeys<MeritStatistics>>(
      query?: {
        academic_only?: boolean
        /** @format date */
        added_on?: string
        /** @format uuid */
        author?: string
        /** @format uuid */
        course?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** @format date-time */
        datetime_after?: string
        /** @format date-time */
        datetime_before?: string
        /** @format date-time */
        escalate_until_after?: string
        /** @format date-time */
        escalate_until_before?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        escalated_sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        house?: string
        merit_iso_year_and_week?: string
        merit_item?: number
        /**
         * * `merit` - Merit
         * * `demerit` - Demerit
         */
        merit_type?: 'demerit' | 'merit'
        /** Which field to use when ordering the results. */
        ordering?: string
        show_not_reviewed?: boolean
        /**
         * * `pending` - Pending
         * * `rejected` - Rejected
         * * `confirmed` - Approved
         * * `deescalated` - De-escalated
         */
        status?: 'confirmed' | 'deescalated' | 'pending' | 'rejected'
        /** @format uuid */
        student?: string
        /** @format float */
        value?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritStatistics, T>, any>({
        path: `/merits/statistics/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  meritsExchange = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeList
     * @request GET:/merits-exchange/
     */
    meritsExchangeList: <T extends FetchKeys<PaginatedAdminMeritRewardList>>(
      query?: {
        house?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        resourcetype?: string
        /**
         * * `pending` - Pending
         * * `pending_attendees` - Pending attendees
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `done` - Done
         * * `cancelled` - Cancelled
         * * `expired` - Expired
         */
        status?:
          | 'approved'
          | 'cancelled'
          | 'done'
          | 'expired'
          | 'pending'
          | 'pending_attendees'
          | 'rejected'
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminMeritRewardList, T>, any>({
        path: `/merits-exchange/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeRetrieve
     * @request GET:/merits-exchange/{id}/
     */ meritsExchangeRetrieve: <T extends FetchKeys<AdminMeritReward>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminMeritReward, T>, any>({
        path: `/merits-exchange/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeApproveCreate
     * @request POST:/merits-exchange/{id}/approve/
     */ meritsExchangeApproveCreate: <T extends FetchKeys<MeritRewardApprove>>(
      id: string,
      data: MeritRewardApproveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritRewardApprove, T>, any>({
        path: `/merits-exchange/${id}/approve/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeAvailableStudentsRetrieve
     * @request GET:/merits-exchange/{id}/available-students/
     */ meritsExchangeAvailableStudentsRetrieve: <
      T extends FetchKeys<AdminMeritReward>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminMeritReward, T>, any>({
        path: `/merits-exchange/${id}/available-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeCancelCreate
     * @request POST:/merits-exchange/{id}/cancel/
     */ meritsExchangeCancelCreate: <T extends FetchKeys<MeritRewardReject>>(
      id: string,
      data: MeritRewardRejectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritRewardReject, T>, any>({
        path: `/merits-exchange/${id}/cancel/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeRejectCreate
     * @request POST:/merits-exchange/{id}/reject/
     */ meritsExchangeRejectCreate: <T extends FetchKeys<MeritRewardReject>>(
      id: string,
      data: MeritRewardRejectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritRewardReject, T>, any>({
        path: `/merits-exchange/${id}/reject/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeAvailableFiltersRetrieve
     * @request GET:/merits-exchange/available-filters/
     */ meritsExchangeAvailableFiltersRetrieve: <
      T extends FetchKeys<FilterRepresentationResponseSchema>
    >(
      query?: {
        house?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        resourcetype?: string
        /**
         * * `pending` - Pending
         * * `pending_attendees` - Pending attendees
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `done` - Done
         * * `cancelled` - Cancelled
         * * `expired` - Expired
         */
        status?:
          | 'approved'
          | 'cancelled'
          | 'done'
          | 'expired'
          | 'pending'
          | 'pending_attendees'
          | 'rejected'
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FilterRepresentationResponseSchema, T>, any>({
        path: `/merits-exchange/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  missionControl = {
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlList
     * @request GET:/mission-control/
     */
    missionControlList: <T extends FetchKeys<PaginatedMissionControlList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMissionControlList, T>, any>({
        path: `/mission-control/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlCreate
     * @request POST:/mission-control/
     */ missionControlCreate: <T extends FetchKeys<MissionControl>>(
      data: MissionControlRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MissionControl, T>, any>({
        path: `/mission-control/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlLogsRetrieve
     * @request GET:/mission-control/{studentId}/logs/
     */ missionControlLogsRetrieve: <T extends FetchKeys<MissionControl>>(
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MissionControl, T>, any>({
        path: `/mission-control/${studentId}/logs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRetrieve
     * @request GET:/mission-control/{student}/
     */ missionControlRetrieve: <T extends FetchKeys<MissionControl>>(
      student: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MissionControl, T>, any>({
        path: `/mission-control/${student}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlUpdate
     * @request PUT:/mission-control/{student}/
     */ missionControlUpdate: <T extends FetchKeys<MissionControl>>(
      student: string,
      data: MissionControlRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MissionControl, T>, any>({
        path: `/mission-control/${student}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlPartialUpdate
     * @request PATCH:/mission-control/{student}/
     */ missionControlPartialUpdate: <T extends FetchKeys<MissionControl>>(
      student: string,
      data: PatchedMissionControlRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MissionControl, T>, any>({
        path: `/mission-control/${student}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlDestroy
     * @request DELETE:/mission-control/{student}/
     */ missionControlDestroy: <T extends FetchKeys<void>>(
      student: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/mission-control/${student}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlCompetenciesList
     * @request GET:/mission-control/competencies/
     */ missionControlCompetenciesList: <
      T extends FetchKeys<PaginatedCompetencyList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCompetencyList, T>, any>({
        path: `/mission-control/competencies/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlCompetenciesRetrieve
     * @request GET:/mission-control/competencies/{id}/
     */ missionControlCompetenciesRetrieve: <T extends FetchKeys<Competency>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Competency, T>, any>({
        path: `/mission-control/competencies/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlDegreeAimsList
     * @request GET:/mission-control/degree-aims/
     */ missionControlDegreeAimsList: <
      T extends FetchKeys<PaginatedDegreeAimList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDegreeAimList, T>, any>({
        path: `/mission-control/degree-aims/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlDegreeAimsRetrieve
     * @request GET:/mission-control/degree-aims/{id}/
     */ missionControlDegreeAimsRetrieve: <T extends FetchKeys<DegreeAim>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DegreeAim, T>, any>({
        path: `/mission-control/degree-aims/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlInterestsList
     * @request GET:/mission-control/interests/
     */ missionControlInterestsList: <
      T extends FetchKeys<PaginatedInterestList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedInterestList, T>, any>({
        path: `/mission-control/interests/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlInterestsRetrieve
     * @request GET:/mission-control/interests/{id}/
     */ missionControlInterestsRetrieve: <T extends FetchKeys<Interest>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Interest, T>, any>({
        path: `/mission-control/interests/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlLogChoicesRetrieve
     * @request GET:/mission-control/log-choices/
     */ missionControlLogChoicesRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/mission-control/log-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRequirementsCreditsStudentsRetrieve
     * @request GET:/mission-control/requirements/credits/students/{studentId}/
     */ missionControlRequirementsCreditsStudentsRetrieve: <
      T extends FetchKeys<void>
    >(
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/mission-control/requirements/credits/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRequirementsCreditsStudentsImportHistoryCreate
     * @request POST:/mission-control/requirements/credits/students/{studentId}/import-history/
     */ missionControlRequirementsCreditsStudentsImportHistoryCreate: <
      T extends FetchKeys<void>
    >(
      studentId: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/mission-control/requirements/credits/students/${studentId}/import-history/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRequirementsStudentsList
     * @request GET:/mission-control/requirements/students/{studentId}/
     */ missionControlRequirementsStudentsList: <
      T extends FetchKeys<PaginatedStudentCourseRequirementList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentCourseRequirementList, T>, any>({
        path: `/mission-control/requirements/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRequirementsStudentsUpdate
     * @request PUT:/mission-control/requirements/students/{studentId}/
     */ missionControlRequirementsStudentsUpdate: <
      T extends FetchKeys<StudentCourseRequirement>
    >(
      studentId: string,
      data: StudentCourseRequirementRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCourseRequirement, T>, any>({
        path: `/mission-control/requirements/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRequirementsStudentsPartialUpdate
     * @request PATCH:/mission-control/requirements/students/{studentId}/
     */ missionControlRequirementsStudentsPartialUpdate: <
      T extends FetchKeys<StudentCourseRequirement>
    >(
      studentId: string,
      data: PatchedStudentCourseRequirementRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCourseRequirement, T>, any>({
        path: `/mission-control/requirements/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlSkillsList
     * @request GET:/mission-control/skills/
     */ missionControlSkillsList: <T extends FetchKeys<PaginatedSkillList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSkillList, T>, any>({
        path: `/mission-control/skills/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlSkillsRetrieve
     * @request GET:/mission-control/skills/{id}/
     */ missionControlSkillsRetrieve: <T extends FetchKeys<Skill>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Skill, T>, any>({
        path: `/mission-control/skills/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeExperiencesList
     * @request GET:/mission-control/tae/experiences/
     */ missionControlTaeExperiencesList: <
      T extends FetchKeys<PaginatedTAEExperienceList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTAEExperienceList, T>, any>({
        path: `/mission-control/tae/experiences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeExperiencesRetrieve
     * @request GET:/mission-control/tae/experiences/{id}/
     */ missionControlTaeExperiencesRetrieve: <
      T extends FetchKeys<TAEExperience>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TAEExperience, T>, any>({
        path: `/mission-control/tae/experiences/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeExperiencesStudentsList
     * @request GET:/mission-control/tae/experiences/students/{studentId}/
     */ missionControlTaeExperiencesStudentsList: <
      T extends FetchKeys<PaginatedStudentTAEExperienceList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentTAEExperienceList, T>, any>({
        path: `/mission-control/tae/experiences/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeExperiencesStudentsUpdate
     * @request PUT:/mission-control/tae/experiences/students/{studentId}/
     */ missionControlTaeExperiencesStudentsUpdate: <
      T extends FetchKeys<StudentTAEExperience>
    >(
      studentId: string,
      data: StudentTAEExperienceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTAEExperience, T>, any>({
        path: `/mission-control/tae/experiences/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeExperiencesStudentsPartialUpdate
     * @request PATCH:/mission-control/tae/experiences/students/{studentId}/
     */ missionControlTaeExperiencesStudentsPartialUpdate: <
      T extends FetchKeys<StudentTAEExperience>
    >(
      studentId: string,
      data: PatchedStudentTAEExperienceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTAEExperience, T>, any>({
        path: `/mission-control/tae/experiences/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeProjectsStudentsList
     * @request GET:/mission-control/tae/projects/students/{studentId}/
     */ missionControlTaeProjectsStudentsList: <
      T extends FetchKeys<PaginatedStudentTAEProjectList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentTAEProjectList, T>, any>({
        path: `/mission-control/tae/projects/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeProjectsStudentsUpdate
     * @request PUT:/mission-control/tae/projects/students/{studentId}/
     */ missionControlTaeProjectsStudentsUpdate: <
      T extends FetchKeys<StudentTAEProject>
    >(
      studentId: string,
      data: StudentTAEProjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTAEProject, T>, any>({
        path: `/mission-control/tae/projects/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeProjectsStudentsPartialUpdate
     * @request PATCH:/mission-control/tae/projects/students/{studentId}/
     */ missionControlTaeProjectsStudentsPartialUpdate: <
      T extends FetchKeys<StudentTAEProject>
    >(
      studentId: string,
      data: PatchedStudentTAEProjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTAEProject, T>, any>({
        path: `/mission-control/tae/projects/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeSportPackagesList
     * @request GET:/mission-control/tae/sport-packages/{studentId}/
     */ missionControlTaeSportPackagesList: <
      T extends FetchKeys<StudentTAESportPackage[]>
    >(
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTAESportPackage[], T>, any>({
        path: `/mission-control/tae/sport-packages/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeSportPackagesUpdate
     * @request PUT:/mission-control/tae/sport-packages/{studentId}/
     */ missionControlTaeSportPackagesUpdate: <
      T extends FetchKeys<StudentTAESportPackage>
    >(
      studentId: string,
      data: StudentTAESportPackageRequest[],
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTAESportPackage, T>, any>({
        path: `/mission-control/tae/sport-packages/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  notifications = {}
  oauth = {}
  parent = {}
  parents = {
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsList
     * @request GET:/parents/
     */
    parentsList: <T extends FetchKeys<PaginatedParentList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedParentList, T>, any>({
        path: `/parents/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsCreate
     * @request POST:/parents/
     */ parentsCreate: <T extends FetchKeys<Parent>>(
      data: ParentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Parent, T>, any>({
        path: `/parents/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsRetrieve
     * @request GET:/parents/{id}/
     */ parentsRetrieve: <T extends FetchKeys<Parent>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Parent, T>, any>({
        path: `/parents/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsUpdate
     * @request PUT:/parents/{id}/
     */ parentsUpdate: <T extends FetchKeys<Parent>>(
      id: string,
      data: ParentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Parent, T>, any>({
        path: `/parents/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsPartialUpdate
     * @request PATCH:/parents/{id}/
     */ parentsPartialUpdate: <T extends FetchKeys<Parent>>(
      id: string,
      data: PatchedParentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Parent, T>, any>({
        path: `/parents/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsDestroy
     * @request DELETE:/parents/{id}/
     */ parentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/parents/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  periods = {
    /**
     * No description
     *
     * @tags periods
     * @name PeriodsQuartersList
     * @request GET:/periods/quarters/
     */
    periodsQuartersList: <T extends FetchKeys<PaginatedQuarterList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedQuarterList, T>, any>({
        path: `/periods/quarters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags periods
     * @name PeriodsSemestersList
     * @request GET:/periods/semesters/
     */ periodsSemestersList: <T extends FetchKeys<PaginatedSemesterList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSemesterList, T>, any>({
        path: `/periods/semesters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags periods
     * @name PeriodsYearsList
     * @request GET:/periods/years/
     */ periodsYearsList: <T extends FetchKeys<PaginatedSchoolYearList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSchoolYearList, T>, any>({
        path: `/periods/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  planner = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesList
     * @request GET:/planner/activities/
     */
    plannerActivitiesList: <
      T extends FetchKeys<PaginatedDraftActivityListList>
    >(
      query?: {
        /** @format uuid */
        course?: string
        exclude_tae_activities?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        room?: string
        students?: string[]
        /** @format uuid */
        teacher?: string
        timetable?: number
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDraftActivityListList, T>, any>({
        path: `/planner/activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesCreate
     * @request POST:/planner/activities/
     */ plannerActivitiesCreate: <T extends FetchKeys<DraftActivityCreate>>(
      data: DraftActivityCreateRequest,
      query?: {
        edit_session_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/`,
        method: 'POST',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesRetrieve
     * @request GET:/planner/activities/{id}/
     */ plannerActivitiesRetrieve: <T extends FetchKeys<DraftActivityCreate>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesUpdate
     * @request PUT:/planner/activities/{id}/
     */ plannerActivitiesUpdate: <T extends FetchKeys<DraftActivityCreate>>(
      id: string,
      data: DraftActivityCreateRequest,
      query?: {
        edit_session_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/${id}/`,
        method: 'PUT',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesPartialUpdate
     * @request PATCH:/planner/activities/{id}/
     */ plannerActivitiesPartialUpdate: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      id: string,
      data: PatchedDraftActivityCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesDestroy
     * @request DELETE:/planner/activities/{id}/
     */ plannerActivitiesDestroy: <T extends FetchKeys<void>>(
      id: string,
      query?: {
        edit_session_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/activities/${id}/`,
        method: 'DELETE',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesEditSessionsCreate
     * @request POST:/planner/activities/{id}/edit-sessions/
     */ plannerActivitiesEditSessionsCreate: <
      T extends FetchKeys<EditSessionCreatedResponse>
    >(
      id: string,
      data: CreateEditSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<EditSessionCreatedResponse, T>,
        EditSessionConflictResponse
      >({
        path: `/planner/activities/${id}/edit-sessions/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesRoomsPreferencesRetrieve
     * @request GET:/planner/activities/{id}/rooms-preferences/
     */ plannerActivitiesRoomsPreferencesRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/${id}/rooms-preferences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesAvailableFiltersRetrieve
     * @request GET:/planner/activities/available-filters/
     */ plannerActivitiesAvailableFiltersRetrieve: <
      T extends FetchKeys<DraftActivityAvailableFilters>
    >(
      query?: {
        /** @format uuid */
        course?: string
        exclude_tae_activities?: boolean
        room?: string
        students?: string[]
        /** @format uuid */
        teacher?: string
        timetable?: number
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityAvailableFilters, T>, any>({
        path: `/planner/activities/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesCountRetrieve
     * @request GET:/planner/activities/count/
     */ plannerActivitiesCountRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        /** @format uuid */
        course?: string
        exclude_tae_activities?: boolean
        room?: number
        students?: string[]
        /** @format uuid */
        teacher?: string
        timetable?: number
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/count/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesDaysList
     * @request GET:/planner/activities/days/
     */ plannerActivitiesDaysList: <T extends FetchKeys<WeekDay[]>>(
      query?: {
        /** @format uuid */
        course?: string
        exclude_tae_activities?: boolean
        room?: number
        students?: string[]
        /** @format uuid */
        teacher?: string
        timetable?: number
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeekDay[], T>, any>({
        path: `/planner/activities/days/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Pass timetable and hour in query param
     *
     * @tags planner
     * @name PlannerActivitiesExportActivitiesAfterHourRetrieve
     * @request GET:/planner/activities/export-activities-after-hour/
     */ plannerActivitiesExportActivitiesAfterHourRetrieve: <
      T extends FetchKeys<string>
    >(
      query?: {
        /**
         * * `0` - 0
         * * `1` - 1
         * * `2` - 2
         * * `3` - 3
         * * `4` - 4
         * * `5` - 5
         * * `6` - 6
         * * `7` - 7
         * * `8` - 8
         * * `9` - 9
         * * `10` - 10
         * * `11` - 11
         * * `12` - 12
         * * `13` - 13
         * * `14` - 14
         * * `15` - 15
         */
        hour?:
          | 0
          | 1
          | 2
          | 3
          | 4
          | 5
          | 6
          | 7
          | 8
          | 9
          | 10
          | 11
          | 12
          | 13
          | 14
          | 15
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<string, T>, any>({
        path: `/planner/activities/export-activities-after-hour/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesExportActivitiesPerSlotRetrieve
     * @request GET:/planner/activities/export-activities-per-slot/
     */ plannerActivitiesExportActivitiesPerSlotRetrieve: <
      T extends FetchKeys<string>
    >(
      query?: {
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<string, T>, any>({
        path: `/planner/activities/export-activities-per-slot/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesExportStudentActivitiesRetrieve
     * @request GET:/planner/activities/export-student-activities/
     */ plannerActivitiesExportStudentActivitiesRetrieve: <
      T extends FetchKeys<string>
    >(
      query?: {
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<string, T>, any>({
        path: `/planner/activities/export-student-activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesExportTeacherActivitiesRetrieve
     * @request GET:/planner/activities/export-teacher-activities/
     */ plannerActivitiesExportTeacherActivitiesRetrieve: <
      T extends FetchKeys<File>
    >(
      query?: {
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<File, T>, any>({
        path: `/planner/activities/export-teacher-activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerActivitiesHoursList
     * @request GET:/planner/activities/hours/
     */ plannerActivitiesHoursList: <T extends FetchKeys<DayHour[]>>(
      query?: {
        /** @format uuid */
        course?: string
        exclude_tae_activities?: boolean
        room?: number
        students?: string[]
        /** @format uuid */
        teacher?: string
        timetable?: number
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayHour[], T>, any>({
        path: `/planner/activities/hours/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesUpdateActivitiesRoomsCreate
     * @request POST:/planner/activities/update-activities-rooms/
     */ plannerActivitiesUpdateActivitiesRoomsCreate: <
      T extends FetchKeys<UpdateActivitiesRooms>
    >(
      data: UpdateActivitiesRoomsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<UpdateActivitiesRooms, T>, any>({
        path: `/planner/activities/update-activities-rooms/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Provide the ability to retrieve a single object for further manipulation.
     *
     * @tags planner
     * @name PlannerConflictsRetrieve
     * @request GET:/planner/conflicts/{id}/{day}/
     */ plannerConflictsRetrieve: <T extends FetchKeys<void>>(
      day: string,
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/conflicts/${id}/${day}/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksList
     * @request GET:/planner/generator/tasks/
     */ plannerGeneratorTasksList: <
      T extends FetchKeys<PaginatedGenerationTaskList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedGenerationTaskList, T>, any>({
        path: `/planner/generator/tasks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksCreate
     * @request POST:/planner/generator/tasks/
     */ plannerGeneratorTasksCreate: <T extends FetchKeys<GenerationTask>>(
      data: GenerationTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/planner/generator/tasks/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksRetrieve
     * @request GET:/planner/generator/tasks/{id}/
     */ plannerGeneratorTasksRetrieve: <T extends FetchKeys<GenerationTask>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/planner/generator/tasks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksStatsRetrieve
     * @request GET:/planner/generator/tasks/{id}/stats/
     */ plannerGeneratorTasksStatsRetrieve: <
      T extends FetchKeys<GenerationTask>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/planner/generator/tasks/${id}/stats/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksStopCreate
     * @request POST:/planner/generator/tasks/{id}/stop/
     */ plannerGeneratorTasksStopCreate: <T extends FetchKeys<GenerationTask>>(
      id: number,
      data: GenerationTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/planner/generator/tasks/${id}/stop/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksCostsRetrieve
     * @request GET:/planner/generator/tasks/costs/
     */ plannerGeneratorTasksCostsRetrieve: <
      T extends FetchKeys<GenerationTask>
    >(
      query?: {
        quarter?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/planner/generator/tasks/costs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerLogsList
     * @request GET:/planner/logs/
     */ plannerLogsList: <T extends FetchKeys<PaginatedTimetableLogList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTimetableLogList, T>, any>({
        path: `/planner/logs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsList
     * @request GET:/planner/mentorings/
     */ plannerMentoringsList: <
      T extends FetchKeys<PaginatedTimetableMentoringList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTimetableMentoringList, T>, any>({
        path: `/planner/mentorings/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsCreate
     * @request POST:/planner/mentorings/
     */ plannerMentoringsCreate: <T extends FetchKeys<TimetableMentoring>>(
      data: TimetableMentoringRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableMentoring, T>, any>({
        path: `/planner/mentorings/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsRetrieve
     * @request GET:/planner/mentorings/{id}/
     */ plannerMentoringsRetrieve: <T extends FetchKeys<TimetableMentoring>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableMentoring, T>, any>({
        path: `/planner/mentorings/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsUpdate
     * @request PUT:/planner/mentorings/{id}/
     */ plannerMentoringsUpdate: <T extends FetchKeys<TimetableMentoring>>(
      id: number,
      data: TimetableMentoringRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableMentoring, T>, any>({
        path: `/planner/mentorings/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsPartialUpdate
     * @request PATCH:/planner/mentorings/{id}/
     */ plannerMentoringsPartialUpdate: <
      T extends FetchKeys<TimetableMentoring>
    >(
      id: number,
      data: PatchedTimetableMentoringRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableMentoring, T>, any>({
        path: `/planner/mentorings/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsDestroy
     * @request DELETE:/planner/mentorings/{id}/
     */ plannerMentoringsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/mentorings/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsInitialDataRetrieve
     * @request GET:/planner/mentorings/initial-data/
     */ plannerMentoringsInitialDataRetrieve: <
      T extends FetchKeys<TimetableMentoring>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableMentoring, T>, any>({
        path: `/planner/mentorings/initial-data/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerPotentialOptimizationsActivitiesMovesList
     * @request GET:/planner/potential-optimizations/activities/{id}/moves/
     */ plannerPotentialOptimizationsActivitiesMovesList: <
      T extends FetchKeys<ActivityMove[]>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ActivityMove[], T>, any>({
        path: `/planner/potential-optimizations/activities/${id}/moves/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Provide the ability to retrieve a single object for further manipulation.
     *
     * @tags planner
     * @name PlannerRoomsAssignerCreate
     * @request POST:/planner/rooms-assigner/{id}/
     */ plannerRoomsAssignerCreate: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/rooms-assigner/${id}/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsList
     * @request GET:/planner/sections/
     */ plannerSectionsList: <T extends FetchKeys<PaginatedSectionListList>>(
      query?: {
        /** @format uuid */
        course?: string
        excluded_section?: string
        id?: number[]
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number
        /** A search term. */
        search?: string
        /** @format uuid */
        students?: string
        /** @format uuid */
        teacher?: string
        /** @format uuid */
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSectionListList, T>, any>({
        path: `/planner/sections/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsCreate
     * @request POST:/planner/sections/
     */ plannerSectionsCreate: <T extends FetchKeys<Section>>(
      data: SectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsRetrieve
     * @request GET:/planner/sections/{id}/
     */ plannerSectionsRetrieve: <T extends FetchKeys<Section>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsUpdate
     * @request PUT:/planner/sections/{id}/
     */ plannerSectionsUpdate: <T extends FetchKeys<Section>>(
      id: number,
      data: SectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsPartialUpdate
     * @request PATCH:/planner/sections/{id}/
     */ plannerSectionsPartialUpdate: <T extends FetchKeys<Section>>(
      id: number,
      data: PatchedSectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsDestroy
     * @request DELETE:/planner/sections/{id}/
     */ plannerSectionsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/sections/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsAllowedStudentsList
     * @request GET:/planner/sections/{id}/allowed-students/
     */ plannerSectionsAllowedStudentsList: <
      T extends FetchKeys<StringOption[]>
    >(
      id: number,
      query?: {
        /** @format uuid */
        course?: string
        excluded_section?: string
        id?: number[]
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        /** A search term. */
        search?: string
        /** @format uuid */
        students?: string
        /** @format uuid */
        teacher?: string
        /** @format uuid */
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/planner/sections/${id}/allowed-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsUsagesRetrieve
     * @request GET:/planner/sections/{id}/usages/
     */ plannerSectionsUsagesRetrieve: <T extends FetchKeys<Section>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/${id}/usages/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsChangesInSectionsCreate
     * @request POST:/planner/sections/changes-in-sections/
     */ plannerSectionsChangesInSectionsCreate: <
      T extends FetchKeys<Record<string, any>>
    >(
      data: ChangesInSectionsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Record<string, any>, T>, any>({
        path: `/planner/sections/changes-in-sections/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsExportStudentsEnrollmentsRetrieve
     * @request GET:/planner/sections/export-students-enrollments/
     */ plannerSectionsExportStudentsEnrollmentsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/sections/export-students-enrollments/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsExportStudentsInSectionsRetrieve
     * @request GET:/planner/sections/export-students-in-sections/
     */ plannerSectionsExportStudentsInSectionsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/sections/export-students-in-sections/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsExportTeachersEnrolmentsRetrieve
     * @request GET:/planner/sections/export-teachers-enrolments/
     */ plannerSectionsExportTeachersEnrolmentsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/sections/export-teachers-enrolments/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * @description Sections with students and scorings
     *
     * @tags planner
     * @name PlannerSectionsSectionToEnrollList
     * @request GET:/planner/sections/section-to-enroll/
     */ plannerSectionsSectionToEnrollList: <
      T extends FetchKeys<SectionToEnroll[]>
    >(
      query?: {
        /** @format uuid */
        course?: string
        excluded_section?: string
        id?: number[]
        quarter?: number
        /** @format uuid */
        students?: string
        /** @format uuid */
        teacher?: string
        /** @format uuid */
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SectionToEnroll[], T>, any>({
        path: `/planner/sections/section-to-enroll/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Sections API without enrollments
     *
     * @tags planner
     * @name PlannerSectionsSimpleList
     * @request GET:/planner/sections/simple/
     */ plannerSectionsSimpleList: <
      T extends FetchKeys<PaginatedSimpleSectionList>
    >(
      query?: {
        /** @format uuid */
        course?: string
        /** multi value filter: id1,id2,id3 */
        id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSimpleSectionList, T>, any>({
        path: `/planner/sections/simple/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Sections API without enrollments
     *
     * @tags planner
     * @name PlannerSectionsSimpleRetrieve
     * @request GET:/planner/sections/simple/{id}/
     */ plannerSectionsSimpleRetrieve: <T extends FetchKeys<SimpleSection>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SimpleSection, T>, any>({
        path: `/planner/sections/simple/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsTotalDurationRetrieve
     * @request GET:/planner/sections/total-duration/
     */ plannerSectionsTotalDurationRetrieve: <T extends FetchKeys<Section>>(
      query?: {
        /** @format uuid */
        course?: string
        excluded_section?: string
        id?: number[]
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        /** A search term. */
        search?: string
        /** @format uuid */
        students?: string
        /** @format uuid */
        teacher?: string
        /** @format uuid */
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/total-duration/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpList
     * @request GET:/planner/students-idp/
     */ plannerStudentsIdpList: <
      T extends FetchKeys<PaginatedStudentIdpPlanListList>
    >(
      query?: {
        aim?: number
        grade_level?: number
        is_active?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /**
         * * `new` - New
         * * `draft` - Draft
         * * `rejected` - Rejected
         * * `waits_for_admin_approval` - In admin approval
         * * `in_parental_approval` - In parental approval
         * * `is_discussion` - In discussion
         * * `approved` - Approved
         * * `automatically_approved` - Automatically approved
         * * `updated` - Updated
         * * `archived` - Archived
         */
        status?:
          | 'approved'
          | 'archived'
          | 'automatically_approved'
          | 'draft'
          | 'in_parental_approval'
          | 'is_discussion'
          | 'new'
          | 'rejected'
          | 'updated'
          | 'waits_for_admin_approval'
        /** @format uuid */
        student?: string
        student__is_new?: boolean
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentIdpPlanListList, T>, any>({
        path: `/planner/students-idp/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCreate
     * @request POST:/planner/students-idp/
     */ plannerStudentsIdpCreate: <T extends FetchKeys<StudentIdpPlan>>(
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpRetrieve
     * @request GET:/planner/students-idp/{id}/
     */ plannerStudentsIdpRetrieve: <T extends FetchKeys<StudentIdpPlan>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpUpdate
     * @request PUT:/planner/students-idp/{id}/
     */ plannerStudentsIdpUpdate: <T extends FetchKeys<StudentIdpPlan>>(
      id: string,
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpPartialUpdate
     * @request PATCH:/planner/students-idp/{id}/
     */ plannerStudentsIdpPartialUpdate: <T extends FetchKeys<StudentIdpPlan>>(
      id: string,
      data: PatchedStudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpDestroy
     * @request DELETE:/planner/students-idp/{id}/
     */ plannerStudentsIdpDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/students-idp/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpApproveCreate
     * @request POST:/planner/students-idp/{id}/approve/
     */ plannerStudentsIdpApproveCreate: <
      T extends FetchKeys<AdminApproveIdpPlan>
    >(
      id: string,
      data: AdminApproveIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminApproveIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/approve/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpAutoAssignSectionsCreate
     * @request POST:/planner/students-idp/{id}/auto-assign-sections/
     */ plannerStudentsIdpAutoAssignSectionsCreate: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      id: string,
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/auto-assign-sections/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCancelApprovalRequestUpdate
     * @request PUT:/planner/students-idp/{id}/cancel-approval-request/
     */ plannerStudentsIdpCancelApprovalRequestUpdate: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/cancel-approval-request/`,
        method: 'PUT',
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpParentApproveCreate
     * @request POST:/planner/students-idp/{id}/parent-approve/
     */ plannerStudentsIdpParentApproveCreate: <T extends FetchKeys<void>>(
      id: string,
      data: AdminParentApproveIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/students-idp/${id}/parent-approve/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpPdfRetrieve
     * @request GET:/planner/students-idp/{id}/pdf/
     */ plannerStudentsIdpPdfRetrieve: <T extends FetchKeys<StudentIdpPlan>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/pdf/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpRejectCreate
     * @request POST:/planner/students-idp/{id}/reject/
     */ plannerStudentsIdpRejectCreate: <T extends FetchKeys<RejectIdpPlan>>(
      id: string,
      data: RejectIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RejectIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/reject/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpUpdateDraftUpdate
     * @request PUT:/planner/students-idp/{id}/update-draft/
     */ plannerStudentsIdpUpdateDraftUpdate: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      id: string,
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/update-draft/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpValidateApproveCreate
     * @request POST:/planner/students-idp/{id}/validate-approve/
     */ plannerStudentsIdpValidateApproveCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/students-idp/${id}/validate-approve/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCalculateCreditsCreate
     * @request POST:/planner/students-idp/calculate-credits/
     */ plannerStudentsIdpCalculateCreditsCreate: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/calculate-credits/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCourseInBasketsRetrieve
     * @request GET:/planner/students-idp/course-in-baskets/{id}/
     */ plannerStudentsIdpCourseInBasketsRetrieve: <
      T extends FetchKeys<CourseInBasketUpdateDepartment>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseInBasketUpdateDepartment, T>, any>({
        path: `/planner/students-idp/course-in-baskets/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCourseInBasketsUpdate
     * @request PUT:/planner/students-idp/course-in-baskets/{id}/
     */ plannerStudentsIdpCourseInBasketsUpdate: <
      T extends FetchKeys<CourseInBasketUpdateDepartment>
    >(
      id: number,
      data: CourseInBasketUpdateDepartmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseInBasketUpdateDepartment, T>, any>({
        path: `/planner/students-idp/course-in-baskets/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCourseInBasketsPartialUpdate
     * @request PATCH:/planner/students-idp/course-in-baskets/{id}/
     */ plannerStudentsIdpCourseInBasketsPartialUpdate: <
      T extends FetchKeys<CourseInBasketUpdateDepartment>
    >(
      id: number,
      data: PatchedCourseInBasketUpdateDepartmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseInBasketUpdateDepartment, T>, any>({
        path: `/planner/students-idp/course-in-baskets/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCoursesRetrieve
     * @request GET:/planner/students-idp/courses/
     */ plannerStudentsIdpCoursesRetrieve: <T extends FetchKeys<CourseInIdp>>(
      query?: {
        aim?: number
        grade_level?: number
        is_active?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `new` - New
         * * `draft` - Draft
         * * `rejected` - Rejected
         * * `waits_for_admin_approval` - In admin approval
         * * `in_parental_approval` - In parental approval
         * * `is_discussion` - In discussion
         * * `approved` - Approved
         * * `automatically_approved` - Automatically approved
         * * `updated` - Updated
         * * `archived` - Archived
         */
        status?:
          | 'approved'
          | 'archived'
          | 'automatically_approved'
          | 'draft'
          | 'in_parental_approval'
          | 'is_discussion'
          | 'new'
          | 'rejected'
          | 'updated'
          | 'waits_for_admin_approval'
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseInIdp, T>, any>({
        path: `/planner/students-idp/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpEnrollToSectionRetrieve
     * @request GET:/planner/students-idp/enroll-to-section/{id}/
     */ plannerStudentsIdpEnrollToSectionRetrieve: <
      T extends FetchKeys<IdpSectionEnroll>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdpSectionEnroll, T>, any>({
        path: `/planner/students-idp/enroll-to-section/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpEnrollToSectionUpdate
     * @request PUT:/planner/students-idp/enroll-to-section/{id}/
     */ plannerStudentsIdpEnrollToSectionUpdate: <
      T extends FetchKeys<IdpSectionEnroll>
    >(
      id: number,
      data: IdpSectionEnrollRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdpSectionEnroll, T>, any>({
        path: `/planner/students-idp/enroll-to-section/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpEnrollToSectionPartialUpdate
     * @request PATCH:/planner/students-idp/enroll-to-section/{id}/
     */ plannerStudentsIdpEnrollToSectionPartialUpdate: <
      T extends FetchKeys<IdpSectionEnroll>
    >(
      id: number,
      data: PatchedIdpSectionEnrollRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdpSectionEnroll, T>, any>({
        path: `/planner/students-idp/enroll-to-section/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpExamsRetrieve
     * @request GET:/planner/students-idp/exams/
     */ plannerStudentsIdpExamsRetrieve: <T extends FetchKeys<StudentIdpPlan>>(
      query?: {
        aim?: number
        grade_level?: number
        is_active?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `new` - New
         * * `draft` - Draft
         * * `rejected` - Rejected
         * * `waits_for_admin_approval` - In admin approval
         * * `in_parental_approval` - In parental approval
         * * `is_discussion` - In discussion
         * * `approved` - Approved
         * * `automatically_approved` - Automatically approved
         * * `updated` - Updated
         * * `archived` - Archived
         */
        status?:
          | 'approved'
          | 'archived'
          | 'automatically_approved'
          | 'draft'
          | 'in_parental_approval'
          | 'is_discussion'
          | 'new'
          | 'rejected'
          | 'updated'
          | 'waits_for_admin_approval'
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/exams/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpLastApprovedRetrieve
     * @request GET:/planner/students-idp/last-approved/
     */ plannerStudentsIdpLastApprovedRetrieve: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      query?: {
        aim?: number
        grade_level?: number
        is_active?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `new` - New
         * * `draft` - Draft
         * * `rejected` - Rejected
         * * `waits_for_admin_approval` - In admin approval
         * * `in_parental_approval` - In parental approval
         * * `is_discussion` - In discussion
         * * `approved` - Approved
         * * `automatically_approved` - Automatically approved
         * * `updated` - Updated
         * * `archived` - Archived
         */
        status?:
          | 'approved'
          | 'archived'
          | 'automatically_approved'
          | 'draft'
          | 'in_parental_approval'
          | 'is_discussion'
          | 'new'
          | 'rejected'
          | 'updated'
          | 'waits_for_admin_approval'
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/last-approved/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpRequestedCoursesList
     * @request GET:/planner/students-idp/requested-courses/
     */ plannerStudentsIdpRequestedCoursesList: <
      T extends FetchKeys<PaginatedRequestedCourseList>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedRequestedCourseList, T>, any>({
        path: `/planner/students-idp/requested-courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpRequestedCoursesRetrieve
     * @request GET:/planner/students-idp/requested-courses/{id}/
     */ plannerStudentsIdpRequestedCoursesRetrieve: <
      T extends FetchKeys<RequestedCourse>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RequestedCourse, T>, any>({
        path: `/planner/students-idp/requested-courses/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpSaveDraftCreate
     * @request POST:/planner/students-idp/save-draft/
     */ plannerStudentsIdpSaveDraftCreate: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/save-draft/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpStatusesList
     * @request GET:/planner/students-idp/statuses/
     */ plannerStudentsIdpStatusesList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        aim?: number
        grade_level?: number
        is_active?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `new` - New
         * * `draft` - Draft
         * * `rejected` - Rejected
         * * `waits_for_admin_approval` - In admin approval
         * * `in_parental_approval` - In parental approval
         * * `is_discussion` - In discussion
         * * `approved` - Approved
         * * `automatically_approved` - Automatically approved
         * * `updated` - Updated
         * * `archived` - Archived
         */
        status?:
          | 'approved'
          | 'archived'
          | 'automatically_approved'
          | 'draft'
          | 'in_parental_approval'
          | 'is_discussion'
          | 'new'
          | 'rejected'
          | 'updated'
          | 'waits_for_admin_approval'
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/planner/students-idp/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpSuggestionsCreate
     * @request POST:/planner/students-idp/suggestions/
     */ plannerStudentsIdpSuggestionsCreate: <T extends FetchKeys<Suggestions>>(
      data: SuggestionsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Suggestions, T>, any>({
        path: `/planner/students-idp/suggestions/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerTaeTaeDraftActivitiesList
     * @request GET:/planner/tae/{taskId}/tae-draft-activities/
     */ plannerTaeTaeDraftActivitiesList: <
      T extends FetchKeys<PaginatedTAEDraftActivityListList>
    >(
      taskId: string,
      query?: {
        /** @format uuid */
        course?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        room?: number
        /** A search term. */
        search?: string
        students?: string[]
        /** @format uuid */
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTAEDraftActivityListList, T>, any>({
        path: `/planner/tae/${taskId}/tae-draft-activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerTaeTaeDraftActivitiesRetrieve
     * @request GET:/planner/tae/{taskId}/tae-draft-activities/{id}/
     */ plannerTaeTaeDraftActivitiesRetrieve: <
      T extends FetchKeys<TAEDraftActivity>
    >(
      id: string,
      taskId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TAEDraftActivity, T>, any>({
        path: `/planner/tae/${taskId}/tae-draft-activities/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags planner
     * @name PlannerTaeTaeDraftActivitiesAvailableFiltersRetrieve
     * @request GET:/planner/tae/{taskId}/tae-draft-activities/available-filters/
     */ plannerTaeTaeDraftActivitiesAvailableFiltersRetrieve: <
      T extends FetchKeys<TAEDraftActivityAvailableFilters>
    >(
      taskId: string,
      query?: {
        /** @format uuid */
        course?: string
        room?: string
        /** A search term. */
        search?: string
        students?: string[]
        /** @format uuid */
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TAEDraftActivityAvailableFilters, T>, any>({
        path: `/planner/tae/${taskId}/tae-draft-activities/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerTaeCreate
     * @request POST:/planner/tae/{timetableId}/
     */ plannerTaeCreate: <T extends FetchKeys<TAEGeneratorTask>>(
      timetableId: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TAEGeneratorTask, T>, MessageResponseSchema>({
        path: `/planner/tae/${timetableId}/`,
        method: 'POST',
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerTaeRetrieve
     * @request GET:/planner/tae/{timetableId}/{taskId}/
     */ plannerTaeRetrieve: <T extends FetchKeys<TAEGeneratorTask>>(
      taskId: number,
      timetableId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TAEGeneratorTask, T>, any>({
        path: `/planner/tae/${timetableId}/${taskId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerTaeApplyCreate
     * @request POST:/planner/tae/{timetableId}/{taskId}/apply/
     */ plannerTaeApplyCreate: <T extends FetchKeys<void>>(
      taskId: number,
      timetableId: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/tae/${timetableId}/${taskId}/apply/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerTaeCancelCreate
     * @request POST:/planner/tae/{timetableId}/{taskId}/cancel/
     */ plannerTaeCancelCreate: <T extends FetchKeys<void>>(
      taskId: number,
      timetableId: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/tae/${timetableId}/${taskId}/cancel/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description It sets to: - "discard" status if previous status is "succeeded" - "discard_and_failed" status if previous status is "failed"
     *
     * @tags planner
     * @name PlannerTaeDiscardCreate
     * @request POST:/planner/tae/{timetableId}/{taskId}/discard/
     */ plannerTaeDiscardCreate: <T extends FetchKeys<void>>(
      taskId: number,
      timetableId: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/tae/${timetableId}/${taskId}/discard/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerTaeRegenerateCreate
     * @request POST:/planner/tae/{timetableId}/{taskId}/regenerate/
     */ plannerTaeRegenerateCreate: <T extends FetchKeys<TAEGeneratorTask>>(
      taskId: number,
      timetableId: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TAEGeneratorTask, T>, MessageResponseSchema>({
        path: `/planner/tae/${timetableId}/${taskId}/regenerate/`,
        method: 'POST',
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerTaeProcessStateRetrieve
     * @request GET:/planner/tae/{timetableId}/process-state/
     */ plannerTaeProcessStateRetrieve: <T extends FetchKeys<TAEGeneratorTask>>(
      timetableId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TAEGeneratorTask, T>, any>({
        path: `/planner/tae/${timetableId}/process-state/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  privateMedia = {}
  progressComments = {}
  public = {}
  recovery = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags recovery
     * @name RecoveryList
     * @request GET:/recovery/
     */
    recoveryList: <T extends FetchKeys<PaginatedAdminRecoveryList>>(
      query?: {
        /** @format uuid */
        assigned_to?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        school_programme__type?:
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'
        /** A search term. */
        search?: string
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminRecoveryList, T>, any>({
        path: `/recovery/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags recovery
     * @name RecoveryRetrieve
     * @request GET:/recovery/{id}/
     */ recoveryRetrieve: <T extends FetchKeys<AdminRecovery>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminRecovery, T>, any>({
        path: `/recovery/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags recovery
     * @name RecoveryUpdate
     * @request PUT:/recovery/{id}/
     */ recoveryUpdate: <T extends FetchKeys<AdminRecovery>>(
      id: string,
      data: AdminRecoveryRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminRecovery, T>, any>({
        path: `/recovery/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags recovery
     * @name RecoveryPartialUpdate
     * @request PATCH:/recovery/{id}/
     */ recoveryPartialUpdate: <T extends FetchKeys<AdminRecovery>>(
      id: string,
      data: PatchedAdminRecoveryRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminRecovery, T>, any>({
        path: `/recovery/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags recovery
     * @name RecoveryAvailableFiltersRetrieve
     * @request GET:/recovery/available-filters/
     */ recoveryAvailableFiltersRetrieve: <
      T extends FetchKeys<RecoveryAvailableFilters>
    >(
      query?: {
        /** @format uuid */
        assigned_to?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        school_programme__type?:
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'
        /** A search term. */
        search?: string
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RecoveryAvailableFilters, T>, any>({
        path: `/recovery/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description return cached staff users map
     *
     * @tags recovery
     * @name RecoveryAvailableStaffUsersList
     * @request GET:/recovery/available-staff-users/
     */ recoveryAvailableStaffUsersList: <T extends FetchKeys<StringOption[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/recovery/available-staff-users/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  reports = {}
  roomBookings = {
    /**
     * No description
     *
     * @tags room-bookings
     * @name RoomBookingsCreate
     * @request POST:/room-bookings/
     */
    roomBookingsCreate: <T extends FetchKeys<RoomBookingCreate>>(
      data: RoomBookingCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RoomBookingCreate, T>, any>({
        path: `/room-bookings/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags room-bookings
     * @name RoomBookingsUpdate
     * @request PUT:/room-bookings/{id}/
     */ roomBookingsUpdate: <T extends FetchKeys<RoomBookingUpdate>>(
      id: number,
      data: RoomBookingUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RoomBookingUpdate, T>, any>({
        path: `/room-bookings/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags room-bookings
     * @name RoomBookingsPartialUpdate
     * @request PATCH:/room-bookings/{id}/
     */ roomBookingsPartialUpdate: <T extends FetchKeys<RoomBookingUpdate>>(
      id: number,
      data: PatchedRoomBookingUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RoomBookingUpdate, T>, any>({
        path: `/room-bookings/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags room-bookings
     * @name RoomBookingsDestroy
     * @request DELETE:/room-bookings/{id}/
     */ roomBookingsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/room-bookings/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags room-bookings
     * @name RoomBookingsBookForOptionsList
     * @request GET:/room-bookings/book-for-options/
     */ roomBookingsBookForOptionsList: <
      T extends FetchKeys<RoomBookingBookForOptions[]>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RoomBookingBookForOptions[], T>, any>({
        path: `/room-bookings/book-for-options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  rooms = {
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsList
     * @request GET:/rooms/
     */
    roomsList: <T extends FetchKeys<PaginatedRoomList>>(
      query?: {
        available_for_exam?: boolean
        department?: number
        is_private?: boolean
        msl_room?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        pri_room?: boolean
        schedule_automatically?: boolean
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedRoomList, T>, any>({
        path: `/rooms/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsCreate
     * @request POST:/rooms/
     */ roomsCreate: <T extends FetchKeys<Room>>(
      data: RoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsRetrieve
     * @request GET:/rooms/{id}/
     */ roomsRetrieve: <T extends FetchKeys<Room>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsUpdate
     * @request PUT:/rooms/{id}/
     */ roomsUpdate: <T extends FetchKeys<Room>>(
      id: number,
      data: RoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsPartialUpdate
     * @request PATCH:/rooms/{id}/
     */ roomsPartialUpdate: <T extends FetchKeys<Room>>(
      id: number,
      data: PatchedRoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsDestroy
     * @request DELETE:/rooms/{id}/
     */ roomsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/rooms/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsAvailableRetrieve
     * @request GET:/rooms/available/
     */ roomsAvailableRetrieve: <T extends FetchKeys<Room>>(
      query: {
        available_for_exam?: boolean
        /** @format uuid */
        course: string
        day?: number
        department?: number
        /** @format double */
        duration?: number
        hour?: number
        is_private?: boolean
        msl_room?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        pri_room?: boolean
        schedule_automatically?: boolean
        /** A search term. */
        search?: string
        section: number
        students: string[]
        /** @format uuid */
        teacher: string
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/available/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description list rooms that include is_occupied regards chosen lesson event
     *
     * @tags rooms
     * @name RoomsWithOccupationRetrieve
     * @request GET:/rooms/with-occupation/
     */ roomsWithOccupationRetrieve: <T extends FetchKeys<Room>>(
      query?: {
        available_for_exam?: boolean
        department?: number
        is_private?: boolean
        msl_room?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        pri_room?: boolean
        schedule_automatically?: boolean
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/with-occupation/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  schema = {}
  schoolBreaks = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksList
     * @request GET:/school-breaks/
     */
    schoolBreaksList: <T extends FetchKeys<PaginatedSchoolBreakList>>(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        school_year?: number[]
        /** A search term. */
        search?: string
        /**
         * * `past` - Past
         * * `active` - Active
         * * `future` - Future
         */
        status?: 'active' | 'future' | 'past'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSchoolBreakList, T>, any>({
        path: `/school-breaks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksCreate
     * @request POST:/school-breaks/
     */ schoolBreaksCreate: <T extends FetchKeys<SchoolBreak>>(
      data: SchoolBreakRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolBreak, T>, any>({
        path: `/school-breaks/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksRetrieve
     * @request GET:/school-breaks/{id}/
     */ schoolBreaksRetrieve: <T extends FetchKeys<SchoolBreak>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolBreak, T>, any>({
        path: `/school-breaks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksUpdate
     * @request PUT:/school-breaks/{id}/
     */ schoolBreaksUpdate: <T extends FetchKeys<SchoolBreak>>(
      id: number,
      data: SchoolBreakRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolBreak, T>, any>({
        path: `/school-breaks/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksPartialUpdate
     * @request PATCH:/school-breaks/{id}/
     */ schoolBreaksPartialUpdate: <T extends FetchKeys<SchoolBreak>>(
      id: number,
      data: PatchedSchoolBreakRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolBreak, T>, any>({
        path: `/school-breaks/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksDestroy
     * @request DELETE:/school-breaks/{id}/
     */ schoolBreaksDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/school-breaks/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  schoolProgrammes = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesList
     * @request GET:/school-programmes/
     */
    schoolProgrammesList: <
      T extends FetchKeys<PaginatedSchoolProgrammeListList>
    >(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        is_compulsory?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        participant?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Requested
         * * `approved` - Approved
         * * `rejected` - Rejected
         */
        status?: 'approved' | 'rejected' | 'requested'
        student?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        type?:
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSchoolProgrammeListList, T>, any>({
        path: `/school-programmes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesCreate
     * @request POST:/school-programmes/
     */ schoolProgrammesCreate: <T extends FetchKeys<SchoolProgramme>>(
      data: SchoolProgrammeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesRetrieve
     * @request GET:/school-programmes/{id}/
     */ schoolProgrammesRetrieve: <T extends FetchKeys<SchoolProgramme>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesUpdate
     * @request PUT:/school-programmes/{id}/
     */ schoolProgrammesUpdate: <T extends FetchKeys<SchoolProgramme>>(
      id: number,
      data: SchoolProgrammeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesPartialUpdate
     * @request PATCH:/school-programmes/{id}/
     */ schoolProgrammesPartialUpdate: <T extends FetchKeys<SchoolProgramme>>(
      id: number,
      data: PatchedSchoolProgrammeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesDestroy
     * @request DELETE:/school-programmes/{id}/
     */ schoolProgrammesDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/school-programmes/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesApproveProgrammeCreate
     * @request POST:/school-programmes/{id}/approve-programme/
     */ schoolProgrammesApproveProgrammeCreate: <
      T extends FetchKeys<SchoolProgramme>
    >(
      id: number,
      data: SchoolProgrammeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/${id}/approve-programme/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesRejectProgrammeCreate
     * @request POST:/school-programmes/{id}/reject-programme/
     */ schoolProgrammesRejectProgrammeCreate: <
      T extends FetchKeys<SchoolProgrammeReject>
    >(
      id: number,
      data: SchoolProgrammeRejectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgrammeReject, T>, any>({
        path: `/school-programmes/${id}/reject-programme/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesRequestProgrammeCreate
     * @request POST:/school-programmes/{id}/request-programme/
     */ schoolProgrammesRequestProgrammeCreate: <
      T extends FetchKeys<SchoolProgramme>
    >(
      id: number,
      data: SchoolProgrammeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/${id}/request-programme/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsList
     * @request GET:/school-programmes/{programmeId}/attendance-reports/
     */ schoolProgrammesAttendanceReportsList: <
      T extends FetchKeys<PaginatedProgrammeAttendanceReportList>
    >(
      programmeId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedProgrammeAttendanceReportList, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsCreate
     * @request POST:/school-programmes/{programmeId}/attendance-reports/
     */ schoolProgrammesAttendanceReportsCreate: <
      T extends FetchKeys<ProgrammeAttendanceReportCreate>
    >(
      programmeId: string,
      data: ProgrammeAttendanceReportCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeAttendanceReportCreate, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsRetrieve
     * @request GET:/school-programmes/{programmeId}/attendance-reports/{id}/
     */ schoolProgrammesAttendanceReportsRetrieve: <
      T extends FetchKeys<ProgrammeAttendanceReport>
    >(
      id: number,
      programmeId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeAttendanceReport, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsUpdate
     * @request PUT:/school-programmes/{programmeId}/attendance-reports/{id}/
     */ schoolProgrammesAttendanceReportsUpdate: <
      T extends FetchKeys<ProgrammeAttendanceReportUpdate>
    >(
      id: number,
      programmeId: string,
      data: ProgrammeAttendanceReportUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeAttendanceReportUpdate, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsPartialUpdate
     * @request PATCH:/school-programmes/{programmeId}/attendance-reports/{id}/
     */ schoolProgrammesAttendanceReportsPartialUpdate: <
      T extends FetchKeys<ProgrammeAttendanceReport>
    >(
      id: number,
      programmeId: string,
      data: PatchedProgrammeAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeAttendanceReport, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsStudentsList
     * @request GET:/school-programmes/{programmeId}/attendance-reports/{reportId}/students/
     */ schoolProgrammesAttendanceReportsStudentsList: <
      T extends FetchKeys<PaginatedProgrammeStudentAttendanceList>
    >(
      programmeId: string,
      reportId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedProgrammeStudentAttendanceList, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${reportId}/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsStudentsRetrieve
     * @request GET:/school-programmes/{programmeId}/attendance-reports/{reportId}/students/{id}/
     */ schoolProgrammesAttendanceReportsStudentsRetrieve: <
      T extends FetchKeys<ProgrammeStudentAttendance>
    >(
      id: number,
      programmeId: string,
      reportId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeStudentAttendance, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${reportId}/students/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsStudentsUpdate
     * @request PUT:/school-programmes/{programmeId}/attendance-reports/{reportId}/students/{id}/
     */ schoolProgrammesAttendanceReportsStudentsUpdate: <
      T extends FetchKeys<ProgrammeStudentAttendanceUpdate>
    >(
      id: number,
      programmeId: string,
      reportId: string,
      data: ProgrammeStudentAttendanceUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeStudentAttendanceUpdate, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${reportId}/students/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsStudentsPartialUpdate
     * @request PATCH:/school-programmes/{programmeId}/attendance-reports/{reportId}/students/{id}/
     */ schoolProgrammesAttendanceReportsStudentsPartialUpdate: <
      T extends FetchKeys<ProgrammeStudentAttendance>
    >(
      id: number,
      programmeId: string,
      reportId: string,
      data: PatchedProgrammeStudentAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeStudentAttendance, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${reportId}/students/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsStudentsEnrolmentHouseNameRetrieve
     * @request GET:/school-programmes/{programmeId}/attendance-reports/{reportId}/students/{id}/enrolment-house-name/
     */ schoolProgrammesAttendanceReportsStudentsEnrolmentHouseNameRetrieve: <
      T extends FetchKeys<ProgrammeStudentAttendance>
    >(
      id: number,
      programmeId: string,
      reportId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeStudentAttendance, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${reportId}/students/${id}/enrolment-house-name/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesAvailableFiltersRetrieve
     * @request GET:/school-programmes/available-filters/
     */ schoolProgrammesAvailableFiltersRetrieve: <
      T extends FetchKeys<SchoolProgrammeAvailableFilters>
    >(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        is_compulsory?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        participant?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Requested
         * * `approved` - Approved
         * * `rejected` - Rejected
         */
        status?: 'approved' | 'rejected' | 'requested'
        student?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        type?:
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgrammeAvailableFilters, T>, any>({
        path: `/school-programmes/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesAvailableStaffUsersRetrieve
     * @request GET:/school-programmes/available-staff-users/
     */ schoolProgrammesAvailableStaffUsersRetrieve: <
      T extends FetchKeys<SchoolProgramme>
    >(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        is_compulsory?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        participant?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Requested
         * * `approved` - Approved
         * * `rejected` - Rejected
         */
        status?: 'approved' | 'rejected' | 'requested'
        student?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        type?:
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/available-staff-users/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesAvailableStudentsList
     * @request GET:/school-programmes/available-students/
     */ schoolProgrammesAvailableStudentsList: <
      T extends FetchKeys<PaginatedAvailableStudentList>
    >(
      query?: {
        ages?: string[]
        /**
         * * `4` - S
         * * `3` - E
         * * `2` - A
         * * `1` - C
         * * `0` - not assigned
         */
        categories?: (0 | 1 | 2 | 3 | 4)[]
        grade_levels?: string[]
        houses?: number[]
        /** @format uuid */
        id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAvailableStudentList, T>, any>({
        path: `/school-programmes/available-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesAvailableStudentsAvailableFiltersRetrieve
     * @request GET:/school-programmes/available-students/available-filters/
     */ schoolProgrammesAvailableStudentsAvailableFiltersRetrieve: <
      T extends FetchKeys<AvailableStudentForSchoolProgrammeAvailableFilters>
    >(
      query?: {
        ages?: string[]
        /**
         * * `4` - S
         * * `3` - E
         * * `2` - A
         * * `1` - C
         * * `0` - not assigned
         */
        categories?: (0 | 1 | 2 | 3 | 4)[]
        grade_levels?: string[]
        houses?: number[]
        /** @format uuid */
        id?: string
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<AvailableStudentForSchoolProgrammeAvailableFilters, T>,
        any
      >({
        path: `/school-programmes/available-students/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetAsApprovedCreate
     * @request POST:/school-programmes/enrolments/{id}/set-as-approved/
     */ schoolProgrammesEnrolmentsSetAsApprovedCreate: <
      T extends FetchKeys<ApproveEnrolment>
    >(
      id: number,
      data: ApproveEnrolmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ApproveEnrolment, T>, any>({
        path: `/school-programmes/enrolments/${id}/set-as-approved/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetAsRejectedCreate
     * @request POST:/school-programmes/enrolments/{id}/set-as-rejected/
     */ schoolProgrammesEnrolmentsSetAsRejectedCreate: <
      T extends FetchKeys<RejectEnrolment>
    >(
      id: number,
      data: RejectEnrolmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RejectEnrolment, T>, any>({
        path: `/school-programmes/enrolments/${id}/set-as-rejected/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetAsRequestedCreate
     * @request POST:/school-programmes/enrolments/{id}/set-as-requested/
     */ schoolProgrammesEnrolmentsSetAsRequestedCreate: <
      T extends FetchKeys<void>
    >(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/school-programmes/enrolments/${id}/set-as-requested/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetManyAsApprovedCreate
     * @request POST:/school-programmes/enrolments/set-many-as-approved/
     */ schoolProgrammesEnrolmentsSetManyAsApprovedCreate: <
      T extends FetchKeys<ManyEnrolmentsToApproved>
    >(
      data: ManyEnrolmentsToApprovedRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyEnrolmentsToApproved, T>, any>({
        path: `/school-programmes/enrolments/set-many-as-approved/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetManyAsRejectedCreate
     * @request POST:/school-programmes/enrolments/set-many-as-rejected/
     */ schoolProgrammesEnrolmentsSetManyAsRejectedCreate: <
      T extends FetchKeys<ManyEnrolmentsToRejected>
    >(
      data: ManyEnrolmentsToRejectedRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyEnrolmentsToRejected, T>, any>({
        path: `/school-programmes/enrolments/set-many-as-rejected/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetManyAsRequestedCreate
     * @request POST:/school-programmes/enrolments/set-many-as-requested/
     */ schoolProgrammesEnrolmentsSetManyAsRequestedCreate: <
      T extends FetchKeys<ManyEnrolmentsToRequested>
    >(
      data: ManyEnrolmentsToRequestedRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyEnrolmentsToRequested, T>, any>({
        path: `/school-programmes/enrolments/set-many-as-requested/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  sickReports = {
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsList
     * @request GET:/sick-reports/
     */
    sickReportsList: <T extends FetchKeys<PaginatedSickReportListList>>(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        status?: string
        /** @format uuid */
        student?: string
        /**
         * * `regular` - Regular
         * * `med_only` - Med-only
         */
        type?: 'med_only' | 'regular'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSickReportListList, T>, any>({
        path: `/sick-reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCreate
     * @request POST:/sick-reports/
     */ sickReportsCreate: <T extends FetchKeys<SickReport>>(
      data: SickReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReport, T>, any>({
        path: `/sick-reports/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsRetrieve
     * @request GET:/sick-reports/{id}/
     */ sickReportsRetrieve: <T extends FetchKeys<SickReport>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReport, T>, any>({
        path: `/sick-reports/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsUpdate
     * @request PUT:/sick-reports/{id}/
     */ sickReportsUpdate: <T extends FetchKeys<SickReport>>(
      id: string,
      data: SickReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReport, T>, any>({
        path: `/sick-reports/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsPartialUpdate
     * @request PATCH:/sick-reports/{id}/
     */ sickReportsPartialUpdate: <T extends FetchKeys<SickReport>>(
      id: string,
      data: PatchedSickReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReport, T>, any>({
        path: `/sick-reports/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsDestroy
     * @request DELETE:/sick-reports/{id}/
     */ sickReportsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsApproveClosureCreate
     * @request POST:/sick-reports/{id}/approve-closure/
     */ sickReportsApproveClosureCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/${id}/approve-closure/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCloseCreate
     * @request POST:/sick-reports/{id}/close/
     */ sickReportsCloseCreate: <T extends FetchKeys<CloseSickReport>>(
      id: string,
      data: CloseSickReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CloseSickReport, T>, any>({
        path: `/sick-reports/${id}/close/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsHasActiveMedicineInPlanRetrieve
     * @request GET:/sick-reports/{id}/has-active-medicine-in-plan/
     */ sickReportsHasActiveMedicineInPlanRetrieve: <
      T extends FetchKeys<SickReport>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReport, T>, any>({
        path: `/sick-reports/${id}/has-active-medicine-in-plan/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsOpenCreate
     * @request POST:/sick-reports/{id}/open/
     */ sickReportsOpenCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/${id}/open/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsRejectClosureCreate
     * @request POST:/sick-reports/{id}/reject-closure/
     */ sickReportsRejectClosureCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/${id}/reject-closure/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsRequestClosureCreate
     * @request POST:/sick-reports/{id}/request-closure/
     */ sickReportsRequestClosureCreate: <
      T extends FetchKeys<SickReportClosureRequest>
    >(
      id: string,
      data: SickReportClosureRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportClosureRequest, T>, any>({
        path: `/sick-reports/${id}/request-closure/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsList
     * @request GET:/sick-reports/{sickReportId}/comments/
     */ sickReportsCommentsList: <T extends FetchKeys<SickReportComment[]>>(
      sickReportId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportComment[], T>, any>({
        path: `/sick-reports/${sickReportId}/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsCreate
     * @request POST:/sick-reports/{sickReportId}/comments/
     */ sickReportsCommentsCreate: <T extends FetchKeys<SickReportComment>>(
      sickReportId: string,
      data: SickReportCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportComment, T>, any>({
        path: `/sick-reports/${sickReportId}/comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsRetrieve
     * @request GET:/sick-reports/{sickReportId}/comments/{id}/
     */ sickReportsCommentsRetrieve: <T extends FetchKeys<SickReportComment>>(
      id: string,
      sickReportId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportComment, T>, any>({
        path: `/sick-reports/${sickReportId}/comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsUpdate
     * @request PUT:/sick-reports/{sickReportId}/comments/{id}/
     */ sickReportsCommentsUpdate: <T extends FetchKeys<SickReportComment>>(
      id: string,
      sickReportId: string,
      data: SickReportCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportComment, T>, any>({
        path: `/sick-reports/${sickReportId}/comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsPartialUpdate
     * @request PATCH:/sick-reports/{sickReportId}/comments/{id}/
     */ sickReportsCommentsPartialUpdate: <
      T extends FetchKeys<SickReportComment>
    >(
      id: string,
      sickReportId: string,
      data: PatchedSickReportCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportComment, T>, any>({
        path: `/sick-reports/${sickReportId}/comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsDestroy
     * @request DELETE:/sick-reports/{sickReportId}/comments/{id}/
     */ sickReportsCommentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      sickReportId: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/${sickReportId}/comments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsList
     * @request GET:/sick-reports/appointments/
     */ sickReportsAppointmentsList: <T extends FetchKeys<Appointment[]>>(
      query?: {
        report?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Appointment[], T>, any>({
        path: `/sick-reports/appointments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsCreate
     * @request POST:/sick-reports/appointments/
     */ sickReportsAppointmentsCreate: <T extends FetchKeys<Appointment>>(
      data: AppointmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Appointment, T>, any>({
        path: `/sick-reports/appointments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsRetrieve
     * @request GET:/sick-reports/appointments/{id}/
     */ sickReportsAppointmentsRetrieve: <T extends FetchKeys<Appointment>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Appointment, T>, any>({
        path: `/sick-reports/appointments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsUpdate
     * @request PUT:/sick-reports/appointments/{id}/
     */ sickReportsAppointmentsUpdate: <T extends FetchKeys<Appointment>>(
      id: number,
      data: AppointmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Appointment, T>, any>({
        path: `/sick-reports/appointments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsPartialUpdate
     * @request PATCH:/sick-reports/appointments/{id}/
     */ sickReportsAppointmentsPartialUpdate: <
      T extends FetchKeys<Appointment>
    >(
      id: number,
      data: PatchedAppointmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Appointment, T>, any>({
        path: `/sick-reports/appointments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsDestroy
     * @request DELETE:/sick-reports/appointments/{id}/
     */ sickReportsAppointmentsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/appointments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsCancelUpdate
     * @request PUT:/sick-reports/appointments/{id}/cancel/
     */ sickReportsAppointmentsCancelUpdate: <
      T extends FetchKeys<AppointmentCancellation>
    >(
      id: number,
      data: AppointmentCancellationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AppointmentCancellation, T>, any>({
        path: `/sick-reports/appointments/${id}/cancel/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsCancelPartialUpdate
     * @request PATCH:/sick-reports/appointments/{id}/cancel/
     */ sickReportsAppointmentsCancelPartialUpdate: <
      T extends FetchKeys<AppointmentCancellation>
    >(
      id: number,
      data: PatchedAppointmentCancellationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AppointmentCancellation, T>, any>({
        path: `/sick-reports/appointments/${id}/cancel/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsGetDiagnosisRetrieve
     * @request GET:/sick-reports/appointments/{id}/get-diagnosis/
     */ sickReportsAppointmentsGetDiagnosisRetrieve: <
      T extends FetchKeys<DoctorDiagnosis>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DoctorDiagnosis, T>, any>({
        path: `/sick-reports/appointments/${id}/get-diagnosis/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsSetDiagnosisUpdate
     * @request PUT:/sick-reports/appointments/{id}/set-diagnosis/
     */ sickReportsAppointmentsSetDiagnosisUpdate: <
      T extends FetchKeys<DoctorDiagnosis>
    >(
      id: number,
      data: DoctorDiagnosisRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DoctorDiagnosis, T>, any>({
        path: `/sick-reports/appointments/${id}/set-diagnosis/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsSetDiagnosisPartialUpdate
     * @request PATCH:/sick-reports/appointments/{id}/set-diagnosis/
     */ sickReportsAppointmentsSetDiagnosisPartialUpdate: <
      T extends FetchKeys<DoctorDiagnosis>
    >(
      id: number,
      data: PatchedDoctorDiagnosisRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DoctorDiagnosis, T>, any>({
        path: `/sick-reports/appointments/${id}/set-diagnosis/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCloseReasonsList
     * @request GET:/sick-reports/close-reasons/
     */ sickReportsCloseReasonsList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        status?: string
        /** @format uuid */
        student?: string
        /**
         * * `regular` - Regular
         * * `med_only` - Med-only
         */
        type?: 'med_only' | 'regular'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/sick-reports/close-reasons/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsFirstContactRetrieve
     * @request GET:/sick-reports/first-contact/{reportId}/
     */ sickReportsFirstContactRetrieve: <T extends FetchKeys<FirstContact>>(
      reportId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FirstContact, T>, any>({
        path: `/sick-reports/first-contact/${reportId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsFirstContactCreate
     * @request POST:/sick-reports/first-contact/{reportId}/
     */ sickReportsFirstContactCreate: <T extends FetchKeys<FirstContact>>(
      reportId: string,
      data: FirstContactRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FirstContact, T>, any>({
        path: `/sick-reports/first-contact/${reportId}/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsFirstContactUpdate
     * @request PUT:/sick-reports/first-contact/{reportId}/
     */ sickReportsFirstContactUpdate: <T extends FetchKeys<FirstContact>>(
      reportId: string,
      data: FirstContactRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FirstContact, T>, any>({
        path: `/sick-reports/first-contact/${reportId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsFirstContactPartialUpdate
     * @request PATCH:/sick-reports/first-contact/{reportId}/
     */ sickReportsFirstContactPartialUpdate: <
      T extends FetchKeys<FirstContact>
    >(
      reportId: string,
      data: PatchedFirstContactRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FirstContact, T>, any>({
        path: `/sick-reports/first-contact/${reportId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsOpenedCovidReportsRetrieve
     * @request GET:/sick-reports/opened-covid-reports/
     */ sickReportsOpenedCovidReportsRetrieve: <
      T extends FetchKeys<SimpleSickReport>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        status?: string
        /** @format uuid */
        student?: string
        /**
         * * `regular` - Regular
         * * `med_only` - Med-only
         */
        type?: 'med_only' | 'regular'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SimpleSickReport, T>, any>({
        path: `/sick-reports/opened-covid-reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsSickReportAbsenceDatesCreate
     * @request POST:/sick-reports/sick-report-absence-dates/
     */ sickReportsSickReportAbsenceDatesCreate: <
      T extends FetchKeys<SickReportAbsenceDates>
    >(
      data: SickReportAbsenceDatesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportAbsenceDates, T>, any>({
        path: `/sick-reports/sick-report-absence-dates/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsSickReportAbsenceDatesUpdate
     * @request PUT:/sick-reports/sick-report-absence-dates/{id}/
     */ sickReportsSickReportAbsenceDatesUpdate: <
      T extends FetchKeys<SickReportAbsenceDates>
    >(
      id: string,
      data: SickReportAbsenceDatesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportAbsenceDates, T>, any>({
        path: `/sick-reports/sick-report-absence-dates/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsSickReportAbsenceDatesPartialUpdate
     * @request PATCH:/sick-reports/sick-report-absence-dates/{id}/
     */ sickReportsSickReportAbsenceDatesPartialUpdate: <
      T extends FetchKeys<SickReportAbsenceDates>
    >(
      id: string,
      data: PatchedSickReportAbsenceDatesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportAbsenceDates, T>, any>({
        path: `/sick-reports/sick-report-absence-dates/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsSymptomsList
     * @request GET:/sick-reports/symptoms/
     */ sickReportsSymptomsList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        status?: string
        /** @format uuid */
        student?: string
        /**
         * * `regular` - Regular
         * * `med_only` - Med-only
         */
        type?: 'med_only' | 'regular'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/sick-reports/symptoms/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsTreatmentHistoryList
     * @request GET:/sick-reports/treatment-history/{studentId}/
     */ sickReportsTreatmentHistoryList: <
      T extends FetchKeys<TreatmentHistoryItem[]>
    >(
      studentId: string,
      query?: {
        /** @format date */
        history_created_after?: string
        /** @format date */
        history_created_before?: string
        /** Which field to use when ordering the results. */
        treatment_history_ordering?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TreatmentHistoryItem[], T>, any>({
        path: `/sick-reports/treatment-history/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  studentApplications = {}
  student = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesList
     * @request GET:/student/campus-absences/
     */
    studentCampusAbsencesList: <
      T extends FetchKeys<PaginatedStudentCampusAbsenceList>
    >(
      query?: {
        /**
         * * `afternoon_ausgang` - Afternoon Ausgang
         * * `evening_ausgang` - Evening Ausgang
         * * `evening_campus_ausgang` - Evening - Campus Ausgang
         * * `birthday_ausgang` - Birthday Ausgang
         * * `family_visit` - Family visit
         * * `on_board` - On board Ausgang
         * * `prolonged_ausgang` - Prolonged Ausgang
         * * `campus` - Campus
         * * `offsite` - Offsite
         * * `offsite_overnight` - Offsite, Overnight
         * * `official_appointment` - Official appointment
         * * `specialist_doctor_appointment` - Specialist Doctor Appointment
         * * `programme` - Programme
         * * `house_evening` - House evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `book_the_cafe` - Book the Cafe
         * * `city_brunch_zurich` - City Brunch Zurich
         * * `additional_ausgang` - Additional Ausgang
         */
        campus_absence_subtype?: (
          | 'additional_ausgang'
          | 'afternoon_ausgang'
          | 'birthday_ausgang'
          | 'book_the_cafe'
          | 'campus'
          | 'city_brunch_zurich'
          | 'evening_ausgang'
          | 'evening_campus_ausgang'
          | 'family_visit'
          | 'house_evening'
          | 'official_appointment'
          | 'offsite'
          | 'offsite_overnight'
          | 'on_board'
          | 'other'
          | 'programme'
          | 'prolonged_ausgang'
          | 'school_trip'
          | 'specialist_doctor_appointment'
          | 'term_break'
          | 'weekend_programme'
        )[]
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?: (
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        )[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?: (
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentCampusAbsenceList, T>, any>({
        path: `/student/campus-absences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesCreate
     * @request POST:/student/campus-absences/
     */ studentCampusAbsencesCreate: <
      T extends FetchKeys<StudentCampusAbsence>
    >(
      data: StudentCampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsence, T>, any>({
        path: `/student/campus-absences/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesRetrieve
     * @request GET:/student/campus-absences/{id}/
     */ studentCampusAbsencesRetrieve: <
      T extends FetchKeys<StudentCampusAbsence>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsence, T>, any>({
        path: `/student/campus-absences/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesUpdate
     * @request PUT:/student/campus-absences/{id}/
     */ studentCampusAbsencesUpdate: <
      T extends FetchKeys<StudentCampusAbsence>
    >(
      id: string,
      data: StudentCampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsence, T>, any>({
        path: `/student/campus-absences/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesPartialUpdate
     * @request PATCH:/student/campus-absences/{id}/
     */ studentCampusAbsencesPartialUpdate: <
      T extends FetchKeys<StudentCampusAbsence>
    >(
      id: string,
      data: PatchedStudentCampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsence, T>, any>({
        path: `/student/campus-absences/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesDestroy
     * @request DELETE:/student/campus-absences/{id}/
     */ studentCampusAbsencesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/student/campus-absences/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesStatusesList
     * @request GET:/student/campus-absences/statuses/
     */ studentCampusAbsencesStatusesList: <
      T extends FetchKeys<StudentCampusAbsenceStatus[]>
    >(
      query?: {
        /**
         * * `afternoon_ausgang` - Afternoon Ausgang
         * * `evening_ausgang` - Evening Ausgang
         * * `evening_campus_ausgang` - Evening - Campus Ausgang
         * * `birthday_ausgang` - Birthday Ausgang
         * * `family_visit` - Family visit
         * * `on_board` - On board Ausgang
         * * `prolonged_ausgang` - Prolonged Ausgang
         * * `campus` - Campus
         * * `offsite` - Offsite
         * * `offsite_overnight` - Offsite, Overnight
         * * `official_appointment` - Official appointment
         * * `specialist_doctor_appointment` - Specialist Doctor Appointment
         * * `programme` - Programme
         * * `house_evening` - House evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `book_the_cafe` - Book the Cafe
         * * `city_brunch_zurich` - City Brunch Zurich
         * * `additional_ausgang` - Additional Ausgang
         */
        campus_absence_subtype?: (
          | 'additional_ausgang'
          | 'afternoon_ausgang'
          | 'birthday_ausgang'
          | 'book_the_cafe'
          | 'campus'
          | 'city_brunch_zurich'
          | 'evening_ausgang'
          | 'evening_campus_ausgang'
          | 'family_visit'
          | 'house_evening'
          | 'official_appointment'
          | 'offsite'
          | 'offsite_overnight'
          | 'on_board'
          | 'other'
          | 'programme'
          | 'prolonged_ausgang'
          | 'school_trip'
          | 'specialist_doctor_appointment'
          | 'term_break'
          | 'weekend_programme'
        )[]
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?: (
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        )[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?: (
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsenceStatus[], T>, any>({
        path: `/student/campus-absences/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesSubtypeChoicesRetrieve
     * @request GET:/student/campus-absences/subtype-choices/
     */ studentCampusAbsencesSubtypeChoicesRetrieve: <
      T extends FetchKeys<StudentCampusAbsenceSubtype>
    >(
      query?: {
        /**
         * * `afternoon_ausgang` - Afternoon Ausgang
         * * `evening_ausgang` - Evening Ausgang
         * * `evening_campus_ausgang` - Evening - Campus Ausgang
         * * `birthday_ausgang` - Birthday Ausgang
         * * `family_visit` - Family visit
         * * `on_board` - On board Ausgang
         * * `prolonged_ausgang` - Prolonged Ausgang
         * * `campus` - Campus
         * * `offsite` - Offsite
         * * `offsite_overnight` - Offsite, Overnight
         * * `official_appointment` - Official appointment
         * * `specialist_doctor_appointment` - Specialist Doctor Appointment
         * * `programme` - Programme
         * * `house_evening` - House evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `book_the_cafe` - Book the Cafe
         * * `city_brunch_zurich` - City Brunch Zurich
         * * `additional_ausgang` - Additional Ausgang
         */
        campus_absence_subtype?: (
          | 'additional_ausgang'
          | 'afternoon_ausgang'
          | 'birthday_ausgang'
          | 'book_the_cafe'
          | 'campus'
          | 'city_brunch_zurich'
          | 'evening_ausgang'
          | 'evening_campus_ausgang'
          | 'family_visit'
          | 'house_evening'
          | 'official_appointment'
          | 'offsite'
          | 'offsite_overnight'
          | 'on_board'
          | 'other'
          | 'programme'
          | 'prolonged_ausgang'
          | 'school_trip'
          | 'specialist_doctor_appointment'
          | 'term_break'
          | 'weekend_programme'
        )[]
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?: (
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        )[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?: (
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsenceSubtype, T>, any>({
        path: `/student/campus-absences/subtype-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesTypeChoicesList
     * @request GET:/student/campus-absences/type-choices/
     */ studentCampusAbsencesTypeChoicesList: <
      T extends FetchKeys<StudentCampusAbsenceType[]>
    >(
      query?: {
        /**
         * * `afternoon_ausgang` - Afternoon Ausgang
         * * `evening_ausgang` - Evening Ausgang
         * * `evening_campus_ausgang` - Evening - Campus Ausgang
         * * `birthday_ausgang` - Birthday Ausgang
         * * `family_visit` - Family visit
         * * `on_board` - On board Ausgang
         * * `prolonged_ausgang` - Prolonged Ausgang
         * * `campus` - Campus
         * * `offsite` - Offsite
         * * `offsite_overnight` - Offsite, Overnight
         * * `official_appointment` - Official appointment
         * * `specialist_doctor_appointment` - Specialist Doctor Appointment
         * * `programme` - Programme
         * * `house_evening` - House evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `book_the_cafe` - Book the Cafe
         * * `city_brunch_zurich` - City Brunch Zurich
         * * `additional_ausgang` - Additional Ausgang
         */
        campus_absence_subtype?: (
          | 'additional_ausgang'
          | 'afternoon_ausgang'
          | 'birthday_ausgang'
          | 'book_the_cafe'
          | 'campus'
          | 'city_brunch_zurich'
          | 'evening_ausgang'
          | 'evening_campus_ausgang'
          | 'family_visit'
          | 'house_evening'
          | 'official_appointment'
          | 'offsite'
          | 'offsite_overnight'
          | 'on_board'
          | 'other'
          | 'programme'
          | 'prolonged_ausgang'
          | 'school_trip'
          | 'specialist_doctor_appointment'
          | 'term_break'
          | 'weekend_programme'
        )[]
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?: (
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        )[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?: (
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsenceType[], T>, any>({
        path: `/student/campus-absences/type-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  students = {
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsList
     * @request GET:/students/
     */
    studentsList: <T extends FetchKeys<PaginatedStudentListList>>(
      query?: {
        activity__course?: string
        activity__quarter?: number
        activity__school_year?: string
        /** Multiple values may be separated by commas. */
        age?: number[]
        allergen_kinds?: string[]
        birthday_in_week?: boolean
        birthday_today?: boolean
        boarding_status?: string
        category?: number
        chronic_condition_types?: string[]
        current_grade_level?: number
        current_location?: string
        exclude_day_student?: boolean
        /**
         * * `fine-arts` - Fine Arts
         * * `performing-arts` - Performing Arts
         * * `leadership` - Diplomacy & Activism
         * * `robotics` - Robotics & Engineering
         * * `environmental-science` - Environmental Science & Sustainability
         * * `fashion` - Fashion & Textile Design
         * * `informatics` - Informatics & Coding
         * * `intensive-english` - Intensive English
         * * `intensive-german` - Intensive German
         * * `rosenberg-x-crimson-university-admission` - Rosenberg x Crimson University Admission Focus Course
         */
        focus_courses?: (
          | 'environmental-science'
          | 'fashion'
          | 'fine-arts'
          | 'informatics'
          | 'intensive-english'
          | 'intensive-german'
          | 'leadership'
          | 'performing-arts'
          | 'robotics'
          | 'rosenberg-x-crimson-university-admission'
        )[]
        /**
         * * `male` - Male
         * * `female` - Female
         */
        gender?: 'female' | 'male'
        has_dietary_restrictions?: boolean
        has_not_downloaded_certificate?: boolean
        homework_status?: string
        house?: string
        /** @format uuid */
        id?: string
        is_accommodated?: boolean
        is_at_home?: boolean
        is_new?: string
        is_on_senior_campus?: boolean
        is_sick_now?: boolean
        is_vaccinated?: boolean
        /**
         * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
         * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
         * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
         * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
         */
        meal_preference?: 'l1' | 'l2' | 'l3' | 'l4'
        /**
         * * `AF` - Afghanistan
         * * `AX` - Åland Islands
         * * `AL` - Albania
         * * `DZ` - Algeria
         * * `AS` - American Samoa
         * * `AD` - Andorra
         * * `AO` - Angola
         * * `AI` - Anguilla
         * * `AQ` - Antarctica
         * * `AG` - Antigua and Barbuda
         * * `AR` - Argentina
         * * `AM` - Armenia
         * * `AW` - Aruba
         * * `AU` - Australia
         * * `AT` - Austria
         * * `AZ` - Azerbaijan
         * * `BS` - Bahamas
         * * `BH` - Bahrain
         * * `BD` - Bangladesh
         * * `BB` - Barbados
         * * `BY` - Belarus
         * * `BE` - Belgium
         * * `BZ` - Belize
         * * `BJ` - Benin
         * * `BM` - Bermuda
         * * `BT` - Bhutan
         * * `BO` - Bolivia
         * * `BQ` - Bonaire, Sint Eustatius and Saba
         * * `BA` - Bosnia and Herzegovina
         * * `BW` - Botswana
         * * `BV` - Bouvet Island
         * * `BR` - Brazil
         * * `IO` - British Indian Ocean Territory
         * * `BN` - Brunei
         * * `BG` - Bulgaria
         * * `BF` - Burkina Faso
         * * `BI` - Burundi
         * * `CV` - Cabo Verde
         * * `KH` - Cambodia
         * * `CM` - Cameroon
         * * `CA` - Canada
         * * `KY` - Cayman Islands
         * * `CF` - Central African Republic
         * * `TD` - Chad
         * * `CL` - Chile
         * * `CN` - China
         * * `CX` - Christmas Island
         * * `CC` - Cocos (Keeling) Islands
         * * `CO` - Colombia
         * * `KM` - Comoros
         * * `CG` - Congo
         * * `CD` - Congo (the Democratic Republic of the)
         * * `CK` - Cook Islands
         * * `CR` - Costa Rica
         * * `CI` - Côte d'Ivoire
         * * `HR` - Croatia
         * * `CU` - Cuba
         * * `CW` - Curaçao
         * * `CY` - Cyprus
         * * `CZ` - Czechia
         * * `DK` - Denmark
         * * `DJ` - Djibouti
         * * `DM` - Dominica
         * * `DO` - Dominican Republic
         * * `EC` - Ecuador
         * * `EG` - Egypt
         * * `SV` - El Salvador
         * * `GQ` - Equatorial Guinea
         * * `ER` - Eritrea
         * * `EE` - Estonia
         * * `SZ` - Eswatini
         * * `ET` - Ethiopia
         * * `FK` - Falkland Islands (Malvinas)
         * * `FO` - Faroe Islands
         * * `FJ` - Fiji
         * * `FI` - Finland
         * * `FR` - France
         * * `GF` - French Guiana
         * * `PF` - French Polynesia
         * * `TF` - French Southern Territories
         * * `GA` - Gabon
         * * `GM` - Gambia
         * * `GE` - Georgia
         * * `DE` - Germany
         * * `GH` - Ghana
         * * `GI` - Gibraltar
         * * `GR` - Greece
         * * `GL` - Greenland
         * * `GD` - Grenada
         * * `GP` - Guadeloupe
         * * `GU` - Guam
         * * `GT` - Guatemala
         * * `GG` - Guernsey
         * * `GN` - Guinea
         * * `GW` - Guinea-Bissau
         * * `GY` - Guyana
         * * `HT` - Haiti
         * * `HM` - Heard Island and McDonald Islands
         * * `VA` - Holy See
         * * `HN` - Honduras
         * * `HK` - Hong Kong
         * * `HU` - Hungary
         * * `IS` - Iceland
         * * `IN` - India
         * * `ID` - Indonesia
         * * `IR` - Iran
         * * `IQ` - Iraq
         * * `IE` - Ireland
         * * `IM` - Isle of Man
         * * `IL` - Israel
         * * `IT` - Italy
         * * `JM` - Jamaica
         * * `JP` - Japan
         * * `JE` - Jersey
         * * `JO` - Jordan
         * * `KZ` - Kazakhstan
         * * `KE` - Kenya
         * * `KI` - Kiribati
         * * `KW` - Kuwait
         * * `KG` - Kyrgyzstan
         * * `LA` - Laos
         * * `LV` - Latvia
         * * `LB` - Lebanon
         * * `LS` - Lesotho
         * * `LR` - Liberia
         * * `LY` - Libya
         * * `LI` - Liechtenstein
         * * `LT` - Lithuania
         * * `LU` - Luxembourg
         * * `MO` - Macao
         * * `MG` - Madagascar
         * * `MW` - Malawi
         * * `MY` - Malaysia
         * * `MV` - Maldives
         * * `ML` - Mali
         * * `MT` - Malta
         * * `MH` - Marshall Islands
         * * `MQ` - Martinique
         * * `MR` - Mauritania
         * * `MU` - Mauritius
         * * `YT` - Mayotte
         * * `MX` - Mexico
         * * `FM` - Micronesia (Federated States of)
         * * `MD` - Moldova
         * * `MC` - Monaco
         * * `MN` - Mongolia
         * * `ME` - Montenegro
         * * `MS` - Montserrat
         * * `MA` - Morocco
         * * `MZ` - Mozambique
         * * `MM` - Myanmar
         * * `NA` - Namibia
         * * `NR` - Nauru
         * * `NP` - Nepal
         * * `NL` - Netherlands
         * * `NC` - New Caledonia
         * * `NZ` - New Zealand
         * * `NI` - Nicaragua
         * * `NE` - Niger
         * * `NG` - Nigeria
         * * `NU` - Niue
         * * `NF` - Norfolk Island
         * * `KP` - North Korea
         * * `MK` - North Macedonia
         * * `MP` - Northern Mariana Islands
         * * `NO` - Norway
         * * `OM` - Oman
         * * `PK` - Pakistan
         * * `PW` - Palau
         * * `PS` - Palestine, State of
         * * `PA` - Panama
         * * `PG` - Papua New Guinea
         * * `PY` - Paraguay
         * * `PE` - Peru
         * * `PH` - Philippines
         * * `PN` - Pitcairn
         * * `PL` - Poland
         * * `PT` - Portugal
         * * `PR` - Puerto Rico
         * * `QA` - Qatar
         * * `RE` - Réunion
         * * `RO` - Romania
         * * `RU` - Russia
         * * `RW` - Rwanda
         * * `BL` - Saint Barthélemy
         * * `SH` - Saint Helena, Ascension and Tristan da Cunha
         * * `KN` - Saint Kitts and Nevis
         * * `LC` - Saint Lucia
         * * `MF` - Saint Martin (French part)
         * * `PM` - Saint Pierre and Miquelon
         * * `VC` - Saint Vincent and the Grenadines
         * * `WS` - Samoa
         * * `SM` - San Marino
         * * `ST` - Sao Tome and Principe
         * * `SA` - Saudi Arabia
         * * `SN` - Senegal
         * * `RS` - Serbia
         * * `SC` - Seychelles
         * * `SL` - Sierra Leone
         * * `SG` - Singapore
         * * `SX` - Sint Maarten (Dutch part)
         * * `SK` - Slovakia
         * * `SI` - Slovenia
         * * `SB` - Solomon Islands
         * * `SO` - Somalia
         * * `ZA` - South Africa
         * * `GS` - South Georgia and the South Sandwich Islands
         * * `KR` - South Korea
         * * `SS` - South Sudan
         * * `ES` - Spain
         * * `LK` - Sri Lanka
         * * `SD` - Sudan
         * * `SR` - Suriname
         * * `SJ` - Svalbard and Jan Mayen
         * * `SE` - Sweden
         * * `CH` - Switzerland
         * * `SY` - Syria
         * * `TW` - Taiwan
         * * `TJ` - Tajikistan
         * * `TZ` - Tanzania
         * * `TH` - Thailand
         * * `TL` - Timor-Leste
         * * `TG` - Togo
         * * `TK` - Tokelau
         * * `TO` - Tonga
         * * `TT` - Trinidad and Tobago
         * * `TN` - Tunisia
         * * `TR` - Türkiye
         * * `TM` - Turkmenistan
         * * `TC` - Turks and Caicos Islands
         * * `TV` - Tuvalu
         * * `UG` - Uganda
         * * `UA` - Ukraine
         * * `AE` - United Arab Emirates
         * * `GB` - United Kingdom
         * * `UM` - United States Minor Outlying Islands
         * * `US` - United States of America
         * * `UY` - Uruguay
         * * `UZ` - Uzbekistan
         * * `VU` - Vanuatu
         * * `VE` - Venezuela
         * * `VN` - Vietnam
         * * `VG` - Virgin Islands (British)
         * * `VI` - Virgin Islands (U.S.)
         * * `WF` - Wallis and Futuna
         * * `EH` - Western Sahara
         * * `YE` - Yemen
         * * `ZM` - Zambia
         * * `ZW` - Zimbabwe
         */
        nationalities?: (
          | 'AD'
          | 'AE'
          | 'AF'
          | 'AG'
          | 'AI'
          | 'AL'
          | 'AM'
          | 'AO'
          | 'AQ'
          | 'AR'
          | 'AS'
          | 'AT'
          | 'AU'
          | 'AW'
          | 'AX'
          | 'AZ'
          | 'BA'
          | 'BB'
          | 'BD'
          | 'BE'
          | 'BF'
          | 'BG'
          | 'BH'
          | 'BI'
          | 'BJ'
          | 'BL'
          | 'BM'
          | 'BN'
          | 'BO'
          | 'BQ'
          | 'BR'
          | 'BS'
          | 'BT'
          | 'BV'
          | 'BW'
          | 'BY'
          | 'BZ'
          | 'CA'
          | 'CC'
          | 'CD'
          | 'CF'
          | 'CG'
          | 'CH'
          | 'CI'
          | 'CK'
          | 'CL'
          | 'CM'
          | 'CN'
          | 'CO'
          | 'CR'
          | 'CU'
          | 'CV'
          | 'CW'
          | 'CX'
          | 'CY'
          | 'CZ'
          | 'DE'
          | 'DJ'
          | 'DK'
          | 'DM'
          | 'DO'
          | 'DZ'
          | 'EC'
          | 'EE'
          | 'EG'
          | 'EH'
          | 'ER'
          | 'ES'
          | 'ET'
          | 'FI'
          | 'FJ'
          | 'FK'
          | 'FM'
          | 'FO'
          | 'FR'
          | 'GA'
          | 'GB'
          | 'GD'
          | 'GE'
          | 'GF'
          | 'GG'
          | 'GH'
          | 'GI'
          | 'GL'
          | 'GM'
          | 'GN'
          | 'GP'
          | 'GQ'
          | 'GR'
          | 'GS'
          | 'GT'
          | 'GU'
          | 'GW'
          | 'GY'
          | 'HK'
          | 'HM'
          | 'HN'
          | 'HR'
          | 'HT'
          | 'HU'
          | 'ID'
          | 'IE'
          | 'IL'
          | 'IM'
          | 'IN'
          | 'IO'
          | 'IQ'
          | 'IR'
          | 'IS'
          | 'IT'
          | 'JE'
          | 'JM'
          | 'JO'
          | 'JP'
          | 'KE'
          | 'KG'
          | 'KH'
          | 'KI'
          | 'KM'
          | 'KN'
          | 'KP'
          | 'KR'
          | 'KW'
          | 'KY'
          | 'KZ'
          | 'LA'
          | 'LB'
          | 'LC'
          | 'LI'
          | 'LK'
          | 'LR'
          | 'LS'
          | 'LT'
          | 'LU'
          | 'LV'
          | 'LY'
          | 'MA'
          | 'MC'
          | 'MD'
          | 'ME'
          | 'MF'
          | 'MG'
          | 'MH'
          | 'MK'
          | 'ML'
          | 'MM'
          | 'MN'
          | 'MO'
          | 'MP'
          | 'MQ'
          | 'MR'
          | 'MS'
          | 'MT'
          | 'MU'
          | 'MV'
          | 'MW'
          | 'MX'
          | 'MY'
          | 'MZ'
          | 'NA'
          | 'NC'
          | 'NE'
          | 'NF'
          | 'NG'
          | 'NI'
          | 'NL'
          | 'NO'
          | 'NP'
          | 'NR'
          | 'NU'
          | 'NZ'
          | 'OM'
          | 'PA'
          | 'PE'
          | 'PF'
          | 'PG'
          | 'PH'
          | 'PK'
          | 'PL'
          | 'PM'
          | 'PN'
          | 'PR'
          | 'PS'
          | 'PT'
          | 'PW'
          | 'PY'
          | 'QA'
          | 'RE'
          | 'RO'
          | 'RS'
          | 'RU'
          | 'RW'
          | 'SA'
          | 'SB'
          | 'SC'
          | 'SD'
          | 'SE'
          | 'SG'
          | 'SH'
          | 'SI'
          | 'SJ'
          | 'SK'
          | 'SL'
          | 'SM'
          | 'SN'
          | 'SO'
          | 'SR'
          | 'SS'
          | 'ST'
          | 'SV'
          | 'SX'
          | 'SY'
          | 'SZ'
          | 'TC'
          | 'TD'
          | 'TF'
          | 'TG'
          | 'TH'
          | 'TJ'
          | 'TK'
          | 'TL'
          | 'TM'
          | 'TN'
          | 'TO'
          | 'TR'
          | 'TT'
          | 'TV'
          | 'TW'
          | 'TZ'
          | 'UA'
          | 'UG'
          | 'UM'
          | 'US'
          | 'UY'
          | 'UZ'
          | 'VA'
          | 'VC'
          | 'VE'
          | 'VG'
          | 'VI'
          | 'VN'
          | 'VU'
          | 'WF'
          | 'WS'
          | 'YE'
          | 'YT'
          | 'ZA'
          | 'ZM'
          | 'ZW'
        )[]
        no_lesson_at_the_moment?: boolean
        non_swimmer?: boolean
        /** Multiple values may be separated by commas. */
        number_of_weeks?: number[]
        /**
         * Ordering
         *
         * * `nickname` - Nickname
         * * `-nickname` - Nickname (descending)
         * * `username` - Username
         * * `-username` - Username (descending)
         * * `first_name` - First name
         * * `-first_name` - First name (descending)
         * * `last_name` - Last name
         * * `-last_name` - Last name (descending)
         * * `current_grade_level__id` - Current grade level  id
         * * `-current_grade_level__id` - Current grade level  id (descending)
         * * `date_of_birth` - Date of birth
         * * `-date_of_birth` - Date of birth (descending)
         * * `age` - Age
         * * `-age` - Age (descending)
         * * `gender` - Gender
         * * `-gender` - Gender (descending)
         * * `category` - Category
         * * `-category` - Category (descending)
         * * `package_option` - Package option
         * * `-package_option` - Package option (descending)
         * * `number_of_weeks_in_camps` - Number of weeks in camps
         * * `-number_of_weeks_in_camps` - Number of weeks in camps (descending)
         * * `nationalities` - Nationalities
         * * `-nationalities` - Nationalities (descending)
         * * `certificate` - Certificate
         * * `-certificate` - Certificate (descending)
         * * `week_of_camps` - Week of camps
         * * `-week_of_camps` - Week of camps (descending)
         * * `date_joined` - Date joined
         * * `-date_joined` - Date joined (descending)
         */
        ordering?: (
          | '-age'
          | '-category'
          | '-certificate'
          | '-current_grade_level__id'
          | '-date_joined'
          | '-date_of_birth'
          | '-first_name'
          | '-gender'
          | '-last_name'
          | '-nationalities'
          | '-nickname'
          | '-number_of_weeks_in_camps'
          | '-package_option'
          | '-username'
          | '-week_of_camps'
          | 'age'
          | 'category'
          | 'certificate'
          | 'current_grade_level__id'
          | 'date_joined'
          | 'date_of_birth'
          | 'first_name'
          | 'gender'
          | 'last_name'
          | 'nationalities'
          | 'nickname'
          | 'number_of_weeks_in_camps'
          | 'package_option'
          | 'username'
          | 'week_of_camps'
        )[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        room?: string
        room_code?: string
        /** A search term. */
        search?: string
        /**
         * * `W1` - Week 1 2024
         * * `W2` - Week 2 2024
         * * `W3` - Week 3 2024
         * * `W4` - Week 4 2024
         */
        week_of_camps?: ('W1' | 'W2' | 'W3' | 'W4')[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentListList, T>, any>({
        path: `/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsCreate
     * @request POST:/students/
     */ studentsCreate: <T extends FetchKeys<StudentDetail>>(
      data: StudentDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsRetrieve
     * @request GET:/students/{id}/
     */ studentsRetrieve: <T extends FetchKeys<StudentDetail>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsUpdate
     * @request PUT:/students/{id}/
     */ studentsUpdate: <T extends FetchKeys<StudentDetail>>(
      id: string,
      data: StudentDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsPartialUpdate
     * @request PATCH:/students/{id}/
     */ studentsPartialUpdate: <T extends FetchKeys<StudentDetail>>(
      id: string,
      data: PatchedStudentDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsDestroy
     * @request DELETE:/students/{id}/
     */ studentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/students/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsAccountOverduePartialUpdate
     * @request PATCH:/students/{id}/account-overdue/
     */ studentsAccountOverduePartialUpdate: <
      T extends FetchKeys<StudentAccountOverdue>
    >(
      id: string,
      data: PatchedStudentAccountOverdueRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAccountOverdue, T>, any>({
        path: `/students/${id}/account-overdue/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsBloodTypePartialUpdate
     * @request PATCH:/students/{id}/blood-type/
     */ studentsBloodTypePartialUpdate: <T extends FetchKeys<StudentBloodType>>(
      id: string,
      data: PatchedStudentBloodTypeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentBloodType, T>, any>({
        path: `/students/${id}/blood-type/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsDietaryRestrictionsUpdate
     * @request PUT:/students/{id}/dietary-restrictions/
     */ studentsDietaryRestrictionsUpdate: <
      T extends FetchKeys<StudentDietaryRestrictions>
    >(
      id: string,
      data: StudentDietaryRestrictionsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDietaryRestrictions, T>, any>({
        path: `/students/${id}/dietary-restrictions/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsDietaryRestrictionsPartialUpdate
     * @request PATCH:/students/{id}/dietary-restrictions/
     */ studentsDietaryRestrictionsPartialUpdate: <
      T extends FetchKeys<StudentDietaryRestrictions>
    >(
      id: string,
      data: PatchedStudentDietaryRestrictionsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDietaryRestrictions, T>, any>({
        path: `/students/${id}/dietary-restrictions/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsExamRecommendationsPartialUpdate
     * @request PATCH:/students/{id}/exam-recommendations/
     */ studentsExamRecommendationsPartialUpdate: <
      T extends FetchKeys<StudentDetail>
    >(
      id: string,
      data: PatchedStudentDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/exam-recommendations/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsFurtherMedicalInfoRetrieve
     * @request GET:/students/{id}/further-medical-info/
     */ studentsFurtherMedicalInfoRetrieve: <
      T extends FetchKeys<StudentFurtherMedicalInfo>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentFurtherMedicalInfo, T>, any>({
        path: `/students/${id}/further-medical-info/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsFurtherMedicalInfoUpdate
     * @request PUT:/students/{id}/further-medical-info/
     */ studentsFurtherMedicalInfoUpdate: <
      T extends FetchKeys<StudentFurtherMedicalInfo>
    >(
      id: string,
      data: StudentFurtherMedicalInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentFurtherMedicalInfo, T>, any>({
        path: `/students/${id}/further-medical-info/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsFurtherMedicalInfoPartialUpdate
     * @request PATCH:/students/{id}/further-medical-info/
     */ studentsFurtherMedicalInfoPartialUpdate: <
      T extends FetchKeys<StudentFurtherMedicalInfo>
    >(
      id: string,
      data: PatchedStudentFurtherMedicalInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentFurtherMedicalInfo, T>, any>({
        path: `/students/${id}/further-medical-info/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsPotentialCategoryRetrieve
     * @request GET:/students/{id}/potential-category/
     */ studentsPotentialCategoryRetrieve: <T extends FetchKeys<StudentDetail>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/potential-category/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsPsychologicalCareRetrieve
     * @request GET:/students/{id}/psychological-care/
     */ studentsPsychologicalCareRetrieve: <
      T extends FetchKeys<StudentPsychologicalCare>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentPsychologicalCare, T>, any>({
        path: `/students/${id}/psychological-care/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsPsychologicalCareUpdate
     * @request PUT:/students/{id}/psychological-care/
     */ studentsPsychologicalCareUpdate: <
      T extends FetchKeys<StudentPsychologicalCare>
    >(
      id: string,
      data: StudentPsychologicalCareRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentPsychologicalCare, T>, any>({
        path: `/students/${id}/psychological-care/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsPsychologicalCarePartialUpdate
     * @request PATCH:/students/{id}/psychological-care/
     */ studentsPsychologicalCarePartialUpdate: <
      T extends FetchKeys<StudentPsychologicalCare>
    >(
      id: string,
      data: PatchedStudentPsychologicalCareRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentPsychologicalCare, T>, any>({
        path: `/students/${id}/psychological-care/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsReportCardRetrieve
     * @request GET:/students/{id}/report-card/
     */ studentsReportCardRetrieve: <T extends FetchKeys<StudentDetail>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/report-card/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsSetPasswordCreate
     * @request POST:/students/{id}/set-password/
     */ studentsSetPasswordCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/students/${id}/set-password/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsToggleActiveCreate
     * @request POST:/students/{id}/toggle-active/
     */ studentsToggleActiveCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/students/${id}/toggle-active/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsToggleVaccinationPartialUpdate
     * @request PATCH:/students/{id}/toggle-vaccination/
     */ studentsToggleVaccinationPartialUpdate: <
      T extends FetchKeys<StudentDetail>
    >(
      id: string,
      data: PatchedStudentDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/toggle-vaccination/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsAccessArrangementsChoicesRetrieve
     * @request GET:/students/access-arrangements-choices/
     */ studentsAccessArrangementsChoicesRetrieve: <
      T extends FetchKeys<StudentDetail>
    >(
      query?: {
        activity__course?: string
        activity__quarter?: number
        activity__school_year?: string
        /** Multiple values may be separated by commas. */
        age?: number[]
        allergen_kinds?: string[]
        birthday_in_week?: boolean
        birthday_today?: boolean
        boarding_status?: string
        category?: number
        chronic_condition_types?: string[]
        current_grade_level?: number
        current_location?: string
        exclude_day_student?: boolean
        /**
         * * `fine-arts` - Fine Arts
         * * `performing-arts` - Performing Arts
         * * `leadership` - Diplomacy & Activism
         * * `robotics` - Robotics & Engineering
         * * `environmental-science` - Environmental Science & Sustainability
         * * `fashion` - Fashion & Textile Design
         * * `informatics` - Informatics & Coding
         * * `intensive-english` - Intensive English
         * * `intensive-german` - Intensive German
         * * `rosenberg-x-crimson-university-admission` - Rosenberg x Crimson University Admission Focus Course
         */
        focus_courses?: (
          | 'environmental-science'
          | 'fashion'
          | 'fine-arts'
          | 'informatics'
          | 'intensive-english'
          | 'intensive-german'
          | 'leadership'
          | 'performing-arts'
          | 'robotics'
          | 'rosenberg-x-crimson-university-admission'
        )[]
        /**
         * * `male` - Male
         * * `female` - Female
         */
        gender?: 'female' | 'male'
        has_dietary_restrictions?: boolean
        has_not_downloaded_certificate?: boolean
        homework_status?: string
        house?: string
        /** @format uuid */
        id?: string
        is_accommodated?: boolean
        is_at_home?: boolean
        is_new?: string
        is_on_senior_campus?: boolean
        is_sick_now?: boolean
        is_vaccinated?: boolean
        /**
         * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
         * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
         * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
         * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
         */
        meal_preference?: 'l1' | 'l2' | 'l3' | 'l4'
        /**
         * * `AF` - Afghanistan
         * * `AX` - Åland Islands
         * * `AL` - Albania
         * * `DZ` - Algeria
         * * `AS` - American Samoa
         * * `AD` - Andorra
         * * `AO` - Angola
         * * `AI` - Anguilla
         * * `AQ` - Antarctica
         * * `AG` - Antigua and Barbuda
         * * `AR` - Argentina
         * * `AM` - Armenia
         * * `AW` - Aruba
         * * `AU` - Australia
         * * `AT` - Austria
         * * `AZ` - Azerbaijan
         * * `BS` - Bahamas
         * * `BH` - Bahrain
         * * `BD` - Bangladesh
         * * `BB` - Barbados
         * * `BY` - Belarus
         * * `BE` - Belgium
         * * `BZ` - Belize
         * * `BJ` - Benin
         * * `BM` - Bermuda
         * * `BT` - Bhutan
         * * `BO` - Bolivia
         * * `BQ` - Bonaire, Sint Eustatius and Saba
         * * `BA` - Bosnia and Herzegovina
         * * `BW` - Botswana
         * * `BV` - Bouvet Island
         * * `BR` - Brazil
         * * `IO` - British Indian Ocean Territory
         * * `BN` - Brunei
         * * `BG` - Bulgaria
         * * `BF` - Burkina Faso
         * * `BI` - Burundi
         * * `CV` - Cabo Verde
         * * `KH` - Cambodia
         * * `CM` - Cameroon
         * * `CA` - Canada
         * * `KY` - Cayman Islands
         * * `CF` - Central African Republic
         * * `TD` - Chad
         * * `CL` - Chile
         * * `CN` - China
         * * `CX` - Christmas Island
         * * `CC` - Cocos (Keeling) Islands
         * * `CO` - Colombia
         * * `KM` - Comoros
         * * `CG` - Congo
         * * `CD` - Congo (the Democratic Republic of the)
         * * `CK` - Cook Islands
         * * `CR` - Costa Rica
         * * `CI` - Côte d'Ivoire
         * * `HR` - Croatia
         * * `CU` - Cuba
         * * `CW` - Curaçao
         * * `CY` - Cyprus
         * * `CZ` - Czechia
         * * `DK` - Denmark
         * * `DJ` - Djibouti
         * * `DM` - Dominica
         * * `DO` - Dominican Republic
         * * `EC` - Ecuador
         * * `EG` - Egypt
         * * `SV` - El Salvador
         * * `GQ` - Equatorial Guinea
         * * `ER` - Eritrea
         * * `EE` - Estonia
         * * `SZ` - Eswatini
         * * `ET` - Ethiopia
         * * `FK` - Falkland Islands (Malvinas)
         * * `FO` - Faroe Islands
         * * `FJ` - Fiji
         * * `FI` - Finland
         * * `FR` - France
         * * `GF` - French Guiana
         * * `PF` - French Polynesia
         * * `TF` - French Southern Territories
         * * `GA` - Gabon
         * * `GM` - Gambia
         * * `GE` - Georgia
         * * `DE` - Germany
         * * `GH` - Ghana
         * * `GI` - Gibraltar
         * * `GR` - Greece
         * * `GL` - Greenland
         * * `GD` - Grenada
         * * `GP` - Guadeloupe
         * * `GU` - Guam
         * * `GT` - Guatemala
         * * `GG` - Guernsey
         * * `GN` - Guinea
         * * `GW` - Guinea-Bissau
         * * `GY` - Guyana
         * * `HT` - Haiti
         * * `HM` - Heard Island and McDonald Islands
         * * `VA` - Holy See
         * * `HN` - Honduras
         * * `HK` - Hong Kong
         * * `HU` - Hungary
         * * `IS` - Iceland
         * * `IN` - India
         * * `ID` - Indonesia
         * * `IR` - Iran
         * * `IQ` - Iraq
         * * `IE` - Ireland
         * * `IM` - Isle of Man
         * * `IL` - Israel
         * * `IT` - Italy
         * * `JM` - Jamaica
         * * `JP` - Japan
         * * `JE` - Jersey
         * * `JO` - Jordan
         * * `KZ` - Kazakhstan
         * * `KE` - Kenya
         * * `KI` - Kiribati
         * * `KW` - Kuwait
         * * `KG` - Kyrgyzstan
         * * `LA` - Laos
         * * `LV` - Latvia
         * * `LB` - Lebanon
         * * `LS` - Lesotho
         * * `LR` - Liberia
         * * `LY` - Libya
         * * `LI` - Liechtenstein
         * * `LT` - Lithuania
         * * `LU` - Luxembourg
         * * `MO` - Macao
         * * `MG` - Madagascar
         * * `MW` - Malawi
         * * `MY` - Malaysia
         * * `MV` - Maldives
         * * `ML` - Mali
         * * `MT` - Malta
         * * `MH` - Marshall Islands
         * * `MQ` - Martinique
         * * `MR` - Mauritania
         * * `MU` - Mauritius
         * * `YT` - Mayotte
         * * `MX` - Mexico
         * * `FM` - Micronesia (Federated States of)
         * * `MD` - Moldova
         * * `MC` - Monaco
         * * `MN` - Mongolia
         * * `ME` - Montenegro
         * * `MS` - Montserrat
         * * `MA` - Morocco
         * * `MZ` - Mozambique
         * * `MM` - Myanmar
         * * `NA` - Namibia
         * * `NR` - Nauru
         * * `NP` - Nepal
         * * `NL` - Netherlands
         * * `NC` - New Caledonia
         * * `NZ` - New Zealand
         * * `NI` - Nicaragua
         * * `NE` - Niger
         * * `NG` - Nigeria
         * * `NU` - Niue
         * * `NF` - Norfolk Island
         * * `KP` - North Korea
         * * `MK` - North Macedonia
         * * `MP` - Northern Mariana Islands
         * * `NO` - Norway
         * * `OM` - Oman
         * * `PK` - Pakistan
         * * `PW` - Palau
         * * `PS` - Palestine, State of
         * * `PA` - Panama
         * * `PG` - Papua New Guinea
         * * `PY` - Paraguay
         * * `PE` - Peru
         * * `PH` - Philippines
         * * `PN` - Pitcairn
         * * `PL` - Poland
         * * `PT` - Portugal
         * * `PR` - Puerto Rico
         * * `QA` - Qatar
         * * `RE` - Réunion
         * * `RO` - Romania
         * * `RU` - Russia
         * * `RW` - Rwanda
         * * `BL` - Saint Barthélemy
         * * `SH` - Saint Helena, Ascension and Tristan da Cunha
         * * `KN` - Saint Kitts and Nevis
         * * `LC` - Saint Lucia
         * * `MF` - Saint Martin (French part)
         * * `PM` - Saint Pierre and Miquelon
         * * `VC` - Saint Vincent and the Grenadines
         * * `WS` - Samoa
         * * `SM` - San Marino
         * * `ST` - Sao Tome and Principe
         * * `SA` - Saudi Arabia
         * * `SN` - Senegal
         * * `RS` - Serbia
         * * `SC` - Seychelles
         * * `SL` - Sierra Leone
         * * `SG` - Singapore
         * * `SX` - Sint Maarten (Dutch part)
         * * `SK` - Slovakia
         * * `SI` - Slovenia
         * * `SB` - Solomon Islands
         * * `SO` - Somalia
         * * `ZA` - South Africa
         * * `GS` - South Georgia and the South Sandwich Islands
         * * `KR` - South Korea
         * * `SS` - South Sudan
         * * `ES` - Spain
         * * `LK` - Sri Lanka
         * * `SD` - Sudan
         * * `SR` - Suriname
         * * `SJ` - Svalbard and Jan Mayen
         * * `SE` - Sweden
         * * `CH` - Switzerland
         * * `SY` - Syria
         * * `TW` - Taiwan
         * * `TJ` - Tajikistan
         * * `TZ` - Tanzania
         * * `TH` - Thailand
         * * `TL` - Timor-Leste
         * * `TG` - Togo
         * * `TK` - Tokelau
         * * `TO` - Tonga
         * * `TT` - Trinidad and Tobago
         * * `TN` - Tunisia
         * * `TR` - Türkiye
         * * `TM` - Turkmenistan
         * * `TC` - Turks and Caicos Islands
         * * `TV` - Tuvalu
         * * `UG` - Uganda
         * * `UA` - Ukraine
         * * `AE` - United Arab Emirates
         * * `GB` - United Kingdom
         * * `UM` - United States Minor Outlying Islands
         * * `US` - United States of America
         * * `UY` - Uruguay
         * * `UZ` - Uzbekistan
         * * `VU` - Vanuatu
         * * `VE` - Venezuela
         * * `VN` - Vietnam
         * * `VG` - Virgin Islands (British)
         * * `VI` - Virgin Islands (U.S.)
         * * `WF` - Wallis and Futuna
         * * `EH` - Western Sahara
         * * `YE` - Yemen
         * * `ZM` - Zambia
         * * `ZW` - Zimbabwe
         */
        nationalities?: (
          | 'AD'
          | 'AE'
          | 'AF'
          | 'AG'
          | 'AI'
          | 'AL'
          | 'AM'
          | 'AO'
          | 'AQ'
          | 'AR'
          | 'AS'
          | 'AT'
          | 'AU'
          | 'AW'
          | 'AX'
          | 'AZ'
          | 'BA'
          | 'BB'
          | 'BD'
          | 'BE'
          | 'BF'
          | 'BG'
          | 'BH'
          | 'BI'
          | 'BJ'
          | 'BL'
          | 'BM'
          | 'BN'
          | 'BO'
          | 'BQ'
          | 'BR'
          | 'BS'
          | 'BT'
          | 'BV'
          | 'BW'
          | 'BY'
          | 'BZ'
          | 'CA'
          | 'CC'
          | 'CD'
          | 'CF'
          | 'CG'
          | 'CH'
          | 'CI'
          | 'CK'
          | 'CL'
          | 'CM'
          | 'CN'
          | 'CO'
          | 'CR'
          | 'CU'
          | 'CV'
          | 'CW'
          | 'CX'
          | 'CY'
          | 'CZ'
          | 'DE'
          | 'DJ'
          | 'DK'
          | 'DM'
          | 'DO'
          | 'DZ'
          | 'EC'
          | 'EE'
          | 'EG'
          | 'EH'
          | 'ER'
          | 'ES'
          | 'ET'
          | 'FI'
          | 'FJ'
          | 'FK'
          | 'FM'
          | 'FO'
          | 'FR'
          | 'GA'
          | 'GB'
          | 'GD'
          | 'GE'
          | 'GF'
          | 'GG'
          | 'GH'
          | 'GI'
          | 'GL'
          | 'GM'
          | 'GN'
          | 'GP'
          | 'GQ'
          | 'GR'
          | 'GS'
          | 'GT'
          | 'GU'
          | 'GW'
          | 'GY'
          | 'HK'
          | 'HM'
          | 'HN'
          | 'HR'
          | 'HT'
          | 'HU'
          | 'ID'
          | 'IE'
          | 'IL'
          | 'IM'
          | 'IN'
          | 'IO'
          | 'IQ'
          | 'IR'
          | 'IS'
          | 'IT'
          | 'JE'
          | 'JM'
          | 'JO'
          | 'JP'
          | 'KE'
          | 'KG'
          | 'KH'
          | 'KI'
          | 'KM'
          | 'KN'
          | 'KP'
          | 'KR'
          | 'KW'
          | 'KY'
          | 'KZ'
          | 'LA'
          | 'LB'
          | 'LC'
          | 'LI'
          | 'LK'
          | 'LR'
          | 'LS'
          | 'LT'
          | 'LU'
          | 'LV'
          | 'LY'
          | 'MA'
          | 'MC'
          | 'MD'
          | 'ME'
          | 'MF'
          | 'MG'
          | 'MH'
          | 'MK'
          | 'ML'
          | 'MM'
          | 'MN'
          | 'MO'
          | 'MP'
          | 'MQ'
          | 'MR'
          | 'MS'
          | 'MT'
          | 'MU'
          | 'MV'
          | 'MW'
          | 'MX'
          | 'MY'
          | 'MZ'
          | 'NA'
          | 'NC'
          | 'NE'
          | 'NF'
          | 'NG'
          | 'NI'
          | 'NL'
          | 'NO'
          | 'NP'
          | 'NR'
          | 'NU'
          | 'NZ'
          | 'OM'
          | 'PA'
          | 'PE'
          | 'PF'
          | 'PG'
          | 'PH'
          | 'PK'
          | 'PL'
          | 'PM'
          | 'PN'
          | 'PR'
          | 'PS'
          | 'PT'
          | 'PW'
          | 'PY'
          | 'QA'
          | 'RE'
          | 'RO'
          | 'RS'
          | 'RU'
          | 'RW'
          | 'SA'
          | 'SB'
          | 'SC'
          | 'SD'
          | 'SE'
          | 'SG'
          | 'SH'
          | 'SI'
          | 'SJ'
          | 'SK'
          | 'SL'
          | 'SM'
          | 'SN'
          | 'SO'
          | 'SR'
          | 'SS'
          | 'ST'
          | 'SV'
          | 'SX'
          | 'SY'
          | 'SZ'
          | 'TC'
          | 'TD'
          | 'TF'
          | 'TG'
          | 'TH'
          | 'TJ'
          | 'TK'
          | 'TL'
          | 'TM'
          | 'TN'
          | 'TO'
          | 'TR'
          | 'TT'
          | 'TV'
          | 'TW'
          | 'TZ'
          | 'UA'
          | 'UG'
          | 'UM'
          | 'US'
          | 'UY'
          | 'UZ'
          | 'VA'
          | 'VC'
          | 'VE'
          | 'VG'
          | 'VI'
          | 'VN'
          | 'VU'
          | 'WF'
          | 'WS'
          | 'YE'
          | 'YT'
          | 'ZA'
          | 'ZM'
          | 'ZW'
        )[]
        no_lesson_at_the_moment?: boolean
        non_swimmer?: boolean
        /** Multiple values may be separated by commas. */
        number_of_weeks?: number[]
        /**
         * Ordering
         *
         * * `nickname` - Nickname
         * * `-nickname` - Nickname (descending)
         * * `username` - Username
         * * `-username` - Username (descending)
         * * `first_name` - First name
         * * `-first_name` - First name (descending)
         * * `last_name` - Last name
         * * `-last_name` - Last name (descending)
         * * `current_grade_level__id` - Current grade level  id
         * * `-current_grade_level__id` - Current grade level  id (descending)
         * * `date_of_birth` - Date of birth
         * * `-date_of_birth` - Date of birth (descending)
         * * `age` - Age
         * * `-age` - Age (descending)
         * * `gender` - Gender
         * * `-gender` - Gender (descending)
         * * `category` - Category
         * * `-category` - Category (descending)
         * * `package_option` - Package option
         * * `-package_option` - Package option (descending)
         * * `number_of_weeks_in_camps` - Number of weeks in camps
         * * `-number_of_weeks_in_camps` - Number of weeks in camps (descending)
         * * `nationalities` - Nationalities
         * * `-nationalities` - Nationalities (descending)
         * * `certificate` - Certificate
         * * `-certificate` - Certificate (descending)
         * * `week_of_camps` - Week of camps
         * * `-week_of_camps` - Week of camps (descending)
         * * `date_joined` - Date joined
         * * `-date_joined` - Date joined (descending)
         */
        ordering?: (
          | '-age'
          | '-category'
          | '-certificate'
          | '-current_grade_level__id'
          | '-date_joined'
          | '-date_of_birth'
          | '-first_name'
          | '-gender'
          | '-last_name'
          | '-nationalities'
          | '-nickname'
          | '-number_of_weeks_in_camps'
          | '-package_option'
          | '-username'
          | '-week_of_camps'
          | 'age'
          | 'category'
          | 'certificate'
          | 'current_grade_level__id'
          | 'date_joined'
          | 'date_of_birth'
          | 'first_name'
          | 'gender'
          | 'last_name'
          | 'nationalities'
          | 'nickname'
          | 'number_of_weeks_in_camps'
          | 'package_option'
          | 'username'
          | 'week_of_camps'
        )[]
        room?: string
        room_code?: string
        /** A search term. */
        search?: string
        /**
         * * `W1` - Week 1 2024
         * * `W2` - Week 2 2024
         * * `W3` - Week 3 2024
         * * `W4` - Week 4 2024
         */
        week_of_camps?: ('W1' | 'W2' | 'W3' | 'W4')[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/access-arrangements-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsAvailableFiltersRetrieve
     * @request GET:/students/available-filters/
     */ studentsAvailableFiltersRetrieve: <
      T extends FetchKeys<StudentsAvailableFilters>
    >(
      query?: {
        activity__course?: string
        activity__quarter?: number
        activity__school_year?: string
        /** Multiple values may be separated by commas. */
        age?: number[]
        allergen_kinds?: string[]
        birthday_in_week?: boolean
        birthday_today?: boolean
        boarding_status?: string
        category?: number
        chronic_condition_types?: string[]
        current_grade_level?: number
        current_location?: string
        exclude_day_student?: boolean
        /**
         * * `fine-arts` - Fine Arts
         * * `performing-arts` - Performing Arts
         * * `leadership` - Diplomacy & Activism
         * * `robotics` - Robotics & Engineering
         * * `environmental-science` - Environmental Science & Sustainability
         * * `fashion` - Fashion & Textile Design
         * * `informatics` - Informatics & Coding
         * * `intensive-english` - Intensive English
         * * `intensive-german` - Intensive German
         * * `rosenberg-x-crimson-university-admission` - Rosenberg x Crimson University Admission Focus Course
         */
        focus_courses?: (
          | 'environmental-science'
          | 'fashion'
          | 'fine-arts'
          | 'informatics'
          | 'intensive-english'
          | 'intensive-german'
          | 'leadership'
          | 'performing-arts'
          | 'robotics'
          | 'rosenberg-x-crimson-university-admission'
        )[]
        /**
         * * `male` - Male
         * * `female` - Female
         */
        gender?: 'female' | 'male'
        has_dietary_restrictions?: boolean
        has_not_downloaded_certificate?: boolean
        homework_status?: string
        house?: string
        /** @format uuid */
        id?: string
        is_accommodated?: boolean
        is_at_home?: boolean
        is_new?: string
        is_on_senior_campus?: boolean
        is_sick_now?: boolean
        is_vaccinated?: boolean
        /**
         * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
         * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
         * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
         * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
         */
        meal_preference?: 'l1' | 'l2' | 'l3' | 'l4'
        /**
         * * `AF` - Afghanistan
         * * `AX` - Åland Islands
         * * `AL` - Albania
         * * `DZ` - Algeria
         * * `AS` - American Samoa
         * * `AD` - Andorra
         * * `AO` - Angola
         * * `AI` - Anguilla
         * * `AQ` - Antarctica
         * * `AG` - Antigua and Barbuda
         * * `AR` - Argentina
         * * `AM` - Armenia
         * * `AW` - Aruba
         * * `AU` - Australia
         * * `AT` - Austria
         * * `AZ` - Azerbaijan
         * * `BS` - Bahamas
         * * `BH` - Bahrain
         * * `BD` - Bangladesh
         * * `BB` - Barbados
         * * `BY` - Belarus
         * * `BE` - Belgium
         * * `BZ` - Belize
         * * `BJ` - Benin
         * * `BM` - Bermuda
         * * `BT` - Bhutan
         * * `BO` - Bolivia
         * * `BQ` - Bonaire, Sint Eustatius and Saba
         * * `BA` - Bosnia and Herzegovina
         * * `BW` - Botswana
         * * `BV` - Bouvet Island
         * * `BR` - Brazil
         * * `IO` - British Indian Ocean Territory
         * * `BN` - Brunei
         * * `BG` - Bulgaria
         * * `BF` - Burkina Faso
         * * `BI` - Burundi
         * * `CV` - Cabo Verde
         * * `KH` - Cambodia
         * * `CM` - Cameroon
         * * `CA` - Canada
         * * `KY` - Cayman Islands
         * * `CF` - Central African Republic
         * * `TD` - Chad
         * * `CL` - Chile
         * * `CN` - China
         * * `CX` - Christmas Island
         * * `CC` - Cocos (Keeling) Islands
         * * `CO` - Colombia
         * * `KM` - Comoros
         * * `CG` - Congo
         * * `CD` - Congo (the Democratic Republic of the)
         * * `CK` - Cook Islands
         * * `CR` - Costa Rica
         * * `CI` - Côte d'Ivoire
         * * `HR` - Croatia
         * * `CU` - Cuba
         * * `CW` - Curaçao
         * * `CY` - Cyprus
         * * `CZ` - Czechia
         * * `DK` - Denmark
         * * `DJ` - Djibouti
         * * `DM` - Dominica
         * * `DO` - Dominican Republic
         * * `EC` - Ecuador
         * * `EG` - Egypt
         * * `SV` - El Salvador
         * * `GQ` - Equatorial Guinea
         * * `ER` - Eritrea
         * * `EE` - Estonia
         * * `SZ` - Eswatini
         * * `ET` - Ethiopia
         * * `FK` - Falkland Islands (Malvinas)
         * * `FO` - Faroe Islands
         * * `FJ` - Fiji
         * * `FI` - Finland
         * * `FR` - France
         * * `GF` - French Guiana
         * * `PF` - French Polynesia
         * * `TF` - French Southern Territories
         * * `GA` - Gabon
         * * `GM` - Gambia
         * * `GE` - Georgia
         * * `DE` - Germany
         * * `GH` - Ghana
         * * `GI` - Gibraltar
         * * `GR` - Greece
         * * `GL` - Greenland
         * * `GD` - Grenada
         * * `GP` - Guadeloupe
         * * `GU` - Guam
         * * `GT` - Guatemala
         * * `GG` - Guernsey
         * * `GN` - Guinea
         * * `GW` - Guinea-Bissau
         * * `GY` - Guyana
         * * `HT` - Haiti
         * * `HM` - Heard Island and McDonald Islands
         * * `VA` - Holy See
         * * `HN` - Honduras
         * * `HK` - Hong Kong
         * * `HU` - Hungary
         * * `IS` - Iceland
         * * `IN` - India
         * * `ID` - Indonesia
         * * `IR` - Iran
         * * `IQ` - Iraq
         * * `IE` - Ireland
         * * `IM` - Isle of Man
         * * `IL` - Israel
         * * `IT` - Italy
         * * `JM` - Jamaica
         * * `JP` - Japan
         * * `JE` - Jersey
         * * `JO` - Jordan
         * * `KZ` - Kazakhstan
         * * `KE` - Kenya
         * * `KI` - Kiribati
         * * `KW` - Kuwait
         * * `KG` - Kyrgyzstan
         * * `LA` - Laos
         * * `LV` - Latvia
         * * `LB` - Lebanon
         * * `LS` - Lesotho
         * * `LR` - Liberia
         * * `LY` - Libya
         * * `LI` - Liechtenstein
         * * `LT` - Lithuania
         * * `LU` - Luxembourg
         * * `MO` - Macao
         * * `MG` - Madagascar
         * * `MW` - Malawi
         * * `MY` - Malaysia
         * * `MV` - Maldives
         * * `ML` - Mali
         * * `MT` - Malta
         * * `MH` - Marshall Islands
         * * `MQ` - Martinique
         * * `MR` - Mauritania
         * * `MU` - Mauritius
         * * `YT` - Mayotte
         * * `MX` - Mexico
         * * `FM` - Micronesia (Federated States of)
         * * `MD` - Moldova
         * * `MC` - Monaco
         * * `MN` - Mongolia
         * * `ME` - Montenegro
         * * `MS` - Montserrat
         * * `MA` - Morocco
         * * `MZ` - Mozambique
         * * `MM` - Myanmar
         * * `NA` - Namibia
         * * `NR` - Nauru
         * * `NP` - Nepal
         * * `NL` - Netherlands
         * * `NC` - New Caledonia
         * * `NZ` - New Zealand
         * * `NI` - Nicaragua
         * * `NE` - Niger
         * * `NG` - Nigeria
         * * `NU` - Niue
         * * `NF` - Norfolk Island
         * * `KP` - North Korea
         * * `MK` - North Macedonia
         * * `MP` - Northern Mariana Islands
         * * `NO` - Norway
         * * `OM` - Oman
         * * `PK` - Pakistan
         * * `PW` - Palau
         * * `PS` - Palestine, State of
         * * `PA` - Panama
         * * `PG` - Papua New Guinea
         * * `PY` - Paraguay
         * * `PE` - Peru
         * * `PH` - Philippines
         * * `PN` - Pitcairn
         * * `PL` - Poland
         * * `PT` - Portugal
         * * `PR` - Puerto Rico
         * * `QA` - Qatar
         * * `RE` - Réunion
         * * `RO` - Romania
         * * `RU` - Russia
         * * `RW` - Rwanda
         * * `BL` - Saint Barthélemy
         * * `SH` - Saint Helena, Ascension and Tristan da Cunha
         * * `KN` - Saint Kitts and Nevis
         * * `LC` - Saint Lucia
         * * `MF` - Saint Martin (French part)
         * * `PM` - Saint Pierre and Miquelon
         * * `VC` - Saint Vincent and the Grenadines
         * * `WS` - Samoa
         * * `SM` - San Marino
         * * `ST` - Sao Tome and Principe
         * * `SA` - Saudi Arabia
         * * `SN` - Senegal
         * * `RS` - Serbia
         * * `SC` - Seychelles
         * * `SL` - Sierra Leone
         * * `SG` - Singapore
         * * `SX` - Sint Maarten (Dutch part)
         * * `SK` - Slovakia
         * * `SI` - Slovenia
         * * `SB` - Solomon Islands
         * * `SO` - Somalia
         * * `ZA` - South Africa
         * * `GS` - South Georgia and the South Sandwich Islands
         * * `KR` - South Korea
         * * `SS` - South Sudan
         * * `ES` - Spain
         * * `LK` - Sri Lanka
         * * `SD` - Sudan
         * * `SR` - Suriname
         * * `SJ` - Svalbard and Jan Mayen
         * * `SE` - Sweden
         * * `CH` - Switzerland
         * * `SY` - Syria
         * * `TW` - Taiwan
         * * `TJ` - Tajikistan
         * * `TZ` - Tanzania
         * * `TH` - Thailand
         * * `TL` - Timor-Leste
         * * `TG` - Togo
         * * `TK` - Tokelau
         * * `TO` - Tonga
         * * `TT` - Trinidad and Tobago
         * * `TN` - Tunisia
         * * `TR` - Türkiye
         * * `TM` - Turkmenistan
         * * `TC` - Turks and Caicos Islands
         * * `TV` - Tuvalu
         * * `UG` - Uganda
         * * `UA` - Ukraine
         * * `AE` - United Arab Emirates
         * * `GB` - United Kingdom
         * * `UM` - United States Minor Outlying Islands
         * * `US` - United States of America
         * * `UY` - Uruguay
         * * `UZ` - Uzbekistan
         * * `VU` - Vanuatu
         * * `VE` - Venezuela
         * * `VN` - Vietnam
         * * `VG` - Virgin Islands (British)
         * * `VI` - Virgin Islands (U.S.)
         * * `WF` - Wallis and Futuna
         * * `EH` - Western Sahara
         * * `YE` - Yemen
         * * `ZM` - Zambia
         * * `ZW` - Zimbabwe
         */
        nationalities?: (
          | 'AD'
          | 'AE'
          | 'AF'
          | 'AG'
          | 'AI'
          | 'AL'
          | 'AM'
          | 'AO'
          | 'AQ'
          | 'AR'
          | 'AS'
          | 'AT'
          | 'AU'
          | 'AW'
          | 'AX'
          | 'AZ'
          | 'BA'
          | 'BB'
          | 'BD'
          | 'BE'
          | 'BF'
          | 'BG'
          | 'BH'
          | 'BI'
          | 'BJ'
          | 'BL'
          | 'BM'
          | 'BN'
          | 'BO'
          | 'BQ'
          | 'BR'
          | 'BS'
          | 'BT'
          | 'BV'
          | 'BW'
          | 'BY'
          | 'BZ'
          | 'CA'
          | 'CC'
          | 'CD'
          | 'CF'
          | 'CG'
          | 'CH'
          | 'CI'
          | 'CK'
          | 'CL'
          | 'CM'
          | 'CN'
          | 'CO'
          | 'CR'
          | 'CU'
          | 'CV'
          | 'CW'
          | 'CX'
          | 'CY'
          | 'CZ'
          | 'DE'
          | 'DJ'
          | 'DK'
          | 'DM'
          | 'DO'
          | 'DZ'
          | 'EC'
          | 'EE'
          | 'EG'
          | 'EH'
          | 'ER'
          | 'ES'
          | 'ET'
          | 'FI'
          | 'FJ'
          | 'FK'
          | 'FM'
          | 'FO'
          | 'FR'
          | 'GA'
          | 'GB'
          | 'GD'
          | 'GE'
          | 'GF'
          | 'GG'
          | 'GH'
          | 'GI'
          | 'GL'
          | 'GM'
          | 'GN'
          | 'GP'
          | 'GQ'
          | 'GR'
          | 'GS'
          | 'GT'
          | 'GU'
          | 'GW'
          | 'GY'
          | 'HK'
          | 'HM'
          | 'HN'
          | 'HR'
          | 'HT'
          | 'HU'
          | 'ID'
          | 'IE'
          | 'IL'
          | 'IM'
          | 'IN'
          | 'IO'
          | 'IQ'
          | 'IR'
          | 'IS'
          | 'IT'
          | 'JE'
          | 'JM'
          | 'JO'
          | 'JP'
          | 'KE'
          | 'KG'
          | 'KH'
          | 'KI'
          | 'KM'
          | 'KN'
          | 'KP'
          | 'KR'
          | 'KW'
          | 'KY'
          | 'KZ'
          | 'LA'
          | 'LB'
          | 'LC'
          | 'LI'
          | 'LK'
          | 'LR'
          | 'LS'
          | 'LT'
          | 'LU'
          | 'LV'
          | 'LY'
          | 'MA'
          | 'MC'
          | 'MD'
          | 'ME'
          | 'MF'
          | 'MG'
          | 'MH'
          | 'MK'
          | 'ML'
          | 'MM'
          | 'MN'
          | 'MO'
          | 'MP'
          | 'MQ'
          | 'MR'
          | 'MS'
          | 'MT'
          | 'MU'
          | 'MV'
          | 'MW'
          | 'MX'
          | 'MY'
          | 'MZ'
          | 'NA'
          | 'NC'
          | 'NE'
          | 'NF'
          | 'NG'
          | 'NI'
          | 'NL'
          | 'NO'
          | 'NP'
          | 'NR'
          | 'NU'
          | 'NZ'
          | 'OM'
          | 'PA'
          | 'PE'
          | 'PF'
          | 'PG'
          | 'PH'
          | 'PK'
          | 'PL'
          | 'PM'
          | 'PN'
          | 'PR'
          | 'PS'
          | 'PT'
          | 'PW'
          | 'PY'
          | 'QA'
          | 'RE'
          | 'RO'
          | 'RS'
          | 'RU'
          | 'RW'
          | 'SA'
          | 'SB'
          | 'SC'
          | 'SD'
          | 'SE'
          | 'SG'
          | 'SH'
          | 'SI'
          | 'SJ'
          | 'SK'
          | 'SL'
          | 'SM'
          | 'SN'
          | 'SO'
          | 'SR'
          | 'SS'
          | 'ST'
          | 'SV'
          | 'SX'
          | 'SY'
          | 'SZ'
          | 'TC'
          | 'TD'
          | 'TF'
          | 'TG'
          | 'TH'
          | 'TJ'
          | 'TK'
          | 'TL'
          | 'TM'
          | 'TN'
          | 'TO'
          | 'TR'
          | 'TT'
          | 'TV'
          | 'TW'
          | 'TZ'
          | 'UA'
          | 'UG'
          | 'UM'
          | 'US'
          | 'UY'
          | 'UZ'
          | 'VA'
          | 'VC'
          | 'VE'
          | 'VG'
          | 'VI'
          | 'VN'
          | 'VU'
          | 'WF'
          | 'WS'
          | 'YE'
          | 'YT'
          | 'ZA'
          | 'ZM'
          | 'ZW'
        )[]
        no_lesson_at_the_moment?: boolean
        non_swimmer?: boolean
        /** Multiple values may be separated by commas. */
        number_of_weeks?: number[]
        /**
         * Ordering
         *
         * * `nickname` - Nickname
         * * `-nickname` - Nickname (descending)
         * * `username` - Username
         * * `-username` - Username (descending)
         * * `first_name` - First name
         * * `-first_name` - First name (descending)
         * * `last_name` - Last name
         * * `-last_name` - Last name (descending)
         * * `current_grade_level__id` - Current grade level  id
         * * `-current_grade_level__id` - Current grade level  id (descending)
         * * `date_of_birth` - Date of birth
         * * `-date_of_birth` - Date of birth (descending)
         * * `age` - Age
         * * `-age` - Age (descending)
         * * `gender` - Gender
         * * `-gender` - Gender (descending)
         * * `category` - Category
         * * `-category` - Category (descending)
         * * `package_option` - Package option
         * * `-package_option` - Package option (descending)
         * * `number_of_weeks_in_camps` - Number of weeks in camps
         * * `-number_of_weeks_in_camps` - Number of weeks in camps (descending)
         * * `nationalities` - Nationalities
         * * `-nationalities` - Nationalities (descending)
         * * `certificate` - Certificate
         * * `-certificate` - Certificate (descending)
         * * `week_of_camps` - Week of camps
         * * `-week_of_camps` - Week of camps (descending)
         * * `date_joined` - Date joined
         * * `-date_joined` - Date joined (descending)
         */
        ordering?: (
          | '-age'
          | '-category'
          | '-certificate'
          | '-current_grade_level__id'
          | '-date_joined'
          | '-date_of_birth'
          | '-first_name'
          | '-gender'
          | '-last_name'
          | '-nationalities'
          | '-nickname'
          | '-number_of_weeks_in_camps'
          | '-package_option'
          | '-username'
          | '-week_of_camps'
          | 'age'
          | 'category'
          | 'certificate'
          | 'current_grade_level__id'
          | 'date_joined'
          | 'date_of_birth'
          | 'first_name'
          | 'gender'
          | 'last_name'
          | 'nationalities'
          | 'nickname'
          | 'number_of_weeks_in_camps'
          | 'package_option'
          | 'username'
          | 'week_of_camps'
        )[]
        room?: string
        room_code?: string
        /** A search term. */
        search?: string
        /**
         * * `W1` - Week 1 2024
         * * `W2` - Week 2 2024
         * * `W3` - Week 3 2024
         * * `W4` - Week 4 2024
         */
        week_of_camps?: ('W1' | 'W2' | 'W3' | 'W4')[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentsAvailableFilters, T>, any>({
        path: `/students/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsCommentsList
     * @request GET:/students/comments/
     */ studentsCommentsList: <T extends FetchKeys<PaginatedCommentList>>(
      query?: {
        /**
         * * `academic` - academic
         * * `boarding` - boarding
         * * `progress` - progress
         */
        comment_type?: ('academic' | 'boarding' | 'progress')[]
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        related_author?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCommentList, T>, any>({
        path: `/students/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsCommentsAddedByChoicesRetrieve
     * @request GET:/students/comments/added-by-choices/
     */ studentsCommentsAddedByChoicesRetrieve: <T extends FetchKeys<Comment>>(
      query?: {
        /**
         * * `academic` - academic
         * * `boarding` - boarding
         * * `progress` - progress
         */
        comment_type?: ('academic' | 'boarding' | 'progress')[]
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        /** @format uuid */
        related_author?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Comment, T>, any>({
        path: `/students/comments/added-by-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Endpoint dedicated to summer camps, in the future may be used in regular panel.
     *
     * @tags students
     * @name StudentsCommentsPdfRetrieve
     * @request GET:/students/comments/pdf/
     */ studentsCommentsPdfRetrieve: <T extends FetchKeys<Comment>>(
      query?: {
        /**
         * * `academic` - academic
         * * `boarding` - boarding
         * * `progress` - progress
         */
        comment_type?: ('academic' | 'boarding' | 'progress')[]
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        /** @format uuid */
        related_author?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Comment, T>, any>({
        path: `/students/comments/pdf/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsCurrentLocationsList
     * @request GET:/students/current-locations/
     */ studentsCurrentLocationsList: <T extends FetchKeys<CurrentLocation[]>>(
      query?: {
        boarding_status?: string
        current_location?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CurrentLocation[], T>, any>({
        path: `/students/current-locations/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsMeRetrieve
     * @request GET:/students/me/
     */ studentsMeRetrieve: <T extends FetchKeys<StudentProfile>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentProfile, T>, any>({
        path: `/students/me/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsMeUpdate
     * @request PUT:/students/me/
     */ studentsMeUpdate: <T extends FetchKeys<StudentProfile>>(
      data: StudentProfileRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentProfile, T>, any>({
        path: `/students/me/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsMePartialUpdate
     * @request PATCH:/students/me/
     */ studentsMePartialUpdate: <T extends FetchKeys<StudentProfile>>(
      data: PatchedStudentProfileRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentProfile, T>, any>({
        path: `/students/me/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Get current state of user new students banner.
     *
     * @tags students
     * @name StudentsNewBannerList
     * @request GET:/students/new/banner/
     */ studentsNewBannerList: <
      T extends FetchKeys<NewStudentsBannerResponse[]>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NewStudentsBannerResponse[], T>, void>({
        path: `/students/new/banner/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Remove specified user new students from banner.
     *
     * @tags students
     * @name StudentsNewBannerCreate
     * @request POST:/students/new/banner/
     */ studentsNewBannerCreate: <T extends FetchKeys<void>>(
      data: StudentIdsListRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, void>({
        path: `/students/new/banner/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),
    /**
     * @description Get current state of user new students counter.
     *
     * @tags students
     * @name StudentsNewCounterRetrieve
     * @request GET:/students/new/counter/
     */ studentsNewCounterRetrieve: <
      T extends FetchKeys<NewStudentsCountResponse>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NewStudentsCountResponse, T>, void>({
        path: `/students/new/counter/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Reset user new students counter.
     *
     * @tags students
     * @name StudentsNewCounterUpdate
     * @request PUT:/students/new/counter/
     */ studentsNewCounterUpdate: <T extends FetchKeys<void>>(
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, void>({
        path: `/students/new/counter/`,
        method: 'PUT',
        ...params
      })
  }
  suggester = {
    /**
     * No description
     *
     * @tags suggester
     * @name SuggesterDraftactivitySuggestionsApplyCreate
     * @request POST:/suggester/draftactivity/suggestions/{id}/apply/
     */
    suggesterDraftactivitySuggestionsApplyCreate: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, MessageResponseSchema>({
        path: `/suggester/draftactivity/suggestions/${id}/apply/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags suggester
     * @name SuggesterDraftactivityTasksCreate
     * @request POST:/suggester/draftactivity/tasks/
     */ suggesterDraftactivityTasksCreate: <
      T extends FetchKeys<CreateSuggesterTaskResponse>
    >(
      data: CreateDraftActivitySuggesterTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<CreateSuggesterTaskResponse, T>,
        MessageResponseSchema
      >({
        path: `/suggester/draftactivity/tasks/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags suggester
     * @name SuggesterDraftactivityTasksRetrieve
     * @request GET:/suggester/draftactivity/tasks/{id}/
     */ suggesterDraftactivityTasksRetrieve: <
      T extends FetchKeys<RetrieveDraftActivitySuggesterTask>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RetrieveDraftActivitySuggesterTask, T>, any>({
        path: `/suggester/draftactivity/tasks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags suggester
     * @name SuggesterDraftactivityTasksConfigRetrieve
     * @request GET:/suggester/draftactivity/tasks/config/
     */ suggesterDraftactivityTasksConfigRetrieve: <
      T extends FetchKeys<SuggesterTaskConfig>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SuggesterTaskConfig, T>, any>({
        path: `/suggester/draftactivity/tasks/config/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags suggester
     * @name SuggesterDraftactivityTasksProcessStateRetrieve
     * @request GET:/suggester/draftactivity/tasks/process_state/
     */ suggesterDraftactivityTasksProcessStateRetrieve: <
      T extends FetchKeys<DraftActivityInProgressSuggesterTask>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityInProgressSuggesterTask, T>, any>({
        path: `/suggester/draftactivity/tasks/process_state/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags suggester
     * @name SuggesterIntegrationWebhookCreate
     * @request POST:/suggester/integration/webhook
     */ suggesterIntegrationWebhookCreate: <
      T extends FetchKeys<SuggesterExternalEvent>
    >(
      data: SuggesterExternalEventRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SuggesterExternalEvent, T>, any>({
        path: `/suggester/integration/webhook`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags suggester
     * @name SuggesterSuggestionsPreviewList
     * @request GET:/suggester/suggestions/{id}/preview
     */ suggesterSuggestionsPreviewList: <
      T extends FetchKeys<PaginatedDraftActivitySuggestionPreviewList>
    >(
      id: number,
      query?: {
        /** @format uuid */
        course?: string
        exclude_tae_activities?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        room?: string
        students?: string[]
        /** @format uuid */
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<PaginatedDraftActivitySuggestionPreviewList, T>,
        any
      >({
        path: `/suggester/suggestions/${id}/preview`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags suggester
     * @name SuggesterSuggestionsPreviewAvailableFiltersRetrieve
     * @request GET:/suggester/suggestions/{id}/preview/available-filters
     */ suggesterSuggestionsPreviewAvailableFiltersRetrieve: <
      T extends FetchKeys<DraftActivityAvailableFilters>
    >(
      id: number,
      query?: {
        /** @format uuid */
        course?: string
        exclude_tae_activities?: boolean
        room?: string
        students?: string[]
        /** @format uuid */
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityAvailableFilters, T>, any>({
        path: `/suggester/suggestions/${id}/preview/available-filters`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  summerCamps = {
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsRetrieve
     * @request GET:/summer-camps/{studentId}/
     */
    summerCampsRetrieve: <T extends FetchKeys<SummerCampStudent>>(
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCampStudent, T>, any>({
        path: `/summer-camps/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsUpdate
     * @request PUT:/summer-camps/{studentId}/
     */ summerCampsUpdate: <T extends FetchKeys<SummerCampStudent>>(
      studentId: string,
      data: SummerCampStudentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCampStudent, T>, any>({
        path: `/summer-camps/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsPartialUpdate
     * @request PATCH:/summer-camps/{studentId}/
     */ summerCampsPartialUpdate: <T extends FetchKeys<SummerCampStudent>>(
      studentId: string,
      data: PatchedSummerCampStudentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCampStudent, T>, any>({
        path: `/summer-camps/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsArrivalInfoRetrieve
     * @request GET:/summer-camps/{studentId}/arrival-info/
     */ summerCampsArrivalInfoRetrieve: <T extends FetchKeys<ArrivalInfo>>(
      studentId: string,
      query?: {
        response_format?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ArrivalInfo, T>, any>({
        path: `/summer-camps/${studentId}/arrival-info/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsArrivalInfoUpdate
     * @request PUT:/summer-camps/{studentId}/arrival-info/
     */ summerCampsArrivalInfoUpdate: <T extends FetchKeys<ArrivalInfo>>(
      studentId: string,
      data: ArrivalInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ArrivalInfo, T>, any>({
        path: `/summer-camps/${studentId}/arrival-info/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsAssignsRetrieve
     * @request GET:/summer-camps/assigns/{student}/
     */ summerCampsAssignsRetrieve: <
      T extends FetchKeys<SummerCampStudentAssigns>
    >(
      student: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCampStudentAssigns, T>, any>({
        path: `/summer-camps/assigns/${student}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsAssignsUpdate
     * @request PUT:/summer-camps/assigns/{student}/
     */ summerCampsAssignsUpdate: <
      T extends FetchKeys<SummerCampStudentAssigns>
    >(
      student: string,
      data: SummerCampStudentAssignsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCampStudentAssigns, T>, any>({
        path: `/summer-camps/assigns/${student}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsAssignsPartialUpdate
     * @request PATCH:/summer-camps/assigns/{student}/
     */ summerCampsAssignsPartialUpdate: <
      T extends FetchKeys<SummerCampStudentAssigns>
    >(
      student: string,
      data: PatchedSummerCampStudentAssignsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCampStudentAssigns, T>, any>({
        path: `/summer-camps/assigns/${student}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsAssignsChoicesRetrieve
     * @request GET:/summer-camps/assigns/choices/
     */ summerCampsAssignsChoicesRetrieve: <
      T extends FetchKeys<SummerAssignOptionsResponse>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerAssignOptionsResponse, T>, any>({
        path: `/summer-camps/assigns/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsCertificateRetrieve
     * @request GET:/summer-camps/certificate/{id}/
     */ summerCampsCertificateRetrieve: <
      T extends FetchKeys<SummerCertificateAttachment>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCertificateAttachment, T>, any>({
        path: `/summer-camps/certificate/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsCertificateCreate
     * @request POST:/summer-camps/certificate/{id}/
     */ summerCampsCertificateCreate: <
      T extends FetchKeys<SummerCertificateAttachment>
    >(
      id: string,
      data: GenerateSummerCertificateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCertificateAttachment, T>, any>({
        path: `/summer-camps/certificate/${id}/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsCertificateCeremonySummaryList
     * @request GET:/summer-camps/certificate/ceremony-summary/
     */ summerCampsCertificateCeremonySummaryList: <
      T extends FetchKeys<SummerCertificateCeremonySummary[]>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCertificateCeremonySummary[], T>, any>({
        path: `/summer-camps/certificate/ceremony-summary/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsCertificateCoursesSummaryRetrieve
     * @request GET:/summer-camps/certificate/courses-summary/{student}/
     */ summerCampsCertificateCoursesSummaryRetrieve: <
      T extends FetchKeys<StudentSummerSummary>
    >(
      student: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentSummerSummary, T>, any>({
        path: `/summer-camps/certificate/courses-summary/${student}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsCertificateDraftRetrieve
     * @request GET:/summer-camps/certificate/draft/{id}/
     */ summerCampsCertificateDraftRetrieve: <
      T extends FetchKeys<SummerCertificateDraft>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCertificateDraft, T>, any>({
        path: `/summer-camps/certificate/draft/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsCertificateDraftUpdate
     * @request PUT:/summer-camps/certificate/draft/{id}/
     */ summerCampsCertificateDraftUpdate: <
      T extends FetchKeys<SummerCertificateDraft>
    >(
      id: string,
      data: SummerCertificateDraftRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCertificateDraft, T>, any>({
        path: `/summer-camps/certificate/draft/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsCertificateDraftPartialUpdate
     * @request PATCH:/summer-camps/certificate/draft/{id}/
     */ summerCampsCertificateDraftPartialUpdate: <
      T extends FetchKeys<SummerCertificateDraft>
    >(
      id: string,
      data: PatchedSummerCertificateDraftRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCertificateDraft, T>, any>({
        path: `/summer-camps/certificate/draft/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsCertificateGenerateZippedCertificatesCreate
     * @request POST:/summer-camps/certificate/generate-zipped-certificates/
     */ summerCampsCertificateGenerateZippedCertificatesCreate: <
      T extends FetchKeys<SummerCertificateAttachment>
    >(
      data: GenerateZippedCertificatesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SummerCertificateAttachment, T>, any>({
        path: `/summer-camps/certificate/generate-zipped-certificates/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags summer-camps
     * @name SummerCampsWeeksRetrieve
     * @request GET:/summer-camps/weeks/
     */ summerCampsWeeksRetrieve: <T extends FetchKeys<WeekResults>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeekResults, T>, any>({
        path: `/summer-camps/weeks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  tasksManagement = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksList
     * @request GET:/tasks-management/tasks/
     */
    tasksManagementTasksList: <T extends FetchKeys<PaginatedTaskList>>(
      query?: {
        assignee?: string[]
        created_by?: string[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        has_subtasks?: boolean
        is_cloned?: boolean
        is_recurring?: boolean
        /**
         * Ordering
         *
         * * `is_done` - Is done
         * * `-is_done` - Is done (descending)
         * * `deadline` - Deadline
         * * `-deadline` - Deadline (descending)
         * * `assignee` - Assignee
         * * `-assignee` - Assignee (descending)
         * * `team` - Team
         * * `-team` - Team (descending)
         */
        ordering?: (
          | '-assignee'
          | '-deadline'
          | '-is_done'
          | '-team'
          | 'assignee'
          | 'deadline'
          | 'is_done'
          | 'team'
        )[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `admin` - Admin
         * * `teacher` - Artisan
         * * `academichod` - Academic HOD
         * * `academicadmin` - Academic Admin
         * * `behaviourmanagement` - Behaviour Management
         * * `boardingdirector` - Boarding Director
         * * `campusheadteam` - Campus Head Team
         * * `infoboardeditor` - Infoboard Editor
         * * `healthcoordinator` - Health Coordinator
         * * `headofhouse` - Head of House
         * * `propertymanager` - Property Manager
         * * `securityteam` - Security & Safety Team
         * * `viewer` - View Only
         * * `housetutor` - House Tutor
         * * `nightguard` - Night Guard
         * * `idpadmin` - IDP Admin
         */
        role?: (
          | 'academicadmin'
          | 'academichod'
          | 'admin'
          | 'behaviourmanagement'
          | 'boardingdirector'
          | 'campusheadteam'
          | 'headofhouse'
          | 'healthcoordinator'
          | 'housetutor'
          | 'idpadmin'
          | 'infoboardeditor'
          | 'nightguard'
          | 'propertymanager'
          | 'securityteam'
          | 'teacher'
          | 'viewer'
        )[]
        /**
         * * `done` - Done
         * * `done_manually` - Done manually
         * * `overdue` - Overdue
         * * `close_deadline` - Close deadline
         * * `unscheduled` - Unscheduled
         */
        status?: (
          | 'close_deadline'
          | 'done'
          | 'done_manually'
          | 'overdue'
          | 'unscheduled'
        )[]
        /** @format uuid */
        team?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTaskList, T>, any>({
        path: `/tasks-management/tasks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksCreate
     * @request POST:/tasks-management/tasks/
     */ tasksManagementTasksCreate: <T extends FetchKeys<Task>>(
      data: TaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksRetrieve
     * @request GET:/tasks-management/tasks/{id}/
     */ tasksManagementTasksRetrieve: <T extends FetchKeys<Task>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksUpdate
     * @request PUT:/tasks-management/tasks/{id}/
     */ tasksManagementTasksUpdate: <T extends FetchKeys<Task>>(
      id: string,
      data: TaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksPartialUpdate
     * @request PATCH:/tasks-management/tasks/{id}/
     */ tasksManagementTasksPartialUpdate: <T extends FetchKeys<Task>>(
      id: string,
      data: PatchedTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksDestroy
     * @request DELETE:/tasks-management/tasks/{id}/
     */ tasksManagementTasksDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/tasks-management/tasks/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksToggleIsDoneCreate
     * @request POST:/tasks-management/tasks/{id}/toggle-is-done/
     */ tasksManagementTasksToggleIsDoneCreate: <
      T extends FetchKeys<TaskToggleIsDone>
    >(
      id: string,
      data: TaskToggleIsDoneRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TaskToggleIsDone, T>, any>({
        path: `/tasks-management/tasks/${id}/toggle-is-done/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksToggleSubtaskIsDoneCreate
     * @request POST:/tasks-management/tasks/{id}/toggle-subtask-is-done/
     */ tasksManagementTasksToggleSubtaskIsDoneCreate: <
      T extends FetchKeys<TaskToggleSubtaskIsDone>
    >(
      id: string,
      data: TaskToggleSubtaskIsDoneRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TaskToggleSubtaskIsDone, T>, any>({
        path: `/tasks-management/tasks/${id}/toggle-subtask-is-done/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksAvailableFiltersRetrieve
     * @request GET:/tasks-management/tasks/available-filters/
     */ tasksManagementTasksAvailableFiltersRetrieve: <
      T extends FetchKeys<Task>
    >(
      query?: {
        assignee?: string[]
        created_by?: string[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        has_subtasks?: boolean
        is_cloned?: boolean
        is_recurring?: boolean
        /**
         * Ordering
         *
         * * `is_done` - Is done
         * * `-is_done` - Is done (descending)
         * * `deadline` - Deadline
         * * `-deadline` - Deadline (descending)
         * * `assignee` - Assignee
         * * `-assignee` - Assignee (descending)
         * * `team` - Team
         * * `-team` - Team (descending)
         */
        ordering?: (
          | '-assignee'
          | '-deadline'
          | '-is_done'
          | '-team'
          | 'assignee'
          | 'deadline'
          | 'is_done'
          | 'team'
        )[]
        /**
         * * `admin` - Admin
         * * `teacher` - Artisan
         * * `academichod` - Academic HOD
         * * `academicadmin` - Academic Admin
         * * `behaviourmanagement` - Behaviour Management
         * * `boardingdirector` - Boarding Director
         * * `campusheadteam` - Campus Head Team
         * * `infoboardeditor` - Infoboard Editor
         * * `healthcoordinator` - Health Coordinator
         * * `headofhouse` - Head of House
         * * `propertymanager` - Property Manager
         * * `securityteam` - Security & Safety Team
         * * `viewer` - View Only
         * * `housetutor` - House Tutor
         * * `nightguard` - Night Guard
         * * `idpadmin` - IDP Admin
         */
        role?: (
          | 'academicadmin'
          | 'academichod'
          | 'admin'
          | 'behaviourmanagement'
          | 'boardingdirector'
          | 'campusheadteam'
          | 'headofhouse'
          | 'healthcoordinator'
          | 'housetutor'
          | 'idpadmin'
          | 'infoboardeditor'
          | 'nightguard'
          | 'propertymanager'
          | 'securityteam'
          | 'teacher'
          | 'viewer'
        )[]
        /**
         * * `done` - Done
         * * `done_manually` - Done manually
         * * `overdue` - Overdue
         * * `close_deadline` - Close deadline
         * * `unscheduled` - Unscheduled
         */
        status?: (
          | 'close_deadline'
          | 'done'
          | 'done_manually'
          | 'overdue'
          | 'unscheduled'
        )[]
        /** @format uuid */
        team?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Returns incomplete tasks with today deadline for authenticated user
     *
     * @tags tasks-management
     * @name TasksManagementTasksCountDailyIncompleteRetrieve
     * @request GET:/tasks-management/tasks/count-daily-incomplete/
     */ tasksManagementTasksCountDailyIncompleteRetrieve: <
      T extends FetchKeys<IncompleteDailyTaskCount>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IncompleteDailyTaskCount, T>, any>({
        path: `/tasks-management/tasks/count-daily-incomplete/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksCountIncompleteRetrieve
     * @request GET:/tasks-management/tasks/count-incomplete/
     */ tasksManagementTasksCountIncompleteRetrieve: <
      T extends FetchKeys<IncompleteTaskCount>
    >(
      query?: {
        assignee?: string[]
        created_by?: string[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        has_subtasks?: boolean
        is_cloned?: boolean
        is_recurring?: boolean
        /**
         * Ordering
         *
         * * `is_done` - Is done
         * * `-is_done` - Is done (descending)
         * * `deadline` - Deadline
         * * `-deadline` - Deadline (descending)
         * * `assignee` - Assignee
         * * `-assignee` - Assignee (descending)
         * * `team` - Team
         * * `-team` - Team (descending)
         */
        ordering?: (
          | '-assignee'
          | '-deadline'
          | '-is_done'
          | '-team'
          | 'assignee'
          | 'deadline'
          | 'is_done'
          | 'team'
        )[]
        /**
         * * `admin` - Admin
         * * `teacher` - Artisan
         * * `academichod` - Academic HOD
         * * `academicadmin` - Academic Admin
         * * `behaviourmanagement` - Behaviour Management
         * * `boardingdirector` - Boarding Director
         * * `campusheadteam` - Campus Head Team
         * * `infoboardeditor` - Infoboard Editor
         * * `healthcoordinator` - Health Coordinator
         * * `headofhouse` - Head of House
         * * `propertymanager` - Property Manager
         * * `securityteam` - Security & Safety Team
         * * `viewer` - View Only
         * * `housetutor` - House Tutor
         * * `nightguard` - Night Guard
         * * `idpadmin` - IDP Admin
         */
        role?: (
          | 'academicadmin'
          | 'academichod'
          | 'admin'
          | 'behaviourmanagement'
          | 'boardingdirector'
          | 'campusheadteam'
          | 'headofhouse'
          | 'healthcoordinator'
          | 'housetutor'
          | 'idpadmin'
          | 'infoboardeditor'
          | 'nightguard'
          | 'propertymanager'
          | 'securityteam'
          | 'teacher'
          | 'viewer'
        )[]
        /**
         * * `done` - Done
         * * `done_manually` - Done manually
         * * `overdue` - Overdue
         * * `close_deadline` - Close deadline
         * * `unscheduled` - Unscheduled
         */
        status?: (
          | 'close_deadline'
          | 'done'
          | 'done_manually'
          | 'overdue'
          | 'unscheduled'
        )[]
        /** @format uuid */
        team?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IncompleteTaskCount, T>, any>({
        path: `/tasks-management/tasks/count-incomplete/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksOverdueCountRetrieve
     * @request GET:/tasks-management/tasks/overdue-count/
     */ tasksManagementTasksOverdueCountRetrieve: <T extends FetchKeys<Task>>(
      query?: {
        assignee?: string[]
        created_by?: string[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        has_subtasks?: boolean
        is_cloned?: boolean
        is_recurring?: boolean
        /**
         * Ordering
         *
         * * `is_done` - Is done
         * * `-is_done` - Is done (descending)
         * * `deadline` - Deadline
         * * `-deadline` - Deadline (descending)
         * * `assignee` - Assignee
         * * `-assignee` - Assignee (descending)
         * * `team` - Team
         * * `-team` - Team (descending)
         */
        ordering?: (
          | '-assignee'
          | '-deadline'
          | '-is_done'
          | '-team'
          | 'assignee'
          | 'deadline'
          | 'is_done'
          | 'team'
        )[]
        /**
         * * `admin` - Admin
         * * `teacher` - Artisan
         * * `academichod` - Academic HOD
         * * `academicadmin` - Academic Admin
         * * `behaviourmanagement` - Behaviour Management
         * * `boardingdirector` - Boarding Director
         * * `campusheadteam` - Campus Head Team
         * * `infoboardeditor` - Infoboard Editor
         * * `healthcoordinator` - Health Coordinator
         * * `headofhouse` - Head of House
         * * `propertymanager` - Property Manager
         * * `securityteam` - Security & Safety Team
         * * `viewer` - View Only
         * * `housetutor` - House Tutor
         * * `nightguard` - Night Guard
         * * `idpadmin` - IDP Admin
         */
        role?: (
          | 'academicadmin'
          | 'academichod'
          | 'admin'
          | 'behaviourmanagement'
          | 'boardingdirector'
          | 'campusheadteam'
          | 'headofhouse'
          | 'healthcoordinator'
          | 'housetutor'
          | 'idpadmin'
          | 'infoboardeditor'
          | 'nightguard'
          | 'propertymanager'
          | 'securityteam'
          | 'teacher'
          | 'viewer'
        )[]
        /**
         * * `done` - Done
         * * `done_manually` - Done manually
         * * `overdue` - Overdue
         * * `close_deadline` - Close deadline
         * * `unscheduled` - Unscheduled
         */
        status?: (
          | 'close_deadline'
          | 'done'
          | 'done_manually'
          | 'overdue'
          | 'unscheduled'
        )[]
        /** @format uuid */
        team?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/overdue-count/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  teacher = {
    /**
     * No description
     *
     * @tags teacher
     * @name TeacherActivitiesLessonList
     * @request GET:/teacher/activities/lesson/
     */
    teacherActivitiesLessonList: <
      T extends FetchKeys<PaginatedTeacherTimetableListLessonList>
    >(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTeacherTimetableListLessonList, T>, any>({
        path: `/teacher/activities/lesson/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags teacher
     * @name TeacherActivitiesLessonRetrieve
     * @request GET:/teacher/activities/lesson/{id}/
     */ teacherActivitiesLessonRetrieve: <
      T extends FetchKeys<TeacherTimetableLesson>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TeacherTimetableLesson, T>, any>({
        path: `/teacher/activities/lesson/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  teams = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags teams
     * @name TeamsList
     * @request GET:/teams/
     */
    teamsList: <T extends FetchKeys<PaginatedTeamList>>(
      query?: {
        house?: number
        /** @format uuid */
        id?: string
        include_in_boarding_rota?: boolean
        /**
         * * `boarding_director` - Boarding Director
         * * `campus_head` - Campus Head
         * * `deputy_campus_head_main` - Deputy Campus Head (Main)
         * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
         * * `head_of_house` - Head of House
         * * `house_tutor` - House Tutor
         */
        members__rota_role?:
          | 'boarding_director'
          | 'campus_head'
          | 'deputy_campus_head_main'
          | 'deputy_campus_head_senior'
          | 'head_of_house'
          | 'house_tutor'
        /** @format uuid */
        members__user?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTeamList, T>, any>({
        path: `/teams/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags teams
     * @name TeamsCreate
     * @request POST:/teams/
     */ teamsCreate: <T extends FetchKeys<Team>>(
      data: TeamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Team, T>, any>({
        path: `/teams/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags teams
     * @name TeamsRetrieve
     * @request GET:/teams/{id}/
     */ teamsRetrieve: <T extends FetchKeys<Team>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Team, T>, any>({
        path: `/teams/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags teams
     * @name TeamsUpdate
     * @request PUT:/teams/{id}/
     */ teamsUpdate: <T extends FetchKeys<Team>>(
      id: string,
      data: TeamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Team, T>, any>({
        path: `/teams/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags teams
     * @name TeamsPartialUpdate
     * @request PATCH:/teams/{id}/
     */ teamsPartialUpdate: <T extends FetchKeys<Team>>(
      id: string,
      data: PatchedTeamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Team, T>, any>({
        path: `/teams/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags teams
     * @name TeamsDestroy
     * @request DELETE:/teams/{id}/
     */ teamsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/teams/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags teams
     * @name TeamsAvailableFiltersRetrieve
     * @request GET:/teams/available-filters/
     */ teamsAvailableFiltersRetrieve: <T extends FetchKeys<Team>>(
      query?: {
        house?: number
        /** @format uuid */
        id?: string
        include_in_boarding_rota?: boolean
        /**
         * * `boarding_director` - Boarding Director
         * * `campus_head` - Campus Head
         * * `deputy_campus_head_main` - Deputy Campus Head (Main)
         * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
         * * `head_of_house` - Head of House
         * * `house_tutor` - House Tutor
         */
        members__rota_role?:
          | 'boarding_director'
          | 'campus_head'
          | 'deputy_campus_head_main'
          | 'deputy_campus_head_senior'
          | 'head_of_house'
          | 'house_tutor'
        /** @format uuid */
        members__user?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Team, T>, any>({
        path: `/teams/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags teams
     * @name TeamsChoicesList
     * @request GET:/teams/choices/
     */ teamsChoicesList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        house?: number
        /** @format uuid */
        id?: string
        include_in_boarding_rota?: boolean
        /**
         * * `boarding_director` - Boarding Director
         * * `campus_head` - Campus Head
         * * `deputy_campus_head_main` - Deputy Campus Head (Main)
         * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
         * * `head_of_house` - Head of House
         * * `house_tutor` - House Tutor
         */
        members__rota_role?:
          | 'boarding_director'
          | 'campus_head'
          | 'deputy_campus_head_main'
          | 'deputy_campus_head_senior'
          | 'head_of_house'
          | 'house_tutor'
        /** @format uuid */
        members__user?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/teams/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags teams
     * @name TeamsRotaRolesChoicesList
     * @request GET:/teams/rota-roles-choices/
     */ teamsRotaRolesChoicesList: <T extends FetchKeys<RotaRoleChoice[]>>(
      query?: {
        house?: number
        /** @format uuid */
        id?: string
        include_in_boarding_rota?: boolean
        /**
         * * `boarding_director` - Boarding Director
         * * `campus_head` - Campus Head
         * * `deputy_campus_head_main` - Deputy Campus Head (Main)
         * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
         * * `head_of_house` - Head of House
         * * `house_tutor` - House Tutor
         */
        members__rota_role?:
          | 'boarding_director'
          | 'campus_head'
          | 'deputy_campus_head_main'
          | 'deputy_campus_head_senior'
          | 'head_of_house'
          | 'house_tutor'
        /** @format uuid */
        members__user?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaRoleChoice[], T>, any>({
        path: `/teams/rota-roles-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags teams
     * @name TeamsStaffListList
     * @request GET:/teams/staff-list/
     */ teamsStaffListList: <T extends FetchKeys<StaffMember[]>>(
      query?: {
        house?: number
        /** @format uuid */
        id?: string
        include_in_boarding_rota?: boolean
        /**
         * * `boarding_director` - Boarding Director
         * * `campus_head` - Campus Head
         * * `deputy_campus_head_main` - Deputy Campus Head (Main)
         * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
         * * `head_of_house` - Head of House
         * * `house_tutor` - House Tutor
         */
        members__rota_role?:
          | 'boarding_director'
          | 'campus_head'
          | 'deputy_campus_head_main'
          | 'deputy_campus_head_senior'
          | 'head_of_house'
          | 'house_tutor'
        /** @format uuid */
        members__user?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StaffMember[], T>, any>({
        path: `/teams/staff-list/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  timetable = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableList
     * @request GET:/timetable/
     */
    timetableList: <T extends FetchKeys<PaginatedTimetableList>>(
      query?: {
        /** @format uuid */
        idp_plans__idp_plan__student?: string
        is_hidden?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTimetableList, T>, any>({
        path: `/timetable/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableCreate
     * @request POST:/timetable/
     */ timetableCreate: <T extends FetchKeys<Timetable>>(
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableRetrieve
     * @request GET:/timetable/{id}/
     */ timetableRetrieve: <T extends FetchKeys<TimetableRetrieve>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableRetrieve, T>, any>({
        path: `/timetable/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableUpdate
     * @request PUT:/timetable/{id}/
     */ timetableUpdate: <T extends FetchKeys<Timetable>>(
      id: number,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetablePartialUpdate
     * @request PATCH:/timetable/{id}/
     */ timetablePartialUpdate: <T extends FetchKeys<Timetable>>(
      id: number,
      data: PatchedTimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableDestroy
     * @request DELETE:/timetable/{id}/
     */ timetableDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/timetable/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableAutoimportConfigPartialUpdate
     * @request PATCH:/timetable/{id}/autoimport-config/
     */ timetableAutoimportConfigPartialUpdate: <
      T extends FetchKeys<TimetableAutoImportConfigUpdate>
    >(
      id: number,
      data: PatchedTimetableAutoImportConfigUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<TimetableAutoImportConfigUpdate, T>,
        MessageResponseSchema
      >({
        path: `/timetable/${id}/autoimport-config/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableBusyInSlotCreate
     * @request POST:/timetable/{id}/busy-in-slot/
     */ timetableBusyInSlotCreate: <T extends FetchKeys<BusyInSlotResponse>>(
      id: number,
      data: BusyInSlotRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BusyInSlotResponse, T>, any>({
        path: `/timetable/${id}/busy-in-slot/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags timetable
     * @name TimetableChangesList
     * @request GET:/timetable/{id}/changes/
     */ timetableChangesList: <T extends FetchKeys<PaginatedLogEntryList>>(
      id: number,
      query?: {
        is_imported?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedLogEntryList, T>, any>({
        path: `/timetable/${id}/changes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableCollectPotentialOptimizationsCreate
     * @request POST:/timetable/{id}/collect-potential-optimizations/
     */ timetableCollectPotentialOptimizationsCreate: <
      T extends FetchKeys<Timetable>
    >(
      id: number,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/collect-potential-optimizations/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableConsistencyRetrieve
     * @request GET:/timetable/{id}/consistency/
     */ timetableConsistencyRetrieve: <T extends FetchKeys<Timetable>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/consistency/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableFilterOptionsRetrieve
     * @request GET:/timetable/{id}/filter-options/
     */ timetableFilterOptionsRetrieve: <T extends FetchKeys<Timetable>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/filter-options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableFreeSlotsRetrieve
     * @request GET:/timetable/{id}/free-slots/
     */ timetableFreeSlotsRetrieve: <T extends FetchKeys<FreeSlot>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FreeSlot, T>, any>({
        path: `/timetable/${id}/free-slots/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags timetable
     * @name TimetablePreviewCreate
     * @request POST:/timetable/{id}/preview/
     */ timetablePreviewCreate: <T extends FetchKeys<DraftActivityList>>(
      id: number,
      data: DraftActivityListRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityList, T>, any>({
        path: `/timetable/${id}/preview/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableStatsRetrieve
     * @request GET:/timetable/{id}/stats/
     */ timetableStatsRetrieve: <T extends FetchKeys<TimetableStats>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableStats, T>, any>({
        path: `/timetable/${id}/stats/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableTogglePreviewForTeachersCreate
     * @request POST:/timetable/{id}/toggle-preview-for-teachers/
     */ timetableTogglePreviewForTeachersCreate: <
      T extends FetchKeys<Timetable>
    >(
      id: number,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/toggle-preview-for-teachers/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableCopyTalentEnrichmentCreate
     * @request POST:/timetable/copy-talent-enrichment/
     */ timetableCopyTalentEnrichmentCreate: <
      T extends FetchKeys<CopyTalentEnrichment>
    >(
      data: CopyTalentEnrichmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CopyTalentEnrichment, T>, any>({
        path: `/timetable/copy-talent-enrichment/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags timetable
     * @name TimetableCurrentLessonsList
     * @request GET:/timetable/current-lessons/
     */ timetableCurrentLessonsList: <T extends FetchKeys<CurrentLesson[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CurrentLesson[], T>, any>({
        path: `/timetable/current-lessons/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags timetable
     * @name TimetableSourceIdpRetrieve
     * @request GET:/timetable/source-idp/{id}/
     */ timetableSourceIdpRetrieve: <T extends FetchKeys<SourceIDPPlan>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SourceIDPPlan, T>, any>({
        path: `/timetable/source-idp/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags timetable
     * @name TimetableSourceIdpRetrieve2
     * @request GET:/timetable/source-idp/{studentId}/{timetableId}/
     */ timetableSourceIdpRetrieve2: <T extends FetchKeys<SourceIDPPlan>>(
      studentId: string,
      timetableId: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SourceIDPPlan, T>, any>({
        path: `/timetable/source-idp/${studentId}/${timetableId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  transcriptsOfGrades = {
    /**
     * No description
     *
     * @tags transcripts-of-grades
     * @name TranscriptsOfGradesList
     * @request GET:/transcripts-of-grades/
     */
    transcriptsOfGradesList: <T extends FetchKeys<TranscriptOfGradesList[]>>(
      query: {
        /**
         * A UUID string indetifying the student who transcripts should be returned for.
         * @format uuid
         */
        student: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TranscriptOfGradesList[], T>, any>({
        path: `/transcripts-of-grades/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags transcripts-of-grades
     * @name TranscriptsOfGradesCreate
     * @request POST:/transcripts-of-grades/
     */ transcriptsOfGradesCreate: <T extends FetchKeys<TranscriptOfGrades>>(
      data: TranscriptOfGradesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TranscriptOfGrades, T>, any>({
        path: `/transcripts-of-grades/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags transcripts-of-grades
     * @name TranscriptsOfGradesRetrieve
     * @request GET:/transcripts-of-grades/{id}/
     */ transcriptsOfGradesRetrieve: <T extends FetchKeys<TranscriptOfGrades>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TranscriptOfGrades, T>, any>({
        path: `/transcripts-of-grades/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags transcripts-of-grades
     * @name TranscriptsOfGradesUpdate
     * @request PUT:/transcripts-of-grades/{id}/
     */ transcriptsOfGradesUpdate: <
      T extends FetchKeys<TranscriptOfGradesUpdate>
    >(
      id: string,
      data: TranscriptOfGradesUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TranscriptOfGradesUpdate, T>, any>({
        path: `/transcripts-of-grades/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags transcripts-of-grades
     * @name TranscriptsOfGradesDestroy
     * @request DELETE:/transcripts-of-grades/{id}/
     */ transcriptsOfGradesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/transcripts-of-grades/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags transcripts-of-grades
     * @name TranscriptsOfGradesCoursesList
     * @request GET:/transcripts-of-grades/{id}/courses/
     */ transcriptsOfGradesCoursesList: <
      T extends FetchKeys<TranscriptOfGradesItemCourse[]>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TranscriptOfGradesItemCourse[], T>, any>({
        path: `/transcripts-of-grades/${id}/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  userPreferences = {}
  userTimetable = {
    /**
     * @description [WARNING] AdminTimetableViewSet inherits from this!
     *
     * @tags user-timetable
     * @name UserTimetableList
     * @request GET:/user-timetable/
     */
    userTimetableList: <T extends FetchKeys<UserTimetable[]>>(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        students?: string[]
        teachers?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<UserTimetable[], T>, any>({
        path: `/user-timetable/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description [WARNING] AdminTimetableViewSet inherits from this!
     *
     * @tags user-timetable
     * @name UserTimetablePdfRetrieve
     * @request GET:/user-timetable/pdf/
     */ userTimetablePdfRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/user-timetable/pdf/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
  }
  users = {
    /**
     * No description
     *
     * @tags users
     * @name UsersAuthLoginCreate
     * @request POST:/users/auth/login/
     * @secure
     */
    usersAuthLoginCreate: <T extends FetchKeys<TwoFactorLogin>>(
      data: AuthTokenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TwoFactorLogin, T>, any>({
        path: `/users/auth/login/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersAuthLoginValidateCodeCreate
     * @request POST:/users/auth/login/validate-code/
     * @secure
     */ usersAuthLoginValidateCodeCreate: <T extends FetchKeys<LoginResponse>>(
      data: ValidateCodeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LoginResponse, T>, any>({
        path: `/users/auth/login/validate-code/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersAuthLoginValidateLinkCreate
     * @request POST:/users/auth/login/validate-link/
     * @secure
     */ usersAuthLoginValidateLinkCreate: <
      T extends FetchKeys<LoginLinkResponse>
    >(
      data: ValidateLoginLinkRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LoginLinkResponse, T>, any>({
        path: `/users/auth/login/validate-link/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersMeRetrieve
     * @request GET:/users/me/
     */ usersMeRetrieve: <T extends FetchKeys<User>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<User, T>, any>({
        path: `/users/me/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  ws = {
    /**
     * No description
     *
     * @tags ws
     * @name WsAuthTokenRetrieve
     * @request GET:/ws/auth/token/
     */
    wsAuthTokenRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/ws/auth/token/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
  }
}
