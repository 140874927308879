import { useWebSocket, type UseWebSocketOptions } from '@vueuse/core'

class WebSocketService {
  baseUrl: string

  constructor({ baseUrl }: { baseUrl: string }) {
    this.baseUrl = baseUrl
  }

  notifications(options?: UseWebSocketOptions) {
    return this.request('', options)
  }

  timetable(timetableId: number, options?: UseWebSocketOptions) {
    return this.request(`timetable/${timetableId}/`, options)
  }

  private request(url: string, options?: UseWebSocketOptions) {
    return useWebSocket(
      () =>
        `${this.baseUrl}/${url}?token=${window.localStorage.getItem(
          'websocketToken'
        )}`,
      {
        autoReconnect: {
          retries: 10,
          delay: 1000,
          onFailed() {
            console.log('Failed to connect WebSocket after 10 retries')
          }
        },
        immediate: true,
        ...options
      }
    )
  }
}

export const websocketService = new WebSocketService({
  baseUrl: import.meta.env.VITE_WS_URL
})
