import {
  useBroadcastChannel,
  type UseBroadcastChannelReturn
} from '@vueuse/core'
import { onMounted, onUnmounted } from 'vue'

type BroadcastEventData = {
  type: string
}

const CHANNEL_INSTANCES: Record<
  string,
  UseBroadcastChannelReturn<BroadcastEventData, BroadcastEventData>
> = {}

export const useMultiTabBroadcast = <
  T extends BroadcastEventData,
  U extends BroadcastEventData
>(
  {
    channelName,
    onEvent
  }: {
    channelName: string
    onEvent?: (event: MessageEvent<T>) => void
  } = { channelName: 'main' }
) => {
  let channelInstance = CHANNEL_INSTANCES[
    channelName
  ] as unknown as UseBroadcastChannelReturn<T, U>

  if (!channelInstance) {
    channelInstance = useBroadcastChannel<T, U>({
      name: channelName
    })
    CHANNEL_INSTANCES[channelName] =
      channelInstance as unknown as UseBroadcastChannelReturn<
        BroadcastEventData,
        BroadcastEventData
      >
  }

  onMounted(() => {
    if (!onEvent) return
    channelInstance.channel.value?.addEventListener('message', onEvent)
  })

  onUnmounted(() => {
    if (!onEvent) return
    channelInstance.channel.value?.removeEventListener('message', onEvent)
  })

  return {
    data: channelInstance.data,
    sendBroadcastEvent: channelInstance.post
  }
}
