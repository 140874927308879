import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

const teEnrolmentChildrenRoutes = [
  {
    path: '',
    name: 'te-enrolment-list' as const,
    component: () => import('@/views/T&EEnrolment/EnrolmentsListView.vue'),
    meta: {
      title: 'T&E enrolments list',
      tab: 'administration'
      // permissions: ['houses.change_house'] // TODO: set permissions
    }
  },
  {
    path: 'add',
    name: 'te-enrolment-add' as const,
    component: () => import('@/views/T&EEnrolment/EntrolmentView.vue'),
    meta: {
      title: 'Create T&E enrolment',
      tab: 'administration'
      // permissions: ['houses.add_house'] // TODO: set permissions
    }
  },
  {
    path: ':enrolmentId',
    name: 'te-enrolment-edit' as const,
    component: () => import('@/views/T&EEnrolment/EntrolmentView.vue'),
    meta: {
      title: 'Edit T&E enrolment',
      tab: 'administration'
      // permissions: ['houses.change_house'] // TODO: set permissions
    }
  }
] satisfies Route[]

export const teEnrolmentRoutes = [
  {
    path: 'te-enrolment',
    name: 'te-enrolment' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'T&E enrolment',
      icon: 'solution',
      tab: 'administration'
    },
    redirect: () => getFirstPossibleRoute(teEnrolmentChildrenRoutes),
    children: teEnrolmentChildrenRoutes
  }
] satisfies Route[]
