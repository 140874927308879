import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

import { artisansAndAdminsRoutes } from './artisans-and-admins'
import { billingReportsRoutes } from './billing-reports'
import { diningHallRoutes } from './dining-hall'
import { doctorsRoutes } from './doctors'
import { documentForAppsRoutes } from './documents-for-apps'
import { examPlannerRoutes } from './exam-planner'
import { generatorRoutes } from './generator/generator'
import { housesRoutes } from './houses'
import { infoboardsRoutes } from './infoboards'
import { settingsRoutes } from './settings'
import { teEnrolmentRoutes } from './t-e-enrolment'
import { teamsRoutes } from './teams'

export const administrationChildrenRoutes = [
  ...generatorRoutes,
  ...artisansAndAdminsRoutes,
  ...housesRoutes,
  ...teamsRoutes,
  ...doctorsRoutes,
  ...examPlannerRoutes,
  ...teEnrolmentRoutes,
  ...documentForAppsRoutes,
  ...diningHallRoutes,
  ...billingReportsRoutes,
  ...infoboardsRoutes,
  ...settingsRoutes
] satisfies Route[]

export const administrationRoutes = [
  {
    path: '',
    name: 'administration' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      tab: 'administration',
      title: 'Administration'
    },
    redirect: () => getFirstPossibleRoute(administrationChildrenRoutes),
    children: administrationChildrenRoutes
  }
] satisfies Route[]
