<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view v-if="$route.meta.public" />
      <div v-else>
        <transition name="fade-up">
          <router-view v-if="resourcesLoaded" />
          <Loader v-else />
        </transition>
      </div>
    </a-config-provider>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, watch, provide } from 'vue'

import { useBoardingRotaNotification } from '@/composables/useBoardingRotaNotification'
import { useDelphi } from '@/composables/useDelphi/useDelphi'
import { useModal } from '@/composables/useModal'
import { useNotificationsWebsocket } from '@/composables/useNotificationsWebsocket/useNotificationsWebsocket'
import { usePermissions } from '@/composables/usePermissions'
import VersionChecker from '@/mixins/VersionChecker.vue'
import { useWsAuthTokenRetrieve } from '@/queries/useWebSocket'
import store from '@/store'

const markerWidget = ref()

provide('markerWidget', markerWidget)

let modalVisibilityObserver

const { isLoggedIn, isStaff, isSuperUser, userInfo, userProfiles } =
  usePermissions()

const { checkStatus, setTaskId, createInfoNotification } =
  useBoardingRotaNotification()

const { addModalVisibilityListener, removeModalVisibilityListener } = useModal()

const { data: wsAuthTokenData, dataUpdatedAt: wsAuthTokenDataUpdatedAt } =
  useWsAuthTokenRetrieve({
    enabled: isLoggedIn
  })

const { goToTimetableDetails, setDelphiSession } = useDelphi()

const { mutate: regenerateTaeGenerationAfterFail } = useMutation(
  ({ taskId, timetableId }) => {
    httpService.planner.plannerTaeRegenerateCreate(taskId, timetableId)
  }
)

const { open } = useNotificationsWebsocket({
  goToTimetableDetails,
  setDelphiSession,
  regenerateTaeGenerationAfterFail
})

const resetMarkerWidget = () => {
  markerWidget.value?.unload()
  markerWidget.value = undefined
}

const initMarkerWidget = async () => {
  const markerId = import.meta.env.VITE_MARKERIO_ID

  if (!markerId || !userInfo.value.can_report_bug) resetMarkerWidget()
  else if (!markerWidget.value) {
    try {
      const markerSDK = (await import('@marker.io/browser')).default
      markerWidget.value = await markerSDK.loadWidget({
        project: markerId
      })
    } catch {
      resetMarkerWidget()
    }
  }
}

const setSafeMode = () => {
  if (isSuperUser.value && userProfiles.value.length >= 1) {
    window.localStorage.setItem('safeModeDisabled', true)
    store.commit('setSafeModeDisabled', true)
    return
  }

  if (isStaff.value && userProfiles.value.length > 1) {
    const safeModeDisabled =
      window.localStorage.getItem('safeModeDisabled') === 'true'

    window.localStorage.setItem('safeModeDisabled', safeModeDisabled)
    store.commit('setSafeModeDisabled', safeModeDisabled)
    return
  }

  window.localStorage.removeItem('safeModeDisabled')
}

watch(
  () => userInfo.value,
  value => {
    if (value.id) setSafeMode()
    initMarkerWidget()
  }
)

watch(
  () => wsAuthTokenDataUpdatedAt.value,
  () => {
    if (!wsAuthTokenData.value) return
    window.localStorage.setItem('websocketToken', wsAuthTokenData.value.token)
    open()
  }
)

onMounted(() => {
  const boardingRotaRequestId = localStorage.getItem('boardingRotaRequestId')

  if (boardingRotaRequestId) {
    setTaskId(Number(boardingRotaRequestId))
    createInfoNotification()
    checkStatus()
  }

  modalVisibilityObserver = addModalVisibilityListener()

  if (isLoggedIn.value) store.dispatch('fetchCommonData')
})

onUnmounted(() => {
  removeModalVisibilityListener(modalVisibilityObserver)
  resetMarkerWidget()
})
</script>

<script>
// eslint-disable-next-line import/order -- this rule is bugged when composition API is used simultaneously with Option API
import moment from 'moment'
import 'moment/locale/en-gb'
// eslint-disable-next-line import/order
import en_GB from 'ant-design-vue/lib/locale-provider/en_GB'
// eslint-disable-next-line import/order
import { every } from 'lodash'

// eslint-disable-next-line import/order
import Loader from '@/components/common/Loader.vue'

// eslint-disable-next-line import/order
import { useMutation } from '@tanstack/vue-query'

import { httpService } from './api/http.service'

moment.locale('en-gb')

export default {
  components: {
    Loader
  },
  mixins: [VersionChecker],
  data() {
    return {
      locale: en_GB
    }
  },
  computed: {
    resourcesLoaded() {
      return every([
        this.userInfo.id,
        this.$store.state.aims.length,
        this.$store.state.grades.length,
        this.$store.state.quarters.length,
        this.$store.state.semesters.length,
        this.$store.state.years.length
      ])
    }
  },
  async created() {
    if (window.localStorage.getItem('token')) {
      await this.$store.dispatch('fetchUserInfo')
    }
  }
}
</script>
